import React, { Component } from "react";
import downloadAll from '../assets/images/DownloadAll.png';
import downloadSpinner from '../assets/images/downloadSpinner.gif';
import {CSVLink} from 'react-csv';
import {getPriceListReport} from "../_services/priceList.service";
import { getFilteredData } from "../_services/priceList.service";
export default class ExportPriceListReport extends Component {

    constructor(props) {
        super(props);
            this.state = {
                priceList: [],
                showbutton:true,
                 headers :[
                  { label: 'Item Number', key: 'itemNumber' },
                  { label: 'Vendor Part Number', key: 'vendorPart' },
                  { label: 'Customer Part Number', key: 'customerPartNumber' },
                  { label: 'Description', key: 'description' },
                  { label: 'Page', key: 'flyerPage' },
                  { label: 'Case/Pack', key: 'sellCase' },
                  { label: 'PackDesc', key: 'sellUms' },
                  { label: 'List Price', key: 'listPrice' },                
                  { label: 'Price', key: 'sellPrice' },
                  { label: 'Pending Price', key: 'pendPrice' },
                  { label: 'Pending Effective Date', key: 'effectiveDate' }, 
                  { label: 'Direct', key: 'directValue' } ,      
                ]
            }
    this.csvLink = React.createRef();
        }
        componentDidMount() {
      
        }
        fetchData = () =>{
          this.setState({ showbutton: false });

            getPriceListReport()
                .then((data) => {   
                    this.setState({ priceList: getFilteredData(data)},
              ()=>{
                setTimeout(() => {
                  this.csvLink.current.link.click();
                }, 50);
                this.setState({ showbutton: true });

              })    
            });
          }
        render() {
            return (
                        <div>
        {
          this.state.showbutton?
          <button style={{ border: "none" }} onClick={this.fetchData}>
          <img
            src={downloadAll}
            title="Download All Price List Items."
            alt="Download Price List"
          />
        </button> 
        : <img src={downloadSpinner} title="Please wait."  alt="Downloading Report" />
        }
        
        <CSVLink
          data={this.state.priceList} headers={this.state.headers}
          filename="All-PriceList-Report.csv"
          className="hidden"
          ref={this.csvLink}
          target="_blank"
        />
                        {/* { this.state.priceList.length > 0 ? 
                               <CSVLink ref="csv" data={this.state.priceList} filename={'All-PriceList-Report.csv'}>
                                    <img src={downloadAll} title="Download All Price List Items."  alt="Download Report" />
                             </CSVLink>
                             : undefined}  */}
                        </div>
            );
        }
    }