import React, { Component } from "react";
import appConstants from "../../_services/applicationConstant";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  getPurchasingDetails,
  getFilteredData,
  getPurchasingTotalDetails,
  getTotalFilteredData
} from "../../_services/purchasing.service";
import downloadbutton from "../../assets/images/downloadbutton.gif";
import { CSVLink } from "react-csv";
import user from '../../models/User';
import { decryptData } from '../../Utils';


class PurchasingDetail extends Component {
  constructor(props) {
    //user.toUser = JSON.parse(localStorage.getItem('UserMenu'))
    const salt = process.env.SALT || process.env.REACT_APP_KEY;
    const originalData = decryptData(localStorage.getItem("UserMenu"), salt);
    user.toUser = originalData;

    super(props);
    this.bottomData=[{ITEMNUMBER:'',
    Customer_Part_NUmber:'',
    Desc:'TOTAL',
    Quntity:this.props.currencyUnit,
    Purchases:this.props.currency
  }];
    this.state = {
      defaultColDef: { resizable: true, sortable: true },
      bottomOptions,
      totalExportHeaders :[
        { label: 'ITEM #', key: 'ITEMNUMBER' },
        { label: 'CUSTOMER PART #', key: 'CUSTOMERPARTNUMBER' },
        { label: 'DESCRIPTION', key: 'DESCRIPTION' },
        { label: 'JANUARY UNITS', key: 'JANUARYUNITSALES' },
        { label: 'JANUARY SALES', key: 'JANUARYSALES' },
        { label: 'FEBRUARY UNITS', key: 'FEBRUARYUNITSALES' },
        { label: 'FEBRUARY SALES', key: 'FEBRUARYSALES' },
        { label: 'MARCH UNITS', key: 'MARCHUNITSALES' },
        { label: 'MARCH SALES', key: 'MARCHSALES' },
        { label: 'APRIL UNITS', key: 'APRILUNITSALES' },
        { label: 'APRIL SALES', key: 'APRILSALES' },    
        { label: 'MAY UNITS', key: 'MAYUNITSALES' },
        { label: 'MAY SALES', key: 'MAYSALES' },  
        { label: 'JUNE UNITS', key: 'JUNEUNITSALES' },
        { label: 'JUNE SALES', key: 'JUNESALES' },
        { label: 'JULY UNITS', key: 'JULYUNITSALES' },
        { label: 'JULY SALES', key: 'JULYSALES' },  
        { label: 'AUGUST UNITS', key: 'AUGUSTUNITSALES' },
        { label: 'AUGUST SALES', key: 'AUGUSTSALES' },
        { label: 'SEPTEMBER UNITS', key: 'SEPTEMBERUNITSALES' },
        { label: 'SEPTEMBER SALES', key: 'SEPTEMBERSALES' },  
        { label: 'OCTOBER UNITS', key: 'OCTOBERUNITSALES' },
        { label: 'OCTOBER SALES', key: 'OCTOBERSALES' },   
        { label: 'OCTOBER UNITS', key: 'NOVEMBERUNITSALES' },
        { label: 'OCTOBER SALES', key: 'NOVEMBERSALES' }, 
        { label: 'OCTOBER UNITS', key: 'DECEMBERUNITSALES' },
        { label: 'OCTOBER SALES', key: 'DECEMBERSALES' },    
        { label: 'TOTAL UNITS', key: 'TOTALUNITSALES' },
        { label: 'TOTAL SALES', key: 'TOTALSALES' },  
      ],
      showCSV: true,
      columnDefs: [
        {
          headerName: "Item Number",
          field: "ITEMNUMBER",
          cellStyle: {
            textAlign: "center",
            borderRight: "0.1px solid lightgray",
          },
          resizable: true,
          width: 120,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Customer Part Number",
          cellStyle: {
            textAlign: "left ",
            borderRight: "0.1px solid lightgray",
          },
          field: "Customer_Part_NUmber",
          resizable: true,
          width: 150,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Item Description",
          cellStyle: {
            textAlign: "left",
            borderRight: "0.1px solid lightgray",
          },
          field: "Desc",
          resizable: true,
          width: 300,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },

        {
          headerName: "Quantity",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          type: "rightAligned",
          field: "Quntity",
          resizable: true,
          width: 120,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },

        {
          headerName: "Purchases",
          hide: user.toUser.CanViewInventoryPrices === "Y" ? false: true,
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "Purchases",
          type: "rightAligned",
          valueFormatter: currencyFormatter,
          resizable: true,
          width: 120,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
      ],
      bottomData: this.bottomData,
      rowData: [],
      exportData: [],
      totalExportData:[]
    };
    this.csvLink = React.createRef();
  }
  
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };

  componentDidMount() {
    if (user.toUser.CanViewInventoryPrices === "N"){
      this.setState({ totalExportHeaders :[
        { label: 'ITEM #', key: 'ITEMNUMBER' },
        { label: 'CUSTOMER PART #', key: 'CUSTOMERPARTNUMBER' },
        { label: 'DESCRIPTION', key: 'DESCRIPTION' },
        { label: 'JANUARY UNITS', key: 'JANUARYUNITSALES' },
        { label: 'FEBRUARY UNITS', key: 'FEBRUARYUNITSALES' },
        { label: 'MARCH UNITS', key: 'MARCHUNITSALES' },
        { label: 'APRIL UNITS', key: 'APRILUNITSALES' },
        { label: 'MAY UNITS', key: 'MAYUNITSALES' },
        { label: 'JUNE UNITS', key: 'JUNEUNITSALES' },
        { label: 'JULY UNITS', key: 'JULYUNITSALES' },
        { label: 'AUGUST UNITS', key: 'AUGUSTUNITSALES' },
        { label: 'SEPTEMBER UNITS', key: 'SEPTEMBERUNITSALES' },
        { label: 'OCTOBER UNITS', key: 'OCTOBERUNITSALES' },
        { label: 'OCTOBER UNITS', key: 'NOVEMBERUNITSALES' },
        { label: 'OCTOBER UNITS', key: 'DECEMBERUNITSALES' },
        { label: 'TOTAL UNITS', key: 'TOTALUNITSALES' },
      ],});
    }
    if(this.props.currencyUnit === '0'){
      this.setState({ rowData: [],showCSV: false });
      this.setState({
        exportData: []
      });
    }
    else
{    getPurchasingDetails(
      this.props.month,
      this.props.year,
      this.props.shipTo     
    ).then((data) => {
      if(data.length > 0){  
      this.setState({ rowData: data });
      this.setState({
        exportData: getFilteredData(data),
      });
    }
    });
}
  }
  fetchTotalDetailsData = () =>{
    getPurchasingTotalDetails(
      "Total",
      this.props.year,
      this.props.shipTo     
    ).then((data) => {
      if(data.length > 0){  
      this.setState({
        totalExportData: getTotalFilteredData(data), 
      },()=>{
        setTimeout(() => {
          this.csvLink.current.link.click();
        }, 50);
      }     
      );
    }
    });   
  }
  render() {
    const renderPurchaseDetailGrid = () => {
      return (
        <div
          className={`ag-theme-alpine ${
            appConstants.isR3App ? "gridMainR3" : "gridMain"
          }`}
          style={{ height: "90%" }}
        >
          <AgGridReact
            
            columnDefs={this.state.columnDefs}
            enableCellTextSelection={true}
            defaultColDef={this.state.defaultColDef}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            rowHeight="30"
            headerHeight="30"
            footerHeight="30"
          ></AgGridReact>
        <div style={{flex: 'none', height: '60px'}}>
          <AgGridReact
            rowData={this.state.bottomData}
            gridOptions={this.state.bottomOptions}
            columnDefs={this.state.columnDefs}
            headerHeight="0"
            rowStyle={{fontWeight: '900'}}
            onGridReady={this.onGridReady}
          />
        </div>
        </div>
      );
    };
    return (
      <div style={{ height: "100%" }}>
        <div className="row content" style={{ height: "98%" }}>
          <div className="col-sm-12" style={{ height: "98%" }}>
            <div className="row" ref="filterComponent">
              <div className="col-sm-4">
                <div
                  className={`${
                    appConstants.isR3App ? "filterBodyR3" : "filterBody"
                  }`}
                >
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="control-inline-label col-form-label ">
                        {this.props.month}
                      </label>
                      <label className="control-inline-label col-form-label ">
                        {this.props.year}
                      </label>
                      <label className="control-inline-label col-form-label ">
                        |
                      </label>
                      <label className="control-inline-label col-form-label ">
                        {this.props.shipToName}
                      </label>
                    </div>
                  </div>
                </div>
              </div>   
              {this.state.showCSV &&           
              <div className="col-sm-1" style={{ paddingTop: "0px" }}>
                <button style={{ border: "none" }} onClick={this.fetchTotalDetailsData}>
                <img
                  src={downloadbutton}
                  title="Download Purchasing Details List Items."
                  alt="Download Report"
                />
              </button>
              <CSVLink
                data={this.state.totalExportData}
                headers={this.state.totalExportHeaders}
                filename="PurchasingDetails.csv"
                className="hidden"
                ref={this.csvLink}
                target="_blank"
              />
              </div>             
  }
            </div>
            <div className="row" ref="gridComponent" style={{ height: "98%" }}>
              <div className="col-sm-12" style={{ height: "98%" }}>
                {renderPurchaseDetailGrid()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PurchasingDetail;
function currencyFormatter(params) {
  if(params.value !== undefined && params.value !== ""){
  if (parseFloat(params.value) < 0) return "-$" +  (parseFloat(params.value).toFixed(2) * -1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else return "$" +  (parseFloat(params.value).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return "";
}
const bottomOptions = {
  alignedGrids: [],
  defaultColDef: {
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100
  }
};
