import api from './apiUrl';
import {authHeader,encodedURL} from '../_helpers/auth-header'
export function getSearchTypes(isR3){
var searchTypes = [
    { value: "CP", label: 'Customer Part' },
    { value: "DS", label: 'Description' },    
];
if(isR3)
searchTypes.push( {value: "IT", label: 'R3 Item #'});
else
searchTypes.push( {value: "IT", label: 'Item #'});
searchTypes.push( { value: "VP", label: 'Vendor Part' } );
return searchTypes;   
}
var identityKey=localStorage.getItem('identityKey')

export function getPageNumbers(){
    var pageNumbers = [
        { value: "20", label: '20' },
        { value: "50", label: '50' },    
        { value: "100", label: '100' }   ,
        { value: "500", label: '500' }   ,
        { value: "1000", label: '1000' } ,
        { value: "5000", label: '5000' } ,
        { value: "10000", label: '10000' } ,
        { value: "15000", label: '15000' },
        { value: "32000", label: '32000' } 

        ];
   
    return pageNumbers;   
}

function currencyFormatter(currencyValue) {
    if (currencyValue !== undefined && currencyValue !== "" && currencyValue !== null && currencyValue !== '0' &&  currencyValue !== '0.00') {
      if (parseFloat(currencyValue) < 0) return "-$" +  (parseFloat(currencyValue).toFixed(2) * -1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      else return "$" +  (parseFloat(currencyValue).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return "$0.00";
}

function getDescription(desc)
{
    return  desc.replace(/"/g,'\""') ;
}
function getPackDescription(desc)
{
    return  "'"+desc+"'";
}
function dateFormatter(value) {
    if(value != null &&  value != "" && value != undefined){
    var d = value.split(" ");
    var dateValue = d[0];
    return dateValue;
    }
    else return "";
  }

export function getFilteredData(data,visiblePropList){
    let filteredData=[];  
    if(data.length > 0) {  
    data.map((item) => { 
        let filterObject = {};
        visiblePropList.forEach((prop) => (filterObject[prop] = ""));
            if (filterObject.hasOwnProperty("CustomerPartNumber"))
            filterObject.CustomerPartNumber	= item.CustomerPartNumber;
            if (filterObject.hasOwnProperty("DistributorPartnumber"))
            filterObject.DistributorPartnumber=	item.DistributorPartnumber;
            if (filterObject.hasOwnProperty("Warehouse"))
            filterObject.Warehouse=	item.Warehouse;
            if (filterObject.hasOwnProperty("StockNonstock"))
            filterObject.StockNonstock=	item.StockNonstock;
            if (filterObject.hasOwnProperty("PartDescription","PartDescription2"))
            filterObject.PartDescription= getDescription(item.PartDescription + " " + item.PartDescription2);
            if (filterObject.hasOwnProperty("CasePack"))
            filterObject.CasePack=	getPackDescription(item.CasePack);
            if (filterObject.hasOwnProperty("PackDesc"))
            filterObject.PackDesc =	getPackDescription(item.PackDesc);
            if (filterObject.hasOwnProperty("Price"))
            filterObject.Price=currencyFormatter(item.Price);
            if (filterObject.hasOwnProperty("QuantityOnHand"))
            filterObject.QuantityOnHand=item.QuantityOnHand;
            if (filterObject.hasOwnProperty("TotalProductValueOnHand"))
            filterObject.TotalProductValueOnHand=currencyFormatter(item.TotalProductValueOnHand);
            if (filterObject.hasOwnProperty("QuantityOnOrder"))
            filterObject.QuantityOnOrder=item.QuantityOnOrder;
            if (filterObject.hasOwnProperty("ScheduledDate"))
            filterObject.ScheduledDate=dateFormatter(item.ScheduledDate);
            if (filterObject.hasOwnProperty("DaysOnHand"))
            filterObject.DaysOnHand=item.DaysOnHand;
            if (filterObject.hasOwnProperty("VendorDaysLeadTime"))
            filterObject.VendorDaysLeadTime=item.VendorDaysLeadTime;
            if (filterObject.hasOwnProperty("MaximumOrderQty"))
            filterObject.MaximumOrderQty=item.MaximumOrderQty;
            
            filteredData.push(filterObject);
          })
    }
    else{
        let filterObject = {};
        visiblePropList.forEach((prop) => (filterObject[prop] = ""));
            if (filterObject.hasOwnProperty("CustomerPartNumber"))
            filterObject.CustomerPartNumber	= "";
            if (filterObject.hasOwnProperty("DistributorPartnumber"))
            filterObject.DistributorPartnumber=	"";
            if (filterObject.hasOwnProperty("Warehouse"))
            filterObject.Warehouse=	"";
            if (filterObject.hasOwnProperty("StockNonstock"))
            filterObject.StockNonstock= "";
            if (filterObject.hasOwnProperty("PartDescription","PartDescription2"))
            filterObject.PartDescription="";
            if (filterObject.hasOwnProperty("CasePack"))
            filterObject.CasePack=	"";
            if (filterObject.hasOwnProperty("PackDesc"))
            filterObject.PackDesc =	"";
            if (filterObject.hasOwnProperty("Price"))
            filterObject.Price="";
            if (filterObject.hasOwnProperty("QuantityOnHand"))
            filterObject.QuantityOnHand="";
            if (filterObject.hasOwnProperty("TotalProductValueOnHand"))
            filterObject.TotalProductValueOnHand="";
            if (filterObject.hasOwnProperty("QuantityOnOrder"))
            filterObject.QuantityOnOrder="";
            if (filterObject.hasOwnProperty("ScheduledDate"))
            filterObject.ScheduledDate="";
            if (filterObject.hasOwnProperty("DaysOnHand"))
            filterObject.DaysOnHand="";
            if (filterObject.hasOwnProperty("VendorDaysLeadTime"))
            filterObject.VendorDaysLeadTime="";
            if (filterObject.hasOwnProperty("MaximumOrderQty"))
            filterObject.MaximumOrderQty="";

            filteredData.push(filterObject);
        }
    return filteredData;   	
}
    

export function getVendors(companyNumber, jwtToken) {
    return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/inventory/vendors?companyNumber=${companyNumber}`)}`, {
        method: 'GET',
        headers:authHeader()
    }).then(response => response.json());
}

export function getInventoryItems(){
    let numberRows ='32000';
    let qualifier = 'contains';
    return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/inventory/records?identityKey=${identityKey.trim()}&companyNumber=${localStorage.getItem('companyNumber')}&customerNumber=${localStorage.getItem('customerNumber')}&loginTypeCode=${localStorage.getItem('loginTypeCode')}&numberRows=${numberRows}&qualifier=${qualifier}`)}`, {
        method: 'GET',
        headers: authHeader()
    }).then(response => response.json());
}

export function getInventoryData(searchText, vendor, searchType, pageCount, qualifier)

{
    var urlparam='&numberRows='+pageCount
    urlparam+='&qualifier='+qualifier
    if(vendor!=='0')
    {
        urlparam+='&vendor='+vendor
    }
    if(searchType!=='none')
    {
        urlparam+='&searchWordType='+searchType
    }
    if(searchText!=='')
    {
        urlparam+='&searchWord='+searchText
    }
    return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/inventory/records?identityKey=${identityKey.trim()}&companyNumber=${localStorage.getItem('companyNumber')}&customerNumber=${localStorage.getItem('customerNumber')}&loginTypeCode=${localStorage.getItem('loginTypeCode')}${urlparam}`)}`, {
        method: 'GET',
        headers:authHeader()
    }).then(response => response.json());
}
    
    
