import React, { Component } from "react";
import { styles } from "../assets/css/style.css";
import user from "../models/User";
import appConstants from "../_services/applicationConstant";
import { withRouter } from "react-router-dom";
import ESSModal from "../components/ESSModal.jsx"
import { decryptData } from '../Utils';
class NavigationSelfService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      isOpen: false,
    };
  }
  logout = (e) => {
    localStorage.clear();
    this.props.history.push({
      pathname: "/Login",
    });
  };
  handleClose = () => this.setState({ show: false });
  handle2ndButtonClick = () => {
    localStorage.clear();
    this.props.history.push({
      pathname: "/Login",
    });
  };
  
  render() {
    const menuClass = `dropdown-menu${this.state.isOpen ? " show" : ""}`;

   // user.toUser = JSON.parse(localStorage.getItem("UserMenu"));

  // alert(JSON.parse(localStorage.getItem("UserMenu")));

  let mkLocalData = localStorage.getItem('UserMenu');
    if(mkLocalData)
   {
   const salt = process.env.SALT || process.env.REACT_APP_KEY;
      const originalData = decryptData(localStorage.getItem("UserMenu"), salt);
      if(originalData !== null && originalData !== "" )
      {
        user.toUser = originalData;
   }
  }

  //   const salt = process.env.SALT || process.env.REACT_APP_KEY;
  //  // const originalData = decryptData(JSON.parse(localStorage.getItem("UserMenu")), salt);
  //   const originalData = decryptData(localStorage.getItem("UserMenu"), salt);
  //  user.toUser = originalData;

    const navStyle = appConstants.isR3App
      ? {
          fontSize: "12px",
          height: "30px",
          fontWeight: "bold",
          backgroundColor: "#0079c2",
        }
      : {
          fontSize: "12px",
          height: "30px",
          fontWeight: "bold",
          backgroundColor: "#79b91c",
        };
    const loginTypeCode = localStorage.getItem("loginTypeCode");
    if (user.toUser !== null) {
      return (
        <div>
          {this.state.show && (
            <ESSModal
              handle2ndButtonClick={this.handle2ndButtonClick}
              handleClose={this.handleClose}
              btn1Text="No"
              btn2Text="Yes"
              userMessage="Are you sure you want to logout?"
              isAlert="false"
              show={this.state.show}
            />
          )}
          <nav
            class={`navbar navbar-expand-sm bg-success navbar-dark ${
              appConstants.isR3App ? "r3" : ""
            }`}
          >
            <ul class="navbar-nav">
              <li class="nav-item active">
                <a class="nav-link" href="/Home">
                  Home
                </a>
              </li>
              <li
                className={`nav-item  ${
                  user.toUser.CanViewAR == "Y" ? "nav-show" : "nav-hide"
                }`}
              >
                <a class="nav-link" href="/AccountsPayable">
                  AP
                </a>
              </li>
              <li
                className={`nav-item ${
                  user.toUser.CanViewInventory == "Y" ? "nav-show" : "nav-hide"
                }`}
              >
                <a class="nav-link" href="/Inventory">
                  Inventory
                </a>
              </li>
              <li
                className={`nav-item ${
                  user.toUser.CanViewInventory == "Y" && loginTypeCode === "G"
                    ? "nav-show"
                    : "nav-hide"
                }`}
              >
                <a class="nav-link" href="/ConsolidatedInventory">
                  Consolidated Inventory
                </a>
              </li>
              <li
                className={`nav-item ${
                  user.toUser.CanViewOrders == "Y" ? "nav-show" : "nav-hide"
                }`}
              >
                <a class="nav-link" href="/DisplayOrder">
                  Display Orders
                </a>
              </li>
              <li
                className={`nav-item ${
                  user.toUser.CanViewCustomerPrices == "Y"
                    ? "nav-show"
                    : "nav-hide"
                }`}
              >
                <a class="nav-link" href="/CustomerPrice">
                  Custom Prices
                </a>
              </li>
              <li
                className={`nav-item ${
                  user.toUser.CanViewSales == "Y" ? "nav-show" : "nav-hide"
                }`}
              >
                <a class="nav-link" href="/Purchasing">
                  Purchasing
                </a>
              </li>
              <li
                className={`nav-item ${
                  (user.toUser.CanViewSalesAnalysis == "Y" || user.toUser.CanViewSales == "Y")
                    ? "nav-show"
                    : "nav-hide"
                }`}
              >
                <a class="nav-link" href="/PurchasingAnalysis">
                  Purchasing Analysis
                </a>
              </li>
              <li
                className={`nav-item ${
                  user.toUser.CanViewPriceList == "Y" ? "nav-show" : "nav-hide"
                }`}
              >
                <a class="nav-link" href="/PriceList">
                  Price List
                </a>
              </li>
              {/* <li
                className={`nav-item ${
                  user.toUser.CanViewSales == "Y" ? "nav-show" : "nav-hide"
                }`}
              >
                <a class="nav-link" href="#">
                  Item Tracking
                </a>
              </li> */}
              {/* <li
                className={`nav-item ${
                  user.toUser.CanViewSales == "Y" ? "nav-show" : "nav-hide"
                }`}
              >
                <a class="nav-link" href="#">
                  Item Forecast
                </a>
              </li> */}
              {/* <li
                className={`nav-item ${
                  user.toUser.CanViewOG == "Y" ? "nav-show" : "nav-hide"
                }`}
              >
                <a class="nav-link" href="#">
                  Order Guide
                </a>
              </li> */}
              <li class="nav-item dropdown">
                <a
                  class="nav-link"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ isOpen: !this.state.isOpen });
                  }}
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  My Account
                </a>
                <div className={menuClass} aria-labelledby="navbarDropdown">
                  <a class="dropdown-item" href="/changepassword">
                    Change Password
                  </a>
                </div>
              </li>
              <li
                className={`nav-item ${
                  loginTypeCode === "E" ? "nav-show" : "nav-hide"
                }`}
              >
                <a class="nav-link" href="/EmployeeListFromMenu">
                  Employee
                </a>
              </li>
              <li
                className={`nav-item ${
                  loginTypeCode === "G" || loginTypeCode === "E"
                    ? "nav-show"
                    : "nav-hide"
                }`}
              >
                <a class="nav-link" href="/CustomerListMenu">
                  Customer
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({ show: true });
                  }}
                >
                  Logout
                </a>
              </li>
            </ul>
          </nav>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default withRouter(NavigationSelfService);
