import React, { Component } from "react";
import { updateChangePassword } from "../_services/changePassword.service";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    };
  }

  searchClicked = () => {
    updateChangePassword(
      this.state.oldPassword.trim(),
      this.state.newPassword.trim()
    ).then((_data) => {
      this.clearClicked();
    });
  };

  clearClicked = () => {
    this.setState({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  inputHandler = (event) => {
    switch (event.target.name) {
      case "oldPassword":
        this.setState({ oldPassword: event.target.value });
        break;
      case "newPassword":
        this.setState({ newPassword: event.target.value });
        break;
      case "confirmPassword":
        this.setState({ confirmPassword: event.target.value });
        break;
      default:
    }
  };

  renderChangePasswordForm() {
    return (
      <div
        style={{
          backgroundColor: "#fff",
          padding: "20px",
        }}
      >
        <div class="form-group row">
          <label className="col-sm-5 col-form-label ">Old Password: </label>
          <div className="col-sm-7">
            <input
              type="text"
              className="form-control form-control-sm filterControls"
              placeholder="Old Password"
              value={this.state.oldPassword}
              name="oldPassword"
              onChange={this.inputHandler}
            ></input>
          </div>
        </div>
        <div class="form-group row">
          <label className="col-sm-5 col-form-label ">New Password: </label>
          <div className="col-sm-7">
            <input
              type="text"
              className="form-control form-control-sm filterControls"
              placeholder="New Password"
              value={this.state.newPassword}
              name="newPassword"
              onChange={this.inputHandler}
            ></input>
          </div>
        </div>
        <div class="form-group row">
          <label className="col-sm-5 col-form-label ">Confirm Password: </label>
          <div className="col-sm-7">
            <input
              type="text"
              className="form-control form-control-sm filterControls"
              placeholder="Confirm Password"
              value={this.state.confirmPassword}
              name="confirmPassword"
              onChange={this.inputHandler}
            ></input>
          </div>
        </div>
        <div class="form-group row">
          <div className="col-sm-3">
            <button
              className="btn btn-primary btn-sm filterButtons"
              type="button"
              onClick={this.searchClicked}
              disabled={
                !this.state.oldPassword ||
                !this.state.newPassword ||
                !this.state.confirmPassword ||
                this.state.newPassword.trim() !==
                  this.state.confirmPassword.trim()
              }
            >
              Change
            </button>
          </div>
          <div className="col-sm-3">
            <button
              className="btn btn-primary btn-sm filterButtons"
              type="button"
              onClick={this.clearClicked}
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div class="container-fluid h-100">
        <div class="row justify-content-center align-items-center h-100 ">
          <div class="col col-sm-6 col-md-6 col-lg-4 col-xl-3 loginBox">
            <div class="form-group row">
              <div
                className="col-sm-12"
                style={{ color: "white", fontSize: "20px" }}
              >
                Change Password
              </div>
            </div>
            {this.renderChangePasswordForm()}
          </div>
        </div>
      </div>
    );
  }
}
export default ChangePassword;
