import api from "./apiUrl";
import { authHeader,encodedURL } from "../_helpers/auth-header";
export function getPurchasingSummary(shipTo) {
  var shipToNum= shipTo ==='      ' ? 'empty'+shipTo : shipTo;//shipTo.trim() === '' ? null :shipTo.trim();
    return fetch(
    `${api.proxyUrl}${api.url}${encodedURL(`/puchasing/get-summary?shipTo=${shipToNum}&companyNumber=${localStorage.getItem(
      "companyNumber"
    )}&customerNumber=${localStorage.getItem("customerNumber")}`)}`,
    {
      // return fetch(`${api.url}/puchasing/get-summary?shipTo=${'ALL'}&companyNumber=${'63'}&customerNumber=${'70353'}`, {
      method: "GET",
      headers: authHeader(),
    }
  ).then((response) => response.json());
}
export function getPurchasingDetails(month, year, shipTo) {
  var updatedYear = year - 1900;
  return fetch(
    `${api.proxyUrl}${api.url}${encodedURL(`/puchasing/details?companyNumber=${localStorage.getItem(
      "companyNumber"
    )}&customerNumber=${localStorage.getItem("customerNumber")}&year=${updatedYear}&shipTo=${shipTo.trim()}&month=${month}`)}`,
    {
      method: "GET",
      headers: authHeader(),
    }
  ).then((response) => response.json());
}

export function getPurchasingTotalDetails(month, year, shipTo) {
  var updatedYear = year - 1900;
  return fetch(
    `${api.proxyUrl}${api.url}${encodedURL(`/puchasing/download-total-details?companyNumber=${localStorage.getItem(
      "companyNumber"
    )}&customerNumber=${localStorage.getItem("customerNumber")}&year=${updatedYear}&shipTo=${shipTo.trim()}&month=${month}`)}`,
    {
      method: "GET",
      headers: authHeader(),
    }
  ).then((response) => response.json());
}
export function getFilteredData(data) {
  let filteredData = [];
if(data!=null)
{
  data.forEach((item) => {
    filteredData.push({
      ItemNumber: item.ITEMNUMBER,
      CustomerPartNumber: item.Customer_Part_NUmber,
      ItemDescription: item.Desc,
      Quantity: item.Quntity,
      Purchases: currencyFormatter(item.Purchases),
    });
  });
}
  return filteredData;
}
function getDescription(desc)
{
    return  desc.replace(/"/g,'\""') ;
}
export function getTotalFilteredData(data) {
  let filteredData = [];
if(data!=null)
{
  data.forEach((item) => {
    filteredData.push({
      ITEMNUMBER: item.ITEMNUMBER,
      CUSTOMERPARTNUMBER: item.CUSTOMERPARTNUMBER,
      DESCRIPTION: getDescription(item.DESCRIPTION),
      JANUARYUNITSALES: item.JANUARYUNITSALES,
      JANUARYSALES: currencyFormatter(item.JANUARYSALES),
      FEBRUARYUNITSALES: item.FEBRUARYUNITSALES,
      FEBRUARYSALES: currencyFormatter(item.FEBRUARYSALES),
      MARCHUNITSALES: item.MARCHUNITSALES,
      MARCHSALES: currencyFormatter(item.MARCHSALES),
      APRILUNITSALES: item.APRILUNITSALES,
      APRILSALES: currencyFormatter(item.APRILSALES),
      MAYUNITSALES: item.MAYUNITSALES,
      MAYSALES: currencyFormatter(item.MAYSALES),
      JUNEUNITSALES: item.JUNEUNITSALES,
      JUNESALES: currencyFormatter(item.JUNESALES),
      JULYUNITSALES: item.JULYUNITSALES,
      JULYSALES: currencyFormatter(item.JULYSALES),
      AUGUSTUNITSALES: item.AUGUSTUNITSALES,
      AUGUSTSALES: currencyFormatter(item.AUGUSTSALES),
      SEPTEMBERUNITSALES: item.SEPTEMBERUNITSALES,
      SEPTEMBERSALES: currencyFormatter(item.SEPTEMBERSALES),
      OCTOBERUNITSALES: item.OCTOBERUNITSALES,
      OCTOBERSALES: currencyFormatter(item.OCTOBERSALES),
      NOVEMBERUNITSALES: item.NOVEMBERUNITSALES,
      NOVEMBERSALES: currencyFormatter(item.NOVEMBERSALES),
      DECEMBERUNITSALES: item.DECEMBERUNITSALES,
      DECEMBERSALES: currencyFormatter(item.DECEMBERSALES),
      TOTALUNITSALES: item.TOTALUNITSALES,
      TOTALSALES: currencyFormatter(item.TOTALSALES),
    });
  });
 

  filteredData.push({
    ITEMNUMBER: "Total:",
    CUSTOMERPARTNUMBER: "",
    DESCRIPTION:"",
    JANUARYUNITSALES:  data.reduce((a, {JANUARYUNITSALES}) => a + JANUARYUNITSALES, 0),
    JANUARYSALES: currencyFormatter( data.reduce((a, {JANUARYSALES}) => a + JANUARYSALES, 0)),
    FEBRUARYUNITSALES: data.reduce((a, {FEBRUARYUNITSALES}) => a + FEBRUARYUNITSALES, 0),// data.sum('FEBRUARYUNITSALES'),
    FEBRUARYSALES: currencyFormatter(data.reduce((a, {FEBRUARYSALES}) => a + FEBRUARYSALES, 0)),
    MARCHUNITSALES: data.reduce((a, {MARCHUNITSALES}) => a + MARCHUNITSALES, 0),
    MARCHSALES: currencyFormatter(data.reduce((a, {MARCHSALES}) => a + MARCHSALES, 0)),
    APRILUNITSALES: data.reduce((a, {APRILUNITSALES}) => a + APRILUNITSALES, 0),
    APRILSALES: currencyFormatter(data.reduce((a, {APRILSALES}) => a + APRILSALES, 0)),
    MAYUNITSALES: data.reduce((a, {MAYUNITSALES}) => a + MAYUNITSALES, 0),
    MAYSALES: currencyFormatter(data.reduce((a, {MAYSALES}) => a + MAYSALES, 0)),
    JUNEUNITSALES: data.reduce((a, {JUNEUNITSALES}) => a + JUNEUNITSALES, 0),
    JUNESALES: currencyFormatter(data.reduce((a, {JUNESALES}) => a + JUNESALES, 0)),
    JULYUNITSALES: data.reduce((a, {JULYUNITSALES}) => a + JULYUNITSALES, 0),
    JULYSALES: currencyFormatter(data.reduce((a, {JULYSALES}) => a + JULYSALES, 0)),
    AUGUSTUNITSALES: data.reduce((a, {AUGUSTUNITSALES}) => a + AUGUSTUNITSALES, 0),
    AUGUSTSALES: currencyFormatter(data.reduce((a, {AUGUSTSALES}) => a + AUGUSTSALES, 0)),
    SEPTEMBERUNITSALES: data.reduce((a, {SEPTEMBERUNITSALES}) => a + SEPTEMBERUNITSALES, 0),
    SEPTEMBERSALES: currencyFormatter(data.reduce((a, {SEPTEMBERSALES}) => a + SEPTEMBERSALES, 0)),
    OCTOBERUNITSALES: data.reduce((a, {OCTOBERUNITSALES}) => a + OCTOBERUNITSALES, 0),
    OCTOBERSALES: currencyFormatter(data.reduce((a, {OCTOBERSALES}) => a + OCTOBERSALES, 0)),
    NOVEMBERUNITSALES: data.reduce((a, {NOVEMBERUNITSALES}) => a + NOVEMBERUNITSALES, 0),
    NOVEMBERSALES: currencyFormatter(data.reduce((a, {NOVEMBERSALES}) => a + NOVEMBERSALES, 0)),
    DECEMBERUNITSALES: data.reduce((a, {DECEMBERUNITSALES}) => a + DECEMBERUNITSALES, 0),
    DECEMBERSALES: currencyFormatter(data.reduce((a, {DECEMBERSALES}) => a + DECEMBERSALES, 0)),
    TOTALUNITSALES: data.reduce((a, {TOTALUNITSALES}) => a + parseInt(TOTALUNITSALES), 0),
    TOTALSALES: currencyFormatter(data.reduce((a, {TOTALSALES}) => a + parseFloat(TOTALSALES), 0)),
  });

}
  return filteredData;
}

function unitsCheck(value)
{
if(value != null && value != "" && value != undefined)
return value;
else
return '0';
}

export function getFilteredSummaryData(data,qualifier){
  let filteredData = [];
  var currentDate= new Date;
  var currentYear=currentDate.getFullYear();
  if(data!=null)
  {
      data.forEach((item) => {
        filteredData.push({
          Period:item.Period,
          [currentYear]: currencyFormatter( item[currentYear] ),
          [currentYear-1]:  currencyFormatter( item[currentYear-1]),
          [currentYear-2]: currencyFormatter(item[currentYear-2]),
          [currentYear-3]:  currencyFormatter( item[currentYear-3]) ,
          [currentYear + "_UNITS"] :unitsCheck( item[currentYear + "_UNITS"]),
          [currentYear-1 + "_UNITS"]: unitsCheck(item [currentYear-1 + "_UNITS"]),
          [currentYear-2 + "_UNITS"]: unitsCheck( item [currentYear-2 + "_UNITS"]),
          [currentYear-3 + "_UNITS"]: unitsCheck(item [currentYear-3 + "_UNITS"])
        });
      });    
}

    return filteredData;
}

function currencyFormatter(value) {
  if (value !== undefined && value !== "") {
    if (parseFloat(value) < 0) return "-$" + (parseFloat(value).toFixed(2) * -1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else return "$" +  (parseFloat(value).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
  }
  return "$0.00";
}