import api from './apiUrl';
import {authHeader,encodedURL} from '../_helpers/auth-header'
var identityKey=localStorage.getItem('identityKey')
export function geDisplayOrderHeaderData(shipToNumber, poNumber, orderStatus, orderFilter,referenceNumber,fromDate,toDate,dateComparisonType,orderSearchNumber,orderSearchSequenceNumber)

{
    var urlparam='&shipToNumber='+shipToNumber
    urlparam+='&poNumber='+poNumber
    urlparam+='&orderStatus='+orderStatus
    urlparam+='&orderFilter='+orderFilter
    urlparam+='&loginType='+localStorage.getItem('loginTypeCode')
    urlparam+='&referenceNumber='+referenceNumber
    urlparam+='&fromDate='+fromDate
    urlparam+='&toDate='+toDate
    urlparam+='&dateComparisonType='+dateComparisonType
    urlparam+='&orderSearchNumber='+orderSearchNumber
    urlparam+='&orderSearchSequenceNumber='+orderSearchSequenceNumber
   

  
    
    return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/customerOrder/orderDetailsHeader?identityKey=${identityKey.trim()}&companyNumber=${localStorage.getItem('companyNumber')}&customerNumber=${localStorage.getItem('customerNumber')}${urlparam}`)}`, {
        method: 'GET',
        headers:authHeader()
    }).then(response => response.json());
}

//used to get UPSTrackingNumber
export function getUPSTrackingNumber(orderNumber, location, orderSequenceNumber) {
    var _companyNumber=localStorage.getItem('companyNumber');
    // var urlparam='&companyNumber='+_companyNumber
    // urlparam+='&location='+location
    // urlparam+='&orderNumber='+orderNumber
    // urlparam+='&orderSequenceNumber='+orderSequenceNumber

    //var urlparam='&companyNumber='+_companyNumber

    var newordernumber=orderNumber;
      
      if(newordernumber.length < 6)
      {
        newordernumber="0" + newordernumber
      }

    
    var urlparam='&location='+location
    urlparam+='&orderNumber='+newordernumber
    urlparam+='&orderSequenceNumber='+orderSequenceNumber

    return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/upstracking/getUPSTrackingNumbers?companyNumber=${localStorage.getItem('companyNumber')}${urlparam}`)}`, {
        method: 'GET',
        headers:authHeader()
    }).then(response => response.json());
  
}
export function getUPSResponse(inquiryNumberField,isfedx) {
    if(isfedx==true)
    {
        return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/fedextracking/getFedexResponses?fedexTrackingNumberParams=${inquiryNumberField}`)}`, {
            method: 'GET',
            headers:authHeader()
        }).then(response => response.json());
    }
    else
    {
    return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/upstracking/getUPSResponses?upsTrackingNumberParams=${inquiryNumberField}`)}`, {
        method: 'GET',
        headers:authHeader()
    }).then(response => response.json());
}
}
export function getFEDXTrackingNumber(orderNumber, location, orderSequenceNumber) {

//added newly 

    var newordernumber=orderNumber;
      
    if(newordernumber.length < 6)
    {
      newordernumber="0" + newordernumber
    }

  
  var urlparam='&location='+location
  urlparam+='&orderNumber='+newordernumber
  urlparam+='&orderSequenceNumber='+orderSequenceNumber

  
    // var urlparam='&location='+location
    // urlparam+='&orderNumber='+orderNumber
    // urlparam+='&orderSequenceNumber='+orderSequenceNumber

    return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/fedextracking/getFedexTrackingNumbers?companyNumber=${localStorage.getItem('companyNumber')}${urlparam}`)}`, {
        method: 'GET',
        headers:authHeader()
    }).then(response => response.json());
  
}
function currencyFormatter(value) {
    if (value !== undefined && value !== "" && value !== null && value!== '0' &&  value!== '0.00') {
        if (parseFloat(value) < 0) return "-$" +  (parseFloat(value).toFixed(2) * -1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        else return "$" +  (parseFloat(value).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return "$0.00";
  }
    
  function orderNumberFortmatter(orderNUmber, seqNumber)
  {
    if(seqNumber != null && seqNumber !="" && seqNumber !="0"){
    return orderNUmber + "-" +  ("0" + seqNumber).slice(-2);

    }
    else
    return orderNUmber + "-" +  "00";
  }
  function dateFormatter(dateValue) {
    if(dateValue != null && dateValue != "" && dateValue != undefined){
    var d = dateValue.split(" ");
    var formattedDateValue = d[0];
    return formattedDateValue;
    }
    else return "";
  }
export function getFilteredData(data, visiblePropList){
    let filteredData=[];
    if(data.length > 0){
    data.map((item) => {  
    let filterObject = {};        
      visiblePropList.forEach(prop => filterObject[prop]='');     
        if(filterObject.hasOwnProperty('PONumber')) filterObject.PONumber = item.poNumber;
        if(filterObject.hasOwnProperty('OrderNumber')) filterObject.OrderNumber =  orderNumberFortmatter(item.orderNumber ,item.orderSequenceNumber);
        if(filterObject.hasOwnProperty('InvoiceNumber')) filterObject.InvoiceNumber = item.referenceNumber;
        if(filterObject.hasOwnProperty('ShipToName')) filterObject.ShipToName = item.shipToName;
        if(filterObject.hasOwnProperty('Type')) filterObject.Type = item.orderType;
        if(filterObject.hasOwnProperty('Status')) filterObject.Status = item.orderStatus;
        if(filterObject.hasOwnProperty('OrderDate')) filterObject.OrderDate = dateFormatter(item.orderDate);
        if(filterObject.hasOwnProperty('ShipDate')) filterObject.ShipDate = dateFormatter(item.shipDate);
        if(filterObject.hasOwnProperty('OrderAmount')) filterObject.OrderAmount = currencyFormatter(item.totalOrderAmount);
        filteredData.push(filterObject);
    })
    }
    else{
        let filterObject = {};
        visiblePropList.forEach(prop => filterObject[prop]='');     
        if(filterObject.hasOwnProperty('PONumber')) filterObject.PONumber = "";
        if(filterObject.hasOwnProperty('OrderNumber')) filterObject.OrderNumber = "";
        if(filterObject.hasOwnProperty('InvoiceNumber')) filterObject.InvoiceNumber = "";
        if(filterObject.hasOwnProperty('ShipToName')) filterObject.ShipToName = "";
        if(filterObject.hasOwnProperty('Type')) filterObject.Type = ""
        if(filterObject.hasOwnProperty('Status')) filterObject.Status = "";
        if(filterObject.hasOwnProperty('OrderDate')) filterObject.OrderDate = "";
        if(filterObject.hasOwnProperty('ShipDate')) filterObject.ShipDate = "";
        if(filterObject.hasOwnProperty('OrderAmount')) filterObject.OrderAmount = "";

        filteredData.push(filterObject);
    }
    return filteredData;   
}
