import api from "./apiUrl";
import { authHeader, encodedURL } from "../_helpers/auth-header";

var companyNumber = localStorage.getItem("companyNumber");

export function getOrderDetails(
  loginid,
  sellingloc,
  orderNumber,
  orderSequenceNumber,
  referenceNumber,
  loginTypeCode
) {
  // let loginid = localStorage.getItem('identityKey');
  // let type = localStorage.getItem('loginTypeCode');

  return fetch(
    `${api.proxyUrl}${api.url}${encodedURL(
      `/customerOrder/orderDetails?loginid=${loginid.trim()}&companyNumber=${companyNumber.trim()}&sellingloc=${sellingloc.trim()}&orderNumber=${orderNumber.trim()}&orderSequenceNumber=${orderSequenceNumber.trim()}&referenceNumber=${referenceNumber.trim()}&type=${loginTypeCode.trim()}`
    )}`,
    {
      method: "GET",
      headers: authHeader(),
    }
  ).then((response) => response.json());
}

export function getOrderDetailsTop(
  companyNum,
  location,
  orderNumber,
  orderSequenceNumber,
  referenceNumber
) {
  return fetch(
    `${api.proxyUrl}${api.url}${encodedURL(
      `/customerOrder/orderDetailsTop?companyNumber=${companyNum.trim()}&location=${location.trim()}&orderNumber=${orderNumber.trim()}&orderSequenceNumber=${orderSequenceNumber.trim()}&referenceNumber=${referenceNumber.trim()}`
    )}`,
    {
      method: "GET",
      headers: authHeader(),
    }
  ).then((response) => response.json());
}

function getDescription(desc)
{
    return  desc.replace(/"/g,'\""') ;
}

export function getDetailData(data, visiblePropList) {
  let filteredData = [];
  if(data.length > 0){
    data.map((item) => {  
    let filterObject = {};        
      visiblePropList.forEach(prop => filterObject[prop]=''); 
        if (filterObject.hasOwnProperty("ItemNumber"))  filterObject.ItemNumber = item.partNumber;
        if (filterObject.hasOwnProperty("Stk"))  filterObject.Stk = item.stockNonStock;
        if (filterObject.hasOwnProperty("MfgPartNumber"))  filterObject.MfgPartNumber = item.manufacturerPartNumber;
        if (filterObject.hasOwnProperty("ItemDescription"))  filterObject.ItemDescription = getDescription(item.partDescription + " "+ item.additionalPartDescription);
        if (filterObject.hasOwnProperty("OrderQty"))  filterObject.OrderQty = item.quantityOrdered;
        if (filterObject.hasOwnProperty("ReservedShipQty"))  filterObject.ReservedShipQty = item.reservedShipQuantity;
        if (filterObject.hasOwnProperty("UnitPrice"))  filterObject.UnitPrice = currencyFormatter(item.unitPrice);
        if (filterObject.hasOwnProperty("Weight"))  filterObject.Weight = item.extendedWeight;
        if (filterObject.hasOwnProperty("Cubes"))  filterObject.Cubes = item.extendedCube;
        if (filterObject.hasOwnProperty("QtyOnHand"))  filterObject.QtyOnHand = item.quantityOnHand;
        if(filterObject.hasOwnProperty("QuantityOnOrder"))  filterObject.QuantityOnOrder= item.quantityOnOrder;
        if(filterObject.hasOwnProperty("EstimatedDeliveryDate"))  filterObject.EstimatedDeliveryDate=item.dateScheduled;
        filteredData.push(filterObject);
    })
  }
  else{
    let filterObject = {};        
      visiblePropList.forEach(prop => filterObject[prop]=''); 
        if (filterObject.hasOwnProperty("ItemNumber")) filterObject.ItemNumber = "";
        if (filterObject.hasOwnProperty("Stk")) filterObject.Stk = "";
        if (filterObject.hasOwnProperty("MfgPartNumber")) filterObject.MfgPartNumber = "";
        if (filterObject.hasOwnProperty("ItemDescription")) filterObject.ItemDescription = "";
        if (filterObject.hasOwnProperty("OrderQty")) filterObject.OrderQty = "";
        if (filterObject.hasOwnProperty("ReservedShipQty")) filterObject.ReservedShipQty = "";
        if (filterObject.hasOwnProperty("UnitPrice")) filterObject.UnitPrice = "";
        if (filterObject.hasOwnProperty("Weight"))  filterObject.Weight = "";
        if (filterObject.hasOwnProperty("Cubes")) filterObject.Cubes = "";
        if (filterObject.hasOwnProperty("QtyOnHand")) filterObject.QtyOnHand = "";
        if(filterObject.hasOwnProperty("QuantityOnOrder")) filterObject.QuantityOnOrder= "";
        if(filterObject.hasOwnProperty("EstimatedDeliveryDate")) filterObject.EstimatedDeliveryDate="";
        filteredData.push(filterObject);
  }

  return filteredData;
}
function currencyFormatter(currencyValue) {
  if (currencyValue !== undefined && currencyValue !== "" && currencyValue !== null && currencyValue !== '0' &&  currencyValue !== '0.00') {
    if (parseFloat(currencyValue) < 0) return "-$" +  (parseFloat(currencyValue).toFixed(2) * -1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else return "$" +  (parseFloat(currencyValue).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return "$0.00";
}