import React, { Component } from "react";

class PAItemRange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromItem: "",
      toItem:""
    };
  }
  clearSelection =() =>{
    this.setState({
      fromItem: "",
      toItem:""
    })
  }
  componentDidMount() {
    this.setState({
      fromItem: this.props.fromItem,
      toItem: this.props.toItem
    });
  }
  itemFromChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ fromItem: val }, () => {
      this.props.onItemFromChange(val);
    });
  };
  itemToChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ toItem: val }, () => {
      this.props.onItemToChange(val);
    });
  };
  render() {
    return (
      <div style={{marginLeft:"100px"}}>
          <center>
       
        <div className="row">
          <div className=" col-md-6 col-sm-12 " >
          From Distributor Item#
            <input 
              type="text"
              className="form-control form-control-sm"
              onChange={this.itemFromChange}
              name="fromItem" value={this.state.fromItem}
            ></input>
          </div>
        </div>       

        <div className="row">
          <div className=" col-md-6 col-sm-12">
          To Distributor Item#  
            <input
              type="text"
              className="form-control form-control-sm"
              onChange={this.itemToChange} value={this.state.toItem}
              name="toItem"
            ></input>
          </div>
        </div>
        </center>
      </div>
    );
  }
}

export default PAItemRange;
