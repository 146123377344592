import React from 'react'

export const CheckBox = props => {
    return (
      <li>
         <div className="form-check form-check-inline">
       <input key={props.id} className="form-check-input" onClick={props.handleCheckChieldElement}
        type="checkbox" checked={props.isChecked} value={props.value} /> 
       <label className="form-check-label">{props.value} </label>
       </div>
      </li>
    )
}

export default CheckBox