import React, { Component } from 'react';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {getCustomerVendorsList} from "../../_services/consolidatedInventory.service";
import appConstants from "../../_services/applicationConstant";




class CIVendors extends Component {
 
 
  constructor(props) {
    super(props);
    
    this.state = {
      
     
      colHeaders: [
        { headerName: "",   tooltip: (params) => 'Control Click to Select Mutiple Rows', cellStyle: {textAlign: 'left',borderRight: '0.1px solid lightgray'},
          field: "name",filter: 'agTextColumnFilter',width:320,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}`}],
         
        
            defaultColDef: {
              flex: 1,
              minWidth: 150,
              filter: true,
              sortable: true,
            },
          rowData:[]
          }
    
  };
  clearSelection =() =>{
    this.gridApi.deselectAll();
    document.getElementById("txtFilter").value ="";
    this.gridApi.setFilterModel(null);
  }
 componentWillMount() {  
   getCustomerVendorsList()
  .then((data) => {
             this.setState({ rowData: data })
        })
         .catch(console.log);
 }


   onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    const httpRequest = new XMLHttpRequest();
    const updateData = data => {
      params.api.setRowData(data.slice(0, 50));
    };

    httpRequest.open(
      'GET',
      getCustomerVendorsList() .then((data) => {
              this.setState({ rowData: data })
              window.watcher = setInterval(this.setSelectedItems, 25, this.gridApi);
            })
    );
    httpRequest.send();
   
  };
  filterchange = () => {
    var hardcodedFilter = {
      name: {
        type: 'contains', 
        filter:document.getElementById("txtFilter").value,
       
      }
      
      
    };
    this.gridApi.setFilterModel(hardcodedFilter);
    
  this.gridApi.onFilterChanged();
};
setSelectedItems = (api) => {
  this.gridApi.forEachNode((node) => { 
    if(this.props.selectedVendors.indexOf(node.data.id) > -1)      
          node.setSelected(true);
  });
    clearInterval(window.watcher);
    window.watcher = 0;        
};
onSelectionChanged = () => {
  var selectedRows = this.gridApi.getSelectedRows();
  var selectedRowsString = [];
  selectedRows.forEach(function(selectedRow, index) {     
    
    selectedRowsString.push(selectedRow.id);
  });
  this.props.handleVendorSelection(selectedRowsString);
 // alert(selectedRowsString);
};
  render() {
    const renderCustomerVendor = () => {
      return     <div>
      <div className="form-group row">
      <label className="col-sm-2" style={{ textAlign: "right", paddingRight: "2px", paddingLeft: "0px" }}>filter by: </label>
      <div className="col-sm-6">
        <input
          type="text" id="txtFilter"
          className="form-control form-control-sm"
          name="searchText" onChange={()=> this.filterchange()}
        ></input>
      </div>
      <div className="col-sm-4" style={{ textAlign: "left", paddingLeft: "0px" }}>
        <div className="form-check form-check-inline">
          <input className="form-check-input"  onClick={this.props.handleCheckCIVendors}
            type="checkbox" checked={this.props.isAllVendors} />
          <label className="form-check-label"> All Vendors </label>
        </div>
      </div>
    </div>
        <div className={`ag-theme-alpine ${appConstants.isR3App ? "apGridR3" : "apGrid"}`} style={{ height: '150px' }}>
        <AgGridReact
       
        
        
          columnDefs={this.state.colHeaders}
          defaultColDef={this.state.defaultColDef}
          onGridReady={this.onGridReady}  
          rowData={this.state.rowData}
          rowHeight="30"
          headerHeight="0"
          rowSelection='multiple'
          tooltipShowDelay="0"
          onSelectionChanged={this.onSelectionChanged.bind(this)}
        >
        </AgGridReact>
      </div>
  </div> 
    }
    
    return (
  
      renderCustomerVendor()

    );
  }
}


export default CIVendors;