import React, { Component } from "react";
import appConstants from "../../_services/applicationConstant";
import { getShipTo } from "../../_services/accountsPayable.service";
import user from '../../models/User';
import { decryptData } from '../../Utils';

class PurchasingFilter extends Component {
    constructor(props) {

    //user.toUser = JSON.parse(localStorage.getItem('UserMenu'))
    const salt = process.env.SALT || process.env.REACT_APP_KEY;
    const originalData = decryptData(localStorage.getItem("UserMenu"), salt);
    user.toUser = originalData;
      super(props);
        this.state={
            shipTo: [],
            selectedShipTo: "ALL",
            selectedStatusType: "Purchases",
            submitClicked:false,
            enableShipTo: true,
            canViewPurchases: false
        }
    }
    
    componentDidMount() {
      this.setState({selectedStatusType: this.props.selectedQualifier});
      if (user.toUser.CanViewInventoryPrices === "Y"){
this.setState({canViewPurchases: true})
      }
      else{
this.setState({canViewPurchases: false, selectedStatusType:"Quantity"})

      }
        getShipTo()
      .then((data) => {
        if(localStorage.getItem('loginTypeCode') === 'C')
        { 
          var userName= localStorage.getItem('userName');
          if(userName.length > 6)
          {
              var shipToID= userName.substring(6,userName.trim().length);
              var shipTOData= data.filter((p) => p.shipToNumber.trim() === shipToID);
              this.setState({ shipTo: shipTOData }, () => {
                if (shipTOData.length === 1) {
                  this.setState({ selectedShipTo: shipTOData[0].shipToNumber });
                }
              }); 
              this.setState({enableShipTo:false});
          }
          else{
            if(user.toUser.ViewAllShipTos==='All'){
              this.setState({ shipTo: data }, () => {             
              }); 
            }
            else{
              this.setState({ shipTo: data }, () => {
                if (data.length === 1) {
                  this.setState({ selectedShipTo: data[0].shipToNumber , enableShipTo:false});
                }
              }); 
            }
          }

      }
      else{
        if(localStorage.getItem('customerGroupShipToValue') !== null &&  localStorage.getItem('customerGroupShipToValue').trim() !== "" ){
          var shipTOData= data.filter((p) => p.shipToNumber.trim() === localStorage.getItem('customerGroupShipToValue').trim());
         this.setState({ shipTo: shipTOData }, () => {
                if (shipTOData.length === 1) {
                  this.setState({ selectedShipTo: shipTOData[0].shipToNumber });
                }
              });   
              this.setState({enableShipTo:false});
      }
      else{
        this.setState({ shipTo: data }, () => {
          // if (data.length === 1) {
          //   this.setState({ selectedShipTo: data[0].shipToNumber });
          // }
        }); 
      }
      }
      if(data.length === 0)
      {
        this.setState({enableShipTo:false});
      }             
      })
      .catch(console.log);
    }
  
  
    myChangeHandler = (event) => {
      let nam = event.target.name;
      let val = event.target.value;
      this.setState({ [nam]: val });     
    }
    resetFilter = () => {
      this.setState({
        customerPart:'',
        item:'',
        department:'',
        description:''
      });
    }
  
    searchClicked=() =>{
      var selectedShipToName= this.state.shipTo.filter(p => p.shipToNumber === this.state.selectedShipTo);
        this.props.doSearch(this.state.selectedShipTo,
        this.state.selectedStatusType ,
        selectedShipToName.length > 0 ? selectedShipToName[0].name :""   
       ); 
    }
    onQualifierChange = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({ [nam]: val });
        this.props.qualifierChanged(val);
      };
    render() {
    const shipToEnabled= this.state.shipTo.length > 1 ? true: false;
    const noShipTos= this.state.shipTo.length === 0 ? true : false;

      return (
        <div  className={`${
          appConstants.isR3App ? "filterBodyR3" : "filterBody"
        }`}        
        >
          <div className="row">
            <div className="col-sm-5">
              <div className=" row">
                <label className="control-inline-label col-form-label ">Ship To's: </label>
                <div className="col-sm-10">
                <select
                      id="disabledSelect"
                      className="form-control form-control-sm filterControls"
                      name="selectedShipTo"
                      disabled= {!this.state.enableShipTo}
                      onChange={this.myChangeHandler}
                      value={this.state.selectedShipTo}
                    >
                      {   this.state.enableShipTo &&
                    <option value="ALL">--ALL--</option>
                      }
                       {noShipTos &&
                    <option value="ALL">--ALL--</option>
                   }
                      {this.state.shipTo.map((type) => (
                        <option
                          key={type.shipToNumber}
                          value={type.shipToNumber}
                        >
                          {type.name} {" - "} {type.shipToNumber}
                        </option>
                      ))}
                    </select>
                </div>
              </div>
            </div>
            {this.state.canViewPurchases && 
            <div className="col-sm-2">
              <div className="row">
              <div className="form-check ">
              <input
                        className="form-check-input"
                        type="radio"
                        value="Purchases"
                        name="selectedStatusType"
                        checked={this.state.selectedStatusType === "Purchases"}
                        onChange={this.onQualifierChange}
                      />
                      <label
                        className="form-check-label"
                        for="inlineRadio1"
                        style={{ paddingTop: "4px" }}
                      >
                        Purchases
                      </label>
                      </div>
              </div>
            </div>    
            }
            <div className="col-sm-2">
              <div className=" row">
              <div className="form-check ">
              <input
                        className="form-check-input"
                        type="radio"
                        value="Quantity"
                        name="selectedStatusType"
                        checked={this.state.selectedStatusType === "Quantity"}
                        onChange={this.onQualifierChange}
                      />
                      <label
                        className="form-check-label"
                        for="inlineRadio1"
                        style={{ paddingTop: "4px" }}
                      >
                        Quantity
                      </label>
                      </div>
              </div>
            </div> 
            
            
            <div className="col-sm-3">
              <button className="btn btn-primary btn-sm filterButtons" onClick={this.searchClicked} type="button">
                Submit
              </button>
            </div>
          </div> 
        </div>
      );
    }
  }
  
  export default PurchasingFilter;