import api from './apiUrl';
import {authHeader,encodedURL} from '../_helpers/auth-header'
import user from '../models/User';
import {decryptData} from '../Utils';

var identityKey=localStorage.getItem('identityKey')
//meaAVL, meaVAL,meaQOO,meaAVLOO,dimCustPart,dimItem,dimCo,dimStk,dimVPart,dimVend,dimPODate,dimPal,dimCurPrice,frItem,toItem,cPType,custList,vendList,itemList
export function getConsolidatedInventory(ciObject)
{
   
    var urlparam='&meaAVL='+ciObject.meaAVL
    urlparam+='&meaVAL='+ciObject.meaVAL
    urlparam+='&meaQOO='+ciObject.meaQOO
    urlparam+='&meaAVLOO='+ciObject.meaAVLOO
    urlparam+='&dimCustPart='+ciObject.dimCustPart
    urlparam+='&dimItem='+ciObject.dimItem
    urlparam+='&dimCo='+ciObject.dimCo
    urlparam+='&dimStk='+ciObject.dimStk
    urlparam+='&dimVPart='+ciObject.dimVPart
    urlparam+='&dimVend='+ciObject.dimVend
    urlparam+='&dimPODate='+ciObject.dimPODate
    urlparam+='&dimPal='+ciObject.dimPal
  
    urlparam+='&dimCurPrice='+ciObject.dimCurPrice
    urlparam+='&frItem='+ciObject.frItem
    urlparam+='&toItem='+ciObject.toItem
    urlparam+='&cPType='+ciObject.cPType
    urlparam+='&custList='+ciObject.custList
    urlparam+='&vendList='+ciObject.vendList
    urlparam+='&itemList='+ciObject.itemList
    
    return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/consolidatedinventory/consInventory?pklogid=${identityKey.trim()}&type=${localStorage.getItem('companyNumber')}${urlparam}`)}`, {
        method: 'GET',
        headers:authHeader()
    }).then(response => response.json());
}

// export function getConsolidatedInventory(meaAVL, meaVAL,meaQOO,meaAVLOO,dimCustPart,dimItem,dimCo,dimStk,dimVPart,dimVend,dimPODate,dimPal,dimCurPrice,frItem,toItem,cPType,custList,vendList,itemList)
// {
//     debugger;
   
//     var urlparam='&meaAVL='+meaAVL
//     urlparam+='&meaVAL='+meaVAL
//     urlparam+='&meaQOO='+meaQOO
//     urlparam+='&meaAVLOO='+meaAVLOO
//     urlparam+='&dimCustPart='+dimCustPart
//     urlparam+='&dimItem='+dimItem
//     urlparam+='&dimCo='+dimCo
//     urlparam+='&dimStk='+dimStk
//     urlparam+='&dimVPart='+dimVPart
//     urlparam+='&dimVend='+dimVend
//     urlparam+='&dimPODate='+dimPODate
//     urlparam+='&dimPal='+dimPal
//     urlparam+='&dimItem='+dimItem
//     urlparam+='&dimCo='+dimCo
//     urlparam+='&dimStk='+dimStk
//     urlparam+='&dimCurPrice='+dimCurPrice
//     urlparam+='&frItem='+frItem
//     urlparam+='&toItem='+toItem
//     urlparam+='&cPType='+cPType
//     urlparam+='&custList='+custList
//     urlparam+='&vendList='+vendList
//     urlparam+='&itemList='+itemList
    
//     return fetch(`${api.url}/consolidatedinventory/consInventory?pklogid=${identityKey.trim()}&type=${localStorage.getItem('companyNumber')}${urlparam}`, {
//         method: 'GET',
//         headers:authHeader()
//     }).then(response => response.json());
// }
function dateFormatter(inputDate) {
    if(inputDate != null &&  inputDate != "" && inputDate != undefined){
    var d = inputDate.split(" ");
    var dateValue = d[0];
    return dateValue;
    }
    else return "";
  }
export function getFilteredData(data, visiblePropList){
    let filteredData=[];
    data.map((item) => {  
    let filterObject = {};
    visiblePropList.forEach(prop => filterObject[prop]='');

        if(filterObject.hasOwnProperty('CustomerPart')) filterObject.CustomerPart = item.customerPart;
        if(filterObject.hasOwnProperty('Description')) filterObject.Description = item.description;
        if(filterObject.hasOwnProperty('QuantityAvailable')) filterObject.QuantityAvailable = item.avl;
        if(filterObject.hasOwnProperty('ProductValueAvailable')) filterObject.ProductValueAvailable = currencyFormatter(item.val);
        if(filterObject.hasOwnProperty('QuantityOnOrder')) filterObject.QuantityOnOrder = item.qoo;
        if(filterObject.hasOwnProperty('AvailableOnOrder')) filterObject.AvailableOnOrder = item.avloo;
        if(filterObject.hasOwnProperty('ItemNumber')) filterObject.ItemNumber = item.itemNumber;
        if(filterObject.hasOwnProperty('CasePack')) filterObject.CasePack = item.casePack;
        if(filterObject.hasOwnProperty('PackDescption')) filterObject.PackDescption = item.packDescption;
        if(filterObject.hasOwnProperty('Warehouse')) filterObject.Warehouse = item.locationAbbr;
        if(filterObject.hasOwnProperty('StockNonStockItem')) filterObject.StockNonStockItem = item.stockNo;
        if(filterObject.hasOwnProperty('PalletQty')) filterObject.PalletQty = item.Pallet;
        if(filterObject.hasOwnProperty('EstimatedDeliveryDatetoDistributorWarehouse')) filterObject.EstimatedDeliveryDatetoDistributorWarehouse = dateFormatter(item.poDate);
        if(filterObject.hasOwnProperty('CurrentPrice')) filterObject.CurrentPrice = currencyFormatter(item.prc);
        if(filterObject.hasOwnProperty('VendorPart')) filterObject.VendorPart = item.vendorPart;
        if(filterObject.hasOwnProperty('VendorNumber')) filterObject.VendorNumber = item.vendorNumber;
        if(filterObject.hasOwnProperty('VendorName')) filterObject.VendorName = item.vendorName; 

        filteredData.push(filterObject);
    })

    // data.forEach((item) => {        
    //     filteredData.push({
    //         CustomerPart:item.customerPart,
    //         Description:item.description,
    //         QuantityAvailable:item.avl,
    //         ProductValueAvailable:item.val,
    //         QuantityOnOrder:item.qoo,
    //         AvailableOnOrder:item.avloo,
    //         ItemNumber:item.itemNumber,           
    //         CasePack:item.casePack,
    //         PackDescption:item.packDescption,
    //         Warehouse:'',
    //         StockNonStockItem:item.stockNo,
    //         LocationAbbr:item.locationAbbr,           
    //         PalletQty:item.Pallet,
    //         EstimatedDeliveryDatetoDistributorWarehouse:item.PODate,
    //         CurrentPrice:item.prc,
    //         // qoo:item.qoo,
    //         VendorPart:item.vendorPart,
    //         VendorNumber:item.vendorNumber,
    //         VendorName:item.vendorName,
    //     });
    // })
    return filteredData;   
}

export function getReportColumns(){
    //user.toUser = JSON.parse(localStorage.getItem('UserMenu'))
    const salt = process.env.SALT || process.env.REACT_APP_KEY; // const originalData = decryptData(JSON.parse(localStorage.getItem("UserMenu")), salt);
    const originalData = decryptData(localStorage.getItem("UserMenu"), salt);
    user.toUser = originalData;

    var reportColumns= [
        {id: 1, value: "Customer Part#", isChecked: false, code: 'dimCustPart'},
        {id: 2, value: "Item Number", isChecked: false,  code: 'dimItem'},
        {id: 3, value: "Warehouse", isChecked: false,  code: 'dimCo'},
        {id: 4, value: "Stock/Non-stock Item", isChecked: false,  code: 'dimStk'},
        {id: 5, value: "Vendor Part", isChecked: false,  code: 'dimVPart'},
        {id: 6, value: "Vendor", isChecked: false,  code: 'dimVend'},
        {id: 7, value: "Estimated Delvery Date to Distributor Warehouse", isChecked: false,  code: 'dimPODate'},
        {id: 8, value: "Pallet Qty", isChecked: false,  code: 'dimPal'},             
      ]    
    if(user.toUser !== null) {
    if(user.toUser.CanViewCustomerPrices=='Y')
    reportColumns.push( {id: 9, value: "Current Price", isChecked: false ,  code: 'dimCurPrice'});
    
    }
    return reportColumns;   
}
export function getDataColumns(){
    //user.toUser = JSON.parse(localStorage.getItem('UserMenu'))
    const salt = process.env.SALT || process.env.REACT_APP_KEY; // const originalData = decryptData(JSON.parse(localStorage.getItem("UserMenu")), salt);
    const originalData = decryptData(localStorage.getItem("UserMenu"), salt);
    user.toUser = originalData;
    var dataColumns= [
        {id: 1, value: "Quantity Available", isChecked: false,  code: 'meaAVL'},
      ]    
    if(user.toUser !== null) {
    if(user.toUser.CanViewCustomerPrices=='Y')
    dataColumns.push(  {id: 2, value: "Product Value Available", isChecked: false,  code: 'meaVAL'});    
    }
    dataColumns.push(   {id: 3, value: "Quantity on Demand", isChecked: false,  code: 'meaQOO'});    
    dataColumns.push(  {id: 4, value: "Available + On Order", isChecked: false,  code: 'meaAVLOO'});   
    return dataColumns;   
}

export function getCustomerVendorsList() {
   
    var identityKey=localStorage.getItem('identityKey')
    var loginType=localStorage.getItem('loginTypeCode')
    return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/consolidatedinventory/customerVendor?identityKey=${identityKey}&loginType=${loginType}`)}`, {
        method: 'GET',
        headers:authHeader()
    }).then(response => response.json());
}
export function getItenEntryList(){
    var reportColumns= [
        {id: 1, value: "Distributor Item#"},
        {id: 2, value: "Customer Part#"},
        {id: 3, value: "Vendor Part#"},
      ]   
    
    return reportColumns;   
}

export function getCustomerList() {
   
    var identityKey=localStorage.getItem('identityKey')
    var loginType=localStorage.getItem('loginTypeCode')
    return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/consolidatedinventory/customerList?identityKey=${identityKey.trim()}&loginTypeCode=${loginType}`)}`, {
        method: 'GET',
        headers:authHeader()
    }).then(response => response.json());
}

function currencyFormatter(value) {
    if (value !== undefined && value !== "" && value !== null && value!== '0' &&  value!== '0.00') {
      if (parseFloat(value) < 0) return "-$" +  (parseFloat(value).toFixed(2) * -1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      else return "$" +  (parseFloat(value).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return "$0.00";
  }
  
