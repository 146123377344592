import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";

class ESSLoadingModal extends Component {
    render() {
        return (
            <Modal className="essLoading"           
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              show='true'              
            >
              <Modal.Body style={{alignSelf:"center", padding:"0px"}}>
              <button class="btn btn-primary" type="button" disabled>
  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  &nbsp;&nbsp;&nbsp; Downloading Invoice Image...
</button>
              </Modal.Body>
             </Modal>
          );
    }
}

export default ESSLoadingModal;