import React, { Component } from "react";
import appConstants from "../../_services/applicationConstant";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  getOrderDetails,
  getDetailData,
  getOrderDetailsTop,
} from "../../_services/orderDetails.service";
import downloadbutton from "../../assets/images/downloadbutton.gif";
import ups from "../../assets/images/ups.jpg";
import fedex from "../../assets/images/FedEx.gif";
import { CSVLink } from "react-csv";
import UPSTrackingNumbersCard from "./UPSTrackingNumbersCard";
import { authHeader, encodedURL } from "../../_helpers/auth-header";
import api from "../../_services/apiUrl";
import ESSLoadingModal from "../../components/ESSLoadingModal";
import user from '../../models/User';
import { decryptData } from '../../Utils';

class OrderDetails extends Component {
  constructor(props) {
    //user.toUser = JSON.parse(localStorage.getItem("UserMenu"));

    const salt = process.env.SALT || process.env.REACT_APP_KEY;
    const originalData = decryptData(localStorage.getItem("UserMenu"), salt);
    user.toUser = originalData;

    super(props);
    this.handleUPSlick = this.handleUPSlick.bind(this);
    this.handleFDXClick = this.handleFDXClick.bind(this);
    this.state = {
      displayOrderNumber:'',
      companyNumber: localStorage.getItem("companyNumber"),
      showLoading: false,
      columnDefs: [
        {
          headerName: "Item Number",
          field: "partNumber",
          cellStyle: {
            textAlign: "center",
            borderRight: "0.1px solid lightgray",
          },
          resizable: true,
          width: 150,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },

        {
          headerName: "Stk",
          cellStyle: {
            textAlign: "left ",
            borderRight: "0.1px solid lightgray",
          },
          field: "stockNonStock",
          resizable: true,
          width: 100,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Mfg Part Number",
          cellStyle: {
            textAlign: "left ",
            borderRight: "0.1px solid lightgray",
          },
          field: "manufacturerPartNumber",
          resizable: true,
          width: 150,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Item Description",
          cellStyle: {
            textAlign: "left",
            borderRight: "0.1px solid lightgray",
          },
          valueFormatter: orderDescription,
          field: "partDescription", 
          resizable: true,
          width: 280,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },

        {
          headerName: "Order Qty",
          cellStyle: {
            textAlign: "center",
            borderRight: "0.1px solid lightgray",
          },
          field: "quantityOrdered",
          resizable: true,
          width: 120,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Reserved Ship Qty",
          cellStyle: {
            textAlign: "left",
            borderRight: "0.1px solid lightgray",
          },
          field: "reservedShipQuantity",
          resizable: true,
          width: 180,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },

        {
          headerName: "Unit Price",
          valueFormatter: currencyFormatter,
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          type: "rightAligned",
          field: "unitPrice",
          resizable: true,
          width: 120,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Weight",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          type: "rightAligned",
          field: "extendedWeight",
          resizable: true,
          width: 80,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Cubes",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "extendedCube",
          resizable: true,
          width: 80,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Qty On Hand",
          hide:  user.toUser.QuantityOnHandViewPermission == "Y" ? false: true,
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "quantityOnHand",
          resizable: true,
          width: 120,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
         {
          headerName: "Qty On Order",
          hide:  user.toUser.QuantityOnOrderViewPermission == "Y" ? false: true,
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "quantityOnOrder",
          resizable: true,
          width: 130,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Estimated Delivery Date",
          hide:  user.toUser.QuantityOnOrderViewPermission == "Y" ? false: true,
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "dateScheduled",
          cellRenderer: dateFormatter,
          resizable: true,
          width: 200,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
      ],

      rowData: [],
      exportData: [],
      orderNumber: [],
      orderStatus: [],
      customerNumber: '',
      shipToNumber: [],
      customerAddress1:'',
      customerAddress2: '',
      customerCity: '',
      customerState: '',
      customerZipCode: '',
      customerCountry: [],
      shipToAddress1: '',
      shipToAddress2: '',
      shipToCity: '',
      shipToState: '',
      shipToZipCode: '',
      shipToCountry: '',
      shipViaName: '',
      customerName:'',
      proNumber:'',
      shipVia: '',
      showUPSDisplay: false,
      showOD: true,
      selectedUPS: "",
      selectedUPSSeq: "",
      selectgedUPSSeqString: "",
      selectedLocation: "",
      showFEDEX: false,
      isfedx: false,
      orderComment1:'',
      orderComment2:'',
      defaultColDef: { resizable: true, sortable: true },
    };
  }

  getExportColumns() {
    let exportColumnsList = [
      "ItemNumber",
      "Stk",
      "MfgPartNumber",
      "ItemDescription",
      "OrderQty",
      "ReservedShipQty",
      "UnitPrice",
      "Weight",
      "Cubes"];
      if(user.toUser.QuantityOnHandViewPermission == "Y")
        exportColumnsList.push("QtyOnHand");
       if(user.toUser.QuantityOnOrderViewPermission == "Y")
       exportColumnsList.push("QuantityOnOrder");
      if(user.toUser.QuantityOnOrderViewPermission == "Y")
       exportColumnsList.push("EstimatedDeliveryDate");

    return exportColumnsList;
  }
  handleUPSlick(order, orderSeq) {
    this.setState({
      showUPSDisplay: true,
      showOD: false,
      selectedUPS: order,
      selectedUPSSeq: orderSeq,
      selectgedUPSSeqString: ("0" + orderSeq).slice(-2),
    });
  }
  handleFDXClick(order, location, orderSeq) {
    this.setState({
      showUPSDisplay: true,
      showOD: false,
      isfedx: true,
      showFEDEX: true,
      selectedUPS: order,
      selectedUPSSeq: orderSeq,
      selectedLocation: location,
    });
  }
  componentDidMount() {
    var customerNumber = localStorage.getItem("identityKey");
    var loginType = localStorage.getItem("loginTypeCode");
    var companyNumber = localStorage.getItem("companyNumber");
    this.setState({displayOrderNumber:  orderFormater(this.props.selectedOrder,this.props.selectedOrderSeq)});
    //alert(this.props.showups)
    getOrderDetails(
      customerNumber,
      this.props.selectedLocation,
      this.props.selectedOrder,
      this.props.selectedOrderSeq,
      this.props.selectedInvoice,
      loginType
    ).then((data) => {
      this.setState({ rowData: data });
      this.setState({
        exportData: getDetailData(data, this.getExportColumns()),
      });
    });
    getOrderDetailsTop(
      companyNumber,
      this.props.selectedLocation,
      this.props.selectedOrder,
      this.props.selectedOrderSeq,
      this.props.selectedInvoice
    ).then((data) => {
      if (data.length>0)
          {
      
       this.setState({customerName:data[0].customerName});
       this.setState({customerAddress1:data[0].customerAddress1});
       this.setState({customerAddress2:data[0].customerAddress2});
       this.setState({customerCity:data[0].customerCity});
       this.setState({customerState:data[0].customerState});
       this.setState({customerZipCode:data[0].customerZipCode});
       this.setState({proNumber:data[0].proNumber});
       this.setState({shipViaName:data[0].shipViaName});
       this.setState({shipVia:data[0].shipVia});
       this.setState({customerNumber:data[0].customerNumber})
       this.setState({shipToNumber: data[0].shipToNumber == null || data[0].shipToNumber.trim() === "" ? data[0].shipToName : data[0].shipToNumber })
//ship details
this.setState({shipToAddress1:data[0].shipToAddress1});
this.setState({shipToAddress2:data[0].shipToAddress2});
this.setState({shipToCity:data[0].shipToCity});
this.setState({shipToState:data[0].shipToState});
this.setState({shipToZipCode:data[0].shipToZipCode});
this.setState({shipToCountry:data[0].shipToCountry});
this.setState({orderComment1:data[0].orderComment1});
this.setState({orderComment2:data[0].orderComment2});
this.setState({displayOrderNumber:  orderFormater(this.props.selectedOrder,data[0].orderSequenceNumber)});

          }
        
    });
  }

  handleInvoiceClick = () => {
    this.setState({showLoading:true});
    var pdfWindow = window.open('','_blank');
    pdfWindow.document.body.innerHTML  = "<center><p style='color:blue;'>Downloading Invoice Image...</p></center>"; 
    
    const custno=String(localStorage.getItem("customerNumber")).padStart(6, '0');


    return fetch(
      `${api.proxyUrlForDownload}${api.url}${encodedURL(
        `/pdf-downloader/invoice-pdf-download?invoiceId=${this.props.selectedInvoice}&companyNumber=${this.state.companyNumber}&group=${custno}`
      )}`,
      {
        method: "GET",
        headers: authHeader(),
      }
    )
    .then((response) => {if(response.status === 500) {
      throw new Error('Error while downloading the Invoice Image.')
    } 
    else return  response.blob()})
    .then((response) => {
      const file = new Blob([response], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //   window.navigator.msSaveOrOpenBlob(file);
      //   return;
      // }
      //const pdfWindow = window.open(fileURL,'_blank');      
      pdfWindow.location.href = fileURL;
      this.setState({showLoading:false});
    }, 
    (error) => {  
      pdfWindow.close(); 
      this.setState({showLoading:false});
      this.setState({
        show: true,
        userMessage: 'Error while downloading the Invoice Image.\n Please retry after some time.'
    });
    })
    .catch(function(error) {
      pdfWindow.close(); 
      this.setState({showLoading:false});
      console.log(error);
  });
}
  render() {
    const renderInventoryGrid = () => {
      return (
        <div
          className={`ag-theme-alpine ${
            appConstants.isR3App ? "gridMainR3" : "gridMain"
          }`}
          style={{ height: "72%", width: "100%" }}
        >
          <AgGridReact
            defaultColDef={this.state.defaultColDef}
            columnDefs={this.state.columnDefs}
            enableCellTextSelection={true}
            rowData={this.state.rowData}
            rowHeight="30"
            headerHeight="30"
            footerHeight="30"
          ></AgGridReact>
        </div>
      );
    };

    return (
      <div style={{ height: "100%" }}>
         {this.state.showLoading && 
          <ESSLoadingModal />}
        {/* <h6 className="card-header">Display Order</h6> */}

        <div className="row content" style={{ height: "98%" }}>
          {this.state.showOD ? (
            <div className="col-sm-12">
              <div className="row" ref="filterComponent">
                <div className="col-sm-8">
                  {/* <p> Order Details Filter : {this.props.selectedPO}</p> */}
                  {/* TopSection */}
                  <div
                    className={`${
                      appConstants.isR3App ? "filterBodyR3" : "filterBody"
                    }`}
                  >
                    <div className="row">
                      <div className="col-sm-4">
                        <label className="control-inline-label col-form-label ">
                          Order Number : {' '} &nbsp;
                        </label>
                       {this.state.displayOrderNumber}
                      </div>
                      <div className="col-sm-4">
                        <label className="control-inline-label col-form-label ">
                          Status:{" "}  &nbsp;
                        </label>
                        {this.props.selectedStatus}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-4">
                        <label className="control-inline-label col-form-label ">
                          Customer Number: {" "}  &nbsp;
                        </label>
                        {this.state.customerNumber}
                      </div>
                      <div className="col-sm-4">
                        <label className="control-inline-label col-form-label ">
                          Ship To #: {" "}  &nbsp;
                        </label>
                        {localStorage.getItem("shipToDO")}

                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4" style={{fontWeight:'bold'}}>
                    <label className="control-inline-label col-form-label " >
                       
                      </label>
                      {this.state.customerName}
                    </div>


                    <div className="col-sm-4" style={{paddingLeft:'45px',fontWeight:'bold' }}>
                     {this.state.shipToNumber}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4" style={{fontWeight:'bold'}}>
                    <label className="control-inline-label col-form-label ">
                       
                      </label>
                      {this.state.customerAddress1}&nbsp;{this.state.customerAddress2}
                    </div>


                    <div className="col-sm-4" style={{paddingLeft:'45px',fontWeight:'bold'}}>
                     {this.state.shipToAddress1} &nbsp; {this.state.shipToAddress2}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4" style={{fontWeight:'bold'}}>
                    <label className="control-inline-label col-form-label ">
                       
                      </label>
                      {this.state.customerCity} {this.state.customerCity.trim().length > 0 ? ',': ''} {this.state.customerState} &nbsp;{this.state.customerZipCode}
                    </div>


                    <div className="col-sm-4" style={{paddingLeft:'45px',fontWeight:'bold'}}>
                     {this.state.shipToCity} {this.state.shipToCity.trim().length > 0 ? ',': ''}  {this.state.shipToState} &nbsp;{this.state.shipToZipCode}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <label className="control-inline-label col-form-label ">
                        Customer P.O.: {" "}  &nbsp;
                      </label>
                      {this.props.selectedPO}
                    </div>

                    <div className="col-sm-4"></div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <label className="control-inline-label col-form-label ">
                        Frieght Bill PRO Number: {" "}  &nbsp;
                      </label>
                      {this.state.proNumber}
                    </div>
                    <div className="col-sm-4" style={{paddingLeft:'20px'}} >
                      <label className="control-inline-label col-form-label ">
                        {this.state.shipVia === "" ?  this.props.showups === "FEDEX" ? "FXGR" :  'UPSC' 
                        : this.state.shipVia}
                        : {" "}  &nbsp;
                      </label>
                      {this.state.shipViaName}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <label className="control-inline-label col-form-label ">
                        Invoice Number: {" "}  &nbsp;
                      </label>
                      {this.props.selectedInvoice}
                    </div>
                  </div>
<div className="row">
  <div className="col-sm-8">
  <label className="control-inline-label col-form-label ">
                       {this.state.orderComment1}  &nbsp;  {this.state.orderComment2}
                      </label>
  </div>
</div>
                  
                </div>
                <div className="col-sm-2" style={{ paddingTop: "30px" }}>
                  <div className="row">
                    <div className="col-sm-3" style={{ paddingTop: "4%" }}>
                      {this.props.showups === "UPS" ? (
                        <img
                          src={ups}
                          style={{ cursor: "Pointer" }}
                          onClick={this.handleUPSlick.bind(
                            this,
                            this.props.selectedOrder,
                            this.props.selectedOrderSeq
                          )}
                        ></img>
                      ) : this.props.showups === "FEDEX" ? (
                        <img
                          src={fedex}
                          style={{ cursor: "Pointer" }}
                          onClick={this.handleFDXClick.bind(
                            this,
                            this.props.selectedOrder,
                            this.props.location,
                            this.props.selectedOrderSeq
                          )}
                        ></img>
                      ) : (
                        ""
                      )}
                    </div>
                    {this.props.selectedInvoice!==null && this.props.selectedInvoice!=='' ? 
                    <div className="col-sm-7" style={{ paddingTop: "4%" }}>
                      <h6
                        style={{ cursor: "pointer", fontSize: "0.8rem" }}
                       onClick={this.handleInvoiceClick.bind(this)}
                      >
                        Show Invoice
                      </h6>
                    </div>
                :''    } 
                  </div>
                </div>
                <div className="col-sm-1" style={{ paddingTop: "30px" }}>
                  <div className="col-sm-1" style={{ paddingTop: "4%" }}>
                    <div style={{ paddingTop: "3%" }}>
                      {1 > 0 ? (
                        <CSVLink
                          ref="csv"
                          data={this.state.exportData}
                          filename={"DisplayOrderDetails.csv"}
                        >
                          <img
                            src={downloadbutton}
                            title="Download Customer Order Details List Items."
                            alt="Download Report"
                          />
                        </CSVLink>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-sm-1" ></div>
              </div>
              <div
                className="row"
                ref="gridComponent"
                style={{ height: "66%" }}
              >
                <div className="col-sm-12">
                  {renderInventoryGrid()}
                </div>
              </div>
            </div>
          ) : (
            this.state.showUPSDisplay && (
              <div style={{ height: "100%" }}>
                <UPSTrackingNumbersCard
                  orderNumber={this.state.selectedUPS}
                  orderSeqNumber={this.state.selectgedUPSSeqString}
                  location={this.props.selectedLocation}
                  showups={this.state.showUPS}
                  showFEDEX={this.state.showFEDEX}
                  isfedx={this.state.isfedx}
                />
              </div>
            )
          )}
        </div>
      </div>
    );
  }
}

export default OrderDetails;
function currencyFormatter(params) {
  if (params.value !== undefined && params.value !== ""){
  if (parseFloat(params.value) < 0) return "-$" + (parseFloat(params.value).toFixed(2) * -1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else return "$" + (parseFloat(params.value).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  else
  return "";
}
function dateFormatter(param) {
  if(param.value != null &&  param.value != "" && param.value != undefined){
  var d = param.value.split(" ");
  var dateValue = d[0];
  return dateValue;
  }
  else return "";
}
function orderDescription(params){
  return params.data.partDescription.trim() + " "+ params.data.additionalPartDescription.trim();
}

function orderFormater(orderNumber,orderSeq) {
  return (
    orderNumber +
    "-" +
    ("0" + orderSeq).slice(-2)
  );
}
