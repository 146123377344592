import React, { Component } from "react";
import bunzlCSS from "../../assets/css/bunzlUI.css";
import { getShipTo } from "../../_services/accountsPayable.service";
import appConstants from "../../_services/applicationConstant";
import mgglass from "../../assets/images/magGlass.jpg";
import ESSModal from "../../components/ESSModal.jsx";
import user from '../../models/User';
import { decryptData } from '../../Utils';

class DisplayOrderFilter extends Component {
  constructor(props) {
    //user.toUser = JSON.parse(localStorage.getItem('UserMenu'))
    
 const salt = process.env.SALT || process.env.REACT_APP_KEY;
 const originalData = decryptData(localStorage.getItem("UserMenu"), salt);
 user.toUser = originalData;
 
    super(props);
    this.state = {
      searchPOText: "",
      shipTo: [],
      selectedShipTo: "All",
      selectedStatusType: "Open",
      selectedOrderType: "A",
      selectedDataType: "P",
      fromDate: "",
      toDate: "",
      orderValue: "",
      seqValue: "",
      invoiceValue: "",
      isOrderTypeEnabled: true,
      show: false,
      userMessage: "",
      enableShipTo: true
    };
  }
  componentDidMount() {
    getShipTo()
      .then((data) => {
        if(localStorage.getItem('loginTypeCode') === 'C')
        { 
          var userName= localStorage.getItem('userName');
          if(userName.length > 6)
          {
              var shipToID= userName.substring(6,userName.trim().length);
              var shipTOData= data.filter((p) => p.shipToNumber.trim() === shipToID);
              this.setState({ shipTo: shipTOData }, () => {
                if (shipTOData.length === 1) {
                  this.setState({ selectedShipTo: shipTOData[0].shipToNumber });
                }
              }); 
              this.setState({enableShipTo:false});
          }
          else{
            if(user.toUser.ViewAllShipTos==='All'){
            this.setState({ shipTo: data }, () => {             
            }); 
          }
          else{
            this.setState({ shipTo: data }, () => {
              if (data.length === 1) {
                this.setState({ selectedShipTo: data[0].shipToNumber , enableShipTo:false});
              }
            }); 
          }
          }
      }
      else{
        if(localStorage.getItem('customerGroupShipToValue') !== null &&  localStorage.getItem('customerGroupShipToValue').trim() !== "" ){
          var shipTOData= data.filter((p) => p.shipToNumber.trim() === localStorage.getItem('customerGroupShipToValue').trim());
          this.setState({ shipTo: shipTOData }, () => {
                if (shipTOData.length === 1) {
                  this.setState({ selectedShipTo: shipTOData[0].shipToNumber, enableShipTo:false });
                }
              });        
      }
      else{
        this.setState({ shipTo: data }, () => {
          // if (data.length === 1) {
          //   this.setState({ selectedShipTo: data[0].shipToNumber });
          // }
        });
      }
      }
      if(data.length === 0)
      {
        this.setState({enableShipTo:false});
      }
      })
      .catch(console.log);
  }
  onQualifierChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
    if (nam === "selectedStatusType") {
      if (val === "Closed") {
        this.setState({ selectedOrderType: "A", isOrderTypeEnabled: false });
      } else {
        this.setState({ isOrderTypeEnabled: true });
      }
    }
    if (nam === "selectedDataType") {
      if (val === "PO") {
        this.setState({ selectedDataType: "P" });
      }
    }
  };
  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
  };
  searchClicked = () => {
    var fromdate = document.getElementById("fromDate").value;
   
   
    var todate = document.getElementById("toDate").value;
    if (document.getElementById("fromDate").value !== '')
    {
     
    if ((fromdate.substring(0,4)) < 1900 ||(fromdate.substring(0,4)) > 2899 ) {
     
      this.setState({
       show: true,
        userMessage: "Invalid year",
     });
     return false;
     
    }
  }
  if(document.getElementById("toDate").value != '')
  {
     if (todate.substring(0,4) > 2899 ||(todate.substring(0,4)) < 1900 )
    {
      
      this.setState({
        show: true,
         userMessage: "Invalid year",
      });
      return false;
    }
  }
    this.setState({ fromDate: fromdate, toDate: todate }, () => {
      // shipToNumber, poNumber, orderStatus, orderFilter,referenceNumber,fromDate,toDate,dateComparisonType,orderSearchNumber,orderSearchSequenceNumber
      this.props.doSearch(
        this.state.selectedShipTo,
        this.state.searchPOText,
        this.state.selectedStatusType,
        this.state.selectedOrderType,
        this.state.invoiceValue === null || this.state.invoiceValue === ""
          ? "0"
          : this.state.invoiceValue,
        this.state.fromDate,
        this.state.toDate,
        this.state.selectedDataType,
        this.state.orderValue === null || this.state.orderValue === ""
          ? "0"
          : this.state.orderValue,
        this.state.seqValue === null || this.state.seqValue === ""
          ? "0"
          : this.state.seqValue
      );
    });
  };
  resetFilter = () => {
    document.getElementById("fromDate").value = null;
    document.getElementById("toDate").value = null;
    this.setState({
      searchPOText: "",
      selectedShipTo: "All",
      selectedStatusType: "Open",
      selectedOrderType: "A",
      selectedDataType: "P",
      fromDate: "",
      toDate: "",
      orderValue: "",
      seqValue: "",
      invoiceValue: "",
      isOrderTypeEnabled: true,
    });
    this.props.resetFilterValues();
  };
  HideClicked = () => {
    var element = document.getElementById("searchFilters");
    var ele = document.getElementById("searchFilter");
    document.getElementById("imgs").style.display = "block";
    ele.classList.remove("filterBody");
    element.classList.add("searchFilter");
  };
  searchFilterClick = () => {
    var element = document.getElementById("searchFilters");
    var ele = document.getElementById("searchFilter");
    document.getElementById("imgs").style.display = "none";
    ele.classList.add("filterBody");
    element.classList.remove("searchFilter");
  };
  handleClose = () => this.setState({ show: false });
  render() {
    const shipToEnabled = this.state.shipTo.length > 1 ? true : false;
    const noShipTos= this.state.shipTo.length === 0 ? true : false;
    const invoiceEnabled= this.state.orderValue.length > 1 || this.state.seqValue.length > 1 ?  false : true;
    const orderSeqEnabled= this.state.invoiceValue.length > 1 ?  false : true;
   
    return (
      <div
        id="searchFilter"
        className={`${appConstants.isR3App ? "filterBodyR3" : "filterBody"}`}
      >
         {this.state.show && (
          <ESSModal
            handleClose={this.handleClose}
            btn1Text="OK"
            userMessage={this.state.userMessage}
            isAlert="true"
            show={this.state.show}
          />
        )}

        <div className="row" id="searchFilters">
          <div className="col-sm-11">
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-sm-3">
                <div className="form-group row">
                  <label
                    className="col-sm-2 col-form-label "
                    style={{ paddingRight: "0" }}
                  >
                    PO{" "}
                  </label>
                  <div className="col-sm-10" style={{ paddingLeft: "2px" }}>
                    <input
                      type="text"
                      className="form-control form-control-sm filterControls"
                      value={this.state.searchPOText}
                      name="searchPOText"
                      onChange={this.myChangeHandler}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group row">
                  <label
                    className="col-sm-2 col-form-label "
                    style={{ paddingRight: "0px", paddingLeft: "0px" }}
                  >
                    Ship To:{" "}
                  </label>
                  <div className="col-sm-9" style={{ paddingLeft: "0px" }}>
                    <select
                      id="disabledSelect"
                      className="form-control form-control-sm filterControls"
                      name="selectedShipTo"
                      disabled={!this.state.enableShipTo}
                      onChange={this.myChangeHandler}
                      value={this.state.selectedShipTo}
                    >
                      { this.state.enableShipTo && <option value="none">--ALL--</option>}
                      {noShipTos &&
                    <option value="none">--ALL--</option>
                   }
                      {this.state.shipTo.map((type) => (
                        <option
                          key={type.shipToNumber}
                          value={type.shipToNumber}
                        >
                          {type.name} {" - "} {type.shipToNumber}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div
                  className="form-group row"
                  style={{ alignItems: "center" }}
                >
                  <label
                    className="col-sm-3 col-form-label "
                    style={{ paddingRight: "0px", paddingLeft: "0px" }}
                  >
                    Status:{" "}
                  </label>
                  <div className="col-sm-3" style={{ paddingLeft: "0px" }}>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="Open"
                        name="selectedStatusType"
                        checked={this.state.selectedStatusType === "Open"}
                        onChange={this.onQualifierChange}
                      />
                      <label
                        className="form-check-label"
                        for="inlineRadio1"
                        style={{ paddingTop: "4px" }}
                      >
                        Open
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="Closed"
                        name="selectedStatusType"
                        checked={this.state.selectedStatusType === "Closed"}
                        onChange={this.onQualifierChange}
                      />
                      <label
                        className="form-check-label"
                        for="inlineRadio2"
                        style={{ paddingTop: "4px" }}
                      >
                        Closed
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="All"
                        name="selectedStatusType"
                        checked={this.state.selectedStatusType === "All"}
                        onChange={this.onQualifierChange}
                      />
                      <label
                        className="form-check-label"
                        for="inlineRadio3"
                        style={{ paddingTop: "4px" }}
                      >
                        All
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div
                  className="form-group row"
                  style={{ alignItems: "center" }}
                >
                  <label
                    className="col-sm-3 col-form-label "
                    style={{ paddingRight: "0px", paddingLeft: "0px" }}
                  >
                    Order Type:{" "}
                  </label>
                  <div className="col-sm-3" style={{ paddingLeft: "0px" }}>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="A"
                        name="selectedOrderType"
                        disabled={!this.state.isOrderTypeEnabled}
                        checked={this.state.selectedOrderType === "A"}
                        onChange={this.onQualifierChange}
                      />
                      <label
                        className="form-check-label"
                        for="inlineRadio1"
                        style={{ paddingTop: "4px" }}
                      >
                        All
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="B"
                        name="selectedOrderType"
                        disabled={!this.state.isOrderTypeEnabled}
                        checked={this.state.selectedOrderType === "B"}
                        onChange={this.onQualifierChange}
                      />
                      <label
                        className="form-check-label"
                        for="inlineRadio2"
                        style={{ paddingTop: "4px" }}
                      >
                        Backorders
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ alignItems: "center" }}>
              <div className="col-sm-3">
                <div
                  className="form-group row"
                  style={{ alignItems: "center" }}
                >
                  <label className="col-sm-6 col-form-label ">
                    Search Data Type:{" "}
                  </label>
                  <div className="col-sm-2" style={{ paddingLeft: "0px" }}>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="P"
                        name="selectedDataType"
                        checked={this.state.selectedDataType === "P"}
                        onChange={this.onQualifierChange}
                      />
                      <label
                        className="form-check-label"
                        for="inlineRadio1"
                        style={{ paddingTop: "4px" }}
                      >
                        PO
                      </label>
                    </div>
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="Ship"
                        name="selectedDataType"
                        checked={this.state.selectedDataType === "Ship"}
                        onChange={this.onQualifierChange}
                      />
                      <label
                        className="form-check-label"
                        for="inlineRadio2"
                        style={{ paddingTop: "4px" }}
                      >
                        Ship
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-sm-4"
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <div
                  className="form-group row"
                  style={{ alignItems: "center", marginBottom: "0.2rem" }}
                >
                  <label
                    className="col-sm-3 col-form-label "
                    style={{ paddingRight: "0px" }}
                  >
                    From Date:{" "}
                  </label>
                  <div className="col-sm-7" style={{ paddingLeft: "0px" }}>
                    <input
                      type="date"
                      id="fromDate"
                      name="fromDate"
                      className="form-control form-control-sm filterControls"
                    ></input>
                  </div>
                </div>
                <div
                  className="form-group row"
                  style={{ alignItems: "center" }}
                >
                  <label
                    className="col-sm-3 col-form-label "
                    style={{ paddingRight: "0px" }}
                  >
                    To Date:{" "}
                  </label>
                  <div className="col-sm-7" style={{ paddingLeft: "0px" }}>
                    <input
                      type="date"
                      id="toDate"
                      name="toDate"
                      onChange={this.datechange}
                      className="form-control form-control-sm filterControls"
                    ></input>
                  </div>
                </div>
              </div>

              <div
                className="col-sm-3"
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <div
                  className="form-group row"
                  style={{ alignItems: "center", marginBottom: "0.2rem" }}
                >
                  <label
                    className="col-sm-2 col-form-label "
                    style={{ paddingRight: "0" }}
                  >
                    Order:{" "}
                  </label>
                  <div className="col-sm-4" style={{ paddingLeft: "2px" }}>
                    <input
                      type="text"
                      className="form-control form-control-sm filterControls"
                      value={this.state.orderValue}
                      disabled={!orderSeqEnabled}
                      name="orderValue"
                      style={ !orderSeqEnabled ?  {backgroundColor:"darkgray"} : {} }
                      onChange={this.myChangeHandler}
                    ></input>
                  </div>
                  <label
                    className="col-sm-1 col-form-label "
                    style={{ paddingRight: "0", paddingLeft: "0px" }}
                  >
                    Seq:{" "}
                  </label>
                  <div className="col-sm-3" style={{ paddingLeft: "2px" }}>
                    <input
                      type="text"
                      className="form-control form-control-sm filterControls"
                      value={this.state.seqValue}
                      disabled={!orderSeqEnabled}
                      style={ !orderSeqEnabled ?  {backgroundColor:"darkgray"} : {} }
                      name="seqValue"
                      onChange={this.myChangeHandler}
                    ></input>
                  </div>
                </div>

                <div
                  className="form-group row"
                  style={{ alignItems: "center" }}
                >
                  <label
                    className="col-sm-3 col-form-label "
                    style={{ paddingRight: "0" }}
                  >
                    Invoice:{" "}
                  </label>
                  <div className="col-sm-6" style={{ paddingLeft: "0px" }}>
                    <input
                      type="text"
                      className="form-control form-control-sm filterControls"
                      value={this.state.invoiceValue}
                      disabled= {!invoiceEnabled}
                      name="invoiceValue"
                      style={ !invoiceEnabled ?  {backgroundColor:"darkgray"} : {} }
                      onChange={this.myChangeHandler}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className=" col-sm-1"
            style={{ alignItems: "center", padding: "0px", paddingTop: "20px" }}
          >
            <div className="row">
              <div className="col-sm-12" style={{ padding: "5px" }}>
                <button
                  className="btn btn-primary btn-sm filterButtons"
                  onClick={this.searchClicked}
                  type="button"
                >
                  Search
                </button>
              </div>
            </div>
            <div className="row col-sm-12" style={{ padding: "5px" }}>
              <button
                className="btn btn-primary btn-sm filterButtons"
                onClick={this.resetFilter}
                type="button"
              >
                Clear
              </button>
            </div>
            <div className="row col-sm-12" style={{ padding: "5px" }}>
              <button
                className="btn btn-primary btn-sm filterButtons"
                onClick={this.HideClicked}
                type="button"
              >
                Hide
              </button>
            </div>
          </div>
        </div>
        <div id="imgs" style={{ display: "none" }}>
          <img src={mgglass} onClick={this.searchFilterClick}></img>
        </div>
      </div>
    );
  }
}

export default DisplayOrderFilter;
