import React, { Component } from "react";
import bunzlsplash from "../assets/images/bunzl-splash.jpg";
import r3splash from "../assets/images/r3-splash.jpg";
import appConstants from "../_services/applicationConstant"


export default class HomePage  extends Component {
    constructor(props){
        super(props);
    }

    render(){

       
        return(
            <div>
               
                 <div className="container-fluid text-center" style={{paddingTop:"40px"}}>    
                <div className="row content">
    
                <div className="col-sm-12"> 
                <div style={{backgroundColor:'black'}}>
                { (appConstants.isR3App)?  <img  src={r3splash} alt="bunzlhomeimage"/> :  <img src={bunzlsplash} alt=""/>}
                </div>
                </div>
                </div>
                </div>
                
             
            </div>
        )
    }
}