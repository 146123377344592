import api from './apiUrl';
import {authHeader,encodedURL} from '../_helpers/auth-header'

var identityKey=localStorage.getItem('identityKey')

export function getFilteredData(data){
    let filteredData=[];
    data.forEach((item) => {
        filteredData.push({
            CustomerPartNumber:item.customerPartNumber,
            ItemNumber:item.itemNumber,
            SellCase:item.sellCase+'/'+item.sellUms,
            SellUms:item.sellUms,
            Description:item.description +item.description2,
            Description2:item.description2,
            PriceSource:item.priceSource,
            ShipmentType:item.shipmentType,
            ShipTypePending:item.curpnd,
            Price:currencyFormatter(item.price),
            EffectiveDate:dateFormatter(item.effectiveDate),
            ExpirationDate:dateFormatter(item.expirationDate)
           });
          })
    return filteredData;   
}
 
export function getCustomPriceReport() {

    return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/customPrice/getCustomPrices?identityKey=${identityKey.trim()}&companyNumber=${localStorage.getItem('companyNumber')}&customerNumber=${localStorage.getItem('customerNumber')}&loginType=${localStorage.getItem('loginTypeCode')}`)}`, {
          method: 'GET',
          headers:authHeader()
      }).then(response => response.json());
  
}

export function getCustomerPriceListData(customerPart, item, department, description)
{
    var urlparam='&custPart='+customerPart
    urlparam+='&distPart='+item
    urlparam+='&department='+department
    urlparam+='&partDescription='+description
    
    return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/customPrice/getCustomPrices?identityKey=${identityKey.trim()}&companyNumber=${localStorage.getItem('companyNumber')}&customerNumber=${localStorage.getItem('customerNumber')}&loginType=${localStorage.getItem('loginTypeCode')}${urlparam}`)}`, {
        method: 'GET',
        headers:authHeader()
    }).then(response => response.json());
}
    
function currencyFormatter(value) {
    if (value !== undefined && value !== "") {
      if (parseFloat(value) < 0) return "-$" + parseFloat(value) * -1;
      else return "$" + value;
    }
    return "";
  }
  function dateFormatter(inputDate) {
    if(inputDate != null &&  inputDate != "" && inputDate != undefined){
    var d = inputDate.split(" ");
    var dateValue = d[0];
    return dateValue;
    }
    else return "";
  }
    
