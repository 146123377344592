import React, { Component } from "react";
import appConstants from "../../_services/applicationConstant";
import DisplayOrderFilter from "./DisplayOrderFilter";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { getInventoryData } from "../../_services/inventory.service";
import { CSVLink } from "react-csv";
import downloadbutton from "../../assets/images/downloadbutton.gif";
import {
  geDisplayOrderHeaderData,
  getFilteredData,
} from "../../_services/displayOrder.service";
import fedex from "../../assets/images/FedEx.gif";
import ups from "../../assets/images/ups.jpg";
import OrderDetails from "./OrderDetails";
import { resetIdCounter } from "react-tabs";
import UPSTrackingNumbersCard from "./UPSTrackingNumbersCard";
import { authHeader, encodedURL } from "../../_helpers/auth-header";
import api from "../../_services/apiUrl";
import ESSModal from "../../components/ESSModal.jsx";
import ESSProgressBar from "../../components/ESSProgressBar";
import ESSLoadingModal from "../../components/ESSLoadingModal";
import user from '../../models/User';
import { decryptData } from '../../Utils';

class DisplayOrder extends Component {
  constructor(props) {
    //user.toUser = JSON.parse(localStorage.getItem("UserMenu"));

    const salt = process.env.SALT || process.env.REACT_APP_KEY;
    const originalData = decryptData(localStorage.getItem("UserMenu"), salt);
    user.toUser = originalData;

    super(props);
    this.handlePOClick = this.handlePOClick.bind(this);
    this.handleInvoiceClick = this.handleInvoiceClick.bind(this);
    this.handleUPSlick = this.handleUPSlick.bind(this);
    this.handleFDXClick = this.handleFDXClick.bind(this);
    this.csvLink = React.createRef();
    this.state = {
      companyNumber: localStorage.getItem("companyNumber"),
      searchClicked:false,
      shipToNumber: "All",
      poNumber:  "",
      orderStatus:  "Open",
      orderFilter: "A",
      referenceNumber:  "0",
      fromDate: "",
      toDate: "",
      dateComparisonType: "P",
      orderSearchNumber: "0",
      orderSearchSequenceNumber: "0",
      show: false,
          userMessage:'',
          showLoading: false,
      columnDefs: [
        {
          headerName: "PO #",
          field: "poNumber",
          cellStyle: {
            textAlign: "left",
            borderRight: "0.1px solid lightgray",
          },
          resizable: true,
          width: 120,
          tooltip: (params) => "Double click to show the details for an order",
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },

        {
          headerName: "Order #",
          tooltip: (params) => "Double click to show the details for an order",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "orderNumber",
          valueFormatter: orderFormater,
          resizable: true,
          width: 120,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Invoice #",
          cellStyle: {
            textAlign: "left ",
            borderRight: "0.1px solid lightgray",
          },
          tooltip: (params) =>
          "Click to view an Image of Invoice#" + "" + params.value,
          field: "referenceNumber",
          resizable: true,
          width: 90,
          cellRendererFramework: (props) => {
            if(props.value === ""|| props.value === null)
            return "";
            else if ( user.toUser.CanViewInvoices == "N")
            return props.value;
            else 
            return (
              <button
                className="btn btn-xs btn-link"
                style={{ fontSize: "0.7rem" }}
                onClick={this.handleInvoiceClick.bind(this, props.data)}
              >
                {props.value}{" "}
              </button>
            );
          },
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Ship To Name",
          tooltip: (params) => "Double click to show the details for an order",
          cellStyle: {
            textAlign: "left",
            borderRight: "0.1px solid lightgray",
          },
          field: "shipToName",
          resizable: true,
          width: 180,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Ship To #",
          tooltip: (params) => "Double click to show the details for an order",
          cellStyle: {
            textAlign: "left",
            borderRight: "0.1px solid lightgray",
          },
          field: "shipToNumber",
          resizable: true,
          width: 80,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Type",
          tooltip: (params) => "Double click to show the details for an order",
          cellStyle: {
            textAlign: "center",
            borderRight: "0.1px solid lightgray",
          },
          field: "orderType",
          resizable: true,
          width: 120,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Status",
          tooltip: (params) => "Double click to show the details for an order",
          cellStyle: {
            textAlign: "center",
            borderRight: "0.1px solid lightgray",
          },
          field: "orderStatus",
          resizable: true,
          width: 120,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Order Date",
          tooltip: (params) => "Double click to show the details for an order",
          cellStyle: {
            textAlign: "center",
            borderRight: "0.1px solid lightgray",
          },
          field: "orderDate",
          cellRenderer: dateFormatter,
           comparator:fn_DateCompare,
          resizable: true,
          width: 100,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Ship Date",
          tooltip: (params) => "Double click to show the details for an order",
          cellStyle: {
            textAlign: "center",
            borderRight: "0.1px solid lightgray",
          },
          field: "shipDate",
          cellRenderer: dateFormatter,
          comparator:fn_DateCompare,
          tooltip: (params) => "Double click to show the details for an order",
          resizable: true,
          width: 100,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Order $",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          type: "rightAligned",
          field: "totalOrderAmount",
          valueFormatter: currencyFormatter,
          comparator: compare,
          tooltip: (params) => "Double click to show the details for an order",
          resizable: true,
          width: 120,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Shipping",
          cellStyle: {
            textAlign: "center",
            borderRight: "0.1px solid lightgray",
            paddingTop: "0px",
            height: "fit-content",
            marginTop: "-5px",
          },
          field: "isShippedViaUPSFedex",
          tooltip: (params) => "Double click to show the details for an order",
          cellRendererFramework: (params) => {
            if (
              params.value !== "" ||
              params.value !== undefined ||
              params.value !== null
            ) {
              var titleMessage =
                "Click to view UPS/Fedex Tracking information for order# " +
                params.data.orderNumber +
                "-" +
                ("0" + params.data.orderSequenceNumber).slice(-2);
              if (params.value === "UPS" && user.toUser.CanViewUPSTrackingInfo == "Y")
                return (
                  <img
                    src={ups}
                    style={{ cursor: "Pointer" }}
                    title={titleMessage}
                    onClick={this.handleUPSlick.bind(
                      this,
                      params.data.orderNumber,
                      params.data.orderSequenceNumber
                    )}
                  />
                );
              else if (params.value === "FEDEX" && user.toUser.CanViewUPSTrackingInfo == "Y")
                return (
                  <img
                    src={fedex}
                    style={{ cursor: "Pointer" }}
                    onClick={this.handleFDXClick.bind(
                      this,
                      params.data.orderNumber,
                      params.data.location,
                      params.data.orderSequenceNumber
                    )}
                  />
                );
              else return "";
            }
          },
          resizable: true,
          width: 120,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
      ],

      rowData: [],
      exportData: [],
      showOD: false,
      selectedPO: "",
      showUPS: false,
      showFEDEX: false,
      isfedx: false,
      selectedUPS: "",
      selectedUPSSeq: "",
      selectedOrder: "",
      selectedOrderSeq: "",
      selectedLocation: "",
      selectedInvoice: "",
      selectgedUPSSeqString: "",
      selectedUPSDisplay: "",
      defaultColDef: { resizable: true, sortable: true },
    };
  }
  fetchData = () => {
    geDisplayOrderHeaderData(
      this.state.shipToNumber,
      this.state.poNumber,
      this.state.orderStatus,
      this.state.orderFilter,
      this.state.referenceNumber,
      this.state.fromDate,
      this.state.toDate,
      this.state.dateComparisonType,
      this.state.orderSearchNumber,
      this.state.orderSearchSequenceNumber
    ).then((data) => {
      this.setState(
        { exportData: getFilteredData(data, this.getExportColumns()) },
        () => {
          setTimeout(() => {
            this.csvLink.current.link.click();
          }, 100);
        }
      );
    });
  };
  onRowDoubleClicked = () => {
    var selectedRows = this.gridApi.getSelectedRows();

    localStorage.setItem("shipToDO",selectedRows[0].shipToNumber,
    );
    this.setState({
      showOD: true,
      selectedUPSDisplay: selectedRows[0].isShippedViaUPSFedex,
      selectedLocation: selectedRows[0].location,
      selectedPO: selectedRows[0].poNumber,
      selectedOrder: selectedRows[0].orderNumber,
      selectedOrderSeq: selectedRows[0].orderSequenceNumber,
      selectedInvoice: selectedRows[0].referenceNumber,
      selectedStatus:selectedRows[0].orderStatus,
      

    });

  };
  handleInvoiceClick(data) {
    this.setState({showLoading:true});
    var pdfWindow = window.open('','_blank');
    pdfWindow.document.body.innerHTML  = "<center><p style='color:blue;'>Downloading Invoice Image...</p></center>"; 
    const custno=String(localStorage.getItem("customerNumber")).padStart(6, '0');
    return fetch(
      `${api.proxyUrlForDownload}${api.url}${encodedURL(
        `/pdf-downloader/invoice-pdf-download?invoiceId=${data.referenceNumber}&companyNumber=${this.state.companyNumber}&group=${custno}`
      )}`,
      {
        method: "GET",
        headers: authHeader()
      }
    )
      .then((response) => {if(response.status === 500) {
        throw new Error('Error while downloading the Invoice Image.')
      } 
      else return  response.blob()})
      .then((response) => {
        const file = new Blob([response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //   window.navigator.msSaveOrOpenBlob(file);
        //   return;
        // }
        pdfWindow.location.href = fileURL;
       this.setState({showLoading:false});
      },
      (error) => {  
        pdfWindow.close(); 
      this.setState({showLoading:false});
        this.setState({
          show: true,
          userMessage: 'Error while downloading the Invoice Image.\n Please retry after some time.'
      });
      })
      .catch(function(error) {
        pdfWindow.close(); 
        console.log(error);
       this.setState({showLoading:false});
    });
  }
  handlePOClick(poNumber, orderNumber, orderSeq, location, invoiceNumber) {
    this.setState({
      showOD: true,
      selectedPO: poNumber,
      selectedOrder: orderNumber,
      selectedOrderSeq: orderSeq,
      selectedLocation: location,
      selectedInvoice: invoiceNumber,
    });
  }
  handleUPSlick(order, orderSeq) {
    this.setState({
      showUPS: true,
      isfedx:false,
      selectedUPS: order,
      selectedUPSSeq: orderSeq,
      selectgedUPSSeqString: ("0" + orderSeq).slice(-2),
    });
  }

  handleClose = () => this.setState({ show: false });


  handleFDXClick(order, location, orderSeq) {
    this.setState({
      showUPS: true,
      isfedx: true,
      showFEDEX: true,
      selectedUPS: order,
      selectedUPSSeq: orderSeq,
      selectedLocation: location,
    });
  }
  getExportColumns() {
    let exportColumnsList = [
      "PONumber",
      "OrderNumber",
      "InvoiceNumber",
      "ShipToName",
      "Type",
      "Status",
      "OrderDate",
      "ShipDate",
      "OrderAmount",
    ];

    return exportColumnsList;
  }
  dateConverter =(dateValue) =>{
    if(dateValue!=='')  {        
      var from=dateValue.split("-")  
     var  fromDate=from[1]  +"/"+ from[2] +"/"+ from[0]  ;
     return fromDate;
  }
  return dateValue;
}
resetFilterValues = () =>{
  this.setState({
    shipToNumber: "All",
    poNumber:  "",
    orderStatus:  "Open",
    orderFilter: "A",
    referenceNumber:  "0",
    fromDate: "",
    toDate: "",
    dateComparisonType: "P",
    orderSearchNumber: "0",
    orderSearchSequenceNumber: "0"}, ()=>{});
}
  doSearch = (
    shipToNumber,
    poNumber,
    orderStatus,
    orderFilter,
    referenceNumber,
    fromDate,
    toDate,
    dateComparisonType,
    orderSearchNumber,
    orderSearchSequenceNumber,

  ) => {
    fromDate = this.dateConverter(fromDate);//fromDate ? new Date(fromDate).toLocaleDateString("en-US") : "";
    toDate =this.dateConverter(toDate); //toDate ? new Date(toDate).toLocaleDateString("en-US") : "";
    this.setState({
      shipToNumber: shipToNumber,
      poNumber: poNumber,
      orderStatus: orderStatus,
      orderFilter: orderFilter,
      referenceNumber: referenceNumber,
      fromDate: fromDate,
      toDate: toDate,
      dateComparisonType: dateComparisonType,
      orderSearchNumber: orderSearchNumber,
      orderSearchSequenceNumber: orderSearchSequenceNumber,
      searchClicked:true,
      rowData:[] 
    });
    geDisplayOrderHeaderData(
      shipToNumber,
      poNumber,
      orderStatus,
      orderFilter,
      referenceNumber,
      fromDate,
      toDate,
      dateComparisonType,
      orderSearchNumber,
      orderSearchSequenceNumber
    ).then((data) => {
      if(data.length > 0)
      this.setState({ rowData: data, searchClicked:false });
      else{
        this.setState({
          show: true,
          userMessage:
            "No matching Orders have been found.",
            searchClicked:false
        });
      }
    });
  };
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };
  orderSummaryClick = () => {
    this.setState({ showOD: false, showUPS: false });
  };

  render() {
    const renderDisplayOrderGrid = () => {
      return (
        <div
          className={`ag-theme-alpine ${
            appConstants.isR3App ? "gridMainR3" : "gridMain"
          }`}
          style={{ height: "72%", width: "100%" }}
        >
        {this.state.searchClicked?
        <ESSProgressBar/>
       :''}
          <AgGridReact
            defaultColDef={this.state.defaultColDef}
            columnDefs={this.state.columnDefs}
            enableCellTextSelection={true}
            rowData={this.state.rowData}
            rowHeight="30"
            headerHeight="30"
            footerHeight="30"
            onGridReady={this.onGridReady}
            rowSelection="single"
            onRowDoubleClicked={this.onRowDoubleClicked.bind(this)}
          ></AgGridReact>
        </div>
      );
    };
    return (
      <div>
        <div
          className={`container-fluid text-center ${
            appConstants.isR3App ? "contentPageR3" : "contentPage"
          }`}
          style={{ height: "98%" }}
        >
          {this.state.showLoading && 
          <ESSLoadingModal />}
          {this.state.show && (
          <ESSModal
            handleClose={this.handleClose}
            btn1Text="OK"
            userMessage={this.state.userMessage}
            isAlert="true"
            show={this.state.show}
          />
        )}
          <div
            className={` card text-left cardNoBorder ${
              appConstants.isR3App ? "logintitleR3" : "logintitle"
            }`}
            style={{ height: "85%" }}
          >
            <div className="row">
              <div className="col-sm-4">
                {this.state.showOD ? (
                  <h6 className="card-header">Display Order</h6>
                ) : this.state.showUPS ? (
                  <h6 className="card-header">
                    Shipments for Order# {this.state.selectedUPS} -{" "}
                    {this.state.selectgedUPSSeqString}{" "}
                    
                  </h6>
                ) : (
                  <h6 className="card-header">
                    Search for and display customer orders
                  </h6>
                )}
              </div>
              <div className="col-sm-3">
                {this.state.showOD || this.state.showUPS ? (
                  <div style={{ padding: "9px" }}>
                    <div className="row">
                      <div className="col-sm-5">
                        <span
                          style={
                            appConstants.isR3App
                              ? {
                                  fontWeight: "bold",
                                  fontSize: "0.8rem",
                                  cursor: "pointer",
                                  color: "#000000",
                                }
                              : {
                                  fontWeight: "bold",
                                  fontSize: "0.8rem",
                                  cursor: "pointer",
                                  color: "#1d2f68",
                                }
                          }
                          onClick={this.orderSummaryClick}
                        >
                          Order Search
                        </span>
                        {" > "}
                        <span></span>
                      </div>
                      <div className="col-sm-5">
                        {this.state.showUPS ? (
                          <span>Order Tracking</span>
                        ) : (
                          <span>Order Detail</span>
                        )}
                      </div>
                      
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <div
              className="card-body"
              style={{ backgroundColor: "#fff", padding: "0.5rem" }}
            >
              {!this.state.showOD && !this.state.showUPS ? (
                <div className="row content" style={{ height: "98%" }}>
                  <div className="col-sm-12" style={{ height: "98%" }}>
                    <div className="row" ref="filterComponent">
                      <div className="col-sm-10">
                        <DisplayOrderFilter resetFilterValues={this.resetFilterValues} doSearch={this.doSearch} />
                      </div>
                      <div className="col-sm-1" style={{ paddingTop: "4%" }}>
                        <div style={{ paddingTop: "3%" }}>
                          {/* { 1 > 0 ? 
                           <CSVLink ref="csv" data={this.state.exportData} filename={'DisplayOrders.csv'}> 
                         <img src={downloadbutton} title="Download Customer Order Details List Items."  alt="Download Report" />
                          </CSVLink> 
                          : null}  */}
                          <button
                            style={{ border: "none" }}
                            onClick={this.fetchData}
                          >
                            <img
                              src={downloadbutton}
                              title="Download Customer Order Details List Items."
                              alt="Download Report"
                            />
                          </button>
                          <CSVLink
                            data={this.state.exportData}
                            filename="DisplayOrders.csv"
                            className="hidden"
                            ref={this.csvLink}
                            target="_blank"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="row"
                      ref="gridComponent"
                      style={{ height: "98%" }}
                    >
                      <div className="col-sm-12" style={{ height: "98%" }}>
                        {renderDisplayOrderGrid()}
                      </div>
                    </div>
                  </div>
                </div>
              ) : this.state.showOD ? (
                <div style={{ height: "100%" }}>
                  <OrderDetails
                    selectedLocation={this.state.selectedLocation}
                    selectedOrder={this.state.selectedOrder}
                    selectedOrderSeq={this.state.selectedOrderSeq}
                    selectedInvoice={this.state.selectedInvoice}
                    selectedPO={this.state.selectedPO}
                    showups={this.state.selectedUPSDisplay}
                    showOD={this.state.showOD}
                    selectedStatus={this.state.selectedStatus}
                  />
                </div>
              ) : (
                this.state.showUPS && (
                  <div style={{ height: "100%" }}>
                    <UPSTrackingNumbersCard
                      orderNumber={this.state.selectedUPS}
                      orderSeqNumber={this.state.selectgedUPSSeqString}
                      location={this.state.selectedLocation}
                      showups={this.state.showUPS}
                      showFEDEX={this.state.showFEDEX}
                      isfedx={this.state.isfedx}
                      showOD="false"
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DisplayOrder;
function currencyFormatter(params) {
  // if (parseFloat(params.value) < 0) return "-$" + parseFloat(params.value) * -1;
  // else return "$" + params.value;
  if (params.value !== undefined && params.value !== "" && params.value !== null && params.value!== '0' &&  params.value!== '0.00') {
    if (parseFloat(params.value) < 0) return "-$" +  (parseFloat(params.value).toFixed(2) * -1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else return "$" +  (parseFloat(params.value).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return "$0.00";
}

function orderFormater(params) {
  return (
    params.data.orderNumber +
    "-" +
    ("0" + params.data.orderSequenceNumber).slice(-2)
  );
}

function dateFormatter(param) {
  if(param.value != null &&  param.value != "" && param.value != undefined){
  var d = param.value.split(" ");
  var dateValue = d[0];
  return dateValue;
  }
  else return "";
}
function changeCursor()
{
  document.body.style.cursor = 'wait';
}
function changeCursorToDefault()
{
  document.body.style.cursor = 'default';
}
function fn_DateCompare(DateA, DateB) {
  var a = new Date(DateA);
  var b = new Date(DateB);

  var msDateA = Date.UTC(a.getFullYear(), a.getMonth()+1, a.getDate());
  var msDateB = Date.UTC(b.getFullYear(), b.getMonth()+1, b.getDate());

  if (parseFloat(msDateA) < parseFloat(msDateB))
    return -1;  // less than
  else if (parseFloat(msDateA) == parseFloat(msDateB))
    return 0;  // equal
  else if (parseFloat(msDateA) > parseFloat(msDateB))
    return 1;  // greater than
  else
    return null;  // error
}
function compare (number1, number2) {
  if (number1 === null && number2 === null) {
  return 0;
  }
  if(isNaN(number1)){ return -1}
  if(isNaN(number2)){ return 1}
  if (number1 === null) {
  return -1;
  }
  if (number2 === null) {
  return 1;
  }
  return number1 - number2;}