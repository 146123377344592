import { AgGridReact } from "ag-grid-react";
import React, { Component } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { getCustomerList } from "../_services/customerList.service";
import authenticationService from "../_services/authentication.services";
import { withRouter } from "react-router-dom";
import appConstants from "../_services/applicationConstant"


class CustomerList extends Component {
  constructor(props) {
    super(props);
    this.Auth = new authenticationService();
    this.state = {
      search: false,
      inventoryList: [],
      loginType:localStorage.getItem('loginTypeCode'),
      columnDefs: [
        {
          headerName: "Company",
          tooltip: (params) => "Double click to Select a customer",
          field: "Company",
          cellStyle: {
            textAlign: "center",
            borderRight: "0.3px solid lightgray",
          },
          resizable: true,sortable:true,
          width: 120,
          headerClass: `${appConstants.isR3App ? "gridheaderR3" : "gridheader"}`,
        },

        {
          headerName: "Location",
          tooltip: (params) => "Double click to Select a customer",
          cellStyle: {
            textAlign: "left",
            borderRight: "0.1px solid lightgray",
          },
          field: "Location",
          resizable: true,sortable:true,
          width: 170,
          headerClass: `${appConstants.isR3App ? "gridheaderR3" : "gridheader"}`,
        },
        {
          headerName: "Customer Number",
          tooltip: (params) => "Double click to Select a customer",
          cellStyle: {
            textAlign: "left ",
            borderRight: "0.1px solid lightgray",
          },
          field: "CustomerNumber",
          resizable: true,sortable:true,
          width: 180,
          headerClass: `${appConstants.isR3App ? "gridheaderR3" : "gridheader"}`,
        },
        {
          headerName: "Ship To",
          tooltip: (params) => "Double click to Select a customer",
          cellStyle: {
            textAlign: "left",
            borderRight: "0.1px solid lightgray",
          },
          field: "ShipTo",
          resizable: true,sortable:true,
          width: 180,
          headerClass: `${appConstants.isR3App ? "gridheaderR3" : "gridheader"}`,
        },
        {
          headerName: "Name",
          tooltip: (params) => "Double click to Select a customer",
          cellStyle: {
            textAlign: "left",
            borderRight: "0.1px solid lightgray",
          },
          field: "Name",
          resizable: true,sortable:true,
          width: 190,
          headerClass: `${appConstants.isR3App ? "gridheaderR3" : "gridheader"}`,
        },
        {
          headerName: "City",
          tooltip: (params) => "Double click to Select a customer",
          cellStyle: {
            textAlign: "left",
            borderRight: "0.1px solid lightgray",
          },
          field: "City",
          resizable: true,sortable:true,
          width: 240,
          headerClass: `${appConstants.isR3App ? "gridheaderR3" : "gridheader"}`,
        },
        {
          headerName: "State",
          tooltip: (params) => "Double click to Select a customer",
          cellStyle: {
            textAlign: "left",sortable:true,
            borderRight: "0.1px solid lightgray",
          },
          field: "State",
          resizable: true,sortable:true,
          width: 190,
          headerClass: `${appConstants.isR3App ? "gridheaderR3" : "gridheader"}`,
        },
      ],
    };
  }

  onGridReady = (params) => {
    document.getElementById("nextBtn").disabled = true;
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    getCustomerList().then((data) => {
      this.setState({ rowData: data });
    });
  };

  onRowDoubleClicked = () => {
  this.navigate();
  };

  
  onSelectionChanged = () => {
    document.getElementById("nextBtn").disabled = false;
    };

  navigate = () =>{
    var selectedRows = this.gridApi.getSelectedRows();
    localStorage.setItem("companyNumber", selectedRows[0].Company);
    localStorage.setItem("customerNumber", selectedRows[0].CustomerNumber);
    localStorage.setItem("customerGroupShipToValue", selectedRows[0].ShipTo);

    this.Auth.getCustomerLocation().then(()=>{
      this.Auth.getCustomerAddress().then(() =>{
      this.props.history.push({
        pathname: "/Home",
      });
    window.location.reload();
    }); 
  });    
  };

  onPreviousClick = () => {
    var loginTypeCode= localStorage.getItem('loginTypeCode')
    if(loginTypeCode === 'E'){
    if(this.props.fromMenu === "false")
    this.props.history.push({
      pathname: "/EmployeeList",
    })
    else this.props.history.push({
      pathname: "/EmployeeListFromMenu",
    });
  }
    else
    this.props.history.push({
      pathname: "/Login",
    })
  };

  render() {
 const renderGrid = ()=> {
   return (
    <div style={{height:"100%"}}>
    <h6 style={{paddingTop:"5px", paddingLeft: "20px"}}>Select a customer</h6>

    <div
      className= {`col-sm-12 ag-theme-alpine ${appConstants.isR3App ? "apGridR3" : "apGrid"}`}
      style={{
        height: "460px",
        width: "1300px",
        alignContent: "center",
        paddingTop: "10px",
      }}
    >
      <AgGridReact
        columnDefs={this.state.columnDefs}
        enableCellTextSelection={true}
        rowData={this.state.rowData}
        onGridReady={this.onGridReady}
       
        rowHeight="30"
        headerHeight="30"
        rowSelection="single"
        rowClassRules={this.state.rowClassRules}
        onSelectionChanged={this.onSelectionChanged.bind(this)}
        onRowDoubleClicked={this.onRowDoubleClicked.bind(this)}
        tooltipShowDelay="0"
      ></AgGridReact>
    </div>
    <div className="row" style={{textAlign:"right"}}>
        
      {this.props.fromMenu !=="false"  && this.state.loginType ==='G' ?"": 
    <button style={{marginLeft:"400px",marginBottom:"20px"}} id="previoustBtn" onClick={this.onPreviousClick} className="btn btn-sm btn-light">Previous</button>
 }
    <h6 className={`${this.props.fromMenu !=="false"  && this.state.loginType ==='G' ? "empListText" : "custListText"}`}>Select a customer and click the 'Next' button</h6>
    <button style={{marginLeft:"20px",marginBottom:"20px"}} id="nextBtn" onClick={this.navigate}  className="btn btn-sm btn-light">Next</button>
</div>

  </div>

   );
 }

    return (
      <div style={{height:"100%"}}>      { this.props.fromMenu == "false" ?
      <div className={`${appConstants.isR3App ? "maindivR3" : "maindiv"}`}>
        <h5>SelfService</h5>
        <div
          className={`${
            appConstants.isR3App ? "divContainerGridR3" : "divContainerGrid"
          }`}
        >
         { renderGrid()}
        </div>
      </div>
      :
      <div style={{margin:"0px",height:"100%", borderTop:"solid"}}
      className={` ${
        appConstants.isR3App ? "divContainerGridR3" : "divContainerGrid"
      }`}
    >
     { renderGrid()}
    </div>
        }
        </div>

    );
  }
}
export default withRouter(CustomerList);
