import React, { Component } from "react";
import { Nav, Navbar, Form, FormControl } from "react-bootstrap";
import styled from "styled-components";
import CustomerContact from "./CustomerContact";
import user from "../models/User";
import bunzlCSS from "../assets/css/bunzlUI.css";
import appConstants from "../_services/applicationConstant";
import { decryptData } from '../Utils';
class NavigationBar extends Component {
  render() {
    const salt = process.env.SALT || process.env.REACT_APP_KEY;
    const originalData = decryptData(localStorage.getItem("UserMenu"), salt);
    user.toUser = originalData;

    const navStyle = appConstants.isR3App
      ? {
          fontSize: "12px",
          height: "30px",
          fontWeight: "bold",
          backgroundColor: "#0079c2",
        }
      : {
          fontSize: "12px",
          height: "30px",
          fontWeight: "bold",
          backgroundColor: "#79b91c",
        };
    const loginTypeCode = localStorage.getItem("loginTypeCode");
    return (
      <div>
        <Navbar
          className={`${appConstants.isR3App ? "LinkBarR3" : "LinkBar"}`}
          className="LinkBar"
          expand="lg"
        >
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="form-center" style={navStyle}>
              <Nav.Item>
                <Nav.Link href="/home">Home</Nav.Link>
              </Nav.Item>

              <h6
                className={`h6 nav-line ${
                  user.toUser.CanViewAR == "Y" ? "nav-show" : "nav-hide"
                }`}
              >
                |
              </h6>
              <Nav.Item
                className={`${
                  user.toUser.CanViewAR == "Y" ? "nav-show" : "nav-hide"
                }`}
              >
                <Nav.Link href="./AccountsPayable">
                  {user.toUser.CanViewAR == "Y" ? "AP" : ""}
                </Nav.Link>
              </Nav.Item>

              <h6
                className={`h6 nav-line ${
                  user.toUser.CanViewInventory == "Y" ? "nav-show" : "nav-hide"
                }`}
              >
                |
              </h6>
              <Nav.Item
                className={`${
                  user.toUser.CanViewInventory == "Y" ? "nav-show" : "nav-hide"
                }`}
              >
                <Nav.Link href="/Inventory">
                  {user.toUser.CanViewInventory == "Y" ? "Inventory" : ""}
                </Nav.Link>
              </Nav.Item>

              <h6
                className={`h6 nav-line ${
                  user.toUser.CanViewOrders == "Y" ? "nav-show" : "nav-hide"
                }`}
              >
                |
              </h6>
              <Nav.Item
                className={`${
                  user.toUser.CanViewOrders == "Y" ? "nav-show" : "nav-hide"
                }`}
              >
                <Nav.Link href="./about">
                  {user.toUser.CanViewOrders == "Y" ? "Display Orders" : ""}
                </Nav.Link>
              </Nav.Item>

              <h6
                className={`h6 nav-line ${
                  user.toUser.CanViewCustomerPrices == "Y"
                    ? "nav-show"
                    : "nav-hide"
                }`}
              >
                |
              </h6>
              <Nav.Item
                className={`${
                  user.toUser.CanViewCustomerPrices == "Y"
                    ? "nav-show"
                    : "nav-hide"
                }`}
              >
                <Nav.Link href="/about">
                  {user.toUser.CanViewCustomerPrices == "Y"
                    ? "Custom Prices"
                    : ""}
                </Nav.Link>
              </Nav.Item>

              <h6
                className={`h6 nav-line ${
                  user.toUser.CanViewSales == "Y" ? "nav-show" : "nav-hide"
                }`}
              >
                |
              </h6>
              <Nav.Item
                className={`${
                  user.toUser.CanViewSales == "Y" ? "nav-show" : "nav-hide"
                }`}
              >
                <Nav.Link href="/about">
                  {user.toUser.CanViewSales == "Y" ? "Purchase" : ""}
                </Nav.Link>
              </Nav.Item>

              <h6
                className={`h6 nav-line ${
                  user.toUser.CanViewSalesAnalysis == "Y"
                    ? "nav-show"
                    : "nav-hide"
                }`}
              >
                |
              </h6>
              <Nav.Item
                className={`${
                  user.toUser.CanViewSalesAnalysis == "Y"
                    ? "nav-show"
                    : "nav-hide"
                }`}
              >
                <Nav.Link href="/about">
                  {user.toUser.CanViewSalesAnalysis == "Y"
                    ? "Purchase Analysis"
                    : ""}
                </Nav.Link>
              </Nav.Item>

              <h6
                className={`h6 nav-line ${
                  user.toUser.CanViewPriceList == "Y" ? "nav-show" : "nav-hide"
                }`}
              >
                |
              </h6>
              <Nav.Item
                className={`${
                  user.toUser.CanViewPriceList == "Y" ? "nav-show" : "nav-hide"
                }`}
              >
                <Nav.Link href="/PriceList">
                  {user.toUser.CanViewPriceList == "Y" ? "Price List" : ""}
                </Nav.Link>
              </Nav.Item>
              <h6
                className={`h6 nav-line ${
                  user.toUser.CanViewSales == "Y" ? "nav-show" : "nav-hide"
                }`}
              >
                |
              </h6>
              <Nav.Item
                className={`${
                  user.toUser.CanViewSales == "Y" ? "nav-show" : "nav-hide"
                }`}
              >
                <Nav.Link href="/about">
                  {user.toUser.CanViewSales == "Y" ? "Item Tracking" : ""}
                </Nav.Link>
              </Nav.Item>
              <h6
                className={`h6 nav-line ${
                  user.toUser.CanViewSales == "Y" ? "nav-show" : "nav-hide"
                }`}
              >
                |
              </h6>
              <Nav.Item
                className={`${
                  user.toUser.CanViewSales == "Y" ? "nav-show" : "nav-hide"
                }`}
              >
                <Nav.Link href="/about">
                  {user.toUser.CanViewSales == "Y" ? "Item Forecast" : ""}
                </Nav.Link>
              </Nav.Item>
              <h6
                className={`h6 nav-line ${
                  user.toUser.CanViewOG == "Y" ? "nav-show" : "nav-hide"
                }`}
              >
                |
              </h6>
              <Nav.Item
                className={`${
                  user.toUser.CanViewOG == "Y" ? "nav-show" : "nav-hide"
                }`}
              >
                <Nav.Link href="/about">
                  {user.toUser.CanViewOG == "Y" ? "Order Guide" : ""}
                </Nav.Link>
              </Nav.Item>
              <h6 className="h6 nav-line">|</h6>
              <Nav.Item>
                <Nav.Link href="/about">My Account</Nav.Link>
              </Nav.Item>
              <h6
                className={`h6 nav-line ${
                  loginTypeCode == "C" || loginTypeCode == "G"
                    ? "nav-hide"
                    : "nav-show"
                }`}
              >
                |
              </h6>
              <Nav.Item
                className={`${loginTypeCode == "C" ? "nav-hide" : "nav-show"}`}
              >
                <Nav.Link href="/EmployeeList">
                  {loginTypeCode === "E" ? "Employee" : ""}
                </Nav.Link>
              </Nav.Item>
              <h6
                className={`h6 nav-line ${
                  loginTypeCode == "C" ? "nav-hide" : "nav-show"
                }`}
              >
                |
              </h6>
              <Nav.Item
                className={`${loginTypeCode == "C" ? "nav-hide" : "nav-show"}`}
              >
                <Nav.Link href="/CustomerListMenu">
                  {loginTypeCode === "G" || loginTypeCode === "E"
                    ? "Customer"
                    : ""}
                </Nav.Link>
              </Nav.Item>
              <h6 className="h6 nav-line">|</h6>
              <Nav.Item>
                <Nav.Link href="/">Log Out</Nav.Link>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}
export default NavigationBar;
