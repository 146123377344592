import { authHeader,encodedURL } from "../_helpers/auth-header";
import user from "../models/User";
import api from "../_services/apiUrl";
import { decryptData } from '../Utils';

export function getItenEntryList(){
  var reportColumns= [
      {id: 1, value: "Distributor Item#"},
      {id: 2, value: "Customer Part#"},      
    ]   
  
  return reportColumns;   
}

function getDescription(desc)
{
    return  desc.replace(/"/g,'\""') ;
}

export function getFilteredData(data, visiblePropList){
  let filteredData=[];
  
  data.map((item) => {  
  let filterObject = {};
      
    visiblePropList.forEach(prop => filterObject[prop]=''); 
      if(filterObject.hasOwnProperty('ComapanyCode')) filterObject.ComapanyCode = item.codeCompany;
      if(filterObject.hasOwnProperty('CompanyDescription')) filterObject.CompanyDescription = getDescription(item.descCompany);
      if(filterObject.hasOwnProperty('CustomerPartNumber')) filterObject.CustomerPartNumber = item.codeCustPart;
      if(filterObject.hasOwnProperty('CustomerCode')) filterObject.CustomerCode = item.codeCustomer;
      if(filterObject.hasOwnProperty('DepartmentCode')) filterObject.DepartmentCode = item.codeDept;
      if(filterObject.hasOwnProperty('CustomerDescription')) filterObject.CustomerDescription = getDescription(item.descCustomer);
      if(filterObject.hasOwnProperty('ItemCode')) filterObject.ItemCode = item.codeItem;
      if(filterObject.hasOwnProperty('Item')) filterObject.Item = getDescription(item.descItem);
      if(filterObject.hasOwnProperty('ItemDescription')) filterObject.ItemDescription = getDescription(item.desc2Item);

      if(filterObject.hasOwnProperty('CurrentPrice')) filterObject.CurrentPrice = currencyFormatter(item.codePrice);
      if(filterObject.hasOwnProperty('ShipTo')) filterObject.ShipTo = item.codeShip;

      if(filterObject.hasOwnProperty('VendorPartNumber')) filterObject.VendorPartNumber = item.codeVPart;
      if(filterObject.hasOwnProperty('VendorCode')) filterObject.VendorCode = item.codeVendor;
      if(filterObject.hasOwnProperty('VendorDescription')) filterObject.VendorDescription = getDescription(item.descVendor); 
      if(filterObject.hasOwnProperty('Invoice')) filterObject.Invoice = item.codeInvoice;
      if(filterObject.hasOwnProperty('CutomerPO')) filterObject.CutomerPO = item.descInvoice;
      if(filterObject.hasOwnProperty('InvoiceDescription')) filterObject.InvoiceDescription = getDescription(item.desc2Invoice);
///
if(filterObject.hasOwnProperty('AddOns_0')) filterObject.AddOns_0 = currencyFormatter(item.range0ADD); 
if(filterObject.hasOwnProperty('AddOns_1')) filterObject.AddOns_1 = currencyFormatter(item.range1ADD); 
if(filterObject.hasOwnProperty('AddOns_2')) filterObject.AddOns_2 = currencyFormatter(item.range2ADD); 
if(filterObject.hasOwnProperty('AddOns_3')) filterObject.AddOns_3 = currencyFormatter(item.range3ADD); 
if(filterObject.hasOwnProperty('Quantity_0')) filterObject.Quantity_0 = item.range0QTY; 
if(filterObject.hasOwnProperty('Quantity_1')) filterObject.Quantity_1 = item.range1QTY; 
if(filterObject.hasOwnProperty('Quantity_2')) filterObject.Quantity_2 = item.range2QTY; 
if(filterObject.hasOwnProperty('Quantity_3')) filterObject.Quantity_3 = item.range3QTY; 
if(filterObject.hasOwnProperty('Purchases_0')) filterObject.Purchases_0 = currencyFormatter(item.range0SLS); 
if(filterObject.hasOwnProperty('Purchases_1')) filterObject.Purchases_1 = currencyFormatter(item.range1SLS); 
if(filterObject.hasOwnProperty('Purchases_2')) filterObject.Purchases_2 = currencyFormatter(item.range2SLS); 
if(filterObject.hasOwnProperty('Purchases_3')) filterObject.Purchases_3 = currencyFormatter(item.range3SLS); 
if(filterObject.hasOwnProperty('AllocTax_0')) filterObject.AllocTax_0 = currencyFormatter(item.range0TAX); 
if(filterObject.hasOwnProperty('AllocTax_1')) filterObject.AllocTax_1 = currencyFormatter(item.range1TAX); 
if(filterObject.hasOwnProperty('AllocTax_2')) filterObject.AllocTax_2 = currencyFormatter(item.range2TAX); 
if(filterObject.hasOwnProperty('AllocTax_3')) filterObject.AllocTax_3 = currencyFormatter(item.range3TAX); 

      filteredData.push(filterObject);
  })
 
  return filteredData;   
}
export function getCustomerVendorsList() {
   
  var identityKey=localStorage.getItem('identityKey')
  var loginType=localStorage.getItem('loginTypeCode')
  if(loginType === 'E')
  {
    identityKey=localStorage.getItem('empCompanyNumber')
  }
  return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/consolidatedinventory/customerVendor?identityKey=${identityKey}&loginType=${loginType}`)}`, {
      method: 'GET',
      headers:authHeader()
  }).then(response => response.json());
}

export function getAllShipToList() {
  var identityKey = localStorage.getItem("identityKey");
  var loginType = localStorage.getItem("loginTypeCode");
  if(loginType === 'E')
  {
    identityKey=localStorage.getItem('empCompanyNumber')
  }
  return fetch(
    `${api.proxyUrl}${api.url}${encodedURL(`/user/all-shiptos?loginId=${identityKey.trim()}&loginType=${loginType}`)}`,
    {
      method: "GET",
      headers: authHeader(),
    }
  ).then((response) => response.json());
}

export function getCustomersList() {

  var identityKey=localStorage.getItem('identityKey')
  
  var loginType=localStorage.getItem('loginTypeCode')
  if(loginType === 'E')
  {
    identityKey=localStorage.getItem('empCompanyNumber')
  }
  return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/consolidatedinventory/customerList-purshaseanalysis?identityKey=${identityKey.trim()}&loginTypeCode=${loginType}`)}`, {
      method: 'GET',
      headers:authHeader()
  }).then(response => response.json());
}

export function getPurchaseAnalysis(searchObject) {
  
  var fromDate, toDate;
  fromDate=searchObject.frDate0;
  toDate=searchObject.toDate0;
  if(fromDate!=='')  {        
      var from=fromDate.split("-")  
       fromDate=from[1]  +"/"+ from[2] +"/"+ from[0]  
  }
  if(toDate!=='')
  {
      var from=toDate.split("-")  
      toDate=from[1]  +"/"+ from[2] +"/"+ from[0]  
  }
  var urlParam = "&frDate0=" + fromDate;
  urlParam += "&toDate0=" + toDate;

  fromDate=searchObject.frDate1;
  toDate=searchObject.toDate1;
  if(fromDate!=='')  {        
      var from=fromDate.split("-")  
      fromDate=from[1]  +"/"+ from[2] +"/"+ from[0]  
  }
  if(toDate!=='')
  {
      var from=toDate.split("-")  
      toDate=from[1]  +"/"+ from[2] +"/"+ from[0]  
  }
  urlParam += "&frDate1=" + fromDate;
  urlParam += "&toDate1=" + toDate;
  fromDate=searchObject.frDate2;
  toDate=searchObject.toDate2;
  if(fromDate!=='')  {        
      var from=fromDate.split("-")  
      fromDate=from[1]  +"/"+ from[2] +"/"+ from[0]  
  }
  if(toDate!=='')
  {
      var from=toDate.split("-")  
      toDate=from[1]  +"/"+ from[2] +"/"+ from[0]  
  }
  urlParam += "&frDate2=" + fromDate;
  urlParam += "&toDate2=" + toDate;
  fromDate=searchObject.frDate3;
  toDate=searchObject.toDate3;
  if(fromDate!=='')  {        
      var from=fromDate.split("-")  
      fromDate=from[1]  +"/"+ from[2] +"/"+ from[0]  
  }
  if(toDate!=='')
  {
      var from=toDate.split("-")  
      toDate=from[1]  +"/"+ from[2] +"/"+ from[0]  
  }
  urlParam += "&frDate3=" + fromDate;
  urlParam += "&toDate3=" + toDate;
  urlParam += "&meaSLS=" + searchObject.meaSLS;
  urlParam += "&meaQTY=" + searchObject.meaQTY;
  urlParam += "&meaTAX=" + searchObject.meaTAX;

  urlParam += "&meaADD=" + searchObject.meaADD;
  urlParam += "&dimCo=" + ('0' + searchObject.dimCo).slice(-2) ;
  urlParam += "&dimCst=" +('0' + searchObject.dimCst).slice(-2) ;
  urlParam += "&dimShip=" + ('0' + searchObject.dimShip).slice(-2) ;
  urlParam += "&dimDept=" + ('0' + searchObject.dimDept).slice(-2) ;

  urlParam += "&dimItem=" + ('0' +searchObject.dimItem).slice(-2) ;
  urlParam += "&dimCustPart=" +('0' + searchObject.dimCustPart).slice(-2) ;
  urlParam += "&dimVend=" + ('0' +searchObject.dimVend).slice(-2) ;
  urlParam += "&dimCurPrice=" +('0' + searchObject.dimCurPrice).slice(-2) ;
  urlParam += "&dimInvoice=" + ('0' +searchObject.dimInvoice).slice(-2) ;

  urlParam += "&dimVPart=" + ('0' + searchObject.dimVPart).slice(-2) ;
  if(searchObject.frItem !=null && searchObject.frItem != "")
  urlParam += "&frItem=" + searchObject.frItem;
  if(searchObject.toItem !=null && searchObject.toItem != "")
  urlParam += "&toItem=" + searchObject.toItem;
  urlParam += "&cpType=" + searchObject.cPType;  
  urlParam += "&custList=" + searchObject.custList;
  if(searchObject.vendList !=null && searchObject.vendList != "")
  urlParam += "&vendorList=" + searchObject.vendList;
  if(searchObject.itemList !=null && searchObject.itemList != "")
  urlParam += "&itemList=" + searchObject.itemList;
  if(searchObject.shipList !=null && searchObject.shipList != "")
  urlParam += "&shipList=" + searchObject.shipList;
  var identityKey=localStorage.getItem('identityKey').trim();
  var loginType=localStorage.getItem('loginTypeCode')
  if(loginType === 'E')
  {
    identityKey=localStorage.getItem('empCompanyNumber')// +localStorage.getItem('customerNumber')  //companyNumber customerNumber
  }
  return fetch(
    `${api.proxyUrl}${api.url}${encodedURL(`/purchaseanalysis/getpurchaseanalysislist?identityKey=${identityKey}&loginType=${localStorage.getItem("loginTypeCode")}${urlParam}`)}`,
    {
      method: "GET",
      headers: authHeader(),
    }
  ).then((response) => response.json());
  // return fetch(
  //   `${api.url}/purchaseanalysis/getpurchaseanalysislist?identityKey=${localStorage.getItem(
  //     "identityKey"
  //   ).trim()}&loginType=${localStorage.getItem("loginTypeCode")}${urlParam}`,
  //   {
  //     method: "GET",
  //     headers: authHeader(),
  //   }
  // ).then((response) => response.json());
}

export function getDataColumns() {

  //user.toUser = JSON.parse(localStorage.getItem("UserMenu"));
  const salt = process.env.SALT || process.env.REACT_APP_KEY;
    const originalData = decryptData(localStorage.getItem("UserMenu"), salt);
    user.toUser = originalData;
  var dataColumns = [];
  if (user.toUser !== null) {
    if (user.toUser.CanViewInventoryPrices === "Y")
      dataColumns.push({
        id: 1,
        value: "Purchases",
        isChecked: false,
        code: "meaSLS",
      });
  }

  dataColumns.push({
    id: 2,
    value: "Quantity",
    isChecked: false,
    code: "meaQTY",
  });
  dataColumns.push({
    id: 3,
    value: "Alloc Add-ons",
    isChecked: false,
    code: "meaADD",
  });
  dataColumns.push({
    id: 4,
    value: "Alloc tax",
    isChecked: false,
    code: "meaTAX",
  });

  return dataColumns;
}

export function getReportColumns() {
  //user.toUser = JSON.parse(localStorage.getItem("UserMenu"));
  const salt = process.env.SALT || process.env.REACT_APP_KEY;
    const originalData = decryptData(localStorage.getItem("UserMenu"), salt);
    user.toUser = originalData;
  var reportColumns = [
    { id: 1, value: "Company", isChecked: false, code: "dimCo" },
    { id: 2, value: "Customer", isChecked: false, code: "dimCst" },
    { id: 3, value: "Ship To", isChecked: false, code: "dimShip" },
    { id: 4, value: "Department", isChecked: false, code: "dimDept" },
    { id: 5, value: "Item Number", isChecked: false, code: "dimItem" },
    { id: 6, value: "Customer Part#", isChecked: false, code: "dimCustPart" },
    { id: 7, value: "Vendor Part", isChecked: false, code: "dimVPart" },
    { id: 8, value: "Vendor Number", isChecked: false, code: "dimVend" },
    { id: 9, value: "Invoice", isChecked: false, code: "dimInvoice" },
  ];
  if (user.toUser !== null) {
    if (     
      user.toUser.CanViewInventoryPrices == "Y"
    )
      reportColumns.push({
        id: 10,
        value: "Current Price",
        isChecked: false,
        code: "dimCurPrice",
      });
  }
  return reportColumns;
}

function currencyFormatter(value) {
  if (value !== undefined && value !== "") {
    if (parseFloat(value) < 0) return "-$" + (parseFloat(value).toFixed(2) * -1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else return "$" +  (parseFloat(value).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
  }
  return "";
}