import React, { Component } from 'react';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {getUPSTrackingNumber} from "../../_services/displayOrder.service";
import {getFEDXTrackingNumber} from "../../_services/displayOrder.service";
import {getUPSResponse} from "../../_services/displayOrder.service";
import UPSTrackingInfo from "./UPSTrackingInfo";
import ESSModal from "../../components/ESSModal.jsx";
import UPSTrackingInitialScreen from "./UPSTrackingInitialScreen"
class UPSTrackingNumberCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            userMessage:'',
            colHeaders: [{ 
                headerName: "",  
                field: "TrackingNumber", 
                cellStyle: {
                    textAlign: "center",
                    borderRight: "0.1px solid lightgray",
                }, 
                resizable: false }
            ],
            defaultColDef: {
            flex: 1,
            minWidth: 50,
            filter: false,
            sortable: false,
            },
            rowData:[],
            trackData:[],
            selectedUPSTrackingNumber:'',
            showUPSInfo:false,
            companyNumber:'',
            orderNumber:'',
            location:'',
            orderSequenceNumber:'',
            isfedex:'',
            status : '',
            weight:'',
            trackinginofnumber:'',
            selectTrackingNumber:'',
            description:'',

        }
        
    };

    componentDidMount() {  
                
        if(this.props.showFEDEX==true&&this.props.isfedx==true) 
    {
        this.getFEDXTrackingNumber()
    }  
    else
    {
        this.setState({isfedex:false})  
        
        this.getUPSTrackingNumber();}  
    }

    onSelectionChanged = () => {
        var selectedRows = this.gridApi.getSelectedRows();
       
         
         this.setState({ selectedUPSTrackingNumber:selectedRows[0].TrackingNumber},()=>{
this.setState({showUPSInfo:true})
        this.setState({isfedex:this.props.isfedx})    
         })
         getUPSResponse(
            this.state.selectedUPSTrackingNumber.trim(),this.state.isfedex
          ).then((data) => {
            
              if (data.length>0)
              {
                this.setState({ trackData: data });
           this.setState({status:data[0].Status});
          this.setState({weight:data[0].weightField});
          this.setState({trackinginofnumber:data[0].trackingNumber})
             this.setState({description:data[0].description}) 
        }
            
            
            else{
              this.setState({
                show: true,
                userMessage:
                  "Tracking info not available for deliveries older than 120 days.",
              });
            }
          });
        };
  
      
    
    getUPSTrackingNumber() {       
        getUPSTrackingNumber(this.props.orderNumber,this.props.location,this.props.orderSeqNumber)
        .then((data) => {
          this.setState({ rowData: data })
          
        });
    }  
    getFEDXTrackingNumber() {       
        getFEDXTrackingNumber(this.props.orderNumber,this.props.location,this.props.orderSeqNumber)
        .then((data) => {
          this.setState({ rowData: data })
          
        });
    }      
    handleClose = () => this.setState({ show: false });
    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };
    loadUPSTrackingGrid() {
        return(          
            <div style={{height:"90%"}}>
                <AgGridReact
                    onGridReady={this.onGridReady}  
                    columnDefs={this.state.colHeaders}
                    defaultColDef={this.state.defaultColDef}                
                    rowData={this.state.rowData}
                    rowSelection='single'
                    enableCellTextSelection={true}
                    tooltipShowDelay="0"
                    onSelectionChanged={this.onSelectionChanged.bind(this)}
                    rowHeight="30"     
                    headerHeight="0"   
                    footerHeight="0"  
                ></AgGridReact>   
            </div>  
                                
        );
    }
    render() {
        return(
            <div className="row" style={{height:"100%", paddingLeft:"13px"}}>
                <div className="col-sm-2">
                    <div className="row" style={{height:"100%", border: "2px solid rgba(0,0,0,.125)"}}>
                        <div className="col-sm-12" >
                           {this.props.isfedx==true ?<h6>Fedex Tracking Numbers</h6>:<h6>UPS Tracking Numbers</h6>}<br/>
                            {  this.loadUPSTrackingGrid()  }
                        </div>
                    </div>                   
                </div>
                {this.state.showUPSInfo ?
               <UPSTrackingInfo selectionChange={this.selectionChange} trackData={this.state.trackData} status={this.state.status} weight={this.state.weight} trackinginofnumber={this.state.trackinginofnumber} description={this.state.description} upsTrackingNumber={this.state.selectedUPSTrackingNumber} isFedex={this.state.isfedex}></UPSTrackingInfo>
               :<UPSTrackingInitialScreen></UPSTrackingInitialScreen>}
                {this.state.show && (
          <ESSModal
            handleClose={this.handleClose}
            btn1Text="OK"
            userMessage={this.state.userMessage}
            isAlert="true"
            show={this.state.show}
          />
        )}
            </div>                   
        );
    };

}
export default UPSTrackingNumberCard;