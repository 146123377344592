import Home from "../modules/Home";
import Inventory from "../modules/Inventory/Inventory";
import AccountsPayable from "../modules/AccountsPayable/AccountsPayable";
import PriceList from "../modules/PriceList/PriceList";
import CustomerListMenu from "../modules/CustomerListMenu";
import ConsolidatedInventory from "../modules/ConsolidatedInventory/ConsolidatedInventory";
import CustomPrice from "../modules/CustomPrice/CustomPrice";
import DisplayOrder from "../modules/DisplayOrders/DisplayOrder";
import Purchasing from "../modules/Purchasing/Purchasing";
import PurchasingAnalysis from "../modules/PurchasingAnalysis/PurchasingAnalysis";
import ChangePassword from "../modules/ChangePassword";
import EmployeeListFromMenu from "../modules/EmployeeListFromMenu";
import ESSModal from "../components/ESSModal";
import user from "../models/User";
import { decryptData } from '../Utils';
const loginTypeCode = localStorage.getItem("loginTypeCode");

let LocalData = localStorage.getItem("UserMenu");
if(!LocalData)
{                              
       // no need to decrypt             
}                              

else
{
  const salt = process.env.SALT || process.env.REACT_APP_KEY;
  const originalData = decryptData(localStorage.getItem("UserMenu"), salt);
  user.toUser = originalData;
}




 //user.toUser = originalData;

 //alert(user.toUser.CanViewInventory);

export const routes = [];
routes.push(
  {
    path: "/Home",
    component: Home,
  });
routes.push(
  {
    path: "/ESSModal",
    component: ESSModal,
  });
  if(user.toUser != null){
if (user.toUser.CanViewInventory === "Y") {
  routes.push(
    {
      path: "/Inventory",
      component: Inventory,
    });
    if(loginTypeCode === "G"){
  routes.push(
    {
      path: "/ConsolidatedInventory",
      component: ConsolidatedInventory,
    });
  }
}
if (user.toUser.CanViewAR == "Y") {
  routes.push(
    {
      path: "/AccountsPayable",
      component: AccountsPayable,
    });
}
if (user.toUser.CanViewPriceList == "Y") {
  routes.push(
    {
      path: "/PriceList",
      component: PriceList,
    });
}
if(loginTypeCode === "G" || loginTypeCode === "E"){
routes.push(
  {
    path: "/CustomerListMenu",
    component: CustomerListMenu,
  });
}
if (user.toUser.CanViewCustomerPrices == "Y") {
  routes.push(
    {
      path: "/CustomerPrice",
      component: CustomPrice,
    });
}
if (user.toUser.CanViewOrders == "Y") {
  routes.push(
    {
      path: "/DisplayOrder",
      component: DisplayOrder,
    });
}
if (user.toUser.CanViewSales == "Y") {
  routes.push(
    {
      path: "/Purchasing",
      component: Purchasing,
    });
}
if ((user.toUser.CanViewSalesAnalysis == "Y" || user.toUser.CanViewSales == "Y")) {
  routes.push(
    {
      path: "/PurchasingAnalysis",
      component: PurchasingAnalysis,
    });
}
routes.push(
  {
    path: "/changepassword",
    component: ChangePassword,
  });
  if( loginTypeCode === "E"){
routes.push(
  {
    path: "/EmployeeListFromMenu",
    component: EmployeeListFromMenu,
  });
}

  }