import React, { Component } from "react";
import appConstants from "../../_services/applicationConstant";
import ReportColumns from "./ReportColumns";
import DataColumns from "./DataColumns";
import TabFilters from "./TabFilters";
import { getCustomersList } from "../../_services/purchasingAnalysis.service";
import { getCustomerVendorsList } from "../../_services/consolidatedInventory.service";
import { getAllShipToList } from "../../_services/purchasingAnalysis.service";
import ESSModal from "../../components/ESSModal.jsx";
class PAFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      frDate0: "",
      frDate1: "",
      frDate2: "",
      frDate3: "",
      toDate0: "",
      toDate1: "",
      toDate2: "",
      toDate3: "",
      selectedReportColumns: [],
      selectedDataColumns: [],
      ItemEntryList: [],
      ItemEntryType: "Distributor Item#",
      fromItemRange: "",
      toItemRange: "",
      isAllCICustomers: true,
      ciCustomers: [],
      isAllShipTo: true,
      ciShipTos: [],
      isAllCIVedors: true,
      ciVendors: [],
      allCICustomers: [],
      allCIVendors: [],
      allPAshiptos: [],
      show: false,
      userMessage: "",
    };
    this.DataColumnsRef = React.createRef();
    this.ReportColumnsRef = React.createRef();
    this.TabFilterRef = React.createRef();
    this.reportColumnsHandler = this.reportColumnsHandler.bind(this);
  }
  componentDidMount() {
    var allCustomers = [];
    getCustomersList().then((data) => {
      data.map((m) => allCustomers.push(m.CustNumber));
    });

    this.setState({ allCICustomers: allCustomers });
    var allVendors = [];
    getCustomerVendorsList().then((data) => {
      data.map((m) => allVendors.push(m.id));
    });
    this.setState({ allCIVendors: allVendors });
    var allShipTos = [];
    getAllShipToList().then((data) => {
      data.map((m) => allShipTos.push(m.ShipId));
    });
    this.setState({ allPAshiptos: allShipTos });
  }

  mapReportColumm() {}
  searchClicked = () => {
    const report = this.state.selectedReportColumns.filter((p) => p.isChecked);
    const data = this.state.selectedDataColumns.filter((p) => p.isChecked);
    var haveDate=true;
    if ((this.state.frDate0 === null || this.state.frDate0 === "") 
    && (this.state.frDate1 === null || this.state.frDate1 === "") 
    && (this.state.frDate2 === null || this.state.frDate2 === "") 
    && (this.state.frDate3 === null || this.state.frDate3 === "") )
    {
      haveDate =false;
    }
    var dateMessage= haveDate=== false ?"There are no Date selections\n":"";

    if (report.length === 0) {
      if (data.length === 0) {
        this.setState({
          show: true,
          userMessage:
          dateMessage+ "Data Columns: has no selections.\nReport Columns: has no selections",
        });
        return false;
      } else {
        this.setState({
          show: true,
          userMessage: dateMessage+"Report Columns: has no selections",
        });
        return false;
      }
    }

    if (data.length === 0) {
      this.setState({
        show: true,
        userMessage:dateMessage+ "Data Columns: has no selections",
      });
      return false;
    }
    if(report.filter((p) => p.isChecked && p.code.includes("dimCurPrice")).length > 0 )
    {
      if(report.filter((p) => p.isChecked && p.code.includes("dimItem")).length > 0){

      }
      else{
        this.setState({
          show: true,
          userMessage:dateMessage+ "Item must be selected with Current Price",
        });
        return false;
      }
    }

    if (this.state.isAllCICustomers || this.state.ciCustomers.length > 0) {
    } else {
      this.setState({
        show: true,
        userMessage: dateMessage+"There are no Customer selections",
      });
      return false;
    }
    var userMessage = "";
    var isValid = false;
  
  //New validation start 
    if (this.state.frDate0 !== null && this.state.frDate0 !== "") 
    {
      isValid= this.yearValidation(this.state.frDate0);

      if(isValid)
      {
        isValid = true;
      }
      else
      {
        isValid = false;
        return false;
      }

      if (this.state.toDate0 !== null && this.state.toDate0 !== "")
      {
        isValid= this.yearValidation(this.state.toDate0);
        if(isValid)
      {
        isValid = true;
      }
      else
      {
        isValid = false;
        
        return false;
      }
      }
    }

    if (this.state.frDate1 !== null && this.state.frDate1 !== "") 
    {
      isValid= this.yearValidation(this.state.frDate1);

      if(isValid)
      {
        isValid = true;
      }
      else
      {
        isValid = false;
        return false;
      }

      if (this.state.toDate1 !== null && this.state.toDate1 !== "")
      {
        isValid= this.yearValidation(this.state.toDate1);
        if(isValid)
      {
        isValid = true;
      }
      else
      {
        isValid = false;
        
        return false;
      }
      }
    }

    if (this.state.frDate2 !== null && this.state.frDate2 !== "") 
    {
      isValid= this.yearValidation(this.state.frDate2);

      if(isValid)
      {
        isValid = true;
      }
      else
      {
        isValid = false;
        return false;
      }

      if (this.state.toDate2 !== null && this.state.toDate2 !== "")
      {
        isValid= this.yearValidation(this.state.toDate2);
        if(isValid)
      {
        isValid = true;
      }
      else
      {
        isValid = false;
        
        return false;
      }
      }
    }

    if (this.state.frDate3 !== null && this.state.frDate3 !== "") 
    {
      isValid= this.yearValidation(this.state.frDate3);

      if(isValid)
      {
        isValid = true;
      }
      else
      {
        isValid = false;
        return false;
      }

      if (this.state.toDate3 !== null && this.state.toDate3 !== "")
      {
        isValid= this.yearValidation(this.state.toDate3);
        if(isValid)
      {
        isValid = true;
      }
      else
      {
        isValid = false;
        
        return false;
      }
      }
    }
    //New validation end 
    if (this.state.frDate0 !== null && this.state.frDate0 !== "") 
    {
      if (this.state.toDate0 !== null && this.state.toDate0 !== "")
      {
      
     
      isValid = true;
      }

      else {
        isValid = false;
        this.setState({
          show: true,
          userMessage:
            "Range 1 is an invalid date range.\nThere are no Date selections",
        });
        return false;
      }
    } 
     if (this.state.frDate1 !== null && this.state.frDate1 !== "") {
      if (this.state.toDate1 !== null && this.state.toDate1 !== "")
        isValid = true;
      else {
        isValid = false;
        this.setState({
          show: true,
          userMessage:
            "Range 2 is an invalid date range.\nThere are no Date selections",
        });
        return false;
      }
    } 
     if (this.state.frDate2 !== null && this.state.frDate2 !== "") {
      if (this.state.toDate2 !== null && this.state.toDate2 !== "")
        isValid = true;
      else {
        isValid = false;
        this.setState({
          show: true,
          userMessage:
            "Range 2 is an invalid date range.\nThere are no Date selections",
        });
        return false;
      }
    } 
     if (this.state.frDate3 !== null && this.state.frDate3 !== "") {
      if (this.state.toDate3 !== null && this.state.toDate2 !== "")
        isValid = true;
      else {
        isValid = false;
        this.setState({
          show: true,
          userMessage:
            "Range 2 is an invalid date range. There are no Date selections",
        });
        return false;
      }
    }
    if (isValid === false) {
      this.setState({
        show: true,
        userMessage: "There are no Date selections",
      });
      return false;
    }

    // var itemEtry = "";
    // const dataEntr = this.state.ItemEntryList.map(
    //   (filteredData) => (itemEtry = itemEtry + filteredData.itemvalue + ", ")
    // );

    var filterObject = {
      frDate0: this.state.frDate0,
      frDate1: this.state.frDate1,
      frDate2: this.state.frDate2,
      frDate3: this.state.frDate3,
      toDate0: this.state.toDate0,
      toDate1: this.state.toDate1,
      toDate2: this.state.toDate2,
      toDate3: this.state.toDate3,
      reportColumn: report,
      dataColumn: data,
      isAllCICustomers: this.state.allCICustomers,
      ciCustomers: this.state.ciCustomers,
      isAllCIVedors: this.state.allCIVendors,
      ciVendors: this.state.ciVendors,
      ItemEntryList: this.state.ItemEntryList,
      ItemEntryType: this.state.ItemEntryType,
      fromItemRange: this.state.fromItemRange,
      toItemRange: this.state.toItemRange,
      ciShipTos: this.state.ciShipTos,
      isAllShipTo: this.state.allPAshiptos,
    };
    this.props.doSearch(filterObject);
  };
  showcloumnsCLicked = () => {
    const report = this.state.selectedReportColumns.filter((p) => p.isChecked);
    const data = this.state.selectedDataColumns.filter((p) => p.isChecked);
    var haveDate=true;
    if ((this.state.frDate0 === null || this.state.frDate0 === "") 
    && (this.state.frDate1 === null || this.state.frDate1 === "") 
    && (this.state.frDate2 === null || this.state.frDate2 === "") 
    && (this.state.frDate3 === null || this.state.frDate3 === "") )
    {
      haveDate =false;
    }
    var dateMessage= haveDate=== false ?"There are no Date selections\n":"";

    if (report.length === 0) {
      if (data.length === 0) {

        this.setState({
          show: true,
          userMessage: dateMessage+ "Data Columns: has no selections.\nReport Columns: has no selections",
        });
        return false;
      } else {
        this.setState({
          show: true,
          userMessage: dateMessage+ "Report Columns: has no selections",
        });
        return false;
      }
    }
    if (data.length === 0) {
      this.setState({
        show: true,
        userMessage: dateMessage+"Data Columns: has no selections",
      });
      return false;
    }

    if (this.state.isAllCICustomers || this.state.ciCustomers.length > 0) {
    } else {
      this.setState({
        show: true,
        userMessage:  "There are no Customer selections",
      });
      return false;
    }

   
    var userMessage = "";
    var isValid = false;
    //New validation start
    if (this.state.frDate0 !== null && this.state.frDate0 !== "") 
    {
      isValid= this.yearValidation(this.state.frDate0);

      if(isValid)
      {
        isValid = true;
      }
      else
      {
        isValid = false;
        return false;
      }

      if (this.state.toDate0 !== null && this.state.toDate0 !== "")
      {
        isValid= this.yearValidation(this.state.toDate0);
        if(isValid)
      {
        isValid = true;
      }
      else
      {
        isValid = false;
        
        return false;
      }
      }
    }

    if (this.state.frDate1 !== null && this.state.frDate1 !== "") 
    {
      isValid= this.yearValidation(this.state.frDate1);

      if(isValid)
      {
        isValid = true;
      }
      else
      {
        isValid = false;
        return false;
      }

      if (this.state.toDate1 !== null && this.state.toDate1 !== "")
      {
        isValid= this.yearValidation(this.state.toDate1);
        if(isValid)
      {
        isValid = true;
      }
      else
      {
        isValid = false;
        
        return false;
      }
      }
    }

    if (this.state.frDate2 !== null && this.state.frDate2 !== "") 
    {
      isValid= this.yearValidation(this.state.frDate2);

      if(isValid)
      {
        isValid = true;
      }
      else
      {
        isValid = false;
        return false;
      }

      if (this.state.toDate2 !== null && this.state.toDate2 !== "")
      {
        isValid= this.yearValidation(this.state.toDate2);
        if(isValid)
      {
        isValid = true;
      }
      else
      {
        isValid = false;
        
        return false;
      }
      }
    }

    if (this.state.frDate3 !== null && this.state.frDate3 !== "") 
    {
      isValid= this.yearValidation(this.state.frDate3);

      if(isValid)
      {
        isValid = true;
      }
      else
      {
        isValid = false;
        return false;
      }

      if (this.state.toDate3 !== null && this.state.toDate3 !== "")
      {
        isValid= this.yearValidation(this.state.toDate3);
        if(isValid)
      {
        isValid = true;
      }
      else
      {
        isValid = false;
        
        return false;
      }
      }
    }
    //New validation end 

    if (this.state.frDate0 !== null && this.state.frDate0 !== "") {
      if (this.state.toDate0 !== null && this.state.toDate0 !== "")
        isValid = true;
      else {
        isValid = false;
        this.setState({
          show: true,
          userMessage:
            "Range 1 is an invalid date range.\nThere are no Date selections",
        });
        return false;
      }
    } 
     if (this.state.frDate1 !== null && this.state.frDate1 !== "") {
      if (this.state.toDate1 !== null && this.state.toDate1 !== "")
        isValid = true;
      else {
        isValid = false;
        this.setState({
          show: true,
          userMessage:
            "Range 2 is an invalid date range.\nThere are no Date selections",
        });
        return false;
      }
    } 
     if (this.state.frDate2 !== null && this.state.frDate2 !== "") {
      if (this.state.toDate2 !== null && this.state.toDate2 !== "")
        isValid = true;
      else {
        isValid = false;
        this.setState({
          show: true,
          userMessage:
            "Range 2 is an invalid date range.\nThere are no Date selections",
        });
        return false;
      }
    } 
     if (this.state.frDate3 !== null && this.state.frDate3 !== "") {
      if (this.state.toDate3 !== null && this.state.toDate2 !== "")
        isValid = true;
      else {
        isValid = false;
        this.setState({
          show: true,
          userMessage:
            "Range 2 is an invalid date range. There are no Date selections",
        });
        return false;
      }
    }
    if (isValid === false) {
      this.setState({
        show: true,
        userMessage: "There are no Date selections",
      });
      return false;
    }
  
    //const report = this.state.selectedReportColumns.filter((p) => p.isChecked);
    //console.log(report);
    //const data = this.state.selectedDataColumns.filter((p) => p.isChecked);
    var filterObject = {
      frDate0: this.state.frDate0,
      frDate1: this.state.frDate1,
      frDate2: this.state.frDate2,
      frDate3: this.state.frDate3,
      toDate0: this.state.toDate0,
      toDate1: this.state.toDate1,
      toDate2: this.state.toDate2,
      toDate3: this.state.toDate3,
      reportColumn: report,
      dataColumn: data,
      isAllCICustomers: this.state.allCICustomers,
      ciCustomers: this.state.ciCustomers,
      isAllCIVedors: this.state.allCIVendors,
      ciVendors: this.state.ciVendors,
      ItemEntryList: this.state.ItemEntryList,
      ItemEntryType: this.state.ItemEntryType,
      fromItemRange: this.state.fromItemRange,
      toItemRange: this.state.toItemRange,
      ciShipTos: this.state.ciShipTos,
      isAllShipTo: this.state.isAllShipTo,
    };
    //console.log(filterObject);
    this.props.showcolumns(filterObject);
  };
  clearClicked = () => {
    this.setState({
      frDate0: "",
      frDate1: "",
      frDate2: "",
      frDate3: "",
      toDate0: "",
      toDate1: "",
      toDate2: "",
      toDate3: "",
      selectedReportColumns: [],
      selectedDataColumns: [],
      ItemEntryList: [],
      ItemEntryType: "Distributor Item#",
      fromItemRange: "",
      toItemRange: "",
      isAllCICustomers: true,
      ciCustomers: [],
      isAllCIVedors: true,
      ciVendors: [],
      show: false,
      setShow: false,
    });
    this.DataColumnsRef.current.clearSelection();
    this.ReportColumnsRef.current.clearSelection();
    this.TabFilterRef.current.clearSelection();
    this.props.clearData();
  };

  FilterEntryItemsChange = (data) => {
    this.setState({ ItemEntryList: data });
  };

  FilterEntryTypeChange = (data) => {
    this.setState({ ItemEntryType: data });
  };
  reportColumnsHandler = (data) => {
    this.setState({ selectedReportColumns: data }, () => {});
  };
  dataColumnsHandler = (data) => {
    this.setState({ selectedDataColumns: data }, () => {});
  };
  onItemFromChange = (data) => {
    this.setState({ fromItemRange: data });
  };
  onItemToChange = (data) => {
    this.setState({ toItemRange: data });
  };

  onAllCICutomerChange = (data) => {
    this.setState({ isAllCICustomers: data });
  };

  onCICustomerSelectionChange = (data) => {
    this.setState({ ciCustomers: data });
  };

  onAllCIVendorChange = (data) => {
    this.setState({ isAllCIVedors: data });
  };

  onCIVendorSelectionChange = (data) => {
    this.setState({ ciVendors: data });
  };
  onPAShiptosSelectionChange = (data) => {
    this.setState({ ciShipTos: data });
  };
  onAllPAShipTos = (data) => {
    this.setState({ isAllShipTo: data });
  };
  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  //date validation

  yearValidation = (year) => {
  
   
    if(year!="")
    {
      
    var currentYear= new Date(year).getFullYear(); 
    var result =parseInt(currentYear, 10)
    
    if(result.toString().length!="4")
    {
      
      this.setState({
                    show: true,
                    userMessage: "Invalid year, please correct",
                  });
                 
                return false;
    }

        
    if((result.toString() < 2010) || (result.toString() > 2100))
    {
    
    this.setState({
      show: true,
      userMessage: "Year should be in range 2010 to 2100",
    });
    
    return false;
    }
    
    return true;
    
  }
  }
  render() {
    return (
      <div
        className={`${appConstants.isR3App ? "filterBodyR3" : "filterBody"}`}
      >
        {this.state.show && (
          <ESSModal
            handleClose={this.handleClose}
            btn1Text="OK"
            userMessage={this.state.userMessage}
            isAlert="true"
            show={this.state.show}
          />
        )}

        <div className="row">
          <div className="col-sm-2" style={{ marginRight: "-5%" }}>
            <div>
              <ReportColumns
                ref={this.ReportColumnsRef}
                onChange={this.reportColumnsHandler}
              />
            </div>
          </div>

          <div
            className="col-sm-2"
            style={{ width: "150px", marginRight: "-60px" }}
          >
            <div>
              <div style={{ padding: "2px" }}>From Date</div>
              <input
                type="date"
                id="frDate0"
                name="frDate0"
                
                value={this.state.frDate0}
                style={{ width: "130px" }}
                onBlur={(event) =>
                  {
                                    
                  this.yearValidation(event.target.value);
                 
                }
                }
                onChange={(event) =>
                  {
                 
                  
                  //this.yearValidation(event.target.value,event.target.name);
                  this.setState({ frDate0: event.target.value })
                }
                }
                className="form-control form-control-sm filterControls"
              ></input>
              &nbsp;
              <input
                type="date"
                id="frDate1"
                name="frDate1"
                style={{ width: "130px" }}
                className="form-control form-control-sm filterControls"
                value={this.state.frDate1}
                onBlur={(event) =>
                  {
                
                  
                    this.yearValidation(event.target.value);
                 
                }
              }
                onChange={(event) =>
                  {
                   
                    this.setState({ frDate1: event.target.value })
                  }
                }
              ></input>
              &nbsp;
              <input
                type="date"
                id="frDate2"
                style={{ width: "130px" }}
                name="frDate2"
                className="form-control form-control-sm filterControls"
                value={this.state.frDate2}
                onBlur={(event) =>
                  {
                
                  
                  this.yearValidation(event.target.value);
                 
                }
              }
                onChange={(event) =>
                  {
                  
                  this.setState({ frDate2: event.target.value })
                  }
                }
              ></input>
              &nbsp;
              <input
                type="date"
                id="frDate3"
                style={{ width: "130px" }}
                name="frDate3"
                className="form-control form-control-sm filterControls"
                value={this.state.frDate3}
                onBlur={(event) =>
                  {
                
                  
                    this.yearValidation(event.target.value);
                 
                }
              }
                onChange={(event) =>
                  {
                                  
                  this.setState({ frDate3: event.target.value })
                  }
                }
              ></input>
            </div>
          </div>
          <div
            className="col-sm-2"
            style={{ width: "150px", marginRight: "-60px" }}
          >
            <div>
              <div style={{ padding: "2px" }}>To Date</div>
              <input
                type="date"
                id="toDate0"
                style={{ width: "130px" }}
                name="toDate0"
                className="form-control form-control-sm filterControls"
                value={this.state.toDate0}
                onBlur={(event) =>
                  {
                
                
                    this.yearValidation(event.target.value);
                 
                }
              }
                onChange={(event) =>
                  {
                  
                  this.setState({ toDate0: event.target.value })
                  }
                }
              ></input>
              &nbsp;
              <input
                type="date"
                id="toDate1"
                style={{ width: "130px" }}
                name="toDate1"
                className="form-control form-control-sm filterControls"
                value={this.state.toDate1}
                onBlur={(event) =>
                  {
                
                  
                    this.yearValidation(event.target.value);
                 
                }
              }
                onChange={(event) =>
                  {

                  
                  this.setState({ toDate1: event.target.value })
                  }
                }
              ></input>
              &nbsp;
              <input
                type="date"
                id="toDate2"
                style={{ width: "130px" }}
                name="toDate2"
                className="form-control form-control-sm filterControls"
                value={this.state.toDate2}
                onBlur={(event) =>
                  {
                
                  
                    this.yearValidation(event.target.value);
                 
                }
              }
                onChange={(event) =>
                  {

                  
                  this.setState({ toDate2: event.target.value })
                  }
                }
              ></input>
              &nbsp;
              <input
                type="date"
                style={{ width: "130px" }}
                id="toDate3"
                name="toDate3"
                className="form-control form-control-sm filterControls"
                value={this.state.toDate3}
                onBlur={(event) =>
                  {
                
                  
                    this.yearValidation(event.target.value);
                 
                }
              }
                onChange={(event) =>
                  {

                
                  this.setState({ toDate3: event.target.value })
                  }
                }
              ></input>
            </div>
          </div>
          <div className="col-sm-1">
            <div className="form-group row">
              <DataColumns
                ref={this.DataColumnsRef}
                onChange={this.dataColumnsHandler}
              />
            </div>
          </div>
          <div className="col-sm-5">
            <div className="row">
              <div
                className="col-sm-2"
                style={{
                  textAlign: "right",
                  paddingRight: "2px",
                  paddingLeft: "0px",
                }}
              >
                Filters:
              </div>
              <div className="col-sm-10" style={{ paddingLeft: "1px", paddingRight:"1px" }}>
                <TabFilters
                  ref={this.TabFilterRef}
                  onEntryItemChange={this.FilterEntryItemsChange}
                  onFilterEntryTypeChange={this.FilterEntryTypeChange}
                  onItemFromChange={this.onItemFromChange}
                  onItemToChange={this.onItemToChange}
                  onAllCICutomerChange={this.onAllCICutomerChange}
                  onCICustomerSelectionChange={this.onCICustomerSelectionChange}
                  onAllCIVendorChange={this.onAllCIVendorChange}
                  onCIVendorSelectionChange={this.onCIVendorSelectionChange}
                  onPAShiptosSelectionChange={this.onPAShiptosSelectionChange}
                  onAllPAShipTos={this.onAllPAShipTos}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-1">
            <div className="row">
              <div className="col-sm-12" style={{paddingLeft:"0px"}}>
                <button
                  className="btn btn-primary btn-sm filterButtons"
                  onClick={this.searchClicked}
                  type="button"
                >
                  Run Report
                </button>

                <div style={{ paddingTop: "3px" }}>
                  <button
                    className="btn btn-primary btn-sm filterButtons"
                    onClick={this.showcloumnsCLicked}
                    type="button"
                  >
                    Show Columns
                  </button>
                </div>
                <div style={{ paddingTop: "3px" }}>
                  <button
                    className="btn btn-primary btn-sm filterButtons"
                    onClick={this.clearClicked}
                    type="button"
                  >
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PAFilter;
