import React, { Component } from 'react';
import user from '../models/User';
import api from '../_services/apiUrl';
import {authHeader} from '../_helpers/auth-header'
class CutomerContact extends Component {
  
    constructor(props) {
    
		super(props);		
		this.state = {			      
            address: ''	,
		}
	}
   
    componentDidMount() {      
    this.setState({address: localStorage.getItem("customerLocation")});
    }
  

    render(){
        return(
            <div className="row"> 
            <div className="col costomerInfo">
             {/* {(this.state.address !==null && this.state.address!=='') && this.state.address.Info}  */}
             {this.state.address}
            </div>
           
            </div>
        );
    }

}
export default CutomerContact;