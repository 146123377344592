import { AgGridReact } from "ag-grid-react";
import React, { Component } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { getEmployeeList } from "../_services/employeeList.service";
import { withRouter } from "react-router-dom";
import appConstants from "../_services/applicationConstant"

class EmployeeList extends Component {
    constructor(props) {
        super(props);
        this.state = {
          search: false,
          inventoryList: [],
          columnDefs: [
            {
              headerName: "Employee #",
              tooltip: (params) => "Click to Select an Employee.",
              field: "EmployeeId",
              cellStyle: {
                textAlign: "center",
                borderRight: "0.3px solid lightgray",
              },
              resizable: true,
              width: 200,
              headerClass: `${appConstants.isR3App ? "gridheaderR3" : "gridheader"}`,
            },
    
            {
              headerName: "Employee Name",
              tooltip: (params) => "Double click to Select an Employee.",
              cellStyle: {
                textAlign: "left",
                borderRight: "0.1px solid lightgray",
              },
              field: "EmployeeName",
              resizable: true,
              width: 875,
              headerClass: `${appConstants.isR3App ? "gridheaderR3" : "gridheader"}`,
            },
            {
                headerName: "Region",
                tooltip: (params) => "Double click to Select an Employee.",
                cellStyle: {
                  textAlign: "left",
                  borderRight: "0.1px solid lightgray",
                },
                field: "Region",
                resizable: true,
                width: 200,
                headerClass: `${appConstants.isR3App ? "gridheaderR3" : "gridheader"}`,
              }
          ],
        };
      }

      onGridReady = (params) => {
        document.getElementById("nextBtn").disabled = true;
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        getEmployeeList().then((data) => {
          this.setState({ rowData: data });
        });
      };

      onRowDoubleClicked = () => {
        this.navigate();
      };
      onPreviousClick = () => {
        
        this.props.history.push({
          pathname: "/Login",
        })
      };
      navigate = () =>{
        var selectedRows = this.gridApi.getSelectedRows();
        localStorage.setItem("empCompanyNumber", selectedRows[0].EmployeeId + selectedRows[0].Region);
        if(this.props.fromMenu === "false")
        this.props.history.push({
          pathname: "/CustomerList",
            });
            else
            this.props.history.push({
              pathname: "/CustomerListMenu",
                });
      };

      onSelectionChanged = () => {
      document.getElementById("nextBtn").disabled = false;
      };

    render() {
      const renderGrid = ()=> {
        return (
          
          <div style={{height:"100%"}} >
            
           
              <div style={{height:"100%"}}>
                <h6 style={{paddingTop:"5px", paddingLeft: "20px"}}>Select an employee</h6>
                
                <div
                  className={`col-sm-12 ag-theme-alpine ${appConstants.isR3App ? "apGridR3" : "apGrid"}`}
                  style={{
                    height: "460px",
                    width: "1300px",
                    alignContent: "center",
                    paddingTop: "10px",
                  }}
                >
                  <AgGridReact
                    columnDefs={this.state.columnDefs}
                    enableCellTextSelection={true}
                    rowData={this.state.rowData}
                    onGridReady={this.onGridReady}
                    
                    rowHeight="30"
                    headerHeight="30"
                    rowSelection="single"
                    onSelectionChanged={this.onSelectionChanged.bind(this)}
                    onRowDoubleClicked={this.onRowDoubleClicked.bind(this)}
                    rowClassRules={this.state.rowClassRules}
                    tooltipShowDelay="0"
                  ></AgGridReact>
                </div>
                <div className="row" style={{textAlign:"right"}}>
              {this.props.fromMenu === "false" &&
            <button style={{marginLeft:"400px",marginBottom:"20px"}} id="previoustBtn" onClick={this.onPreviousClick} className="btn btn-sm btn-light">Previous</button>
                }
                  <h6 className={`${this.props.fromMenu === "false" ? "custListText":"empListText" }`}>Select an employee and click the 'Next' button</h6>
                  <button style={{marginLeft:"20px",marginBottom:"20px"}} id="nextBtn" onClick={this.navigate} className="btn btn-sm btn-light">Next</button>
            </div>
              </div>
           
          </div>
        );
      }
        return (
          <div style={{height:"100%"}}>      { this.props.fromMenu == "false" ?
          <div className={`${appConstants.isR3App ? "maindivR3" : "maindiv"}`}>
        <h5>SelfService</h5>
        <div
          className={`${
            appConstants.isR3App ? "divContainerGridR3" : "divContainerGrid"
          }`}
        >
         { renderGrid()}
        </div>
      </div>
          :
          <div style={{margin:"0px",height:"100%",borderTop:"solid"}}
          className={` ${
            appConstants.isR3App ? "divContainerGridR3" : "divContainerGrid"
          }`}
        >
         { renderGrid()}
        </div>
            }
            </div>
    
        );
      }
}
export default withRouter(EmployeeList);