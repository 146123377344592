import React, { Component } from 'react';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {getAllShipToList} from "../../_services/purchasingAnalysis.service";
import appConstants from "../../_services/applicationConstant";

class PAshipTos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      colHeaders: [
        {
          headerName: "",
          tooltip: (params) => "Control Click to Select Mutiple Rows",
          cellStyle: {
            textAlign: "left",
            borderRight: "0.1px solid lightgray",
          },
          field: "Name",
          filter: "agTextColumnFilter",
          width: 320,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
      ],

      defaultColDef: {
        flex: 1,
        minWidth: 150,
        filter: true,
        sortable: true,
      },
      rowData: [],
    };
  }
  clearSelection = () => {
    this.gridApi.deselectAll();
    this.gridApi.setFilterModel(null);
  };
  componentWillMount() {
    // getAllShipToList()
    //   .then((data) => {
    //     if (this.props.isAllCustomers === false) {
    //       const shipToList = data.filter((shipTo) =>
    //         this.props.selectedCustomers.includes(shipTo.CustId)
    //       );
    //       this.setState({rowData:[]},()=>{
    //       this.setState({ rowData: shipToList });
    //       });
    //     } else {          
    //       this.setState({ rowData: data });
    //     }
    //   })
    //   .catch(console.log);
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    const httpRequest = new XMLHttpRequest();
    const updateData = (data) => {
      params.api.setRowData(data.slice(0, 50));
    };

    httpRequest.open(
      "GET",
      getAllShipToList().then((data) => {
        if (this.props.isAllCustomers === false) {
          const shipToList = data.filter((shipTo) =>
            this.props.selectedCustomers.includes(shipTo.CustId)
          );
          
          if(localStorage.getItem('loginTypeCode') === 'C')
          { 
            var userName= localStorage.getItem('userName');
            if(userName.length > 6)
            {
                var shipTOData= data.filter((p) => p.ShipId.trim().includes(userName.trim()));
                this.setState({ rowData: shipTOData }); 
            }
            else{
              this.setState({rowData:[]},()=>{
                this.setState({ rowData: shipToList });
                });
            }
        }
        else{
          this.setState({rowData:[]},()=>{
          this.setState({ rowData: shipToList });
          });
        }
        } else {     
          if(localStorage.getItem('loginTypeCode') === 'C')
          { 
            var userName= localStorage.getItem('userName');
            if(userName.length > 6)
            {              
                var shipTOData= data.filter((p) => p.ShipId.trim().includes(userName.trim()));
                this.setState({ rowData: shipTOData }); 
            }
            else{
              this.setState({rowData:[]},()=>{
                this.setState({ rowData: data });
                });
            }
        }
        else     
          this.setState({ rowData: data });
        }

        window.watcher = setInterval(this.setSelectedItems, 25, this.gridApi);
      })
    );
    httpRequest.send();
  };

  setSelectedItems = (api) => {
    var containsSelections= false;
    this.gridApi.forEachNode((node) => {
      if (
        this.props.selectedShipTos.indexOf(
          (node.data.ShipId + "              ").substring(0, 14)
        ) > -1
      ){
        node.setSelected(true);       
      }
    });
    var selectedRows = this.gridApi.getSelectedRows();
    var selectedRowsString = [];
    selectedRows.forEach(function (selectedRow, index) {
      selectedRowsString.push(
        (selectedRow.ShipId + "              ").substring(0, 14)
      );
    });
    this.props.handleShipToSelection(selectedRowsString);
    clearInterval(window.watcher);
    window.watcher = 0;
  };
  onSelectionChanged = () => {
    var selectedRows = this.gridApi.getSelectedRows();
    var selectedRowsString = [];
    selectedRows.forEach(function (selectedRow, index) {
      selectedRowsString.push(
        (selectedRow.ShipId + "              ").substring(0, 14)
      );
    });
    this.props.handleShipToSelection(selectedRowsString);
  };
  render() {
    const renderShipTo = () => {
      return (
        <div>
          <div className="form-group row">
            {/* <label className="col-sm-2" style={{ textAlign: "right", paddingRight: "2px", paddingLeft: "0px" }}>filter by: </label> */}
            {/* <div className="col-sm-6">
        <input
          type="text" id="txtFilter"
          className="form-control form-control-sm"
          name="searchText" onChange={()=> this.filterchange()}
        ></input>
      </div> */}
            <div className="col-sm-4" style={{ textAlign: "right" }}>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  onClick={this.props.handleCheckAllShipTo}
                  type="checkbox"
                  checked={this.props.isAllShipTo}
                />
                <label className="form-check-label"> All Ship To </label>
              </div>
            </div>
            {this.props.isAllCustomers === false &&
            this.props.selectedCustomers.length <= 0 ? (
              <div
                className="col-sm-8"
                style={{ textAlign: "left", fontSize: "1rem" }}
              >
                No Customers are selected
              </div>
            ) : (
              ""
            )}
          </div>
          {this.props.isAllCustomers === false &&
          this.props.selectedCustomers.length <= 0 ? (
            ""
          ) : (
            <div
              className={`ag-theme-alpine ${
                appConstants.isR3App ? "apGridR3" : "apGrid"
              }`}
              style={{ height: "150px" }}
            >
              <AgGridReact
                columnDefs={this.state.colHeaders}
                defaultColDef={this.state.defaultColDef}
                onGridReady={this.onGridReady}
                rowData={this.state.rowData}
                rowHeight="30"
                headerHeight="0"
                rowSelection="multiple"
                tooltipShowDelay="0"
                onSelectionChanged={this.onSelectionChanged.bind(this)}
              ></AgGridReact>
            </div>
          )}
        </div>
      );
    };

    return renderShipTo();
  }
}


export default PAshipTos;