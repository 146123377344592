import React, { Component } from 'react';
import EmployeeList from '../components/EmployeeList'

class EmployeeListFromMenu extends Component {
    render() {
        return (
            <div style={{height:"100%"}}>
                 <EmployeeList fromMenu="ture" />
            </div>
        );
    }
}

export default EmployeeListFromMenu;