import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import DataColumns from "./DataColumns";
import CICustomer from "./CICustomer";
import CIVendors from "../ConsolidatedInventory/CIVendors";
import CIItemRange from "./CIItemRange";
import CIItemEntry from "./CIItemEntry";
import openfolder from "../../assets/images/openfolder.gif";

class TabFilters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItemEntries: [{ itemvalue: "" }],
      selectedItemEntryType: "Distributor Item#",
      fromItem: "",
      toItem: "",
      isAllCustomers: true,
      selectedCustomers: [],
      isAllVendors: true,
      selectedVendors: [],
    };
    this.ItemEntryRef=React.createRef();
    this.ItemRangeRef=React.createRef();  
    this.CICutomerRef=React.createRef(); 
    this.CIVendorRef = React.createRef(); 
  }
  clearSelection =() =>{
    this.setState({
      selectedItemEntries: [{ itemvalue: "" }],
      selectedItemEntryType: "Distributor Item#",
      fromItem: "",
      toItem: "",
      isAllCustomers: true,
      selectedCustomers: [],
      isAllVendors: true,
      selectedVendors: [],
    }, () => {
      if(this.ItemEntryRef.current !== null)
      this.ItemEntryRef.current.clearSelection();
      if(this.ItemRangeRef.current !== null)
      this.ItemRangeRef.current.clearSelection();
      if(this.CICutomerRef.current !== null)
      this.CICutomerRef.current.clearSelection();
      if(this.CIVendorRef.current !== null)
      this.CIVendorRef.current.clearSelection();

    });
    
  }
  itemEntryHandler = (data) => {
    this.setState({ selectedItemEntries: data }, () => {
      this.props.onEntryItemChange(this.state.selectedItemEntries);
    });
  };
  onItemEntryTypeChange = (data) => {
    this.setState({ selectedItemEntryType: data }, () => {
      
      this.props.onFilterEntryTypeChange(this.state.selectedItemEntryType);
    });
  };
  onItemFromChange = (data) => {
    this.setState({ fromItem: data }, () => {
      this.props.onItemFromChange(this.state.fromItem);
    });
  };
  onItemToChange = (data) => {
    this.setState({ toItem: data }, () => {
      this.props.onItemToChange(this.state.toItem);
    });
  };
  handleCheckCICustomers = (data) => {
    this.setState(
      {
        isAllCustomers: !this.state.isAllCustomers,
      },
      () => {
        this.props.onAllCICutomerChange(this.state.isAllCustomers);
        this.CICutomerRef.current.clearSelection();
        if(this.state.isAllCustomers === true)
        this.setState({ selectedCustomers: []},() => {
          this.props.onCICustomerSelectionChange(this.state.selectedCustomers);
        });
      
      }
    );
  };
  handleCustomerSelection = (data) => {
    this.setState(
      {
        selectedCustomers: data,
      },
      () => {
        this.props.onCICustomerSelectionChange(this.state.selectedCustomers);
        if(this.state.selectedCustomers.length > 0)
        this.setState({ isAllCustomers: false},() => {
          this.props.onAllCICutomerChange(this.state.isAllCustomers);
        });
      }
    );
  };
  handleCheckCIVendors = (data) => {
    this.setState(
      {
        isAllVendors: !this.state.isAllVendors,
      },
      () => {
        this.props.onAllCIVendorChange(this.state.isAllVendors);
        this.CIVendorRef.current.clearSelection();
        if(this.state.isAllVendors === true)
        this.setState({ selectedVendors: []},() => {
          this.props.onCIVendorSelectionChange(this.state.selectedVendors);
        });
      }
    );
  };
  handleVendorSelection = (data) => {
    this.setState(
      {
        selectedVendors: data,
      },
      () => {
        this.props.onCIVendorSelectionChange(this.state.selectedVendors);
        if(this.state.selectedVendors.length > 0)
        this.setState({ isAllVendors: false},() => {
          this.props.onAllCIVendorChange(this.state.isAllVendors);
        });
      }
    );
  };
  // componentDidUpdate() {
  //   if (this.props.onChange) {
  //     this.props.onChange(this.state);
  //   }
  // }
  render() {
    const CustomTab = ({ children }) => <Tab>{children}</Tab>;

    CustomTab.tabsRole = "Tab";
    return (
      <div
        style={{
          backgroundColor: "#fff",
          color: "#000000",
          width: "98%",
          height: "98%",
        }}
      >
        <Tabs style={{ borderBlock: "2px solid #fff" }}>
          <TabList>
            <Tab>
              {this.state.selectedCustomers.length > 0 && (
                <img src={openfolder} alt="Add" />
              )}{" "}
              Customers
            </Tab>
            <Tab>
              {" "}
              {this.state.selectedVendors.length > 0 && (
                <img src={openfolder} alt="Add" />
              )}{" "}
              Vendors
            </Tab>
            <Tab>
              {(this.state.fromItem !== "" || this.state.toItem !== "") && (
                <img src={openfolder} alt="Add" />
              )}
              Item Range
            </Tab>
            <Tab>
              {this.state.selectedItemEntries.length > 1 && (
                <img src={openfolder} alt="Add" />
              )}
              Item Entry
            </Tab>
          </TabList>
          <TabPanel style={{ height: "220px" }}>
            <div>
              <CICustomer ref={this.CICutomerRef}
                isAllCustomers={this.state.isAllCustomers}
                handleCheckCICustomers={this.handleCheckCICustomers}
                selectedCustomers={this.state.selectedCustomers}
                handleCustomerSelection={this.handleCustomerSelection}
              />
            </div>
          </TabPanel>
          <TabPanel style={{ height: "220px" }}>
            <CIVendors  ref={this.CIVendorRef}
              isAllVendors={this.state.isAllVendors}
              handleCheckCIVendors={this.handleCheckCIVendors}
              selectedVendors={this.state.selectedVendors}
              handleVendorSelection={this.handleVendorSelection}
            />
          </TabPanel>
          <TabPanel style={{ height: "220px" }}>
            <CIItemRange ref={this.ItemRangeRef}
              fromItem={this.state.fromItem}
              toItem={this.state.toItem}
              onItemFromChange={this.onItemFromChange}
              onItemToChange={this.onItemToChange}
            />
          </TabPanel>
          <TabPanel style={{ height: "220px" }}>
            <CIItemEntry ref={this.ItemEntryRef}
              onChange={this.itemEntryHandler}
              selectedItemEntries={this.state.selectedItemEntries}
              onItemEntryTypeChange={this.onItemEntryTypeChange}
            />
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

export default TabFilters;
