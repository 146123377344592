// To parse this data:
//
//   const Convert = require("./file");
//
//   const user = Convert.toUser(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
function toUser(json) {
    return cast(JSON.parse(json), r("User"));
}

function userToJson(value) {
    return JSON.stringify(uncast(value, r("User")), null, 2);
}

function invalidValue(typ, val) {
    throw Error(`Invalid value ${JSON.stringify(val)} for type ${JSON.stringify(typ)}`);
}

function jsonToJSProps(typ) {
    if (typ.jsonToJS === undefined) {
        const map = {};
        typ.props.forEach((p) => map[p.json] = { key: p.js, typ: p.typ });
        typ.jsonToJS = map;
    }
    return typ.jsonToJS;
}

function jsToJSONProps(typ) {
    if (typ.jsToJSON === undefined) {
        const map = {};
        typ.props.forEach((p) => map[p.js] = { key: p.json, typ: p.typ });
        typ.jsToJSON = map;
    }
    return typ.jsToJSON;
}

function transform(val, typ, getProps) {
    function transformPrimitive(typ, val) {
        if (typeof typ === typeof val) return val;
        return invalidValue(typ, val);
    }

    function transformUnion(typs, val) {
        // val must validate against one typ in typs
        const l = typs.length;
        for (let i = 0; i < l; i++) {
            const typ = typs[i];
            try {
                return transform(val, typ, getProps);
            } catch (_) {}
        }
        return invalidValue(typs, val);
    }

    function transformEnum(cases, val) {
        if (cases.indexOf(val) !== -1) return val;
        return invalidValue(cases, val);
    }

    function transformArray(typ, val) {
        // val must be an array with no invalid elements
        if (!Array.isArray(val)) return invalidValue("array", val);
        return val.map(el => transform(el, typ, getProps));
    }

    function transformDate(val) {
        if (val === null) {
            return null;
        }
        const d = new Date(val);
        if (isNaN(d.valueOf())) {
            return invalidValue("Date", val);
        }
        return d;
    }

    function transformObject(props, additional, val) {
        if (val === null || typeof val !== "object" || Array.isArray(val)) {
            return invalidValue("object", val);
        }
        const result = {};
        Object.getOwnPropertyNames(props).forEach(key => {
            const prop = props[key];
            const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
            result[prop.key] = transform(v, prop.typ, getProps);
        });
        Object.getOwnPropertyNames(val).forEach(key => {
            if (!Object.prototype.hasOwnProperty.call(props, key)) {
                result[key] = transform(val[key], additional, getProps);
            }
        });
        return result;
    }

    if (typ === "any") return val;
    if (typ === null) {
        if (val === null) return val;
        return invalidValue(typ, val);
    }
    if (typ === false) return invalidValue(typ, val);
    while (typeof typ === "object" && typ.ref !== undefined) {
        typ = typeMap[typ.ref];
    }
    if (Array.isArray(typ)) return transformEnum(typ, val);
    if (typeof typ === "object") {
        return typ.hasOwnProperty("unionMembers") ? transformUnion(typ.unionMembers, val)
            : typ.hasOwnProperty("arrayItems")    ? transformArray(typ.arrayItems, val)
            : typ.hasOwnProperty("props")         ? transformObject(getProps(typ), typ.additional, val)
            : invalidValue(typ, val);
    }
    // Numbers can be parsed by Date but shouldn't be.
    if (typ === Date && typeof val !== "number") return transformDate(val);
    return transformPrimitive(typ, val);
}

function cast(val, typ) {
    return transform(val, typ, jsonToJSProps);
}

function uncast(val, typ) {
    return transform(val, typ, jsToJSONProps);
}

function a(typ) {
    return { arrayItems: typ };
}

function u(...typs) {
    return { unionMembers: typs };
}

function o(props, additional) {
    return { props, additional };
}


function r(name) {
    return { ref: name };
}

const typeMap = {
    "User": o([
        { json: "IsAutenticated", js: "IsAutenticated", typ: u(undefined, true) },
        { json: "IdentityKey", js: "IdentityKey", typ: u(undefined, "") },
        { json: "ViewAllShipTos", js: "ViewAllShipTos", typ: u(undefined, "") },
        { json: "ViewOrderAmount", js: "ViewOrderAmount", typ: u(undefined, "") },
        { json: "QuantityOnOrderViewPermission", js: "QuantityOnOrderViewPermission", typ: u(undefined, "") },
        { json: "QuantityOnHandViewPermission", js: "QuantityOnHandViewPermission", typ: u(undefined, "") },
        { json: "CanViewInventory", js: "CanViewInventory", typ: u(undefined, "") },
        { json: "CanViewOrders", js: "CanViewOrders", typ: u(undefined, "") },
        { json: "CanUploadEDI", js: "CanUploadEDI", typ: u(undefined, "") },
        { json: "CanViewUPSTrackingInfo", js: "CanViewUPSTrackingInfo", typ: u(undefined, "") },
        { json: "CanViewInventoryPrices", js: "CanViewInventoryPrices", typ: u(undefined, "") },
        { json: "CanViewCustomerPrices", js: "CanViewCustomerPrices", typ: u(undefined, "") },
        { json: "CanViewSales", js: "CanViewSales", typ: u(undefined, "") },
        { json: "CanViewInvoices", js: "CanViewInvoices", typ: u(undefined, "") },
        { json: "CanViewInventoryDaysOnHand", js: "CanViewInventoryDaysOnHand", typ: u(undefined, "") },
        { json: "MinMaxQuantityViewPermission", js: "MinMaxQuantityViewPermission", typ: u(undefined, "") },
        { json: "CanViewAllItems", js: "CanViewAllItems", typ: u(undefined, "") },
        { json: "CanCreateOrders", js: "CanCreateOrders", typ: u(undefined, "") },
        { json: "CanViewListPrice", js: "CanViewListPrice", typ: u(undefined, "") },
        { json: "CanViewPriceList", js: "CanViewPriceList", typ: u(undefined, "") },
        { json: "CanViewAR", js: "CanViewAR", typ: u(undefined, "") },
        { json: "CanViewSalesAnalysis", js: "CanViewSalesAnalysis", typ: u(undefined, "") },
        { json: "JWTToken", js: "JWTToken", typ: u(undefined, "") },
    ], false),
};

module.exports = {
    "userToJson": userToJson,
    "toUser": toUser,
};
