import api from "./apiUrl";
import { authHeader, encodedURL } from "../_helpers/auth-header";

export function updateChangePassword(oldPassword, newPassword) {
  var urlparam = "&oldPassword=" + oldPassword;
  urlparam += "&newPassword=" + newPassword;

  return fetch(
    `${api.proxyUrl}${api.url}${encodedURL(
      `/changepassword/updateLoginPassword?identityKey=${localStorage
        .getItem("identityKey")
        .trim()}&companyNumber=${localStorage.getItem(
        "companyNumber"
      )}&customerNumber=${localStorage.getItem("customerNumber")}${urlparam}`
    )}`,
    {
      method: "GET",
      headers: authHeader(),
    }
  ).then((response) => response.json());
}
