import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {getArBucketItems} from "../../_services/accountsPayable.service";
import api from '../../_services/apiUrl';
import appConstants from "../../_services/applicationConstant";
import {encryptData} from '../../Utils';
import {decryptData} from '../../Utils';

class ARBucket extends Component {
  constructor(props) {
    super(props);
    const salt = process.env.SALT || process.env.REACT_APP_KEY;
        const originalData = decryptData(localStorage.getItem("id_token"), salt);
    this.state = {
        companyNumber:  localStorage.getItem('companyNumber'),
        customerNumber: localStorage.getItem('customerNumber'),
        jwtToken: originalData,

      colHeaders: [
        { headerName: "Days Old",comparator: compare,   tooltip: (params) => 'Click to show the details', cellStyle: {textAlign: 'center',borderRight: '0.1px solid lightgray'},
          field: "Name",width:141,sortable: true,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: "Current Balance", type: 'rightAligned', valueFormatter: currencyFormatter, cellStyle: {textAlign: 'right',borderRight: '0.1px solid lightgray'}, tooltip: (params) => 'Click to show the details', field: "Balance",width:142,sortable: true,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: "# of Invoices",type: 'rightAligned', cellStyle: {textAlign: 'right',borderRight: '0.1px solid lightgray !important'}, tooltip: (params) => 'Click to show the details', field: "Invcount",width:142,sortable: true,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}`  }],
        rowData:[],
        rowClassRules: {
          'total-row': function(params) {
            var rowVal = params.data.Name;
            return rowVal === 'Total';
          },
        },
         
    };
  }
componentDidMount() {
      
  getArBucketItems()
  .then((data) => {
            this.setState({ rowData: data.sort((a, b) => a.sort > b.sort ? 1 : -1) })
        })
        .catch(console.log);
}
onGridReady = params => {
  this.gridApi = params.api;
  this.gridColumnApi = params.columnApi;
  params.api.sizeColumnsToFit(); 

};

onSelectionChanged = () => {
  var selectedRows = this.gridApi.getSelectedRows();
 
   // selectedRows.length === 1 ? 
    //alert(selectedRows[0].Name);// : '';
    this.props.rowSelected(selectedRows[0].Bucket);
};

onRowDoubleClicked = () => {
  var selectedRows = this.gridApi.getSelectedRows();
 
   // selectedRows.length === 1 ? 
    //alert(selectedRows[0].Name);// : '';
    this.props.rowSelected(selectedRows[0].Bucket);
  };

  render() {
    const renderARBucketGrid = () => {
        return <div className= {`ag-theme-alpine ${appConstants.isR3App ? "apGridR3" : "apGrid"}`}
         style={ {height: '70%'} }>
        <AgGridReact
            columnDefs={this.state.colHeaders}
            enableCellTextSelection={true}
            rowData={this.state.rowData} 
            onGridReady={this.onGridReady}  
            rowHeight="30"   
            headerHeight="30" 
            rowSelection="single"
            rowClassRules={this.state.rowClassRules}
            onRowDoubleClicked={this.onRowDoubleClicked.bind(this)}
            tooltipShowDelay="0"
            >
        </AgGridReact>
      </div>;
  }
    return (
     
                      renderARBucketGrid() 
             
    );
  }
}

export default ARBucket;
function currencyFormatter(params) {
  if (params.value !== undefined && params.value !== "" && params.value !== null && params.value!== '0' &&  params.value!== '0.00') {
    if (parseFloat(params.value) < 0) return "-$" +  (parseFloat(params.value).toFixed(2) * -1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else return "$" +  (parseFloat(params.value).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return "$0.00";
}
function compare (number1, number2) {
  if (number1 === null && number2 === null) {
  return 0;
  }
  if(isNaN(number1)){ return -1}
  if(isNaN(number2)){ return 1}
  if (number1 === null) {
  return -1;
  }
  if (number2 === null) {
  return 1;
  }
  return number1 - number2;}
