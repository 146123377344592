import React, { Component } from "react";
import { getItenEntryList } from "../../_services/purchasingAnalysis.service";

class PAItemEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTypes: getItenEntryList(),
      inputList: [],
      itemTypeChange: 1,
    };
  }
  clearSelection = () => {
    this.setState({
      searchTypes: getItenEntryList(),
      inputList: this.props.selectedItemEntries,
      itemTypeChange: 1,
    });
  };
  componentDidMount() {
    this.setState({
      inputList: this.props.selectedItemEntries,
    });
  }
 handleInputChange = (e, index) => {
  const { name, value } = e.target;
  const list = [...this.state.inputList];
  list[index][name] = value;
  this.setState({ inputList: list });
    
  };
  handleEnterTab = (e, index) => {
    if ((e.key === "Enter" || e.key === "Tab" || e.type === "blur") && (e.target.value !== "" && e.target.value !== null)) {
      const { name, value } = e.target;
      const list = [...this.state.inputList];
      list[index][name] = (value + "            ").substring(0, 12);
      if(list.length-1 === index){
      list.push({ itemvalue: "" });
      }
      this.setState({ inputList: list }, () => {
        this.props.onChange(list);
      });
    }
  };
  cancelItems = () => {
    const list = [];

    this.setState((prevState) => ({
      inputList: prevState.inputList.filter((el) => true !== true),
    }));
    list.push({ itemvalue: "" });
    this.setState({ inputList: list }, () => {
      this.props.onChange(list);
    });
  };

  itemTypeChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val }, () => {
      this.props.onItemEntryTypeChange(val);
    });
  };

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-1 ">&nbsp;</div>
          <div className="col-sm-5 ">
            <div
              style={{
                maxHeight: "200px",
                scrollBehavior: "auto",
                contain: "content",
                overflowY: "auto",
              }}
            >
              Enter Item List
              {this.state.inputList.map((x, i) => {
                return (
                  <div id={i} className="box">
                    <input
                      className="form-control form-control-sm filterControls"
                      name="itemvalue"
                      placeholder="Enter Item#"
                      value={x.itemvalue.trim()}
                      onChange={(e) => this.handleInputChange(e, i)}
                      onKeyDown={(e) => this.handleEnterTab(e, i)}
                      onBlur ={(e) => this.handleEnterTab(e, i)}
                      onFocus ={(e) => this.handleEnterTab(e, i)}

                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-sm-5">
            <div className="form-group row">
              <select
                id="disabledSelect"
                className="form-control form-control-sm filterControls"
                name="itemTypeChange"
                onChange={this.itemTypeChange}
                value={this.state.itemTypeChange}
              >
                {this.state.searchTypes.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.value}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group row" style={{ marginBottom: "0.3rem" }}>
              <button
                className="btn btn-secondary btn-sm filterButtonsClear"
                onClick={this.cancelItems}
                type="button"
              >
                Clear Selection
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PAItemEntry;
