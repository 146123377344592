import React, { Component } from "react";
import CheckBox from "../../components/Checkbox";
import { getReportColumns } from "../../_services/purchasingAnalysis.service";

class ReportColumns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportColumns: getReportColumns(),
    };
  }
  handleCheckChieldElement = (event) => {
    let reportColumns = this.state.reportColumns;
    reportColumns.forEach((report) => {
      if (report.value === event.target.value)
        report.isChecked = event.target.checked;
    });
    this.setState(
      {
        reportColumns: reportColumns,
      },
      () => {
        this.props.onChange(reportColumns);
      }
    );
  };
  clearSelection = () => {
    this.setState({
      reportColumns: getReportColumns(),
    });
  };
  render() {
    return (
      <div style={{ padding: "2px" }}>
        Report Columns
        <div
          style={{
            backgroundColor: "#fff",
            color: "black",
            padding: "8px",
            width: "135px",
          }}
        >
          <ul style={{ listStyleType: "none", padding: "4px" }}>
            {this.state.reportColumns.map((report) => {
              return (
                <CheckBox
                  handleCheckChieldElement={this.handleCheckChieldElement}
                  {...report}
                />
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default ReportColumns;
