import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

export class PurchasingChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedQualifier: "Purchases",
      Data: {},
      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          align: "end",
        },
        scales: {
          xAxes: [
            {
              barPercentage: 1,
              gridLines: {
                display: true,
                color: "rgba(0, 0, 0, 0.1)",
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: "rgba(0, 0, 0, 0.1)",
              },
            },
          ],
        },
      },
    };
  }

  componentDidMount() {
    this.setState({
      Data: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: this.generateDummyData(),
      },
      selectedQualifier: this.props.selectedQualifier,
    });
  }
  qualifierChanged = (changedValue) => {
    this.setState({ selectedQualifier: changedValue }, () => {
      this.setState({
        Data: {
          labels: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
          datasets: this.generateDummyData(),
        },
      });
    });
  };
  generateDummyData() {
    let _data = this.props.chartData.filter((x) => x.Period !== "Total");
    let years = Object.keys(_data[0])
      .filter((x) => x.indexOf("_") === -1 && x.indexOf("Period") === -1)
      .map(Number);

    //Remoe "IF" block when to show all year data in chart
    if (years.length > 4) {
      let toBeRemovedYear = Math.min(...years);
      years = years.sort((a, b) => b - a).slice(0, 4);
    }

    let dataSet = [];

    years.forEach((year) => {
      let obj = {
        label: year,
        backgroundColor: this.getColor(year),
        data: [],
      };
      var column = year;
      if (this.props.selectedQualifier === "Purchases") column = year;
      else column = year + "_UNITS";
      _data.forEach((data) => {
        obj.data.push(data[column]);
      });
      dataSet.push(obj);
    });

    return dataSet;
  }
  getColor(year) {
    switch (year) {
      case 2020:
        return "#79b91c";
      case 2019:
        return "#cccc99";
      case 2018:
        return "#6699ff";
      case 2017:
        return "#ffff99";
    }
  }
  render() {
    return (
      <div class="chart-container" style={{ height: "300px", width: "600px" }}>
        <Bar data={this.state.Data} options={this.state.barChartOptions}></Bar>
      </div>
    );
  }
}

export default PurchasingChart;
