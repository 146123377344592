import React, { Component } from "react";
import NavigationBar from "../components/NavigationBar";
import CustomerContact from "../components/CustomerContact";
import bunzlLogo from '../assets/images/bunzl-logo.gif';
import r3Logo from '../assets/images/r3-logo.gif';
import onlineChat from '../assets/images/onlinechat.gif';
import onlineR3Chat from '../assets/images/R3_chat.jpg';
import appConstants from "../_services/applicationConstant";

export default class HeaderBunzl  extends Component {
render(){
    return(
        <div style={{borderBlockEnd:"solid"}}>
            <div className="row" >    
                <div className="col-sm-1"> 
                {appConstants.isR3App ?
                <a target="_blank" title="Click for Company Home page" href="http://www.r3safety.com/">
                <img src={r3Logo} className="logoess" alt="R3" />
                </a>
                :
                <a target="_blank" title="Click for Company Home page" href="http://www.bunzldistribution.com/">
                <img src={bunzlLogo} className="logoess" alt="Bunzl" />
                </a>
                }
                </div>
                <div className="col-sm-10 zeroPadding">
                    <div className="row"> 
                        <div className="col-sm-12">
                        <NavigationBar isR3App={appConstants.isR3App} ></NavigationBar>
                        </div>
                    </div> 
                    <div className="row"> 
                        <div className="col-sm-12">
                        <CustomerContact />
                        </div>
                    </div>   
                    
                </div>   
                <div className="col-sm-1">
                <a href="https://app.velaro.com/chat?siteId=20913&groupId=0&showwhen=inqueue&secure=yes" target="_blank">
                 {appConstants.isR3App ?
                    <img src={onlineR3Chat} className="float-right"  alt=""/>
                    :
                    <img src={onlineChat} className="float-right"  alt=""/>
                 }
                </a>
                </div>
            </div>         
        </div>
    );
}

}