import React, { Component } from "react";

class ESSProgressBar extends Component {
  render() {
    return (
      <div style={{ padding: "3px" }}>
        <div className="progress">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated active progress-bar-success"
            role="progressbar"
            aria-valuenow="100"
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: "100%" }}
          >
            <span style={{ color: "black", fontWeight: "bold" }}>
              Getting Report Data...
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default ESSProgressBar;
