
import React, { Component } from 'react';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import {getUPSResponse} from "../../_services/displayOrder.service";
import appConstants from "../../_services/applicationConstant";
import { decryptData } from '../../Utils';
class UPSTrackingInitialScreen extends Component {
    constructor(props) {
        super(props);
        const salt = process.env.SALT || process.env.REACT_APP_KEY;
        const originalData = decryptData(localStorage.getItem("id_token"), salt);
        this.state = {
            companyNumber:  localStorage.getItem('companyNumber'),
            customerNumber: localStorage.getItem('customerNumber'),
            jwtToken: originalData,
    
          colHeaders: [
            { headerName: "Date",   tooltip: (params) => 'Click to show the details', cellStyle: {textAlign: 'center',borderRight: '0.1px solid lightgray'},
              field: "dateField",width:260,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
            { headerName: "Time", type: 'rightAligned', cellStyle: {textAlign: 'right',borderRight: '0.1px solid lightgray'}, tooltip: (params) => 'Click to show the details', field: "timeField",width:240,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
            
            { headerName: "Location",type: 'rightAligned', cellStyle: {textAlign: 'right'}, tooltip: (params) => 'Click to show the details', field: "cityField",width:320,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}`  },
        { headerName: "Activity",type: 'rightAligned', cellStyle: {textAlign: 'right'}, tooltip: (params) => 'Click to show the details', field: "descriptionField",width:290,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}`  }],
            rowData:[],
            config : null,
            rowClassRules: {
              'total-row': function(params) {
                var rowVal = params.data.Name;
                return rowVal === 'Total';
              },
            },
             
        };
      };
    
    render() {
        const renderARBucketGrid = () => {
            return <div className= {`ag-theme-alpine ${appConstants.isR3App ? "apGridR3" : "apGrid"}`}
             style={ {height: '220px'} }>
            <AgGridReact
                columnDefs={this.state.colHeaders}
                rowData={this.state.rowData} 
                //onGridReady={this.onGridReady}  
                rowHeight="30"   
                headerHeight="30" 
                rowSelection="single"
                rowClassRules={this.state.rowClassRules}
                //onSelectionChanged={this.onSelectionChanged.bind(this)}
                tooltipShowDelay="0"
                >
            </AgGridReact>
          </div>;
      }
        return(
        
<div className="col-sm-10" >

                    <div className="row" style={{height:"40%", borderBottom: "1px solid rgba(0,0,0,.125)"}}>
                        <div className="col-sm-12" >
                            <h6>Last Activity</h6><br/>
                            <div className="col-sm-12">
                            <div className="row">
                          <div className="col-sm-2">
                      <label
                        className="form-check-label"
                        for="inlineRadio2" value={this.state.descriptionField}
                        style={{ paddingTop: "4px" }}
                      >
                    Status:
                      </label>
                      </div>
                      <div className="col-sm-1">
                      <label
                        className="form-check-label"
                        for="inlineRadio2" value={this.state.descriptionField}
                        style={{ paddingTop: "4px" }}
                      >
                  
                      </label>
                      </div>
                      </div>
                      <div className="row">
                          <div className="col-sm-2">
                      <label
                        className="form-check-label"
                        for="inlineRadio2"
                        style={{ paddingTop: "4px" }}
                      >
                    Scheduled Delivery:
                      </label>
                      </div>
                      <div className="col-sm-1">
                      <label
                        className="form-check-label"
                        for="inlineRadio2" value={this.state.descriptionField}
                        style={{ paddingTop: "4px" }}
                      >
                 
                      </label>
                      </div>
                      </div>
                      <div className="row">
                          <div className="col-sm-2">
                      <label
                        className="form-check-label"
                        for="inlineRadio2"
                        style={{ paddingTop: "4px" }}
                      >
                Shipped or Billed On:
                      </label>
                      </div>
                      <div className="col-sm-1">
                      <label
                        className="form-check-label"
                        for="inlineRadio2" value={this.state.descriptionField}
                        style={{ paddingTop: "4px" }}
                      >
                  
                      </label>
                      </div>
                      </div>
                      <div className="row">
                          <div className="col-sm-2">
                      <label
                        className="form-check-label"
                        for="inlineRadio2"
                        style={{ paddingTop: "4px" }}
                      >
                    Tracking Number:
                      </label>
                      </div>
                      <div className="col-sm-1">
                      <label
                        className="form-check-label"
                        for="inlineRadio2" value={this.state.valueField}
                        style={{ paddingTop: "4px" }}
                      >
                  
                      </label>
                      </div>
                      </div>
                      <div className="row">
                          <div className="col-sm-2">
                      <label
                        className="form-check-label"
                        for="inlineRadio2"
                        style={{ paddingTop: "4px" }}
                      >
                    Service Type:
                      </label>
                      </div>
                      <div className="col-sm-1">
                      <label
                        className="form-check-label"
                        for="inlineRadio2" value={this.state.descriptionField}
                        style={{ paddingTop: "4px" }}
                      >
                   
                      </label>
                      </div>
                      </div>
                      <div className="row">
                          <div className="col-sm-2">
                      <label
                        className="form-check-label"
                        for="inlineRadio2"
                        style={{ paddingTop: "4px" }}
                      >
                    Package Weight:
                      </label>
                      </div>
                      <div className="col-sm-1">
                      <label
                        className="form-check-label"
                        for="inlineRadio2" value={this.state.descriptionField}
                        style={{ paddingTop: "4px" }}
                      >
                  
                      </label>
                      </div>
                      </div>
                      </div>
                      
                            </div>
                        
                    </div>
                    <div className="row" style={{height:"60%"}}>
                        <div className="col-sm-12" >
                            <h6>Package Progress</h6><br/>
                        {renderARBucketGrid()}
                            <div>NOTICE: UPS/Fedex tracking systems and the information they contain are the private property of UPS/Fedex and may be used solely to track shipments shipped by or to you and for no other purpose. You may not make the information obtained through the tracking systems available on any web site or otherwise distribute the information.</div>
                        </div>
                    </div>
                </div>
        );
};
}
export default UPSTrackingInitialScreen;