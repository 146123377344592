import React, { Component } from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import downloadbutton from "../../assets/images/downloadbutton.gif";
import PurchasingFilter from "./PurchasingFilter";
import PurchasingChart from "./PurchasingChart";
import chart from "../../assets/images/icon_chart.png";
import grid from "../../assets/images/icon_grid.png";
import { getFilteredSummaryData } from "../../_services/purchasing.service";
import { CSVLink } from "react-csv";
import { getCustomerPriceListData } from "../../_services/customPriceService";
import appConstants from "../../_services/applicationConstant";
import { getPurchasingSummary } from "../../_services/purchasing.service";
import PurchasingDetail from "./PurchasingDetail";
import ESSModal from "../../components/ESSModal.jsx";
import ESSProgressBar from "../../components/ESSProgressBar";
import user from '../../models/User';
import { decryptData } from '../../Utils';

class Purchasing extends Component {
  constructor(props) {
    super(props);
   // user.toUser = JSON.parse(localStorage.getItem('UserMenu'))

  const salt = process.env.SALT || process.env.REACT_APP_KEY;
  const originalData = decryptData(localStorage.getItem("UserMenu"), salt);
  user.toUser = originalData;

    this.handleCellClick = this.handleCellClick.bind(this);
    this.gridViewClick = this.gridViewClick.bind(this);
    this.ChartRef = React.createRef();
    var currentYear=new Date().getFullYear();
    this.state = {
      customerNumber: localStorage.getItem("companyNumber") + localStorage.getItem("customerNumber"),
      headers :[
        { label: 'Period', key: 'Period' },
        { label:  (new Date().getFullYear())+' DOLLARS', key: ""+currentYear+"" },
        { label: (new Date().getFullYear())+' UNITS', key: ""+currentYear+"_UNITS" },
        { label: (new Date().getFullYear()-1)+' DOLLARS', key: ""+currentYear -1 +"" },
        { label:(new Date().getFullYear()-1)+ ' UNITS', key: ""+currentYear-1+"_UNITS" },
        { label: (new Date().getFullYear()-2)+' DOLLARS', key: ""+currentYear-2 +"" },
        { label: (new Date().getFullYear()-2)+' UNITS', key: ""+currentYear-2+"_UNITS" },
        { label: (new Date().getFullYear()-3)+' DOLLARS', key: ""+currentYear-3+"" },
        { label: (new Date().getFullYear()-3)+' UNITS', key:  ""+currentYear-3+"_UNITS" }    ],
      selectedQualifier: "Purchases",
      searchClicked: false,
      gridView: true,
      
      columnDefs: [
        {
          headerName: "Period",
          field: "Period",
          cellStyle: {
            textAlign: "left",
            borderRight: "0.1px solid lightgray",
          },
          resizable: true,
          width: 280,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: (new Date().getFullYear()),
          field: new Date().getFullYear().toString(),
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          type: "rightAligned",
          hide: user.toUser.CanViewInventoryPrices === "Y" ? false: true,
          cellRendererFramework: (props) => {
            var amount = currencyFormatter(props.value);
            return (
              <button
                className="btn btn-xs btn-link"
                style={{ fontSize: "0.7rem", fontWeight: "bold" }}
                onClick={this.handleCellClick.bind(
                  this,
                  props.data.Period,
                  new Date().getFullYear(),
                  props
                )}
              >
                {" "}
                {amount}{" "}
              </button>
            );
          },
          resizable: true,
          width: 250,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: (new Date().getFullYear()-1),
          field:(new Date().getFullYear()-1).toString(),
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          type: "rightAligned",
          hide: user.toUser.CanViewInventoryPrices === "Y" ? false: true,
          cellRendererFramework: (props) => {
            var amount = currencyFormatter(props.value);
            return (
              <button
                className="btn btn-xs btn-link"
                style={{ fontSize: "0.7rem", fontWeight: "bold" }}
                onClick={this.handleCellClick.bind(
                  this,
                  props.data.Period,
                  new Date().getFullYear()-1,
                  props
                )}
              >
                {" "}
                {amount}{" "}
              </button>
            );
          },
          resizable: true,
          width: 250,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: (new Date().getFullYear()-2),
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          hide: user.toUser.CanViewInventoryPrices === "Y" ? false: true,
          type: "rightAligned",
         
          field:(new Date().getFullYear() -2).toString(),
          valueFormatter:currencyFormatterWithParam,
          resizable: true,
          width: 260,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: (new Date().getFullYear()-3),
          field:  (new Date().getFullYear()-3).toString(),
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          hide: user.toUser.CanViewInventoryPrices === "Y" ? false: true,
          type: "rightAligned",
          valueFormatter:currencyFormatterWithParam,
          resizable: true,
          width: 260,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        
        {
          headerName: (new Date().getFullYear()),
          field:  (new Date().getFullYear()).toString()+ "_UNITS",
          hide: user.toUser.CanViewInventoryPrices === "N" ? false: true,
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          type: "rightAligned",
          cellRendererFramework: (props) => {
            return (
              <button
                className="btn btn-xs btn-link"
                style={{ fontSize: "0.7rem", fontWeight: "bold" }}
                onClick={this.handleCellClick.bind(
                  this,
                  props.data.Period,
                  new Date().getFullYear(),
                  props
                )}
              >
                {(props.value === undefined || props.value === null || props.value === "" ) ? '0': parseFloat(props.value).toLocaleString()}
              </button>
            );
          },
          resizable: true,
          width: 250,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: (new Date().getFullYear()-1),
          hide: user.toUser.CanViewInventoryPrices === "N" ? false: true,          
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          type: "rightAligned",
          cellRendererFramework: (props) => {
            return (
              <button
                className="btn btn-xs btn-link"
                style={{ fontSize: "0.7rem", fontWeight: "bold" }}
                onClick={this.handleCellClick.bind(
                  this,
                  props.data.Period,
                  new Date().getFullYear()-1,
                  props
                )}
              >
                {(props.value === undefined || props.value === null || props.value === "" ) ? '0': parseFloat(props.value).toLocaleString()}
              </button>
            );
          },
          field:  (new Date().getFullYear()-1).toString()+ "_UNITS",
          resizable: true,
          width: 260,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: (new Date().getFullYear()-2),
          field:  (new Date().getFullYear()-2).toString()+ "_UNITS",
          hide: user.toUser.CanViewInventoryPrices === "N" ? false: true,
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          type: "rightAligned",
          valueFormatter:unitsFormatterWithParam,
          resizable: true,
          width: 260,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: (new Date().getFullYear())-3,
          field:  (new Date().getFullYear()-3).toString()+ "_UNITS",
          hide: user.toUser.CanViewInventoryPrices === "N" ? false: true,          
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          type: "rightAligned",
          valueFormatter:unitsFormatterWithParam,
          resizable: true,
          width: 300,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
      ],

      rowData: [],
      exportList: [],
      shipToNumber: "",
      poStatus: "",
      showPurchaseDetails: false,
      selectedMonth: "",
      selectedYear: "",
      shipToName: "",
      show: false,
      userMessage: "",
      selectedCurrency:"",
      selectedCurrencyUnit:"",

    };
  }
  handleCellClick = (month, year,propsInput) => {
    var  currency= propsInput.node.data[year] ;
    var year_unit= year+"_UNITS";
    var currencyUnit=propsInput.node.data[year_unit];
    
    this.setState({
      showPurchaseDetails: true,
      selectedMonth: month,
      selectedYear: year,
      selectedCurrency:currency,
      selectedCurrencyUnit:currencyUnit,
    });
  };
  componentDidMount() {
    var currentYear= new Date().getFullYear();
    if (user.toUser.CanViewInventoryPrices === "Y"){
      this.setState({canViewPurchases: true, headers:[
        { label: 'Period', key: 'Period' },
        { label:  (new Date().getFullYear())+' DOLLARS', key: ""+currentYear+"" },
        { label: (new Date().getFullYear())+' UNITS', key: ""+currentYear+"_UNITS" },
        { label: (new Date().getFullYear()-1)+' DOLLARS', key: ""+currentYear -1 +"" },
        { label:(new Date().getFullYear()-1)+ ' UNITS', key: ""+currentYear-1+"_UNITS" },
        { label: (new Date().getFullYear()-2)+' DOLLARS', key: ""+currentYear-2 +"" },
        { label: (new Date().getFullYear()-2)+' UNITS', key: ""+currentYear-2+"_UNITS" },
        { label: (new Date().getFullYear()-3)+' DOLLARS', key: ""+currentYear-3+"" },
        { label: (new Date().getFullYear()-3)+' UNITS', key:  ""+currentYear-3+"_UNITS" }    
      ]})
            }
            else{
      this.setState({canViewPurchases: false, selectedQualifier:"Quantity", headers:[
        { label: 'Period', key: 'Period' },
        { label: (new Date().getFullYear())+' UNITS', key: ""+currentYear+"_UNITS" },
        { label:(new Date().getFullYear()-1)+ ' UNITS', key: ""+currentYear-1+"_UNITS" },
        { label: (new Date().getFullYear()-2)+' UNITS', key: ""+currentYear-2+"_UNITS" },
        { label: (new Date().getFullYear()-3)+' UNITS', key:  ""+currentYear-3+"_UNITS" }       
      ]})      
            }
  }
  setGridColumns(qualifierName) {  
    var currentYear= new Date().getFullYear();

    if (qualifierName === "Quantity" ||  user.toUser.CanViewInventoryPrices === "N") {
      this.gridColumnApi.setColumnVisible(currentYear, false);
      this.gridColumnApi.setColumnVisible(currentYear-1, false);
      this.gridColumnApi.setColumnVisible(currentYear-2, false);
      this.gridColumnApi.setColumnVisible(currentYear-3, false);
      this.gridColumnApi.setColumnVisible(currentYear+"_UNITS", true);
      this.gridColumnApi.setColumnVisible(currentYear-1+"_UNITS", true);
      this.gridColumnApi.setColumnVisible(currentYear-2+"_UNITS", true);
      this.gridColumnApi.setColumnVisible(currentYear-3+"_UNITS", true);
    } else {
      this.gridColumnApi.setColumnVisible(currentYear, true);
      this.gridColumnApi.setColumnVisible(currentYear-1, true);
      this.gridColumnApi.setColumnVisible(currentYear-2, true);
      this.gridColumnApi.setColumnVisible(currentYear-3, true);
      this.gridColumnApi.setColumnVisible(currentYear+"_UNITS", false);
      this.gridColumnApi.setColumnVisible(currentYear-1+"_UNITS", false);
      this.gridColumnApi.setColumnVisible(currentYear-2+"_UNITS", false);
      this.gridColumnApi.setColumnVisible(currentYear-3+"_UNITS", false);
    }    
  }

  qualifierChanged = (changedValue) => {
    this.setState({ selectedQualifier: changedValue });
    if (this.state.rowData.length > 0) {
      this.setGridColumns(changedValue);
      if (!this.state.gridView)
        this.ChartRef.current.qualifierChanged(changedValue);
    } else {
    }
  };
  purchasingSummaryClick = () => {
    this.setState({
      showPurchaseDetails: false,
    }, ()=>{
      setTimeout(() => {
        this.qualifierChanged(this.state.selectedQualifier);             
          }, 100);
    });  

  };


  gridViewClick = (flag) => {
    this.setState({ gridView: this.state.rowData.length ? flag : true },()=>{
      setTimeout(() => {
    this.qualifierChanged(this.state.selectedQualifier); 
        
      }, 100);
    });
      
  };
  doSearch = (shipTo, POStatus, selectedShipToName) => {
    this.setState(
      {
        shipToNumber: shipTo,
        poStatus: POStatus,
        selectedQualifier: POStatus,
        shipToName: shipTo === "ALL" ? "ALL" : selectedShipToName,
        searchClicked:true,
        rowData:[]
      },
      () => {
        getPurchasingSummary(this.state.shipToNumber).then((data) => {
          if(data.length > 0)
          {
            this.setState({searchClicked:false });
          }
          if(data === null || data.length === 0 || data.Message === "An error has occurred."){
          this.setState({
            show: true,
            userMessage:
              "No matching sales have been found!",
              searchClicked:false
          });
          this.setState({ rowData: [], exportList:[]});
          return null;
          }
          else {
          this.setState({ rowData: data, exportList:getFilteredSummaryData(data,POStatus) });
          this.setGridColumns(POStatus);
          }


        });
      }
    );
  };
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };
  render() {
    const renderInventoryGrid = () => {
      return (
        <div
          className={`ag-theme-alpine ${
            appConstants.isR3App ? "gridMainR3" : "gridMain"
          }`}
          style={{ height: "98%" }}
        >
         {this.state.searchClicked?
        <ESSProgressBar/>
       :''}
          <AgGridReact
            columnDefs={this.state.columnDefs}
            enableCellTextSelection={true}
            
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            rowHeight="30"
            headerHeight="30"
            footerHeight="30"
          ></AgGridReact>
        </div>
      );
    };

    const renderInventoryChart = () => {
      return (
        <div>
          <PurchasingChart
            ref={this.ChartRef}
            selectedQualifier={this.state.selectedQualifier}
            chartData={this.state.rowData}
          ></PurchasingChart>
        </div>
      );
    };

    return (
      <div>
        <div
          className={`container-fluid text-center ${
            appConstants.isR3App ? "contentPageR3" : "contentPage"
          }`}
          style={{ height: "98%" }}
        >
          <div
            className={` card text-left cardNoBorder ${
              appConstants.isR3App ? "logintitleR3" : "logintitle"
            }`}
            style={{ height: "80%" }}
          >
            <div className="row">
              <div className="col-sm-3">
                {this.state.showPurchaseDetails ? (
                  <h6 className="card-header">Purchasing Detail</h6>
                ) : (
                  <h6 className="card-header">Purchasing Summary</h6>
                )}
              </div>
              <div className="col-sm-6" style={{ padding: "8px" }}>
                {this.state.showPurchaseDetails && (
                  <div>
                    <span
                      style={
                        appConstants.isR3App
                          ? {
                              fontWeight: "bold",
                              fontSize: "0.8rem",
                              cursor: "pointer",
                              color: "#000000",
                            }
                          : {
                              fontWeight: "bold",
                              fontSize: "0.8rem",
                              cursor: "pointer",
                              color: "#1d2f68",
                            }
                      }
                      onClick={this.purchasingSummaryClick}
                    >
                     Purchasing Summary &gt;
                    </span>
                    <span>
                      {" "}
                      Purchasing Detail for  {this.state.selectedMonth !== "Total" ? this.state.selectedMonth +", " : "" }
                     
                      {this.state.selectedYear}{" "}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div
              className="card-body"
              style={{ backgroundColor: "#fff", padding: "0.5rem" }}
            >
              {this.state.showPurchaseDetails ? (
                <PurchasingDetail
                  shipToName={this.state.shipToName}
                  month={this.state.selectedMonth}
                  year={this.state.selectedYear}
                  currency={this.state.selectedCurrency}
                  currencyUnit={this.state.selectedCurrencyUnit}
                  shipTo={this.state.shipToNumber}
                />
              ) : (
                <div className="row content" style={{ height: "98%" }}>
                  <div className="col-sm-12" style={{ height: "98%" }}>
                    <div className="row" ref="filterComponent">
                      <div className="form-group col-sm-10">
                        <PurchasingFilter
                        selectedQualifier={this.state.selectedQualifier}
                          qualifierChanged={this.qualifierChanged}
                          doSearch={this.doSearch}
                        />
                      </div>
                      <div style={{ paddingTop: "5px" }}>
                        {this.state.exportList.length > 0 ? (
                          <CSVLink
                            ref="csv" headers={this.state.headers}
                            data={this.state.exportList}
                            filename={"Purchasing_"+ this.state.customerNumber +"_"+ this.state.shipToName +".csv"}
                          >
                            <img
                              src={downloadbutton}
                              title="Download Purchasing Items."
                              alt="Download Report"
                            />
                          </CSVLink>
                        ) : undefined}
                      </div>
                    </div>
                    <div
                      className="row"
                      ref="gridComponent"
                      style={{ height: "90%" }}
                    >
                      <div className="col-sm-12" style={{ height: "100%" }}>
                        {!this.state.gridView && this.state.rowData.length
                          ? renderInventoryChart()
                          : renderInventoryGrid()}
                      </div>
                    </div>
                    <div
                      className="row"
                      //style={{ paddingTop: "5px", backgroundColor: "#79B91C" }}
                      style={
                        appConstants.isR3App
                          ? {backgroundColor: "#0079c2" }
                          : {backgroundColor: "#79B91C"}
                          }
                    >
                      <div className="col-sm-12">
                        <div>
                          <img
                            src={grid}
                            style={
                              this.state.gridView
                                ? {
                                    cursor: "Pointer",
                                    border: "1px solid blue",
                                    padding: "4px",
                                  }
                                : { cursor: "Pointer", padding: "4px" }
                            }
                            onClick={() => {
                              this.gridViewClick(true);
                            }}
                          />
                          &nbsp; &nbsp;
                          <img
                            src={chart}
                            style={
                              !this.state.gridView
                                ? {
                                    cursor: "Pointer",
                                    border: "1px solid blue",
                                    padding: "4px",
                                  }
                                : { cursor: "Pointer", padding: "4px" }
                            }
                            onClick={() => {
                              this.gridViewClick(false);
                            }}
                          />
                        </div>
                      </div>

                      <div></div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Purchasing;

function currencyFormatterWithParam(params) {
  if (params.value !== undefined && params.value !== "" && params.value !== null && params.value !== '0' && params.value !== '0.00') {
  if (parseFloat(params.value) < 0) return "-$" +  (parseFloat(params.value).toFixed(2) * -1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else return "$" +   (parseFloat(params.value).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  else return "$0.00";
}

function unitsFormatterWithParam(params){
  if (params.value !== undefined && params.value !== "" && params.value !== null) {
    return parseFloat(params.value).toLocaleString();
    }
    else return "0";
}

function unitsFormatter(value){
  if (value !== undefined && value !== "" && value !== null) {
    return parseFloat(value).toLocaleString();
    }
    else return "0";
}

function currencyFormatter(value) {
  if (value !== undefined && value !== "" && value !== null && value!== '0' &&  value!== '0.00') {
    if (parseFloat(value) < 0) return "-$" +  (parseFloat(value).toFixed(2) * -1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else return "$" +  (parseFloat(value).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return "$0.00";
}
