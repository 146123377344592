import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import { getArBucketItems } from "../../_services/accountsPayable.service";
import appConstants from "../../_services/applicationConstant";

export class ARBucketChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Data: {},

      barChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        onClick: (e, element) => {
          if (element.length > 0) {
            var ind = element[0]._index;
            var bucket = this.state.Data.datasets[0].bucketId[ind];
            this.props.rowSelected(bucket);
          }
        },
        scales: {
          xAxes: [
            {
              barPercentage: 1,
              gridLines: {
                display: true,
                color: "rgba(0, 0, 0, 0.1)",
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                color: "rgba(0, 0, 0, 0.1)",
              },
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    };
  }

  componentDidMount() {
    getArBucketItems().then((res) => {
      const bucket = res
        .filter((x) => x.Name != "Total")
        .sort((a, b) => (a.sort > b.sort ? 1 : -1));
      let Balance = [];
      let DaysOld = [];
      let Bucketid = [];
      bucket.forEach((item) => {
        Balance.push(item.Balance);
        DaysOld.push(item.Name);
        Bucketid.push(item.Bucket);
      });
      this.setState({
        Data: {
          labels: DaysOld,

          datasets: [
            {
              label: "Balance",
              bucketId: Bucketid,
              data: Balance,
              backgroundColor: [
                appConstants.isR3App ? "#0079c2" : "#9ACD32",
                appConstants.isR3App ? "#0079c2" : "#9ACD32",
                appConstants.isR3App ? "#0079c2" : "#9ACD32",
                appConstants.isR3App ? "#0079c2" : "#9ACD32",
                appConstants.isR3App ? "#0079c2" : "#9ACD32",
              ],
            },
          ],
        },
      });
    });
  }
  render() {
    return (
      <div class="chart-container" style={{ height: "300px", width: "600px" }}>
        <Bar data={this.state.Data} options={this.state.barChartOptions}></Bar>
      </div>
    );
  }
}
export default ARBucketChart;
