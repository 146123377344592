import React, { Component } from "react";
import bunzlLogo from "../assets/images/bunzl-logo.gif";
import infologo from "../assets/images/information.png";
import authenticationService from "../_services/authentication.services";
import "../assets/css/login.css";
import { withRouter } from "react-router-dom";
import r3Logo from "../assets/images/r3-logo.gif";
import appConstants from "../_services/applicationConstant";
import { styles } from "../assets/css/bunzlUI.css";
import ESSModal from "../components/ESSModal.jsx";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCompanyField: true,
      txtUserName: "",
      txtPassword: "",
      txtCompanyNumber: "",
      logintype: "C",
      showLoginBox: false,
      version: require("../../package.json").version,
      userMessage:"",
      ssoFlag:"0",
      value: '',
      

      
    };

    this.Auth = new authenticationService();
    this.submit = this.submit.bind(this);
  }

  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;

    if (val == "Customer Group") {
      this.state.logintype = "G";
      this.state.txtCompanyNumber = "0";
    } else if (val == "Employee") {
      this.state.logintype = "E";
      this.state.txtCompanyNumber = "0";
    } else if (val == "Customer") {
      this.state.logintype = "C";
    }
    if (nam == "logintype") {
      if (val == "Customer Group" || val == "Employee") {
        this.setState({ showCompanyField: false });
      } else {
        this.setState({ showCompanyField: true });
      }
    } else {
      this.setState({ [nam]: val });
    }
  };


componentDidMount(){


  let url = window.location.href;
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let sharedsecret = params.get('sharedsecret');
  let loginType = params.get('loginType');
  let customer = params.get('customer');
  let userId = null;
  let compnayNumber = null
  
  if(url.includes("SelfService/Login.html"))
    {
      {
        this.props.history.push({
          pathname: "/Login",
          });
      }
       
    }
  
  
  if(customer != null)
  {
    compnayNumber = customer.substring(0,2);
    userId = customer.substring(2, customer.length);
  }
  
      fetch(url, { method: 'GET', headers: new Headers({
        'Content-Type': 'application/json',
      })})
      
      .then((res) => {

        
       
  if(sharedsecret != null)
  {
  if(loginType == "customer")
  {
    this.setState({ logintype: "C" });
  }
  else if(loginType == "customergroup")
  {
    this.setState({ logintype: "G" });
  }
  else if(loginType == "employee")
  {
    this.setState({ logintype: "E" });
  }
  
  
  
  document.getElementById("loginDiv").style.display = "none";
   return this.Auth.login(
      userId,
      '',
      compnayNumber,
      this.state.logintype,
      sharedsecret
      )
      .then((res) => {

       
        this.props.setSessionStateFunc("true");
  
        if (this.state.logintype === "G") {
          this.props.history.push({
            pathname: "/CustomerList",
          });
        } else if (this.state.logintype === "E") {
          this.props.history.push({
            pathname: "/EmployeeList",
          });
        } else {
          this.props.history.push({
            pathname: "/Home",  
          });
        }
         
      })
      .catch((err) => {
          window.location = "./SSOModal";
      });
  }    })
      .catch(function(err) {
          console.info(err + " url: " + url);
      });
      
  
    }
    maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
     object.target.value = object.target.value.slice(0, object.target.maxLength)
      }
    }
  handleClose = () => this.setState({ showLoginBox: false });

  cancleLogin = () => {
    this.setState({
      ...this.state,
      txtPassword: "",
      txtUserName: "",
      txtCompanyNumber: "",
    });
  };
  submit(e) {
    e.preventDefault();

    this.Auth.loginSubmit(
      this.state.txtUserName,
      this.state.txtPassword,
      this.state.txtCompanyNumber,
      this.state.logintype,
      this.state.ssoFlag
    )
      .then((res) => {
      
        if(res.Message!=="An error has occurred.")
        {
        this.props.setSessionStateFunc("true");
        if (this.state.logintype === "G") {
          this.props.history.push({
            pathname: "/CustomerList",
          });
        } else if (this.state.logintype === "E") {
          this.props.history.push({
            pathname: "/EmployeeList",
          });
        } else {
          this.Auth.getCustomerLocation().then(()=>{
            this.Auth.getCustomerAddress().then(() =>{
          this.props.history.push({
            pathname: "/Home",
          })
        });
        });
        }
        
      }
      else{
        this.setState({showLoginBox:true, userMessage:'Userid/password combination is invalid'})
      }
      })
      .catch((err) => {
        this.setState({showLoginBox:true, userMessage:'Userid/password combination is invalid'})
      });
    {
    }
  }
  render() {
    //Below details has to change once build timestamp identified
   
    return (
      <div id="loginDiv" style={{height:"100%"}}>
        {this.state.showLoginBox && (
          <ESSModal
            handle2ndButtonClick={this.handle2ndButtonClick}
            handleClose={this.handleClose}
            btn1Text="Ok"
            userMessage={this.state.userMessage}
            isAlert="true"
            isContentMessage="true"
            show={this.state.showLoginBox}
          />
        )}
        <div className={`${appConstants.isR3App ? "maindivR3" : "maindiv"}`}>
          <h5 className="mainHeading">Self Service</h5>
          <div className="divContainer">
            <form onSubmit={this.submit.bind(this)} id="login-form">
              <div className="divlogin">
                <div className="card">
                  <div
                    className={`${
                      appConstants.isR3App ? "logintitleR3" : "logintitle"
                    }`}
                  >
                    <div className="card-header">
                      <h5 className="card-title">Login</h5>
                    </div>
                  </div>
                  <div
                    className={`${
                      appConstants.isR3App ? "divcardR3" : "divcard"
                    }`}
                  >
                    <div className="card-body card-body_login border-primary">
                      <div>
                        {appConstants.isR3App ? (
                          <a
                            target="_blank"
                            title="Click for Company Home page"
                            href="http://www.r3safety.com/"
                          >
                            <img src={r3Logo} className="logoess" alt="R3" />
                          </a>
                        ) : (
                          <a
                            target="_blank"
                            title="Click for Company Home page"
                            href="http://www.bunzldistribution.com/"
                          >
                            <img
                              src={bunzlLogo}
                              className="logoess"
                              alt="Bunzl"
                            />
                          </a>
                        )}
                        {appConstants.isR3App ? (
                          <label className="bunzltitle">
                            R3 Reliable Redistribution Resource
                          </label>
                        ) : (
                          <label className="bunzltitle">
                            Bunzl Distribution
                          </label>
                        )}
                        <img
                          src={infologo}
                          className="infologo"
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({ showLoginBox: true, userMessage:`Customer Self Service\nVersion: ${
                              process.env.REACT_APP_VERSIONUMBER
                              }\nBuild Timestamp: ${new Date().toLocaleString()}\nBuild Number: ${
                                process.env.REACT_APP_VERSIONUMBER
                              }` });
                          }}
                        ></img>
                      </div>

                      <center>
                        <h6>Enter Login Credentials and click login</h6>
                      </center>
                      <div className="loginContent">
                        <div className="form-group row">
                          <label
                            className="col-sm-4 col-form-label"
                            for="loginAs"
                          >
                            Login as
                          </label>
                          <select
                            name="logintype"
                            onChange={this.myChangeHandler}
                            className="form-control card-body_login col-sm-6"
                            id="loginAs"
                          >
                            <option> Customer</option>
                            <option>Employee</option>
                            <option>Customer Group</option>
                          </select>
                        </div>

                        {this.state.showCompanyField ? (
                          <div className="form-group row">
                            <label className="col-sm-4 col-form-label">
                              Company Number
                            </label>
                            <input
                              type="companyNo"
                              onChange={this.myChangeHandler}
                              name="txtCompanyNumber"
                              type="number"
                              maxLength = "2" onInput={this.maxLengthCheck} onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                              value={this.state.txtCompanyNumber}
                              className="form-control card-body_login col-sm-6"
                              placeholder="Enter Company Number"
                              />
                          </div>
                        ) : null}

                        <div className="form-group row">
                          <label className="col-sm-4 col-form-label">
                            User Id
                          </label>
                          <input
                            type="userId"
                            name="txtUserName"
                            maxLength="12"
                            onChange={this.myChangeHandler}
                            value={this.state.txtUserName}
                            className="form-control card-body_login col-sm-6"
                            placeholder="Enter User Id"
                          />
                        </div>

                        <div className="form-group row">
                          <label className="col-sm-4 col-form-label">
                            Password
                          </label>
                          <input
                            type="password"
                            maxLength="10"
                            name="txtPassword"
                            onChange={this.myChangeHandler}
                            value={this.state.txtPassword}
                            className="form-control card-body_login col-sm-6"
                            placeholder="Enter password"
                          />
                        </div>

                        <div>
                          <div className="btn-group col-sm-5 ">
                            <button
                              
                              type="submit"
                              className="btn btn-primary btn-sm"
                              disabled={!(this.state.txtPassword) || !(this.state.txtUserName) || (!(this.state.txtCompanyNumber) && this.state.logintype==="C" )}
                              value={this.state.inputVal}
                            >
                              Submit
                            </button>
                          </div>

                          <div className="btn-group col-sm-5 ">
                            <button
                              type="button"
                              onClick={this.cancleLogin}
                              className="btn btn-secondary btn-sm"
                              value={this.state.inputVal}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${
                      appConstants.isR3App ? "footercolorR3" : "footercolor"
                    }`}
                  >
                    <div className="card-footer">
                    <div className="row">
                      <div className="col-12">
                        <center>
                      <small  className="text-muted">
                      {appConstants.isR3App
                          ? "Copyright"+" "+ (new Date().getFullYear())+ "R3 Reliable Redistribution Resource"
                          : "Copyright"+" " +(new Date().getFullYear())+" Bunzl Distribution"}
                      </small>
                      </center>
                      </div>
                  
                    </div>
                    <div className="row" >
                    <div className="col-12">
                    <center>
<small>
                       < a href="https://www.bunzlnalegal.com/privacypolicy" style={{color:"white"}}>Privacy Policy  |</a>
            &nbsp;
            <a href="https://www.bunzlnalegal.com/terms-of-use" style={{color:"white"}}> Terms of Use  |</a>
            &nbsp;
            <a href="https://bunzlnalegal.com/privacypolicy/#california-residents" style={{color:"white"}}>  California Collection Notice  |</a>
            &nbsp;
            <a href="https://bunzlnalegal.com/privacypolicy/#notice-of-right-to-opt-out" style={{color:"white"}}> Do Not Sell My Info</a>
                   
            </small></center>
                    </div>
                   
                    </div>
                     </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Login);
