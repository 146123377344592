import React, { Component } from "react";
import CheckBox from "../../components/Checkbox";
import { getDataColumns } from "../../_services/purchasingAnalysis.service";

class DataColumns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataColumns: getDataColumns(),
    };
  }
  handleCheckChieldElement = (event) => {
    let dataColumns = this.state.dataColumns;
    dataColumns.forEach((report) => {
      if (report.value === event.target.value)
        report.isChecked = event.target.checked;
    });
    this.setState({ dataColumns: dataColumns }, () => {
      this.props.onChange(dataColumns);
    });
  };
  clearSelection = () => {
    this.setState({
      dataColumns: getDataColumns(),
    });
  };
  render() {
    return (
      <div style={{ padding: "2px" }}>
        Data Columns
        <div
          style={{
            backgroundColor: "#fff",
            color: "black",
            padding: "8px",
            width: "150px",
          }}
        >
          <ul style={{ listStyleType: "none", padding: "4px", width: "200px" }}>
            {this.state.dataColumns.map((data) => {
              return (
                <CheckBox
                  handleCheckChieldElement={this.handleCheckChieldElement}
                  {...data}
                />
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default DataColumns;
