import React, { Component } from 'react';
import CustomerList from "../components/CustomerList";

class CustomerListMenu extends Component {
    render() {
        return (
            <div style={{height:"100%"}}>
                <CustomerList fromMenu="ture" />
            </div>
        );
    }
}

export default CustomerListMenu;