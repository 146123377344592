import api from './apiUrl';
import {authHeader,encodedURL} from '../_helpers/auth-header'
import user from '../models/User';

export function getCustomerList()
{    
    var customerNumber=localStorage.getItem('userName')
    var loginTypeCode= localStorage.getItem('loginTypeCode')

    if(loginTypeCode ==="E")
    {
        customerNumber = localStorage.getItem('empCompanyNumber')
    }
    return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/user/customer?customerNumber=${customerNumber}&loginTypeCode=${loginTypeCode}`)}`, {
        method: 'GET',
        headers:authHeader()
    }).then(response => response.json());
}
    
    