import React, { Component } from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import downloadbutton from '../../assets/images/downloadbutton.gif';
import bunzlsplash from "../../assets/images/bunzl-splash.jpg";
import CustomerFooter from "../../components/CustomerFooter";
import r3splash from "../../assets/images/r3-splash.jpg";
import HeaderBunzl from "../../components/HeaderBunzl";
import ContactCC from "../../components/contactCC";
import CustomPriceFilter from "./CustomPriceFilter";
import {getFilteredData} from "../../_services/customPriceService";
import {CSVLink} from 'react-csv';
import {getCustomerPriceListData} from "../../_services/customPriceService";
import appConstants from "../../_services/applicationConstant";
import ESSModal from "../../components/ESSModal.jsx";
import ESSProgressBar from "../../components/ESSProgressBar";



class CustomPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers :[
        { label: 'Customer Part Number', key: 'CustomerPartNumber' },
        { label: 'Item Number', key: 'ItemNumber' },
        { label: 'Case Qty/Pack', key: 'SellCase' },
        { label: 'Description', key: 'Description' },
        { label: 'Source', key: 'PriceSource' },
        { label: 'Ship Type Pending', key: 'ShipTypePending' },
        { label: 'Price', key: 'Price' },
        { label: 'Effective Date', key: 'EffectiveDate' },
        
        { label: 'Expiration Date', key: 'ExpirationDate' }

      ],
      searchText:'',
      show: false,
          userMessage:'',
      vendor:'', 
      searchType:'', 
      pageCount:'', 
      qualifier:'',
      searchClicked:false,
      columnDefs: [
        { headerName: "Customer Part Number",  field: "customerPartNumber",sortable: true,
          cellStyle: {
           textAlign: "left",          
            borderRight: "0.1px solid lightgray",
          }, resizable: true, width:120,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` 
        },
        { headerName: "Item Number",  field: "itemNumber",sortable: true,
          cellStyle: {
          textAlign: "center",
          borderRight: "0.1px solid lightgray",
          }, resizable: true, width:120,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` 
        },
        { headerName: "Case Qty/Pack",  sortable: true,
          cellStyle: {
          textAlign: "center",
          borderRight: "0.1px solid lightgray",
          }, field: "sellCase/sellUms",valueGetter: sellValueGetter,resizable: true,  width:120,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` 
        },
        { headerName: "Description",  field: "description",valueGetter:descriptionValueGetter,sortable: true,
          cellStyle: {
           textAlign: "center",
            borderRight: "0.1px solid lightgray",
          }, resizable: true, width:250,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` 
        },
        { headerName: "Source",  field: "priceSource",sortable: true,
          cellStyle: {
          textAlign: "center",
            borderRight: "0.1px solid lightgray",
          }, resizable: true, width:100,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` 
        },
        { headerName: "Ship Type Pending",  field: "curpnd",sortable: true,
          cellStyle: {
          textAlign: "center",
            borderRight: "0.1px solid lightgray",
          }, resizable: true, width:160,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` 
        },
        { headerName: "Price",comparator: compare,  field: "price",sortable: true, valueFormatter: currencyFormatter, 
          cellStyle: {
          textAlign: "right",
            borderRight: "0.1px solid lightgray",
          }, resizable: true, width:100,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` 
        },
        { headerName: "Effective Date",  field: "effectiveDate",sortable: true,cellRenderer: dateFormatter,comparator:fn_DateCompare,
          cellStyle: {
          textAlign: "center",
            borderRight: "0.1px solid lightgray",
          }, resizable: true, width:160,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` 
        },
        { headerName: "Expiration Date",  field: "expirationDate",sortable: true,cellRenderer: dateFormatter,comparator:fn_DateCompare,
          cellStyle: {
          textAlign: "center",
            borderRight: "0.1px solid lightgray",
          }, resizable: true, width:160,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` 
        }        
      ],
        
        rowData:[],
        inventoryList:[]
    };
  }
  handleClose = () => this.setState({ show: false });

  doSearch= (customerPart, item, department, description) => {  
   this.setState({customerPart:customerPart,
    item:item,
    department:department,
    description:description,
    rowData:[],
    searchClicked:true});
    getCustomerPriceListData(customerPart, item, department, description)
    .then((data) => {
      this.setState({inventoryList:getFilteredData(data)})
      this.setState({ rowData: data })
      if(data.length > 0)
      this.setState({ rowData: data, searchClicked:false });
      else{
        this.setState({
          show: true,
          userMessage:
            "No matching Record have been found.",
            searchClicked:false
        });
      }
    });
  }
  onGridReady = params => {    
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit(); 
  };
  render() {
    const renderInventoryGrid = () => {
          return <div className= {`ag-theme-alpine ${
            appConstants.isR3App ? "gridMainR3" : "gridMain"
          }`} style={ {height: "95%" } }>
         {this.state.searchClicked?
        <ESSProgressBar/>
       :''}
          <AgGridReact          
              
              columnDefs={this.state.columnDefs}
              enableCellTextSelection={true}
              onGridReady={this.onGridReady} 
              rowData={this.state.rowData}  rowHeight="30"     headerHeight="30"   footerHeight="30"    
              >
          </AgGridReact>
        </div>;      
    }
    return (
      <div>
        <div          
          className={`container-fluid text-center ${
            appConstants.isR3App ? "contentPageR3" : "contentPage"
          }`}       style={{height:"98%"}}        
        >
                    {this.state.show && (
          <ESSModal
            handleClose={this.handleClose}
            btn1Text="OK"
            userMessage={this.state.userMessage}
            isAlert="true"
            show={this.state.show}
          />
        )}
          <div
            className={` card text-left cardNoBorder ${
              appConstants.isR3App ? "logintitleR3" : "logintitle"
            }`} style={{ height: "85%"}}
          >
            <h6 className="card-header">Customer Price</h6>
            <div className="card-body" style={{ backgroundColor: "#fff", padding:"0.5rem" }}>
              <div className="row content" style={{height:"98%"}}>
                <div className="col-sm-12" style={{height:"98%"}}>
                  <div className="row" ref="filterComponent">
                    <div className="form-group col-sm-10">
                      <CustomPriceFilter doSearch={this.doSearch} />
                    </div>
                    <div style={{paddingTop:"5px"}}>
                      { this.state.inventoryList.length > 0 ? 
                           <CSVLink ref="csv" data={this.state.inventoryList} headers={this.state.headers}  filename={'Filtered-CustomPriceList-Report.csv'}> 
                         <img src={downloadbutton} title="Download Filtered Custom Price List Items."  alt="Download Report" />
                          </CSVLink> 
                          : undefined} 
                    </div>
                  </div>
                  <div
                    className="row"                  
                    ref="gridComponent" style={{height:"98%"}}
                  >
                    <div className="col-sm-12" style={{height:"98%"}}>
                    {  renderInventoryGrid() }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomPrice;
function compare (number1, number2) {
  if (number1 === null && number2 === null) {
  return 0;
  }
  if(isNaN(number1)){ return -1}
  if(isNaN(number2)){ return 1}
  if (number1 === null) {
  return -1;
  }
  if (number2 === null) {
  return 1;
  }
  return number1 - number2;}

  function dateFormatter(param) {
    if(param.value != null &&  param.value != "" && param.value != undefined){
    var d = param.value.split(" ");
    var dateValue = d[0];
    return dateValue;
    }
    else return "";
  }

  function currencyFormatter(params) {
    if (params.value !== undefined && params.value !== "" && params.value !== null && params.value!== '0' &&  params.value!== '0.00') {
      if (parseFloat(params.value) < 0) return "-$" + parseFloat(params.value * -1 ).toLocaleString();
      else return "$" + parseFloat(params.value).toLocaleString();
    }
    return "$0.00";
  }
  function sellValueGetter(params) {
    return params.data.sellCase+'/ '+ params.data.sellUms;
  }
    function descriptionValueGetter(params) {
    return params.data.description+' '+ params.data.description2;
  }
  function fn_DateCompare(DateA, DateB) {
    var a = new Date(DateA);
    var b = new Date(DateB);
  
    var msDateA = Date.UTC(a.getFullYear(), a.getMonth()+1, a.getDate());
    var msDateB = Date.UTC(b.getFullYear(), b.getMonth()+1, b.getDate());
  
    if (parseFloat(msDateA) < parseFloat(msDateB))
      return -1;  // less than
    else if (parseFloat(msDateA) == parseFloat(msDateB))
      return 0;  // equal
    else if (parseFloat(msDateA) > parseFloat(msDateB))
      return 1;  // greater than
    else
      return null;  // error
  }