import React, { Component } from "react";
import {NavigationBar} from "../components/NavigationBar";
import CustomerContact from "../components/CustomerContact";
import onlineChat from '../assets/images/onlinechat.gif';
import onlineR3Chat from '../assets/images/R3_chat.jpg';
import appConstants from "../_services/applicationConstant"


export default class ContactCC  extends Component {
render(){
    return(
        <div className="container-fluid" style={{borderBlockStart:"solid"}}>  
            <div className="row">    
                <div className="col-sm-12"> 
                <a href="https://app.velaro.com/chat?siteId=20913&groupId=0&showwhen=inqueue&secure=yes" target="_blank">
                 {appConstants.isR3App ?
                    <img src={onlineR3Chat} className="float-right"  alt=""/>
                    :
                    <img src={onlineChat} className="float-right"  alt=""/>
                 }
                </a>
                </div>                
            </div>         
        </div>
    );
}

}