import React, { Component } from "react";
import bunzlCSS from "../../assets/css/bunzlUI.css";
import {getSearchTypes} from "../../_services/inventory.service";
import {getVendors} from "../../_services/inventory.service";
import {getPageNumbers} from "../../_services/inventory.service"
import appConstants from "../../_services/applicationConstant";
import ESSModal from "../../components/ESSModal.jsx";
import { decryptData } from "../../Utils";


class InventoryFilter extends Component {
  constructor(props) {
    super(props);
      this.state={
        searchTypes: getSearchTypes(appConstants.isR3App),
        pageNumbers: getPageNumbers(),
        vendors: [],
        selectedType:'none',
        selectedVendor:'0',
        searchText:'',
        pageCount:'20',
        qualifier:'contains',
        searchClicked:false,
        show: false,
        userMessage: "",
      }
  }
  
  componentDidMount() {
    const salt = process.env.SALT || process.env.REACT_APP_KEY;
    const originalData = decryptData(localStorage.getItem("id_token"), salt);
    getVendors(localStorage.getItem('companyNumber'),originalData)
    .then((data) => {
              this.setState({ vendors: data })
    })
    .catch(console.log)
   

  }
  onQualifierChange= (event) => {
    this.setState({
      qualifier: event.target.value
    });
  }

  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });     
  }
  resetFilter = () => {
    this.setState({
      selectedType:'none',
      selectedVendor:'0',
      searchText:'',
      pageCount:'20',
      qualifier:'contains'
    });
  }

  searchClicked=() =>{
    this.props.doSearch(this.state.searchText,
      this.state.selectedVendor,
      this.state.selectedType,
      this.state.pageCount,
      this.state.qualifier);
     
  }

  render() {
    const enabled =
    ((this.state.searchText.length == 0  &&  this.state.selectedType == "none")   &&
    this.state.selectedVendor !== "0" )
    ||
    (this.state.searchText.length > 0 &&  this.state.selectedType != "none" )
     ;
    return (
      <div  className={`${
        appConstants.isR3App ? "filterBodyR3" : "filterBody"
      }`}        
      >
        {this.state.show && (
          <ESSModal
            handleClose={this.handleClose}
            btn1Text="OK"
            userMessage={this.state.userMessage}
            isAlert="true"
            show={this.state.show}
          />
        )}
        <div className="row">
          <div className="col-sm-3">
            <div className="form-group row">
              <label className="col-sm-4 col-form-label ">Search </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control form-control-sm filterControls"
                  placeholder="Search text.."
                  value={this.state.searchText
                  }
                  name="searchText" onChange={this.myChangeHandler}
                ></input>
              </div>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-group row">
              <select
                id="disabledSelect"
                className="form-control form-control-sm filterControls"
                name="selectedType" onChange={this.myChangeHandler}
                value={this.state.selectedType} 
              >
                <option value="none" >--Select Search Type--</option>
                {this.state.searchTypes.map((type) => <option key={type.value} value={type.value}>{type.label}</option>)}
              </select>
            </div>
          </div>
          <div className="col-sm-5">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"               
                value="equal"
                checked={this.state.qualifier === "equal"}
                onChange={this.onQualifierChange}
              />
              <label className="form-check-label" for="inlineRadio1">
                Equals
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"               
                value="contains"
                checked={this.state.qualifier === "contains"}
                onChange={this.onQualifierChange}
              />
              <label className="form-check-label" for="inlineRadio2">
                Contains
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"               
                value="starts"
                checked={this.state.qualifier === "starts"}
                onChange={this.onQualifierChange}
              />
              <label className="form-check-label" for="inlineRadio3">
                Starts with
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"               
                value="point"
                checked={this.state.qualifier === "point"}
                onChange={this.onQualifierChange}
              />
              <label className="form-check-label" for="inlineRadio3">
                Starting Point
              </label>
            </div>
          </div>
          <div className="col-sm-2">
            <button className="btn btn-primary btn-sm filterButtons" disabled={!enabled} onClick={this.searchClicked} type="button">
              Search
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-3">
            <div className="form-group row">
              <label className="col-sm-4 col-form-label">Vendor</label>
              <div className="col-sm-8">
                <select name="selectedVendor" onChange={this.myChangeHandler}
                  id="disabledSelect"
                  className="form-control form-control-sm filterControls"
                  value={this.state.selectedVendor} 
                >
                  <option value='0'>-- All --</option>
                {this.state.vendors.map((type) => <option key={type.vendorId} value={type.vendorId}>{type.vendorName}</option>)}
                 
                </select>
              </div>
            </div>
          </div>
          <div className="col-sm-2">&nbsp;</div>
          <div className="col-sm-5">
            <div className="form-group row">
              <label className="col-sm-6 col-form-label">
                Number of rows to display
              </label>
              <div className="col-sm-3">
                <select
                  id="disabledSelect" name="pageCount"
                  onChange={this.myChangeHandler}
                  value={this.state.pageCount} 
                  className="form-control form-control-sm filterControls"
                >
                {this.state.pageNumbers.map((page) => <option key={page.value} value={page.label}>{page.label}</option>)}
               
                </select>
              </div>
            </div>
          </div>
          <div className="col-sm-2">
            <button className="btn btn-primary btn-sm filterButtons" onClick={this.resetFilter}  type="button">
              Clear
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default InventoryFilter;
