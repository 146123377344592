import React, { Component } from "react";
import bunzlCSS from "../assets/css/bunzlUI.css";
import api from "../_services/apiUrl";
import { authHeader } from "../_helpers/auth-header";
import appConstants from "../_services/applicationConstant";
import ESSModal from "../components/ESSModal.jsx";
import { withRouter } from "react-router-dom";

class CustomerFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoginBox: false,
      companyNumber: localStorage.getItem("companyNumber"),
      customerNumber: localStorage.getItem("customerNumber"),
      address: '',
    };
  }

  componentDidMount() {
    this.setState({address: localStorage.getItem("customerAddress")});
  }
 
  logout = (e) => {
    localStorage.clear();
    this.props.history.push({
      pathname: "/Login",
    });
  };

  handleClose = () => this.setState({ showLoginBox: false });

  handle2ndButtonClick = () => {
    localStorage.clear();
    this.props.history.push({
      pathname: "/Login",
    });
  };
  render() {
    return (
      <div>
        {this.state.showLoginBox && (
          <ESSModal
            handle2ndButtonClick={this.handle2ndButtonClick}
            handleClose={this.handleClose}
            btn1Text="No"
            btn2Text="Yes"
            userMessage="Are you sure you want to logout?"
            isAlert="false"
            show={this.state.showLoginBox}
          />
        )}
        <div
          className={`collapse ${this.state.toggle ? "show" : "hide"}`}
          id="collapseExample"
        >
          <div className="card card-body">
            <div style={{ border: "solid" }}>
              For questions concerning specific orders or products, please
              contact your branch.
              <br />
              For questions concerning the Self Service system, please contact
              us by E-mail{" "}
              {appConstants.isR3App === false ? 
              <a href="mailto:internetFieldSupport@bunzlusa.com?subject=Bunzl Customer Self Service Support" style={{color:"blue"}} className="contactpopupfont">
                Internet Field Support
              </a>
              :
              <a href="mailto:marketing@r3safety.com?subject=R3 Customer Self Service Support" style={{color:"blue"}} className="contactpopupfont">
                 Support
              </a>
            }{"  "}
           
              {/* or phone us at (888) 492-4940. */}
            </div> 
          </div>
        </div>

        <div
          className={`row ${
            appConstants.isR3App ? "footerBackGroundR3" : "footerBackGround"
          }`}
        >
          <div className="col-4">
            Customer Number: {this.state.companyNumber}{" "}
            {this.state.customerNumber} {" "}
            {(this.state.address !== null && this.state.address !== "") && this.state.address}
          </div>
          <div className="col-2">
            {appConstants.isR3App
              ? "Copyright"+"  " +(new Date().getFullYear())+"  "+"R3 Reliable Redistribution Resource"
              : "Copyright"+"  "+(new Date().getFullYear()) +"  "+"Bunzl Distribution"}
          </div>
          <div className="col-4">
            <a href="https://www.bunzlnalegal.com/privacypolicy" style={{color:"white"}}>Privacy Policy |</a>
            &nbsp;&nbsp;
            <a href="https://www.bunzlnalegal.com/terms-of-use" style={{color:"white"}}>Terms of Use |</a>
            &nbsp;&nbsp;
            <a href="https://bunzlnalegal.com/privacypolicy/#california-residents" style={{color:"white"}}>California Collection Notice |</a>
            &nbsp;&nbsp;
            <a href="https://bunzlnalegal.com/privacypolicy/#notice-of-right-to-opt-out" style={{color:"white"}}>Do Not Sell My Info</a>
          </div>
          <div className="col-2">
            <div>
              <button
                className="footerbackground"
                onClick={() => {
                  this.setState({ toggle: !this.state.toggle });
                }}
                type="button"
                data-toggle="collapse"
                data-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Contact Us
              </button>
              <span>&nbsp;</span>
              <button
                className="footerbackground"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ showLoginBox: true });
                }}
                type="button"
                data-toggle="collapse"
                data-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CustomerFooter);
