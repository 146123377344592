import api from './apiUrl';
import {authHeader,encodedURL} from '../_helpers/auth-header'

export function getEmployeeList()
{    
    var empLoginID=localStorage.getItem('userName')
    return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/user/employee?empLoginID=${empLoginID}`)}`, {
        method: 'GET',
        headers:authHeader()
    }).then(response => response.json());
}
    
    