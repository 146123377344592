import React, { Component } from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import downloadbutton from "../../assets/images/downloadbutton.gif";
import PriceListFilter from "./PriceListFilter";
import { getFilteredData } from "../../_services/priceList.service";
import { CSVLink } from "react-csv";
import ExportPriceListReport from "../../components/ExportPriceListReport";
import { getPriceListData } from "../../_services/priceList.service";
import appConstants from "../../_services/applicationConstant";
import ESSModal from "../../components/ESSModal.jsx";
import ESSProgressBar from "../../components/ESSProgressBar";


class PriceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
       headers :[
        { label: 'Item Number', key: 'itemNumber' },
        { label: 'Vendor Part Number', key: 'vendorPart' },
        { label: 'Customer Part Number', key: 'customerPartNumber' },
        { label: 'Description', key: 'description' },
        { label: 'Page', key: 'flyerPage' },
        { label: 'Case/Pack', key: 'sellCase' },
        { label: 'PackDesc', key: 'sellUms' },
        { label: 'List Price', key: 'listPrice' },        
        { label: 'Price', key: 'sellPrice' },
        { label: 'Pending Price', key: 'pendPrice' },
        { label: 'Pending Effective Date', key: 'effectiveDate' } ,
        { label: 'Direct', key: 'directValue' }       
      ],
      searchText: "",
      vendor: "",
      show: false,
      userMessage: "",
      searchType: "",
      pageCount: "",
      qualifier: "",
      rowData: null,
      searchClicked: false,
      columnDefs: [
        {
          headerName: "Item Number",
          field: "itemNumber",
          sortable: true,
          cellStyle: {
            textAlign: "center",
            borderRight: "0.1px solid lightgray",
          },
          resizable: true,
          width: 120,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Vendor Part Number",
          field: "vendorPart",
          sortable: true,
          cellStyle: {
            textAlign: "center",
            borderRight: "0.1px solid lightgray",
          },
          resizable: true,
          width: 120,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Customer Part Number",
          sortable: true,
          cellStyle: {
            textAlign: "center",
            borderRight: "0.1px solid lightgray",
          },
          field: "customerPartNumber",
          resizable: true,
          width: 120,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Description",
          sortable: true,
          field: "description",
          cellStyle: {
            textAlign: "center",
            borderRight: "0.1px solid lightgray",
          },
          resizable: true,
          width: 120,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Page",
          field: "flyerPage",
          sortable: true,
          cellStyle: {
            textAlign: "center",
            borderRight: "0.1px solid lightgray",
          },
          comparator: compare,
          resizable: true,
          width: 120,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Case/Pack",
          field: "sellCase",
          sortable: true,
          cellStyle: {
            textAlign: "center",
            borderRight: "0.1px solid lightgray",
          },
          resizable: true,
          width: 120,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Pack Description",
          sortable: true,
          field: "sellUms",
          cellStyle: {
            textAlign: "center",
            borderRight: "0.1px solid lightgray",
          },
          resizable: true,
          width: 120,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "List Price",
          field: "listPrice",
          sortable: true,          
          hide: localStorage.getItem('loginTypeCode') === 'C' ? false : true,
          valueFormatter: currencyFormatter,
           comparator: compare,
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          type: "rightAligned",
          resizable: true,
          width: 120,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Price",
          field: "sellPrice",
          sortable: true,
          valueFormatter: currencyFormatter,
           comparator: compare,
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          type: "rightAligned",
          resizable: true,
          width: 120,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },        
        {
          headerName: "Pending Price",
          field: "pendPrice",
          type: "rightAligned",
          valueFormatter: currencyFormatterPending,
          sortable: true,
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          resizable: true,
          width: 120,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Pending Effective Date",
          field: "effectiveDate",
          sortable: true,

         cellRenderer: dateFormatter,
          cellStyle: {
            textAlign: "center",
            borderRight: "0.1px solid lightgray",
          },
          resizable: true,
          width: 120,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Direct",
          field: "",
          sortable: true,
          cellStyle: {
            textAlign: "center",
            borderRight: "0.1px solid lightgray",
          },
          resizable: true,
          width: 120,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
      ],

      rowData: [],
      defaultColDef: {
        editable: true,
        sortable: true,
        flex: 1,
        minWidth: 100,
        filter: true,
        resizable: true,
      },
      inventoryList: [],
    };
  }

  handleClose = () => this.setState({ show: false });

  doSearch = (
    selectedsearchText,
    selectedvendor,
    selectedsearchType,
    selectedpageCount,
    selectedqualifier
  ) => {
    this.setState({
      searchText: selectedsearchText,
      vendor: selectedvendor,
      searchType: selectedsearchType,
      pageCount: selectedpageCount,
      qualifier: selectedqualifier,
      searchClicked: true,
      rowData:[]
    });
    getPriceListData(
      selectedsearchText,
      selectedvendor,
      selectedsearchType,
      selectedpageCount,
      selectedqualifier
    ).then((data) => {
      this.setState({ inventoryList: getFilteredData(data) });
      this.setState({ rowData: data });
      if (data.length>0)
          {
            this.setState({searchClicked:false})
          }
      if (data.length === 0) {
            this.setState({searchClicked:false})

            this.setState({
              show: true,
              userMessage:
                "No Price List Matched the selected criteria.",
            });
          }
    });
  };
  
  onGridReady = (params) => {
    this.gridApi = params.api.showLoadingOverlay;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };
  render() {
    const renderInventoryGrid = () => {
      return (
        <div
          className={`ag-theme-alpine ${
            appConstants.isR3App ? "gridMainR3" : "gridMain"
          }`}
          style={{ height: "82%" }}
        >
        {this.state.searchClicked?
        <ESSProgressBar/>
       :''}
          <AgGridReact
            columnDefs={this.state.columnDefs}
            enableCellTextSelection={true}
            onGridReady={this.onGridReady}
            rowData={this.state.rowData}
            rowHeight="30"
            headerHeight="30"
            footerHeight="30"
          ></AgGridReact>
        </div>
      );
    };
    return (
      <div>
        <div
          className={`container-fluid text-center ${
            appConstants.isR3App ? "contentPageR3" : "contentPage"
          }`}
          style={{ height: "100%" }} //contentPageR3
        >
           <ESSModal
            handleClose={this.handleClose}
            btn1Text="OK"
            userMessage={this.state.userMessage}
            isAlert="true"
            show={this.state.show}
          />
          <div
            className={` card text-left cardNoBorder ${
              appConstants.isR3App ? "logintitleR3" : "logintitle"
            }`}
            style={{ height: "82%" }}
          >
            <h6 className="card-header">Price List</h6>
            <div
              className="card-body"
              style={{ backgroundColor: "#fff", padding: "0.5rem" }}
            >
              <div className="row content" style={{ height: "98%" }}>
                <div className="col-sm-12" style={{ height: "98%" }}>
                  <div className="row" ref="filterComponent">
                    <div className="col-sm-10">
                      <PriceListFilter doSearch={this.doSearch} />
                    </div>
                    <div className="col-sm-1" style={{ paddingTop: "30px" }}>
                      <ExportPriceListReport />
                    </div>
                    <div className="col-sm-1" style={{ paddingTop: "30px" }}>
                      {this.state.inventoryList.length > 0 ? (
                        <CSVLink
                          ref="csv"
                          data={this.state.inventoryList} headers={this.state.headers}
                          filename={"Filtered-PriceList-Report.csv"}
                        >
                          <img
                            src={downloadbutton}
                            title="Download Filtered PriceList Items."
                            alt="Download Report"
                          />
                        </CSVLink>
                      ) : undefined}
                    </div>
                  </div>
                  <div
                    className="row"
                    ref="gridComponent"
                    style={{ height: "100%" }}
                  >
                    <div className="col-sm-12" style={{ height: "98%" }}>
                      {renderInventoryGrid()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PriceList;
function dateFormatter(param) {
  if(param.value != null &&  param.value != "" && param.value != undefined){
  var d = param.value.split(" ");
  var dateValue = d[0];
  return dateValue;
  }
  else return "";
}
function currencyFormatterPending(params){
  if (params.value !== undefined && params.value !== "" && params.value !== null && params.value !== '0' && params.value !== '0.00') {
    if (parseFloat(params.value) < 0) 
    return "-$" + (parseFloat(params.value).toFixed(2) * -1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else if(parseFloat(params.value).toLocaleString() == "0")  return "$0.00";
    else return "$" + (parseFloat(params.value).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    else return "";
}
function currencyFormatter(params) {
  if (params.value !== undefined && params.value !== "" && params.value !== null && params.value !== '0' && params.value !== '0.00') {
    if (parseFloat(params.value) < 0) 
    return "-$" + (parseFloat(params.value).toFixed(2) * -1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else if(parseFloat(params.value).toLocaleString() == "0")  return "$0.00";
    else return "$" + (parseFloat(params.value).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    else return "$0.00";
}
function compare (number1, number2) {
  if (number1 === null && number2 === null) {
  return 0;
  }
  if(isNaN(number1)){ return -1}
  if(isNaN(number2)){ return 1}
  if (number1 === null) {
  return -1;
  }
  if (number2 === null) {
  return 1;
  }
  return number1 - number2;}