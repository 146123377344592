import React, { Component } from "react";
import bunzlCSS from "../../assets/css/bunzlUI.css";
import appConstants from "../../_services/applicationConstant";


class CustomPriceFilter extends Component {
  constructor(props) {
    super(props);
      this.state={
        customerPart: 'All',
        item:'All',
        department:'',
        description:'',
        searchClicked:false
      }
  }
  
  componentDidMount() {
  }


  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    if(nam=="customerPart")
    {
      document.getElementById("disableitem").disabled=true;
    }
    else if(nam=="item")
    {
      document.getElementById("disablecust").disabled=true;
    }
    if(val=="All")
    {
      document.getElementById("disableitem").disabled=false;
      document.getElementById("disablecust").disabled=false;
    }
    this.setState({ [nam]: val });     
  }
  resetFilter = () => {
    this.setState({
      customerPart:'',
      item:'',
      department:'',
      description:''
    });
  }

  searchClicked=() =>{
    this.props.doSearch(this.state.customerPart,
      this.state.item,
      this.state.department,
      this.state.description);     
  }

  render() {
    return (
      <div  className={`${
        appConstants.isR3App ? "filterBodyR3" : "filterBody"
      }`}        
      >
        <div className="row">
          <div className="col-sm-2">
            <div className=" row">
              <label className="control-inline-label col-form-label ">Customer Part#: </label>
              <div className="col-sm-5">
                <input
                  type="text"
                  className="form-control form-control-sm filterControls"
                  value={this.state.customerPart
                  }
                  id="disablecust"
                  name="customerPart" maxlength = "12" onChange={this.myChangeHandler}
                ></input>
              </div>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="row">
              <label className="control-inline-label col-form-label ">Item#: </label>
              <div className="col-sm-6">
                <input
                  type="text"
                  className="form-control form-control-sm filterControls"
                  value={this.state.item
                  }
                  id="disableitem"
                  name="item" maxlength = "12" onChange={this.myChangeHandler}
                ></input>
              </div>
            </div>
          </div>    
          <div className="col-sm-2">
            <div className=" row">
              <label className="control-inline-label col-form-label ">Department: </label>
              <div className="col-sm-6">
                <input
                  type="text"
                  className="form-control form-control-sm filterControls"
                  value={this.state.department
                  }
                  name="department" maxlength = "4" onChange={this.myChangeHandler}
                ></input>
              </div>
            </div>
          </div> 
          <div className="col-sm-3">
            <div className=" row">
              <label className="control-inline-label col-form-label ">Description: </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control form-control-sm filterControls"
                  value={this.state.description
                  }
                  name="description" maxlength = "30" onChange={this.myChangeHandler}
                ></input>
              </div>
            </div>
          </div>  
          <div className="col-sm-2">
            <button className="btn btn-primary btn-sm filterButtons" onClick={this.searchClicked} type="button">
              Search
            </button>
          </div>
        </div> 
      </div>
    );
  }
}

export default CustomPriceFilter;
