import React, { Component } from "react";
import bunzlCSS from "../../assets/css/bunzlUI.css";
import {
  getPurchaseOrder,
  getStatus,
} from "../../_services/accountsPayable.service";
import { getShipTo } from "../../_services/accountsPayable.service";
import { getArBucketItems } from "../../_services/accountsPayable.service";
import mgglass from "../../assets/images/magGlass.jpg";
import appConstants from "../../_services/applicationConstant";
import user from '../../models/User';
import { decryptData } from '../../Utils';

class AccountPayableFilter extends Component {
  constructor(props) {

    //user.toUser = JSON.parse(localStorage.getItem('UserMenu'))

    const salt = process.env.SALT || process.env.REACT_APP_KEY;
    const originalData = decryptData(localStorage.getItem("UserMenu"), salt);
    user.toUser = originalData;
    super(props);
    this.state = {
      purchaseOrder: getPurchaseOrder(appConstants.isR3App),
      daysOld: [],
      shipTo: [],
      Status: getStatus(this.props.isR3),
      selectedShipTo: "0",
      searchText: "",

      HideClicked: false,
      selecteddaysOld: "0",
      selectedStatus: "O",
      selectedpurchaseOrder: "PO",
      enableShipTo: true
    };
  }
  componentWillReceiveProps(props) {
    this.setState({ selecteddaysOld: this.props.bucketSelected });
  }
  componentDidMount() {
    this.setState({ selecteddaysOld: this.props.bucketSelected });
    getShipTo()
    .then((data) => {
      if(localStorage.getItem('loginTypeCode') === 'C')
      { 
        var userName= localStorage.getItem('userName');
        if(userName.length > 6)
        {
            var shipToID= userName.substring(6,userName.trim().length);
            var shipTOData= data.filter((p) => p.shipToNumber.trim() === shipToID);
            this.setState({ shipTo: shipTOData }, () => {
              if (shipTOData.length === 1) {
                this.setState({ selectedShipTo: shipTOData[0].shipToNumber });
              }
            }); 
            this.setState({enableShipTo:false});
        }
        else{
          if(user.toUser.ViewAllShipTos==='All'){
          this.setState({ shipTo: data }, () => {             
          }); 
        }
        else{
          this.setState({ shipTo: data }, () => {
            if (data.length === 1) {
              this.setState({ selectedShipTo: data[0].shipToNumber , enableShipTo:false});
            }
          }); 
        }
        }
    }
    else{
      if(localStorage.getItem('customerGroupShipToValue') !== null &&  localStorage.getItem('customerGroupShipToValue').trim() !== "" ){
        var shipTOData= data.filter((p) => p.shipToNumber.trim() === localStorage.getItem('customerGroupShipToValue').trim());
        this.setState({ shipTo: shipTOData }, () => {
              if (shipTOData.length === 1) {
                this.setState({ selectedShipTo: shipTOData[0].shipToNumber, enableShipTo:false });
              }
            });        
    }
    else{
      this.setState({ shipTo: data }, () => {
        // if (data.length === 1) {
        //   this.setState({ selectedShipTo: data[0].shipToNumber });
        // }
      });
    }
    }
    if(data.length === 0)
    {
      this.setState({enableShipTo:false});
    }
    })
    .catch(console.log);

    getArBucketItems()
      .then((data) => {
        this.setState({ daysOld: data });
      })
      .catch(console.log);
  }
  onQualifierChange = (event) => {
    this.setState({
      qualifier: event.target.value,
    });
  };

  myChangeHandler = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
    if (val == "C" && nam == "selectedStatus") {
      document.getElementById("daysOldid").disabled = true;
    } else {
      document.getElementById("daysOldid").disabled = false;
    }
    if (nam === "selecteddaysOld") {
      this.props.clearBucketSelection(val);
    }
  };
  resetFilter = () => {
    document.getElementById("fromDate").value = null;
    document.getElementById("toDate").value = null;
    this.setState(
      {
        selectedpurchaseOrder: "0",
        selectedShipTo: "0",
        searchText: "",
        selecteddaysOld: "0",
        selectedStatus: "O",
      },
      () => {
        this.props.clearBucketSelection(this.state.selecteddaysOld);
      }
    );
  };

  searchClicked = () => {
    var fromdate = document.getElementById("fromDate").value;
    var toDate = document.getElementById("toDate").value;
    this.props.doSearch(
      this.state.searchText,
      this.state.selectedShipTo,
      this.state.selectedpurchaseOrder,
      this.state.selecteddaysOld,
      this.state.selectedStatus,
      (this.state.selectedfromDate = fromdate),
      (this.state.selectedtoDate = toDate)
    );
  };
  HideClicked = () => {
    var element = document.getElementById("searchFilters");
    var ele = document.getElementById("searchFilter");
    document.getElementById("imgs").style.display = "block";
    ele.classList.remove("filterBody");
    element.classList.add("searchFilter");
  };

  searchFilterClick = () => {
    var element = document.getElementById("searchFilters");
    var ele = document.getElementById("searchFilter");
    document.getElementById("imgs").style.display = "none";
    ele.classList.add("filterBody");
    element.classList.remove("searchFilter");
  };

  UpdateDaysSelected = (selecteddaysOld) => {
    //alert( this.props.bucketSelected);
    this.setState({ selecteddaysOld: this.props.bucketSelected });
  };

  handleChange(event) {
    if (document.getElementById("fromDate").value > event.target.value) {
      var fromdate= document.getElementById("fromDate").value
      alert(
        "The From Date" +
          "" +""+"  "+
          getFormattedDate(fromdate) +
          "  " +
          "must be less than or equal to the To Date" +"  "+
          getFormattedDate(event.target.value)
      );
      document.getElementById("btnsubmit").disabled = true;
    } else {
      document.getElementById("btnsubmit").disabled = false;
    }
  }
   handleFromChange(event) {
    if (document.getElementById("toDate").value != null && event.target.value) {
      document.getElementById("btnsubmit").disabled = true;
    } 
    if (document.getElementById("fromDate").value < document.getElementById("toDate").value) {
      document.getElementById("btnsubmit").disabled = false;
    }
    if (document.getElementById("fromDate").value > document.getElementById("toDate").value) {
      document.getElementById("btnsubmit").disabled = true;
    }
      else {
      document.getElementById("btnsubmit").disabled = false;
    }
  }
  render() {
    const shipToEnabled= this.state.shipTo.length > 1 ? true: false;
    const noShipTos= this.state.shipTo.length === 0 ? true: false;
    return (
      <div
        id="searchFilter"
        className={`${appConstants.isR3App ? "filterBodyR3" : "filterBody"}`}
      >
        <div className="row" id="searchFilters">
          <div className="col-sm-10">
            <div className="row">
              <div className="col-sm-3">
                <div className="form-group row">
                  <label className="col-sm-4  ">Search </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control form-control-sm filterControls"
                      placeholder="Search text.."
                      value={this.state.searchText}
                      name="searchText"
                      onChange={this.myChangeHandler}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="col-sm-2">
                <div className="form-group row">
                  <select
                    id="disabledSelect"
                    className="form-control form-control-sm filterControls"
                    name="selectedpurchaseOrder"
                    onChange={this.myChangeHandler}
                    value={this.state.selectedpurchaseOrder}
                  >
                    {this.state.purchaseOrder.map((type) => (
                      <option key={type.value} value={type.value}>
                        {type.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label ">From Date </label>
                  <div className="col-sm-8">
                    <input
                      type="date"
                      id="fromDate"
                      name="selectedfromDate"
                      onChange={this.handleFromChange.bind(this)}
                      className="form-control form-control-sm filterControls"
                    ></input>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label ">Days Old </label>
                  <div className="col-sm-8">
                    <select
                      id="daysOldid"
                      className="form-control form-control-sm filterControls"
                      name="selecteddaysOld"
                      onChange={this.myChangeHandler}
                      value={this.state.selecteddaysOld}
                    >
                      {this.state.daysOld.map((type) => (
                        <option key={type.Bucket} value={type.Bucket}>
                          {type.Name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-5">
                <div className="form-group row">
                  <label className="col-sm-3 col-form-label" style={{marginRight:"-20px"}}>Ship To</label>
                  <div className="col-sm-9">
                    <select
                      name="selectedShipTo"
                      onChange={this.myChangeHandler}
                      id="disabledSelect"
                      disabled= {!this.state.enableShipTo}
                      className="form-control form-control-sm filterControls"
                      value={this.state.selectedShipTo}
                    >
                   {   this.state.enableShipTo &&
                    <option value="0">--ALL--</option>
                   }
                   {noShipTos &&
                    <option value="0">--ALL--</option>
                   }
                      {this.state.shipTo.map((type) => (
                        <option
                          key={type.shipToNumber}
                          value={type.shipToNumber}
                        >
                          {type.name} {" - "} {type.shipToNumber}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">To Date</label>
                  <div className="col-sm-8">
                    <input
                      type="date"
                      id="toDate"
                      name="selectedtoDate"
                      className="form-control form-control-sm filterControls"
                      onChange={this.handleChange.bind(this)}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="form-group row">
                  <label className="col-sm-4 col-form-label">Status</label>
                  <div className="col-sm-8">
                    <select
                      name="selectedStatus"
                      onChange={this.myChangeHandler}
                      id="disabledSelect"
                      className="form-control form-control-sm filterControls"
                      value={this.state.selectedStatus}
                    >
                      {this.state.Status.map((type) => (
                        <option option key={type.value} value={type.value}>
                          {type.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="form-group row" style={{ marginBottom: "0.3rem" }}>
              <button
                className="btn btn-primary btn-sm filterButtons"
                id="btnsubmit"
                onClick={this.searchClicked}
                type="button"
              >
                Submit
              </button>
            </div>
            <div className="form-group row" style={{ marginBottom: "0.3rem" }}>
              <button
                className="btn btn-primary btn-sm filterButtons"
                onClick={this.resetFilter}
                type="button"
              >
                Clear
              </button>
            </div>
            <div className="form-group row" style={{ marginBottom: "0.3rem" }}>
              <button
                className="btn btn-primary btn-sm filterButtons"
                onClick={this.HideClicked}
                type="button"
              >
                Hide
              </button>
            </div>
          </div>
        </div>
        <div id="imgs" style={{ display: "none" }}>
          <img src={mgglass} onClick={this.searchFilterClick}></img>
        </div>
      </div>
    );
  }
}

export default AccountPayableFilter;
function getFormattedDate(date) {
  var dt = new Date(date);
  var month=(dt.getMonth()+1);
  var day=(dt.getDate());
  var year=(dt.getFullYear());
  return month + '/' + day + '/' + year;
}
