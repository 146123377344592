
import  authenticationService from '../_services/authentication.services';
import { encryptData } from '../Utils';
import { decryptData } from '../Utils';

var fromLogin = true;
export default function  checkJWTTokenExpiry()
 {

 
   var jwtTokenExp ="";
   let mkLocalData = localStorage.getItem('jwtExpiry');
   
    if(mkLocalData)
   {
    
   const salt = process.env.SALT || process.env.REACT_APP_KEY;
      const originalData = decryptData(localStorage.getItem("jwtExpiry"), salt);
      if(originalData !== null && originalData !== "" )
      {
    jwtTokenExp = originalData;
   }
  }
  // var jwtTokenExp = localStorage.getItem("jwtExpiry");
   
    if (jwtTokenExp !== null && jwtTokenExp !== "")
     {

      var currentDate = new Date().toUTCString();
      var jwtDate = new Date(jwtTokenExp).toUTCString();
           
      var diffMs = new Date(jwtDate) - new Date(currentDate); 
      var diffMins =  Math.floor(diffMs / 60000); 
      
  
      var MS_PER_MINUTE = 60000;

      var currentDateTimeToValidate = new Date(new Date() - 15 * MS_PER_MINUTE).toUTCString();
     
       // if (diffMins <= 0) 
      // {
      //   fromLogin = false;
      //   localStorage.clear(); return false;
      // }
      

      if (diffMins <= 0) 
      {
       
       fromLogin = false;
       localStorage.clear(); 
       return false;
      }
      
      else 
      {
        return true;
      }
      
    } 
    else
     {
      return false;
      fromLogin=false;
    }
    
     
      }


export function encodedURL(url)
{

  
  const salt = process.env.SALT || process.env.REACT_APP_KEY;
  const originalData = decryptData(localStorage.getItem("id_token"), salt);
  console.log(originalData)

    //var encodedURLWithJWT= encodeURIComponent(url) +`&jwtToken=${localStorage.getItem('id_token')}`
    var encodedURLWithJWT= encodeURIComponent(url) +`&jwtToken=${originalData}`
   
    return encodedURLWithJWT;
}


export function authHeader() 
{

    const jwtvalid = checkJWTTokenExpiry();
    //alert(jwtvalid);
    //alert(fromLogin);
   if(jwtvalid === false && fromLogin === false)
   {
    
    //alert("Your Session has expired,Please Login again!");
   window.location = "./ESSModal";
   }
    // return authorization header with jwt token

   // const currentUser = localStorage.getItem('id_token');    

   const salt = process.env.SALT || process.env.REACT_APP_KEY;
  const originalData = decryptData(localStorage.getItem("id_token"), salt);
  const currentUser = originalData; 

    if (currentUser) {
        return { 
        Authorization: `Bearer ${currentUser}`,
        //'Accept': 'application/json',
        'Content-Type': 'application/json',
        //'Access-Control-Allow-Origin':'*',
        //'Access-Control-Allow-Headers': "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With"
     };
    } 
    else 
    {
     
        return {};
    }
}

function setWithExpiry(key) {
   var theDate = new Date();
  theDate.setHours(theDate.getHours() +2);
  var isodate = theDate.toISOString();
localStorage.setItem(key,isodate)
}
