import React, { Component } from 'react';
import appConstants from "../../_services/applicationConstant";
import ReportColumns from "../ConsolidatedInventory/ReportColumns";
import DataColumns from "../ConsolidatedInventory/DataColumns";
import TabFilters from "../ConsolidatedInventory/TabFilters"
import {getCustomerList} from "../../_services/consolidatedInventory.service";
import {getCustomerVendorsList} from "../../_services/consolidatedInventory.service";
import ESSModal from "../../components/ESSModal.jsx";

class CIFiler extends Component {
  constructor(props) {
    super(props);
    this.state={
        selectedReportColumns:[],
        selectedDataColumns: [],
        ItemEntryList:[],
        ItemEntryType:"Distributor Item#",
        fromItemRange: "",
        toItemRange:"" ,
        isAllCICustomers: true,
        ciCustomers:[],
        isAllCIVedors: true,
        ciVendors:[],
        allCICustomers:[],
        allCIVendors:[],
        show: false,
        userMessage: "",
    }
    this.DataColumnsRef= React.createRef();
    this.ReportColumnsRef =React.createRef();
    this.TabFilterRef = React.createRef();
    this.reportColumnsHandler = this.reportColumnsHandler.bind(this);
  }
  componentDidMount(){
    var allCustomers = [];
    getCustomerList().then((data) => {
     data.map(m =>( allCustomers.push(m.CustNumber)) ) 
    });
  
    this.setState({allCICustomers: '      '})
var allVendors= [];
getCustomerVendorsList().then((data) => {
  data.map(m =>( allVendors.push(m.id)) ) 
 });
 this.setState({allCIVendors: allVendors})

}

  mapReportColumm(){

  }
  searchClicked=() =>{
    var reports ="";
    const report = this.state.selectedReportColumns.filter(p => p.isChecked);
    // .map(filteredReport => (
    //  reports = reports+ filteredReport.value +", "
    // ));
    var dataseleced ="";
    const data = this.state.selectedDataColumns.filter(p => p.isChecked);
    // .map(filteredData => (
    //   dataseleced = dataseleced+ filteredData.value +", "  
    // ));
    if(this.state.fromItemRange>this.state.toItemRange)
    {
      this.setState({
        show: true,
        userMessage:
          "Item Range is invalid",
      });
      return false;
    }
    if(report.length === 0)
    {
      if(data.length === 0)
      {
        this.setState({
          show: true,
          userMessage:
            "Data Columns: has no selections.\nReport Columns: has no selections ",
        });
        return false;
      }
      else{
        this.setState({
          show: true,
          userMessage:
            "Report Columns: has no selections ",
        });
        return false;
      }
    }
    if(data.length === 0)
    {
      this.setState({
        show: true,
        userMessage:
          "Data Columns: has no selections ",
      });
      return false;
    }
    if(report.filter((p) => p.isChecked && p.code.includes("dimStk")).length > 0 )
    {
      if(report.filter((p) => p.isChecked && p.code.includes("dimCo")).length > 0){

      }
      else{
        this.setState({
          show: true,
          userMessage: "Stock/Non-stock  must be selected with Warehouse",
        });
        return false;
      }
    }
    if(report.filter((p) => p.isChecked && p.code.includes("dimPal")).length > 0 )
    {
      if(report.filter((p) => p.isChecked && p.code.includes("dimCo")).length > 0){

      }
      else{
        this.setState({
          show: true,
          userMessage: "Pallet Qty must be selected with Warehouse",
        });
        return false;
      }
    }
    if(report.filter((p) => p.isChecked && p.code.includes("dimCurPrice")).length > 0 )
    {
      if(report.filter((p) => p.isChecked && p.code.includes("dimCo")).length > 0){

      }
      else{
        this.setState({
          show: true,
          userMessage: "Current Price must be selected with Warehouse",
        });
        return false;
      }
    }
    if(report.filter((p) => p.isChecked && p.code.includes("dimPODate")).length > 0 )
    {
      if(report.filter((p) => p.isChecked && p.code.includes("dimCo")).length > 0){

      }
      else{
        this.setState({
          show: true,
          userMessage: "Estimated Delvery Date to Distributor Warehouse Item must be selected with Warehouse",
        });
        return false;
      }
    }
    if(!this.state.isAllCICustomers && this.state.ciCustomers.length === 0  )
    {
      this.setState({
        show: true,
        userMessage:
          "There are no Customer selections",
      });
      return false;
    }
    var itemEtry ="";
    const dataEntr = this.state.ItemEntryList
    .map(filteredData => (
      itemEtry = itemEtry+ filteredData.itemvalue +", "  
    ));
    
    var filterObject = {
      reportColumn : report,
      dataColumn: data,
      isAllCICustomers:this.state.allCICustomers,
      ciCustomers:this.state.ciCustomers,
      isAllCIVedors:this.state.allCIVendors,
      ciVendors:this.state.ciVendors,
      ItemEntryList:this.state.ItemEntryList,
      ItemEntryType:this.state.ItemEntryType,
      fromItemRange:this.state.fromItemRange,
      toItemRange:this.state.toItemRange

    }
   
    this.props.doSearch(filterObject);
  }
  showcloumnsCLicked=()=>{

    
    const report = this.state.selectedReportColumns.filter(p => p.isChecked);
    
    const data = this.state.selectedDataColumns.filter(p => p.isChecked);
    if(this.state.fromItemRange>this.state.toItemRange)
    {
      this.setState({
        show: true,
        userMessage:
          "Item Range is invalid",
      });
      return false;
    }
    if(report.length === 0)
    {
      if(data.length === 0)
      {
        this.setState({
          show: true,
          userMessage:
            "Data Columns: has no selections.\nReport Columns: has no selections ",
        });
        return false;
      }
      else{
        this.setState({
          show: true,
          userMessage:
            "Report Columns: has no selections ",
        });
        return false;
      }
    }
    if(data.length === 0)
    {
      this.setState({
        show: true,
        userMessage:
          "Data Columns: has no selections ",
      });
      return false;
    }
    if(report.filter((p) => p.isChecked && p.code.includes("dimStk")).length > 0 )
    {
      if(report.filter((p) => p.isChecked && p.code.includes("dimCo")).length > 0){

      }
      else{
        this.setState({
          show: true,
          userMessage: "Stock/Non-stock  must be selected with Warehouse",
        });
        return false;
      }
    }
    if(report.filter((p) => p.isChecked && p.code.includes("dimPal")).length > 0 )
    {
      if(report.filter((p) => p.isChecked && p.code.includes("dimCo")).length > 0){

      }
      else{
        this.setState({
          show: true,
          userMessage: "Pallet Qty must be selected with Warehouse",
        });
        return false;
      }
    }
    if(report.filter((p) => p.isChecked && p.code.includes("dimCurPrice")).length > 0 )
    {
      if(report.filter((p) => p.isChecked && p.code.includes("dimCo")).length > 0){

      }
      else{
        this.setState({
          show: true,
          userMessage: "Current Price must be selected with Warehouse",
        });
        return false;
      }
    }
    if(report.filter((p) => p.isChecked && p.code.includes("dimPODate")).length > 0 )
    {
      if(report.filter((p) => p.isChecked && p.code.includes("dimCo")).length > 0){

      }
      else{
        this.setState({
          show: true,
          userMessage: "Estimated Delvery Date to Distributor Warehouse Item must be selected with Warehouse",
        });
        return false;
      }
    }
    if(!this.state.isAllCICustomers && this.state.ciCustomers.length === 0  )
    {
      this.setState({
        show: true,
        userMessage:
          "There are no Customer selections",
      });
      return false;
    }
    var filterObject = {
      reportColumn : report,
      dataColumn: data,
    }
    this.props.showcolumns(filterObject);
  }
  clearClicked =() =>{
    this.setState({
      selectedReportColumns:[],
      selectedDataColumns: [],
      ItemEntryList:[],
      ItemEntryType:"Distributor Item#",
      fromItemRange: "",
      toItemRange:"" ,
      isAllCICustomers: true,
      ciCustomers:[],
      isAllCIVedors: true,
      ciVendors:[]
  });
  this.DataColumnsRef.current.clearSelection();
  this.ReportColumnsRef.current.clearSelection();
  this.TabFilterRef.current.clearSelection();
  this.props.doClear();
  }

  FilterEntryItemsChange =(data)=>{
   
    this.setState({ItemEntryList:data});
   
  }

  FilterChange =(data)=>{
    
  }
  FilterEntryTypeChange =(data)=>{
    
     this.setState({ItemEntryType:data});
   }
  reportColumnsHandler = (data) => {
    this.setState({selectedReportColumns: data}, () => {
    
  })     
  }
  dataColumnsHandler = (data) => {
    this.setState({selectedDataColumns: data}, () => {
   
  })    
  }
  onItemFromChange = (data) =>{
    this.setState({fromItemRange: data});
  }
  onItemToChange =(data)=>{
    this.setState({toItemRange: data})  ;
  } 

  onAllCICutomerChange = (data) => {
    this.setState({isAllCICustomers: data});
  }

  onCICustomerSelectionChange =(data) =>{
    this.setState({ciCustomers: data});
  }

  onAllCIVendorChange = (data) => {
    this.setState({isAllCIVedors: data});
  }
  handleClose = () => this.setState({ show: false });
  onCIVendorSelectionChange =(data) =>{
    this.setState({ciVendors: data});
  }
    render() {  

        return (
            <div  className={`${
                appConstants.isR3App ? "filterBodyR3" : "filterBody"
              }`}        
              >
                 <ESSModal
            handleClose={this.handleClose}
            btn1Text="OK"
            userMessage={this.state.userMessage}
            isAlert="true"
            show={this.state.show}
          />
                <div className="row">
                  <div className="col-sm-3" style={{width: "280px"}}>
                    <div >
                      <ReportColumns ref={this.ReportColumnsRef} onChange={this.reportColumnsHandler}/>
                    </div>
                  </div>
                  &nbsp;
                  &nbsp;
                  &nbsp;
                  <div className="col-sm-2">
                    <div className="form-group row">
                    <DataColumns ref={this.DataColumnsRef} onChange={this.dataColumnsHandler}/>
                    </div>
                  </div>
                  <div className="col-sm-5">
                  <div className="row">
                  <div className="col-sm-2" style={{textAlign:"right",paddingRight:"2px",paddingLeft:"0px"}} >
                    Filters:
                    </div>
                    <div className="col-sm-10" style={{paddingLeft:"1px"}}>
                  <TabFilters ref={this.TabFilterRef}  onEntryItemChange={this.FilterEntryItemsChange} 
                  onFilterEntryTypeChange ={this.FilterEntryTypeChange}
                  onItemFromChange={this.onItemFromChange}
                  onItemToChange={this.onItemToChange}
                  onAllCICutomerChange ={this.onAllCICutomerChange}
                  onCICustomerSelectionChange ={this.onCICustomerSelectionChange}
                  onAllCIVendorChange={this.onAllCIVendorChange}
                  onCIVendorSelectionChange={this.onCIVendorSelectionChange}
                   />

                    </div>
                    </div>
                  </div>
                  <div className="col-sm-1">
                  <div className="row">
                  <div className="col-sm-5">
                    <button className="btn btn-primary btn-sm filterButtons" onClick={this.searchClicked} type="button">
                      Run Report
                    </button>
                    
                    <div style={{paddingTop:'3px'}}>
                    <button className="btn btn-primary btn-sm filterButtons" onClick={this.showcloumnsCLicked} type="button">
                      Show Columns
                    </button>
                    </div>
                    <div style={{paddingTop:'3px'}}>
                    <button className="btn btn-primary btn-sm filterButtons" onClick={this.clearClicked} type="button">
                      Clear
                    </button>
                    </div>
                    </div>
                    </div>
                  </div>
                </div>
            </div>
        );
    }
}

export default CIFiler;