import React, { Component } from "react";
import ARBucket from "./ARBucketGrid";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import downloadbutton from "../../assets/images/downloadbutton.gif";
import AccountPayableFilter from "./AccountPayableFilter";
import { getArBucketItems } from "../../_services/accountsPayable.service";
import api from "../../_services/apiUrl";
import { CSVLink } from "react-csv";
import { getAPData } from "../../_services/accountsPayable.service";
import ARBucketChart from "./ARBucketChart";
import { getFilteredARData } from "../../_services/accountsPayable.service";
import { getFilteredAPData } from "../../_services/accountsPayable.service";
import appConstants from "../../_services/applicationConstant";
import OrderDetails from "../../modules/DisplayOrders/OrderDetails";
import ESSModal from "../../components/ESSModal.jsx";
import ESSProgressBar from "../../components/ESSProgressBar";
import ESSLoadingModal from "../../components/ESSLoadingModal";
import {authHeader, encodedURL} from "../../_helpers/auth-header"

class AccountsPayable extends Component {
  constructor(props) {
    super(props);
    this.handlePOClick = this.handlePOClick.bind(this);
    this.handleInvoiceClick = this.handleInvoiceClick.bind(this);

    this.state = {
      _isMounted: false,
      companyNumber: localStorage.getItem("companyNumber"),
      customerNumber: localStorage.getItem("customerNumber"),
      jwtToken: localStorage.getItem("id_token"),
      searchClicked: false,
      showProgressBar: false,
      bucketSelected: "0",
      defaultColDef: {
        sortable: true,
        sortingOrder: ["desc", "asc"],
      },
      columnDefs: [
        {
          headerName: "Invoice #",
          cellStyle: {
            textAlign: "center",
            borderRight: "0.1px solid lightgray",
          },
          tooltip: (params) =>
           "Click to view an Image of Invoice#" + "" + params.value,
          field: "ReferenceNumber",
          width: 130,
          resizable: true,
          width: 120,
          cellRendererFramework: (props) => {
            if(props.value === null || props.value === "")
            return "";
            else
            return (
              <button
                className="btn btn-xs btn-link"
                style={{ fontSize: "0.7rem" }}
                onClick={this.handleInvoiceClick.bind(this, props.data)}
              >
                {props.value}{" "}
              </button>
            );
          },
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
          sortable: true,
        },
        {
          headerName: "Invoice Date",
          field: "InvoiceDate",comparator:fn_DateCompare,
          resizable: true,
          width: 160,
          cellStyle: {
            textAlign: "center",
            borderRight: "0.1px solid lightgray",
          },
          cellRenderer: invoiceDateFormatter,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
          sortable: true,
          tooltip: (params) => "Double click to show the details for an order"
        },
        {
          headerName: "PO #",
          cellStyle: {
            textAlign: "center",
            borderRight: "0.1px solid lightgray",
          },
          field: "PoNumber",
          resizable: true,
          width: 130,
          tooltip: (params) => "Double click to show the details for an order",
          cellRendererFramework: (props) => {
            return (
              <button
                className="btn btn-xs btn-link"
                style={{ fontSize: "0.7rem" }}
                onClick={this.handlePOClick.bind(this, props.data)}
              >
                {props.value}{" "}
              </button>
            );
          },
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
          sortable: true,
        },
        {
          headerName: "Order #",
          tooltip: (params) => "Double click to show the details for an order",
          cellStyle: {
            textAlign: "center",
            borderRight: "0.1px solid lightgray",
          },
          field: "OrderNumber",
          resizable: true,
          width: 130,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
          sortable: true,
        },
        {
          headerName: "Days Old",
          tooltip: (params) => "Double click to show the details for an order",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          comparator: compare,
          field: "Days",
          resizable: true,
          width: 100,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
          sortable: true,
        },
        {
          headerName: "Customer #",
          tooltip: (params) => "Double click to show the details for an order",
          cellStyle: {
            textAlign: "center",
            borderRight: "0.1px solid lightgray",
          },
          field: "SoldCustomer",
          resizable: true,
          width: 130,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
          sortable: true,
        },
        {
          headerName: "Ship To #",
          tooltip: (params) => "Double click to show the details for an order",
          field: "SoldShipTo",
          resizable: true,
          width: 130,
          cellStyle: {
            textAlign: "center",
            borderRight: "0.1px solid lightgray",
          },
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
          sortable: true,
        },
        {
          headerName: "Address",
          tooltip: (params) => "Double click to show the details for an order",
          resizable: true,
          width: 190,
          cellStyle: {
            textAlign: "left",
            borderRight: "0.1px solid lightgray",
          },
          field: "City",
           headerClass:`${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
          sortable: true,
        },
        {
          headerName: "	Current Balance",
          tooltip: (params) => "Double click to show the details for an order",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          valueFormatter: currencyFormatter,
          comparator: compare,
          field: "CurrentBalance",
          resizable: true,
          width: 120,
          type: "rightAligned",
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
          sortable: true,
        },
        {
          headerName: "Original Balance",
          tooltip: (params) => "Double click to show the details for an order",
          cellStyle: { textAlign: "right" },
          valueFormatter: currencyFormatter,
          comparator: compare,
          field: "OriginalAmount",
          resizable: true,
          width: 120,
          type: "rightAligned",
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
          sortable: true,
        },
      ],
      rowData: [],
      accountsPayableList: [],
      showOD: false,
      selectedInvoice: "",
      selectedLocation: "",
      selectedOrder: "",
      selectedOrderSeq: "",
      selectedPO: "",
      showLoading: false,
    };
    this.APFilterRef = React.createRef();
  }
  handlePOClick(data) {
    this.setState({
      showOD: true,
      selectedInvoice: data.ReferenceNumber,
      selectedLocation: data.Location,
      selectedOrder: data.OrderNumber,
      selectedOrderSeq: "00",
      selectedPO: data.PoNumber,      
    });
  }

  handleInvoiceClick(data) { 
    this.setState({showLoading:true});  
    var pdfWindow = window.open('','_blank');
    pdfWindow.document.body.innerHTML  = "<center><p style='color:blue;'>Downloading Invoice Image...</p></center>"; 
    const custno=String(localStorage.getItem("customerNumber")).padStart(6, '0');

    return fetch(
      `${api.proxyUrlForDownload}${api.url}${encodedURL(
        `/pdf-downloader/invoice-pdf-download?invoiceId=${data.ReferenceNumber}&companyNumber=${this.state.companyNumber}&group=${custno}`
      )}`,
      {
        method: "GET",
        headers: authHeader(),
      }
    )
      .then((response) => {if(response.status === 500) {
        throw new Error('Error while downloading the Invoice Image.')
      } 
      else return  response.blob()})
      .then((response) => {
        const file = new Blob([response], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //   window.navigator.msSaveOrOpenBlob(file);
        //   return;
        // }
        //const pdfWindow = window.open(fileURL,'_blank');
        pdfWindow.location.href = fileURL;
        this.setState({showLoading:false});  
      }, 
      (error) => { 
        pdfWindow.close(); 
      this.setState({showLoading:false});  
        this.setState({
          show: true,
          userMessage: 'Error while downloading the Invoice Image.\n Please retry after some time.'
      });
      })
      .catch(function(error) {
        pdfWindow.close(); 
        console.log(error);
      this.setState({showLoading:false});
    });
  }

  componentWillUnmount() {
    this.setState({ _isMounted: false });
  }

  // componentDidMount() {

  //   // this.setState({ _isMounted: true });
  //   // if (this.state._isMounted) {
  //     getArBucketItems()
  //       .then((data) => {
  //         this.setState({
  //           accountsPayableList: getFilteredARData(
  //             data.sort((a, b) => (a.sort > b.sort ? 1 : -1))
  //           ),
  //         },() =>{
  //           console.log(this.state.accountsPayableList);
  //         });
  //       })
  //       .catch(console.log);
  //  // }
  // }

  doSearch = (
    selectedsearchText,
    selectedselectedShipTo,
    selectedType,
    selecteddaysOld,
    selectedStatus,
    selectedfromDate,
    selectedtoDate
  ) => {
    this.setState({ searchClicked: true, showProgressBar: true, rowData:[] });
    //getAPData(shipTo, searchText, searchType, fromDate, toDate, status, bucket, showInvoice)
    getAPData(
      selectedselectedShipTo,
      selectedsearchText,
      selectedType,
      selectedfromDate,
      selectedtoDate,
      selectedStatus,
      selecteddaysOld,
      ""
    ).then((data) => {
    this.setState({ showProgressBar: false });
     if (data !== null && data.length > 0)
     {
        this.setState({ accountsPayableList: getFilteredAPData(data) });
      this.setState({ rowData: data });
     }
     else
     {
        this.setState({
          show: true,
          userMessage:
            "No Matching Details Have Been Found.",
        });
      }
    });
  };
  handleClose = () => this.setState({ show: false });

  clearBucketSelection = (selecteddaysOld) => {
    this.setState({ bucketSelected: selecteddaysOld }, () => {
      this.APFilterRef.current.UpdateDaysSelected(selecteddaysOld);
    });
  };
  rowSelected = (bucket) => {
    this.setState({ searchClicked: true, bucketSelected: bucket, showProgressBar: true,rowData:[] });
    getAPData("0", "", "", "", "", "", bucket, "").then((data) => {
    this.setState({ showProgressBar: false });

      if (data !== null && data.length > 0){
        this.setState({ accountsPayableList: getFilteredAPData(data) });
      this.setState({ rowData: data });
      }
      else
     {
        this.setState({
          show: true,
          userMessage:
            "No Matching Details Have Been Found.",
        });
      }
    });
  };

  componentDidMount() {
    getArBucketItems()
      .then((data) => {  

        this.setState({
         // accountsPayableList: data.sort((a, b) => (a.sort > b.sort ? 1 : -1)),
          accountsPayableList: getFilteredARData(
            data.sort((a, b) => (a.sort > b.sort ? 1 : -1))
          ),
        });
      })
      .catch(console.log);
  }

  apSummaryClick = () => {
    this.setState({ searchClicked: false, showOD: false });
    getArBucketItems()
      .then((data) => {
        this.setState({
          accountsPayableList: data.sort((a, b) => (a.sort > b.sort ? 1 : -1)),
        });
      })
      .catch(console.log);
  };
  apDetailsClick = () => {
    this.setState({ showOD: false });
  };
  onRowDoubleClicked = () => {
    var selectedRows = this.gridApi.getSelectedRows();
    this.setState({
      showOD: true,
      selectedInvoice: selectedRows[0].ReferenceNumber,
      selectedLocation: selectedRows[0].Location,
      selectedOrder: selectedRows[0].OrderNumber,
      selectedOrderSeq: "00",
      selectedPO: selectedRows[0].PoNumber,
    });
  }
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };
  render() {
    const renderAPGrid = () => {
      return (
        <div
          className={`ag-theme-alpine ${
            appConstants.isR3App ? "gridMainR3" : "gridMain"
          }`}
          style={{ height: "80%" }}
        >
           {this.state.showProgressBar?
        <ESSProgressBar/>
       :''}
          <AgGridReact
            columnDefs={this.state.columnDefs}
            enableCellTextSelection={true}
            defaultColDef={this.state.defaultColDef}
            rowData={this.state.rowData}
            rowHeight="30"
            headerHeight="30"
            footerHeight="30"
            rowDragManaged={true}
            onGridReady={this.onGridReady}
            rowSelection="single"
            onRowDoubleClicked={this.onRowDoubleClicked.bind(this)}
          ></AgGridReact>
        </div>
      );
    };
    return (
      <div>
        <div
          className={`container-fluid text-center ${
            appConstants.isR3App ? "contentPageR3" : "contentPage"
          }`}
        >
           {this.state.showLoading && 
          <ESSLoadingModal />}
           {this.state.show && (
          <ESSModal
            handleClose={this.handleClose}
            btn1Text="OK"
            userMessage={this.state.userMessage}
            isAlert="true"
            show={this.state.show}
          />
        )}
          <div
            className={` card text-left cardNoBorder ${
              appConstants.isR3App ? "logintitleR3" : "logintitle"
            }`}
            style={{ height: "85%" }}
          >
            <div className="row">
              <div className="col-sm-2">
                {this.state.showOD ? (
                  <h6 className="card-header">Order Details</h6>
                ) : (
                  <h6 className="card-header">Accounts Payable</h6>
                )}
              </div>
              <div className="col-sm-4">
                {this.state.searchClicked ? (
                  <div style={{ padding: "9px" }}>
                    <div className="row">
                      <div className="col-sm-4">
                        <span
                          style={
                            appConstants.isR3App
                              ? {
                                  fontWeight: "bold",
                                  fontSize: "0.8rem",
                                  cursor: "pointer",
                                  color: "#000000",
                                }
                              : {
                                  fontWeight: "bold",
                                  fontSize: "0.8rem",
                                  cursor: "pointer",
                                  color: "#1d2f68",
                                }
                          }
                          onClick={this.apSummaryClick}
                        >
                          AP Summary
                        </span>
                        {" > "}
                        <span></span>
                      </div>
                      <div className="col-sm-4">
                        {this.state.showOD ? (
                          <span
                            style={
                              appConstants.isR3App
                                ? {
                                    fontWeight: "bold",
                                    fontSize: "0.8rem",
                                    cursor: "pointer",
                                    color: "#000000",
                                  }
                                : {
                                    fontWeight: "bold",
                                    fontSize: "0.8rem",
                                    cursor: "pointer",
                                    color: "#1d2f68",
                                  }
                            }
                            onClick={this.apDetailsClick}
                          >
                            AP Detail {" > "}
                          </span>
                        ) : (
                          <span>AP Detail</span>
                        )}
                      </div>
                      {this.state.showOD ? (
                        <div className="col-sm-4">
                          <span> Order Details</span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            <div
              className="card-body"
              style={{
                backgroundColor: "#fff",
                padding: "0.5rem",
                height: "98%",
              }}
            >
              {!this.state.showOD ? (
                <div className="row content" style={{ height: "98%" }}>
                  <div className="col-sm-12" style={{ height: "98%" }}>
                    <div className="row">
                      <div className="col-sm-10">
                        <AccountPayableFilter
                          ref={this.APFilterRef}
                          bucketSelected={this.state.bucketSelected}
                          clearBucketSelection={this.clearBucketSelection}
                          doSearch={this.doSearch}
                        />
                      </div>
                      <div className="col-sm-1" style={{ paddingTop: "30px" }}>
                        {this.state.accountsPayableList.length > 0 ? (
                          <CSVLink
                            ref="csv"
                            data={this.state.accountsPayableList}
                            filename={"AccountsPayableBuckets.csv"}
                          >
                            <img
                              src={downloadbutton}
                              title="Download Accounts Payable Buckets."
                              alt="Download Report"
                            />
                          </CSVLink>
                        ) : undefined}
                      </div>
                    </div>
                    <div className="row" style={{ height: "98%" }}>
                      {this.state.searchClicked ? (
                        <div className="col-sm-12">{renderAPGrid()}</div>
                      ) : (
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col-sm-4">
                              <ARBucket rowSelected={this.rowSelected} />
                            </div>
                            <div className="col-sm-8">
                              <ARBucketChart rowSelected={this.rowSelected} />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div style={{height:"85%"}}>
                  <OrderDetails
                    selectedInvoice={this.state.selectedInvoice}
                    selectedLocation={this.state.selectedLocation}
                    selectedOrder={this.state.selectedOrder}
                    selectedOrderSeq={this.state.selectedOrderSeq}
                    selectedPO={this.state.selectedPO}
                    showOD={this.state.showOD}
                    selectedStatus="Closed"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountsPayable;
function currencyFormatter(params) {
  if (params.value !== undefined && params.value !== "" && params.value !== null && params.value!== '0' &&  params.value!== '0.00') {
    if (parseFloat(params.value) < 0) return "-$" +  (parseFloat(params.value).toFixed(2) * -1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else return "$" +  (parseFloat(params.value).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return "$0.00";
}

function invoiceLinkFormatter(param) {
  return (
    `<a href='https://bunzlesource.com/selfService/protected/getCustomerInvoice.do?referenceNumber=${param.value}' target="_blank" title="Click to view image of invoice: #` +
    param.value +
    '" rel="noopener">' +
    param.value +
    "</a>"
  )
}

function poLinkFormatter(param) {
  return (
    '<a href="https://www.google.com" title="Click to show the details for an order" target="_blank" rel="noopener">' +
    param.value +
    "</a>"
  );
}
function fn_DateCompare(DateA, DateB) {
    var a = new Date(DateA);
    var b = new Date(DateB);
  
    var msDateA = Date.UTC(a.getFullYear(), a.getMonth()+1, a.getDate());
    var msDateB = Date.UTC(b.getFullYear(), b.getMonth()+1, b.getDate());
  
    if (parseFloat(msDateA) < parseFloat(msDateB))
      return -1;  // less than
    else if (parseFloat(msDateA) == parseFloat(msDateB))
      return 0;  // equal
    else if (parseFloat(msDateA) > parseFloat(msDateB))
      return 1;  // greater than
    else
      return null;  // error
  }

function invoiceDateFormatter(param) {
  if(param.value != null &&  param.value != "" && param.value != undefined){
  var d = param.value.split(" ");
  var dateValue = d[0];
  return dateValue;
  }
  else return "";
}
function compare (number1, number2) {
  if (number1 === null && number2 === null) {
  return 0;
  }
  if(isNaN(number1)){ return -1}
  if(isNaN(number2)){ return 1}
  if (number1 === null) {
  return -1;
  }
  if (number2 === null) {
  return 1;
  }
  return number1 - number2;}