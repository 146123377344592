import React, { Component } from "react";
import appConstants from "../../_services/applicationConstant";
import { styles } from "../../assets/css/bunzlUI.css";
import PAFilter from "./PAFilter";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  getPurchaseAnalysis,
   getFilteredData,
} from "../../_services/purchasingAnalysis.service";
import { CSVLink } from "react-csv";
import downloadbutton from "../../assets/images/downloadbutton.gif";
import ESSModal from "../../components/ESSModal.jsx";
import ESSProgressBar from "../../components/ESSProgressBar";

class PurchasingAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      userMessage: "",
      headers:[],
      companyNumber: localStorage.getItem("companyNumber"),
      customerNumber: localStorage.getItem("customerNumber"),
      jwtToken: localStorage.getItem("id_token"),
      defaultColDef: { resizable: true, sortable: true },
      searchClicked: false,
      colHeaders: [
        {
          headerName: "Company",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "codeCompany",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Company Name",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "descCompany",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Customer",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "codeCustomer",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Customer Name",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "descCustomer",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Ship to",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "codeShip",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Department",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "codeDept",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Item Number",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "codeItem",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Description",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "descItem",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Case Pack",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "desc2Item",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Customer Part#",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "codeCustPart",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Vendor Part#",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "codeVPart",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Vendor Number",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "codeVendor",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Vendor Name",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "descVendor",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Invoice",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "codeInvoice",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Customer PO#",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "descInvoice",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Invoice Date",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "desc2Invoice",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Current Price",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          valueFormatter: currencyFormatter,
          comparator: compare,
          field: "codePrice",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Purchase {0}",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          valueFormatter: currencyFormatter,
          comparator: compare,
          field: "range0SLS",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Purchase {1}",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          valueFormatter: currencyFormatter,
          comparator: compare,
          field: "range1SLS",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Purchase {2}",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          valueFormatter: currencyFormatter,
          comparator: compare,
          field: "range2SLS",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Purchase {3}",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          valueFormatter: currencyFormatter,
          comparator: compare,
          field: "range3SLS",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Quantity {0}",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "range0QTY",
          comparator: compare,
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Quantity {1}",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "range1QTY",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Quantity {2}",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "range2QTY",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Quantity {3}",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          field: "range3QTY",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Tax {0}",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          valueFormatter: currencyFormatter,
          comparator: compare,
          field: "range0TAX",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Tax {1}",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          valueFormatter: currencyFormatter,
          comparator: compare,
          field: "range1TAX",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Tax {2}",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          valueFormatter: currencyFormatter,
          comparator: compare,
          field: "range2TAX",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Tax {3}",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          valueFormatter: currencyFormatter,
          comparator: compare,
          field: "range3TAX",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Add {0}",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          valueFormatter: currencyFormatter,
          comparator: compare,
          field: "range0ADD",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Add {1}",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          valueFormatter: currencyFormatter,
          comparator: compare,
          field: "range1ADD",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Add {2}",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          valueFormatter: currencyFormatter,
          comparator: compare,
          field: "range2ADD",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
        {
          headerName: "Add {3}",
          hide: true,
          type: "rightAligned",
          cellStyle: {
            textAlign: "right",
            borderRight: "0.1px solid lightgray",
          },
          valueFormatter: currencyFormatter,
          comparator: compare,
          field: "range3ADD",
          width: 142,
          headerClass: `${
            appConstants.isR3App ? "gridheaderR3" : "gridheader"
          }`,
        },
      ],
      rowData: [],
      exportList: [],
      rowClassRules: {
        "total-row": function (params) {
          var rowVal = params.data.Name;
          return rowVal === "Total";
        },
      },
    };
  }

  doSearch = (filterObject) => {
    this.setState({searchClicked:true, rowData:[]});
   var itemEtry ="";
    const dataEntr = filterObject['ItemEntryList']
    .map(filteredData => (
      itemEtry = itemEtry+ filteredData.itemvalue  
    ));
   
    var searchObject = {
      
      frDate0: filterObject.frDate0,
      frDate1: filterObject.frDate1,
      frDate2: filterObject.frDate2,
      frDate3: filterObject.frDate3,
      toDate0: filterObject.toDate0,
      toDate1: filterObject.toDate1,
      toDate2: filterObject.toDate2,
      toDate3: filterObject.toDate3,
      dimCo:
        filterObject["reportColumn"].filter(
          (p) => p.value === "Company" && p.isChecked === true
        ).length || 0,
      dimCst:
        filterObject["reportColumn"].filter(
          (p) => p.value === "Customer" && p.isChecked === true
        ).length || 0,
      dimShip:
        filterObject["reportColumn"].filter(
          (p) => p.value === "Ship To" && p.isChecked === true
        ).length || 0,
      dimDept:
        filterObject["reportColumn"].filter(
          (p) => p.value === "Department" && p.isChecked === true
        ).length || 0,
      dimItem:
        filterObject["reportColumn"].filter(
          (p) => p.value === "Item Number" && p.isChecked === true
        ).length || 0,
      dimCustPart:
        filterObject["reportColumn"].filter(
          (p) => p.value === "Customer Part#" && p.isChecked === true
        ).length || 0,
      dimVPart:
        filterObject["reportColumn"].filter(
          (p) => p.value === "Vendor Part" && p.isChecked === true
        ).length || 0,
      dimVend:
        filterObject["reportColumn"].filter(
          (p) => p.value === "Vendor Number" && p.isChecked === true
        ).length || 0,
      dimInvoice:
        filterObject["reportColumn"].filter(
          (p) => p.value === "Invoice" && p.isChecked === true
        ).length || 0,
      dimCurPrice:
        filterObject["reportColumn"].filter(
          (p) => p.value === "Current Price" && p.isChecked === true
        ).length || 0,

      meaSLS:
        filterObject["dataColumn"].filter(
          (p) => p.value === "Purchases" && p.isChecked === true
        ).length || 0,
      meaQTY:
        filterObject["dataColumn"].filter(
          (p) => p.value === "Quantity" && p.isChecked === true
        ).length || 0,
      meaADD:
        filterObject["dataColumn"].filter(
          (p) => p.value === "Alloc Add-ons" && p.isChecked === true
        ).length || 0,
      meaTAX:
        filterObject["dataColumn"].filter(
          (p) => p.value === "Alloc tax" && p.isChecked === true
        ).length || 0,

      frItem:
        filterObject["fromItemRange"] === ""
          ? ""
          : filterObject["fromItemRange"],
      toItem:
        filterObject["toItemRange"] === "" ? "" : filterObject["toItemRange"],

      cPType:
        filterObject["ItemEntryType"].trim() === "Distributor Item#"
          ? "I"
          : filterObject["ItemEntryType"].trim() === "Customer Part#"
          ? "C"
          :"I",
      custList:
        filterObject["ciCustomers"].length > 0
          ? filterObject["ciCustomers"].join("")
          : filterObject["isAllCICustomers"].join(""),
      vendList:
        filterObject["ciVendors"].length > 0
          ? filterObject["ciVendors"].join("")
          : "",
      shipList:
        filterObject["ciShipTos"].length > 0
          ? filterObject["ciShipTos"].join("")
          : "",
      itemList: itemEtry,
    };
    getPurchaseAnalysis(searchObject).then((data) => {
    this.setState({searchClicked:false});

      if(data.length === 0){
        this.setState({
          show: true,
          userMessage: "No sales match the criteria.",
        });
      }
      this.setState({ rowData: data || [] });
      this.setState({
        exportList: getFilteredData(
          data,
          this.showFilterExportColumn(searchObject)
        ),
        headers: this.showFilterExportColumnHeaders(searchObject)
      });
    }).catch(()=>{
      this.setState({searchClicked:false});
      this.setState({
        show: true,
        userMessage: "Error while fetching records.\nNo sales match the criteria.",
      });
    })
    ;

    this.showGrid(searchObject);
  };
  showcolumns = (filterObject) => {
    var searchObject = {
    frDate0: filterObject.frDate0,
    frDate1: filterObject.frDate1,
    frDate2: filterObject.frDate2,
    frDate3: filterObject.frDate3,
    toDate0: filterObject.toDate0,
    toDate1: filterObject.toDate1,
    toDate2: filterObject.toDate2,
    toDate3: filterObject.toDate3,
    dimCo:
      filterObject["reportColumn"].filter(
        (p) => p.value === "Company" && p.isChecked === true
      ).length || 0,
    dimCst:
      filterObject["reportColumn"].filter(
        (p) => p.value === "Customer" && p.isChecked === true
      ).length || 0,
    dimShip:
      filterObject["reportColumn"].filter(
        (p) => p.value === "Ship To" && p.isChecked === true
      ).length || 0,
    dimDept:
      filterObject["reportColumn"].filter(
        (p) => p.value === "Department" && p.isChecked === true
      ).length || 0,
    dimItem:
      filterObject["reportColumn"].filter(
        (p) => p.value === "Item Number" && p.isChecked === true
      ).length || 0,
    dimCustPart:
      filterObject["reportColumn"].filter(
        (p) => p.value === "Customer Part#" && p.isChecked === true
      ).length || 0,
    dimVPart:
      filterObject["reportColumn"].filter(
        (p) => p.value === "Vendor Part" && p.isChecked === true
      ).length || 0,
    dimVend:
      filterObject["reportColumn"].filter(
        (p) => p.value === "Vendor Number" && p.isChecked === true
      ).length || 0,
    dimInvoice:
      filterObject["reportColumn"].filter(
        (p) => p.value === "Invoice" && p.isChecked === true
      ).length || 0,
    dimCurPrice:
      filterObject["reportColumn"].filter(
        (p) => p.value === "Current Price" && p.isChecked === true
      ).length || 0,

    meaSLS:
      filterObject["dataColumn"].filter(
        (p) => p.value === "Purchases" && p.isChecked === true
      ).length || 0,
    meaQTY:
      filterObject["dataColumn"].filter(
        (p) => p.value === "Quantity" && p.isChecked === true
      ).length || 0,
    meaADD:
      filterObject["dataColumn"].filter(
        (p) => p.value === "Alloc Add-ons" && p.isChecked === true
      ).length || 0,
    meaTAX:
      filterObject["dataColumn"].filter(
        (p) => p.value === "Alloc tax" && p.isChecked === true
      ).length || 0,

    
        
  };

    this.showGrid(searchObject);
  };

  showGrid = (searchObject) => {
    this.gridColumnApi.setColumnVisible(
      "codeCompany",
      searchObject["dimCo"] === 1 ? true : false
    );
    this.gridColumnApi.setColumnVisible(
      "descCompany",
      searchObject["dimCo"] === 1 ? true : false
    );
    this.gridColumnApi.setColumnVisible(
      "codeCustomer",
      searchObject["dimCst"] === 1 ? true : false
    );
    this.gridColumnApi.setColumnVisible(
      "descCustomer",
      searchObject["dimCst"] === 1 ? true : false
    );
    this.gridColumnApi.setColumnVisible(
      "codeShip",
      searchObject["dimShip"] === 1 ? true : false
    );
    this.gridColumnApi.setColumnVisible(
      "codeDept",
      searchObject["dimDept"] === 1 ? true : false
    );
    this.gridColumnApi.setColumnVisible(
      "codeItem",
      searchObject["dimItem"] === 1 ? true : false
    );
    this.gridColumnApi.setColumnVisible(
      "descItem",
      searchObject["dimItem"] === 1 ? true : false
    );
    this.gridColumnApi.setColumnVisible(
      "desc2Item",
      searchObject["dimItem"] === 1 ? true : false
    );
    this.gridColumnApi.setColumnVisible(
      "codeCustPart",
      searchObject["dimCustPart"] === 1 ? true : false
    );
    this.gridColumnApi.setColumnVisible(
      "codeVPart",
      searchObject["dimVPart"] === 1 ? true : false
    );
    this.gridColumnApi.setColumnVisible(
      "codeVendor",
      searchObject["dimVend"] === 1 ? true : false
    );
    this.gridColumnApi.setColumnVisible(
      "descVendor",
      searchObject["dimVend"] === 1 ? true : false
    );
    this.gridColumnApi.setColumnVisible(
      "codeInvoice",
      searchObject["dimInvoice"] === 1 ? true : false
    );
    this.gridColumnApi.setColumnVisible(
      "desc2Invoice",
      searchObject["dimInvoice"] === 1 ? true : false
    );
    this.gridColumnApi.setColumnVisible(
      "descInvoice",
      searchObject["dimInvoice"] === 1 ? true : false
    );
    this.gridColumnApi.setColumnVisible(
      "codePrice",
      searchObject["dimCurPrice"] === 1 ? true : false
    );
    this.gridApi.getColumnDef("range0SLS").headerName = "Purchases "+ this.dateConverter(searchObject["frDate0"]) + " - " +  this.dateConverter(searchObject["toDate0"]);

    this.gridColumnApi.setColumnVisible(
      "range0SLS",
      searchObject["meaSLS"] === 1 &&
        searchObject["frDate0"] &&
        searchObject["toDate0"]
        ? true
        : false
    );
    this.gridApi.getColumnDef("range1SLS").headerName = "Purchases "+ this.dateConverter(searchObject["frDate1"]) + " - " +  this.dateConverter(searchObject["toDate1"]) ;
    this.gridColumnApi.setColumnVisible(
      "range1SLS",
      searchObject["meaSLS"] === 1 &&
        searchObject["frDate1"] &&
        searchObject["toDate1"]
        ? true
        : false
    );
    this.gridApi.getColumnDef("range2SLS").headerName = "Purchases "+ this.dateConverter(searchObject["frDate2"]) + " - " +  this.dateConverter(searchObject["toDate2"]) ;
    this.gridColumnApi.setColumnVisible(
      "range2SLS",
      searchObject["meaSLS"] === 1 &&
        searchObject["frDate2"] &&
        searchObject["toDate2"]
        ? true
        : false
    );
    this.gridApi.getColumnDef("range3SLS").headerName = "Purchases "+ this.dateConverter(searchObject["frDate3"]) + " - " +  this.dateConverter(searchObject["toDate3"]);
    this.gridColumnApi.setColumnVisible(
      "range3SLS",
      searchObject["meaSLS"] === 1 &&
        searchObject["frDate3"] &&
        searchObject["toDate3"]
        ? true
        : false
    );

    this.gridApi.getColumnDef("range0QTY").headerName = "Quantity "+ this.dateConverter(searchObject["frDate0"]) + " - " +  this.dateConverter(searchObject["toDate0"] );
    this.gridColumnApi.setColumnVisible(
      "range0QTY",
      searchObject["meaQTY"] === 1 &&
        searchObject["frDate0"] &&
        searchObject["toDate0"]
        ? true
        : false
    );
    this.gridApi.getColumnDef("range1QTY").headerName = "Quantity "+ this.dateConverter(searchObject["frDate1"]) + " - " +  this.dateConverter(searchObject["toDate1"]) ;
    this.gridColumnApi.setColumnVisible(
      "range1QTY",
      searchObject["meaQTY"] === 1 &&
        searchObject["frDate1"] &&
        searchObject["toDate1"]
        ? true
        : false
    );
    this.gridApi.getColumnDef("range2QTY").headerName = "Quantity "+ this.dateConverter(searchObject["frDate2"]) + " - " +  this.dateConverter(searchObject["toDate2"]) ;

    this.gridColumnApi.setColumnVisible(
      "range2QTY",
      searchObject["meaQTY"] === 1 &&
        searchObject["frDate2"] &&
        searchObject["toDate2"]
        ? true
        : false
    );
    this.gridApi.getColumnDef("range3QTY").headerName = "Quantity "+ this.dateConverter(searchObject["frDate3"]) + " - " + this.dateConverter(searchObject["toDate3"]);

    this.gridColumnApi.setColumnVisible(
      "range3QTY",
      searchObject["meaQTY"] === 1 &&
        searchObject["frDate3"] &&
        searchObject["toDate3"]
        ? true
        : false
    );

    this.gridApi.getColumnDef("range0TAX").headerName = "Tax "+ this.dateConverter(searchObject["frDate0"]) + " - " +  this.dateConverter(searchObject["toDate0"]);

    this.gridColumnApi.setColumnVisible(
      "range0TAX",
      searchObject["meaTAX"] === 1 &&
        searchObject["frDate0"] &&
        searchObject["toDate0"]
        ? true
        : false
    );
    this.gridApi.getColumnDef("range1TAX").headerName = "Tax "+ this.dateConverter(searchObject["frDate1"]) + " - " +  this.dateConverter(searchObject["toDate1"]) ;
    this.gridColumnApi.setColumnVisible(
      "range1TAX",
      searchObject["meaTAX"] === 1 &&
        searchObject["frDate1"] &&
        searchObject["toDate1"]
        ? true
        : false
    );
    this.gridApi.getColumnDef("range2TAX").headerName = "Tax "+ this.dateConverter(searchObject["frDate2"]) + " - " +  this.dateConverter(searchObject["toDate2"]) ;
    this.gridColumnApi.setColumnVisible(
      "range2TAX",
      searchObject["meaTAX"] === 1 &&
        searchObject["frDate2"] &&
        searchObject["toDate2"]
        ? true
        : false
    );
    this.gridApi.getColumnDef("range3TAX").headerName = "Tax "+ this.dateConverter(searchObject["frDate3"]) + " - " +  this.dateConverter(searchObject["toDate3"]) ;

    this.gridColumnApi.setColumnVisible(
      "range3TAX",
      searchObject["meaTAX"] === 1 &&
        searchObject["frDate3"] &&
        searchObject["toDate3"]
        ? true
        : false
    );

    this.gridApi.getColumnDef("range0ADD").headerName = "Add "+ this.dateConverter(searchObject["frDate0"]) + " - " +  this.dateConverter(searchObject["toDate0"]);
    this.gridColumnApi.setColumnVisible(
      "range0ADD",
      searchObject["meaADD"] === 1 &&
        searchObject["frDate0"] &&
        searchObject["toDate0"]
        ? true
        : false
    );
    this.gridApi.getColumnDef("range1ADD").headerName = "Add "+ this.dateConverter(searchObject["frDate1"]) + " - " +  this.dateConverter(searchObject["toDate1"] );
    this.gridColumnApi.setColumnVisible(
      "range1ADD",
      searchObject["meaADD"] === 1 &&
        searchObject["frDate1"] &&
        searchObject["toDate1"]
        ? true
        : false
    );
    this.gridApi.getColumnDef("range2ADD").headerName = "Add "+ this.dateConverter(searchObject["frDate2"]) + " - " +  this.dateConverter(searchObject["toDate2"]) ;
    this.gridColumnApi.setColumnVisible(
      "range2ADD",
      searchObject["meaADD"] === 1 &&
        searchObject["frDate2"] &&
        searchObject["toDate2"]
        ? true
        : false
    );
    this.gridApi.getColumnDef("range3ADD").headerName = "Add "+ this.dateConverter(searchObject["frDate3"]) + " - " +  this.dateConverter(searchObject["toDate3"]) ;
    this.gridColumnApi.setColumnVisible(
      "range3ADD",
      searchObject["meaADD"] === 1 &&
        searchObject["frDate3"] &&
        searchObject["toDate3"]
        ? true
        : false
    );
    this.gridApi.refreshHeader();
  };

  showFilterExportColumn(searchObject) {
    let filterPropList = [];

    if (searchObject.dimCustPart === 1) {
      filterPropList.push("CustomerPartNumber");
    }
    if (searchObject.dimCst === 1) {
      filterPropList.push("CustomerCode");
      filterPropList.push("CustomerDescription");
    }
    if (searchObject.dimItem === 1) {
      filterPropList.push("ItemCode");
      filterPropList.push("Item");
      filterPropList.push("ItemDescription");
    }
    if (searchObject.dimCo === 1) {
      filterPropList.push("ComapanyCode");
      filterPropList.push("CompanyDescription");      
    }
    if (searchObject.dimDept === 1) {
      filterPropList.push("DepartmentCode");
    }    
    if (searchObject.dimVPart === 1) {
      filterPropList.push("VendorPartNumber");
    }
    if (searchObject.dimVend === 1) {
      filterPropList.push("VendorCode");
      filterPropList.push("VendorDescription");
    }       
    if(searchObject.dimInvoice === 1){
      filterPropList.push("Invoice");
      filterPropList.push("InvoiceDescription");     
      filterPropList.push("CutomerPO");  

    }
    if (searchObject.dimCurPrice === 1) {
      filterPropList.push("CurrentPrice");
    }
    if(searchObject.dimShip === 1){
      filterPropList.push("ShipTo"); 
    }
    if (searchObject.meaSLS === 1) {
      if(searchObject.frDate0 !== null && searchObject.frDate0 !== "" )
      filterPropList.push("Purchases_0");
      if(searchObject.frDate1 !== null && searchObject.frDate1 !== "" )
      filterPropList.push("Purchases_1");
      if(searchObject.frDate2 !== null && searchObject.frDate2 !== "" )
      filterPropList.push("Purchases_2");
      if(searchObject.frDate3 !== null && searchObject.frDate3 !== "" )
      filterPropList.push("Purchases_2");
    }
    if (searchObject.meaQTY === 1) {
      if(searchObject.frDate0 !== null && searchObject.frDate0 !== "" )
      filterPropList.push("Quantity_0");
      if(searchObject.frDate1 !== null && searchObject.frDate1 !== "" )
      filterPropList.push("Quantity_1");
      if(searchObject.frDate2 !== null && searchObject.frDate2 !== "" )
      filterPropList.push("Quantity_2");
      if(searchObject.frDate3 !== null && searchObject.frDate3 !== "" )
      filterPropList.push("Quantity_3");
    }
    if (searchObject.meaADD === 1) {
      if(searchObject.frDate0 !== null && searchObject.frDate0 !== "" )
      filterPropList.push("AddOns_0");
      if(searchObject.frDate1 !== null && searchObject.frDate1 !== "" )
      filterPropList.push("AddOns_1");
      if(searchObject.frDate2 !== null && searchObject.frDate2 !== "" )
      filterPropList.push("AddOns_2");
      if(searchObject.frDate3 !== null && searchObject.frDate3 !== "" )
      filterPropList.push("AddOns_3");
    }
    if (searchObject.meaTAX === 1) {
      if(searchObject.frDate0 !== null && searchObject.frDate0 !== "" )
      filterPropList.push("AllocTax_0");
      if(searchObject.frDate1 !== null && searchObject.frDate1 !== "" )
      filterPropList.push("AllocTax_1");
      if(searchObject.frDate2 !== null && searchObject.frDate2 !== "" )
      filterPropList.push("AllocTax_2");
      if(searchObject.frDate3 !== null && searchObject.frDate3 !== "" )
      filterPropList.push("AllocTax_3");
    }

    return filterPropList;
  }

  showFilterExportColumnHeaders(searchObject) {
    let filterPropList = [];
    
    if (searchObject.dimCo === 1) {
      filterPropList.push({ label: 'Company', key: 'ComapanyCode' });
      filterPropList.push({ label: 'Company Description', key: 'CompanyDescription' });    
    }
    if (searchObject.dimCst === 1) {
      filterPropList.push({ label: 'Customer', key: 'CustomerCode' });
      filterPropList.push({ label: 'Customer Description', key: 'CustomerDescription' });
    }
    if(searchObject.dimShip === 1){
      filterPropList.push({ label: 'Ship To', key: 'ShipTo' }); 
    }
    if (searchObject.dimDept === 1) {
      filterPropList.push({ label: 'Department', key: 'DepartmentCode' });   
    }    
    if (searchObject.dimItem === 1) {
      filterPropList.push({ label: 'Item Number', key: 'ItemCode' });
      filterPropList.push({ label: 'Description', key: 'Item' });
      filterPropList.push({ label: 'Case/Pack', key: 'ItemDescription' });
    }
   
    if (searchObject.dimCustPart === 1) {
      filterPropList.push({ label: 'Customer Part#', key: 'CustomerPartNumber' });
    }
   
    if (searchObject.dimVPart === 1) {
      filterPropList.push({ label: 'Vendor Part#', key: 'VendorPartNumber' });   
    }
    if (searchObject.dimVend === 1) {
      filterPropList.push({ label: 'Vendor Number', key: 'VendorCode' });   
      filterPropList.push({ label: 'Vendor Name', key: 'VendorDescription' });   
    }       
    if(searchObject.dimInvoice === 1){
      filterPropList.push({ label: 'Invoice', key: 'Invoice' });  
      filterPropList.push({ label: 'Customer PO#', key: 'CutomerPO' });   
      filterPropList.push({ label: 'Invoice Date', key: 'InvoiceDescription' });   
    }
    if (searchObject.dimCurPrice === 1) {
      filterPropList.push({ label: 'Current Price', key: 'CurrentPrice' });   
    }
    
    if (searchObject.meaSLS === 1) {
      if(searchObject.frDate0 !== null && searchObject.frDate0 !== "" )
      filterPropList.push({ label: 'Purchases '+searchObject.frDate0 +" to "+searchObject.toDate0, key: 'Purchases_0' }); 
      if(searchObject.frDate1 !== null && searchObject.frDate1 !== "" )
      filterPropList.push({ label: 'Purchases '+searchObject.frDate1 +" to "+searchObject.toDate1, key: 'Purchases_1' }); 
      if(searchObject.frDate2 !== null && searchObject.frDate2 !== "" )
      filterPropList.push({ label: 'Purchases '+searchObject.frDate2 +" to "+searchObject.toDate2, key: 'Purchases_2' }); 
      if(searchObject.frDate3 !== null && searchObject.frDate3 !== "" )
      filterPropList.push({ label: 'Purchases '+searchObject.frDate3 +" to "+searchObject.toDate3, key: 'Purchases_3' }); 

    }
    if (searchObject.meaQTY === 1) {
      if(searchObject.frDate0 !== null && searchObject.frDate0 !== "" )
      filterPropList.push({ label: 'Quantity '+searchObject.frDate0 +" to "+searchObject.toDate0, key: 'Quantity_0' }); 

      if(searchObject.frDate1 !== null && searchObject.frDate1 !== "" )
      filterPropList.push({ label: 'Quantity '+searchObject.frDate1 +" to "+searchObject.toDate1, key: 'Quantity_1' }); 

      if(searchObject.frDate2 !== null && searchObject.frDate2 !== "" )
      filterPropList.push({ label: 'Quantity '+searchObject.frDate2 +" to "+searchObject.toDate2, key: 'Quantity_2' }); 

      if(searchObject.frDate3 !== null && searchObject.frDate3 !== "" )
      filterPropList.push({ label: 'Quantity '+searchObject.frDate3 +" to "+searchObject.toDate3, key: 'Quantity_3' }); 

    }
    if (searchObject.meaADD === 1) {
      if(searchObject.frDate0 !== null && searchObject.frDate0 !== "" )
      filterPropList.push({ label: 'Add '+searchObject.frDate0 +" to "+searchObject.toDate0, key: 'AddOns_0' }); 

      if(searchObject.frDate1 !== null && searchObject.frDate1 !== "" )
      filterPropList.push({ label: 'Add '+searchObject.frDate1 +" to "+searchObject.toDate1, key: 'AddOns_1' }); 

      if(searchObject.frDate2 !== null && searchObject.frDate2 !== "" )
      filterPropList.push({ label: 'Add '+searchObject.frDate2 +" to "+searchObject.toDate2, key: 'AddOns_2' }); 

      if(searchObject.frDate3 !== null && searchObject.frDate3 !== "" )
      filterPropList.push({ label: 'Add '+searchObject.frDate3 +" to "+searchObject.toDate3, key: 'AddOns_3' }); 

    }
    if (searchObject.meaTAX === 1) {
      if(searchObject.frDate0 !== null && searchObject.frDate0 !== "" )
      filterPropList.push({ label: 'Tax '+searchObject.frDate0 +" to "+searchObject.toDate0, key: 'AllocTax_0' }); 

      if(searchObject.frDate1 !== null && searchObject.frDate1 !== "" )
      filterPropList.push({ label: 'Tax '+searchObject.frDate1 +" to "+searchObject.toDate1, key: 'AllocTax_1' }); 

      if(searchObject.frDate2 !== null && searchObject.frDate2 !== "" )
      filterPropList.push({ label: 'Tax '+searchObject.frDate2 +" to "+searchObject.toDate2, key: 'AllocTax_2' }); 

      if(searchObject.frDate3 !== null && searchObject.frDate3 !== "" )
      filterPropList.push({ label: 'Tax '+searchObject.frDate3 +" to "+searchObject.toDate3, key: 'AllocTax_3' }); 

    }

    return filterPropList;
  }


  clearData =() =>{
    this.setState({rowData:[]});
    this.hideGridColumns();
  }
  hideGridColumns =() =>{
    
    this.gridColumnApi.setColumnVisible(
      "codeCompany",
      false
    );
    this.gridColumnApi.setColumnVisible(
      "descCompany",
      false
    );
    this.gridColumnApi.setColumnVisible(
      "codeCustomer",
     false
    );
    this.gridColumnApi.setColumnVisible(
      "descCustomer",
       false
    );
    this.gridColumnApi.setColumnVisible(
      "codeShip",
      false
    );
    this.gridColumnApi.setColumnVisible(
      "codeDept",
       false
    );
    this.gridColumnApi.setColumnVisible(
      "codeItem",
       false
    );
    this.gridColumnApi.setColumnVisible(
      "descItem",
      false
    );
    this.gridColumnApi.setColumnVisible(
      "desc2Item",
      false
    );
    this.gridColumnApi.setColumnVisible(
      "codeCustPart",
      false
    );
    this.gridColumnApi.setColumnVisible(
      "codeVPart",
      false
    );
    this.gridColumnApi.setColumnVisible(
      "codeVendor",
      false
    );
    this.gridColumnApi.setColumnVisible(
      "descVendor",
      false
    );
    this.gridColumnApi.setColumnVisible(
      "codeInvoice",
      false
    );
    this.gridColumnApi.setColumnVisible(
      "desc2Invoice",
      false
    );
    this.gridColumnApi.setColumnVisible(
      "descInvoice",
      false
    );
    this.gridColumnApi.setColumnVisible(
      "codePrice",
     false
    );

    this.gridColumnApi.setColumnVisible(
      "range0SLS",
     false
    );
    this.gridColumnApi.setColumnVisible(
      "range1SLS",
      false
    );
    this.gridColumnApi.setColumnVisible(
      "range2SLS",
      false
    );
    this.gridColumnApi.setColumnVisible(
      "range3SLS",
      false
    );
    this.gridColumnApi.setColumnVisible(
      "range0QTY",
      false
    );
    this.gridColumnApi.setColumnVisible(
      "range1QTY",
      false
    );

    this.gridColumnApi.setColumnVisible(
      "range2QTY",
      false
    );
    this.gridColumnApi.setColumnVisible(
      "range3QTY",
     false
    );
    this.gridColumnApi.setColumnVisible(
      "range0TAX",
    false
    );
    this.gridColumnApi.setColumnVisible(
      "range1TAX",
       false
    );
    this.gridColumnApi.setColumnVisible(
      "range2TAX",
     false
    );
    this.gridColumnApi.setColumnVisible(
      "range3TAX",
     false
    );
    this.gridColumnApi.setColumnVisible(
      "range0ADD",
      false
    );
    this.gridColumnApi.setColumnVisible(
      "range1ADD",
       false
    );
    this.gridColumnApi.setColumnVisible(
      "range2ADD",
      false
    );
    this.gridColumnApi.setColumnVisible(
      "range3ADD",
       false
    );
  }
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };
  handleClose = () => this.setState({ show: false });

  dateConverter =(dateValue) =>{
    if(dateValue!=='')  {        
      var from=dateValue.split("-")  
     var  fromDate=from[1]  +"/"+ from[2] +"/"+ from[0]  ;
     return fromDate;
  }
  else
  return '';
  }

  render() {
    const renderCIGrid = () => {
      return (
        <div
          className={`ag-theme-alpine ${
            appConstants.isR3App ? "apGridR3" : "apGrid"
          }`}
          style={{ height: "80%" }}
        >
          {this.state.searchClicked?
        <ESSProgressBar/>
       :''}
          <AgGridReact
            columnDefs={this.state.colHeaders}
            rowData={this.state.rowData}
            defaultColDef={this.state.defaultColDef}
            onGridReady={this.onGridReady}
            rowHeight="30"
            headerHeight="30"
            rowSelection="single"
            rowClassRules={this.state.rowClassRules}
            tooltipShowDelay="0"
            enableCellTextSelection={true}
          ></AgGridReact>
        </div>
      );
    };
    return (
      <div>
        <div
          className={`container-fluid text-center ${
            appConstants.isR3App ? "contentPageR3" : "contentPage"
          }`} //contentPageR3
        >
          {this.state.show && (
          <ESSModal
            handleClose={this.handleClose}
            btn1Text="OK"
            userMessage={this.state.userMessage}
            isAlert="true"
            show={this.state.show}
          />
        )}
          <div
            className={` card text-left cardNoBorder ${
              appConstants.isR3App ? "logintitleR3" : "logintitle"
            }`}
            style={{ height: "80%" }}
          >
            <h6 className="card-header">Purchasing Analysis</h6>
            <div
              className="card-body"
              style={{ backgroundColor: "#fff", padding: "2px", height: "80%" }}
            >
              <div className="row content" style={{ height: "80%" }}>
                <div className="col-sm-12" style={{ height: "80%" }}>
                  <div className="row" ref="filterComponent">
                    <div className="col-sm-11">
                      <PAFilter
                        doSearch={this.doSearch}
                        showcolumns={this.showcolumns}
                        clearData= {this.clearData}
                      />
                    </div>
                    <div className="col-sm-1">
                      <div style={{ paddingTop: "5px" }}>
                        {this.state.exportList.length > 0 ? (
                          <CSVLink
                            ref="csv" headers={this.state.headers}
                            data={this.state.exportList}
                            filename={
                              "Filtered-PA-Report.csv"
                            }
                          >
                            <img
                              src={downloadbutton}
                              title="Download Filtered Purchase Analysis List Items."
                              alt="Download Report"
                            />
                          </CSVLink>
                        ) : undefined}
                      </div>
                    </div>
                  </div>
                  <div
                    className="row"
                    ref="gridComponent"
                    style={{ height: "80%" }}
                  >
                    <div className="col-sm-12">{renderCIGrid()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
function currencyFormatter(params) {
  if(params.value !== undefined &&params.value !== null  && params.value !== ""){
  if (parseFloat(params.value) < 0) return  "-$" +  (parseFloat(params.value).toFixed(2) * -1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else return "$" +  (parseFloat(params.value).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  else
  return "";
}
function compare (number1, number2) {
  if (number1 === null && number2 === null) {
  return 0;
  }
  if(isNaN(number1)){ return -1}
  if(isNaN(number2)){ return 1}
  if (number1 === null) {
  return -1;
  }
  if (number2 === null) {
  return 1;
  }
  return number1 - number2;}
export default PurchasingAnalysis;
