import {authHeader,encodedURL} from '../_helpers/auth-header';

import api from '../_services/apiUrl';

export function getPurchaseOrder(isR3){
    var PurchaseOrder = [
        { value: "PO", label: 'Purchase Order' },
        { value: "ON", label: 'Order Number' },    
        { value: "IN", label: 'Invoice Number' }   
    ];
    return PurchaseOrder
}



export function getStatus(isR3){
    var Status = [
        { value: "O", label: 'Open' },
        { value: "C", label: 'Closed' },    
        { value: "B", label: 'Both' }   
    ];
    return Status
}

// export function  getArBucketItems(url = '', jwtToken){
//     return fetch(url, {
//         method: 'GET',
//         headers: authHeader()
//     }).then(response => response.json());
// }
export function getArBucketItems() {
   
    var companyNumber=localStorage.getItem('companyNumber')
    var customerNumber=localStorage.getItem('customerNumber')
    return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/accountspayable/arbuckets?companyNumber=${companyNumber}&customerNumber=${customerNumber}`)}`, {
        method: 'GET',
        headers:authHeader()
    }).then(response => response.json());
}

export function getFilteredARData(data){
    let filteredData=[];

    data.forEach((item) => {
        filteredData.push({
            DaysOld: 	item.Name,
            CurrentBalance:	currencyFormatter(item.Balance),
            NoOfInvoice: item.Invcount 
           });
          })
    return filteredData;   
}
function currencyFormatter(currencyValue) {
    if (currencyValue !== undefined && currencyValue !== "" && currencyValue !== null && currencyValue !== '0' &&  currencyValue !== '0.00') {
      if (parseFloat(currencyValue) < 0) return "-$" +  (parseFloat(currencyValue).toFixed(2) * -1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      else return "$" +  (parseFloat(currencyValue).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return "$0.00";
}
  function invoiceDateFormatter(input) {
    if(input != null &&  input!= "" && input != undefined){
    var d = input.split(" ");
    var dateValue = d[0];
    return dateValue;
    }
    else return "";
}
export function getFilteredAPData(data){
    let filteredData=[];
    data.forEach((item) => {
        filteredData.push({
            Invoice : item.ReferenceNumber,
            InvoiceDate : invoiceDateFormatter(item.InvoiceDate),
            PO : item.PoNumber,
            Order : item.OrderNumber,
            DaysOld :  item.Days,
            Customer : item.SoldCustomer,
            ShipTo : item.SoldShipTo,
            Address : item.City,
            CurrentBalance :currencyFormatter(item.CurrentBalance),
            OriginalBalance : currencyFormatter(item.OriginalAmount)
           });
          })
    return filteredData;   
}


export function getShipTo() {
    var identityKey=localStorage.getItem('identityKey')
    var loginTypeCode=localStorage.getItem('loginTypeCode')
    var companyNumber=localStorage.getItem('companyNumber')
    var customerNumber=localStorage.getItem('customerNumber')
    var canViewAllShipTos='';
    var shipTo='';
    if(localStorage.getItem('customerGroupShipToValue') !== null &&  localStorage.getItem('customerGroupShipToValue').trim() !== "" ){
        canViewAllShipTos='&canViewAllShipTos=N&';
        shipTo='shipTo='+localStorage.getItem('customerGroupShipToValue').trim();
    }
    return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/user/shipTo?identityKey=${identityKey}&loginTypeCode=${loginTypeCode}&companyNumber=${companyNumber}&customerNumber=${customerNumber}${canViewAllShipTos}${shipTo}`)}`, {
        method: 'GET',
        headers:authHeader()
    }).then(response =>
              response.json());
}

export function getAPData(shipTo, searchText, searchType, fromDate, toDate, status, bucket, showInvoice)
{
    var urlparam='';
    //urlparam+='&qualifier='+qualifier
    if(shipTo!=='0')
    {
        urlparam+='&shipTo='+shipTo
    }    
    if(searchText!=='')
    {
        urlparam+='&searchWord='+searchText;
        urlparam+='&searchWordType='+searchType;
    }
    if(bucket!=='')
    {
        urlparam+='&bucket='+bucket
    }
    if(fromDate!=='')
    {
        //var d = new Date(fromDate);
        //var dateFrom = d.getMonth()+1 +"/"+ d.getDate() +"/"+ d.getFullYear()   
        var from=fromDate.split("-")  
        var dateFrom=from[1]  +"/"+ from[2] +"/"+ from[0]  
        urlparam+='&fromDate='+dateFrom
    }
    if(toDate!=='')
    {
        var from=toDate.split("-")  
        var dateFrom=from[1]  +"/"+ from[2] +"/"+ from[0]  
        urlparam+='&toDate='+dateFrom
    }
    if(status!=='')
    {
        urlparam+='&status='+status
    }
    if(showInvoice!=='')
    {
        urlparam+='&showInvoice='+showInvoice
    }
    return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/accountspayable/ardetails?companyNumber=${localStorage.getItem('companyNumber')}&customerNumber=${localStorage.getItem('customerNumber')}${urlparam}`)}`, {
        method: 'GET',
        headers:authHeader()
    }).then(response => response.json());
}


