import React, { Component } from "react";
import Modal from "react-bootstrap/Modal";

class ESSModal extends Component {
  render() {
    return (
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.show}
        onHide={this.props.handleClose}
      >
        <Modal.Body style={{ paddingBottom: "0px" }}>
          {!this.props.isContentMessage ? (
            <center>
              <p style={{ whiteSpace: "pre-wrap", fontWeight: "bold" }}>
                {this.props.userMessage}
              </p>
            </center>
          ) : (
            <p style={{ whiteSpace: "pre-wrap", fontWeight: "bold" }}>
              {this.props.userMessage}
            </p>
          )}
        </Modal.Body>
        <Modal.Footer
          style={{ display: "block", justifyContent: "center", border: "0" }}
        >
          <center>
            {this.props.isAlert === "false" && (
              <button
                variant="primary"
                style={{
                  marginRight: "20px",
                  width: "60px",
                  borderColor: "#0079c2",
                }}
                onClick={this.props.handle2ndButtonClick}
              >
                {this.props.btn2Text}
              </button>
            )}
            <button
              variant="secondary"
              style={{ width: "60px", borderColor: "#0079c2" }}
              centered
              onClick={this.props.handleClose}
            >
              {this.props.btn1Text}
            </button>
          </center>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ESSModal;
