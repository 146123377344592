import React, { Component } from 'react';
import appConstants from "../../_services/applicationConstant";
import {styles} from "../../assets/css/bunzlUI.css"
import CIFilter from "./CIFiler"
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {getConsolidatedInventory, getFilteredData} from "../../_services/consolidatedInventory.service"
import {CSVLink} from 'react-csv';
import downloadbutton from '../../assets/images/downloadbutton.gif';
import ESSProgressBar from "../../components/ESSProgressBar";
import ESSModal from "../../components/ESSModal.jsx";
import { decryptData } from '../../Utils';

class ConsolidatedInventory extends Component {
  constructor(props) {
    super(props);
    const salt = process.env.SALT || process.env.REACT_APP_KEY;
        const originalData = decryptData(localStorage.getItem("id_token"), salt);
    this.state = {
        companyNumber:  localStorage.getItem('companyNumber'),
        customerNumber: localStorage.getItem('customerNumber'),
        jwtToken: originalData ,
        defaultColDef: { resizable: true },
        searchClicked:false,
        show: false,
        userMessage: "",
        colHeaders: [
        { headerName: "Customer Part#",hide:true,cellStyle: {textAlign: 'center',borderRight: '0.1px solid lightgray'},
          field: "customerPart",sortable: true,width:141,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: "Item Number",sortable: true, hide:true, type: 'rightAligned', cellStyle: {textAlign: 'right',borderRight: '0.1px solid lightgray'}, field: "itemNumber",width:142,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: "Description",sortable: true,hide:true, type: 'rightAligned', cellStyle: {textAlign: 'right',borderRight: '0.1px solid lightgray'}, field: "description",width:142,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}`  },
        { headerName: "Case Pack",sortable: true,hide:true,  type: 'rightAligned',  cellStyle: {textAlign: 'right',borderRight: '0.1px solid lightgray'},field: "casePack",width:142,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: "Pack Description",sortable: true,hide:true,  type: 'rightAligned',  cellStyle: {textAlign: 'right',borderRight: '0.1px solid lightgray'},  field: "packDescption",width:142,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: "Warehouse",sortable: true,hide:true,  type: 'rightAligned',  cellStyle: {textAlign: 'right',borderRight: '0.1px solid lightgray'},  field: "locationAbbr",width:142,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: "Stock/Non-Stock item",sortable: true,hide:true,  type: 'rightAligned',  cellStyle: {textAlign: 'right',borderRight: '0.1px solid lightgray'}, field: "stockNo",width:142,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: "Vendor Part",sortable: true,hide:true,  type: 'rightAligned', cellStyle: {textAlign: 'right',borderRight: '0.1px solid lightgray'}, field: "vendorPart",width:142,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: "Vendor",sortable: true,hide:true,  type: 'rightAligned',  cellStyle: {textAlign: 'right',borderRight: '0.1px solid lightgray'}, field: "vendorNumber",width:142,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: "Vendor Name",sortable: true,hide:true,  type: 'rightAligned', cellStyle: {textAlign: 'right',borderRight: '0.1px solid lightgray'},  field: "vendorName",width:142,headerClass: `${appConstants.isR3App? "gridheaderR3"  : "gridheader"}` },
        { headerName: "Estimated Delivery Date to Distributor",sortable: true,comparator:fn_DateCompare,sortable: true,cellRenderer: dateFormatter,hide:true,  type: 'rightAligned', cellStyle: {textAlign: 'right',borderRight: '0.1px solid lightgray'}, field: "poDate",width:142,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: "Pallet Qty",sortable: true,comparator: compare,hide:true,  type: 'rightAligned',  cellStyle: {textAlign: 'right',borderRight: '0.1px solid lightgray'}, field: "pallet",width:142,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: "Current Price",sortable: true, valueFormatter:currencyFormatterWithParam,comparator: compare,hide:true,  type: 'rightAligned', cellStyle: {textAlign: 'right',borderRight: '0.1px solid lightgray'}, field: "prc",width:142,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: "Quantity Available",sortable: true,comparator: compare, hide:true, type: 'rightAligned', cellStyle: {textAlign: 'right',borderRight: '0.1px solid lightgray'}, field: "avl",width:142,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: "Product Value Available",sortable: true, valueFormatter:currencyFormatterWithParam,comparator: compare,hide:true,  type: 'rightAligned', cellStyle: {textAlign: 'right',borderRight: '0.1px solid lightgray'}, field: "val",width:142,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: "Quantity on Order",sortable: true,comparator: compare,hide:true,  type: 'rightAligned', cellStyle: {textAlign: 'right',borderRight: '0.1px solid lightgray'}, field: "qoo",width:142,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: "Available + On Order",sortable: true,comparator: compare,hide:true,  type: 'rightAligned',  cellStyle: {textAlign: 'right',borderRight: '0.1px solid lightgray'}, field: "avloo",width:142,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` }],
        rowData:[],
        inventoryList:[],
        rowClassRules: {
          'total-row': function(params) {
            var rowVal = params.data.Name;
            return rowVal === 'Total';
          },
        },
         
    };
  }
  
  doClear =()=>{
    this.setState({rowData:[]})
    this.hideAllGridColumns();    
  }
hideAllGridColumns =() =>{
    this.gridColumnApi.setColumnVisible('customerPart',  false);
    this.gridColumnApi.setColumnVisible('description', false);
    this.gridColumnApi.setColumnVisible('itemNumber',  false);
    this.gridColumnApi.setColumnVisible('locationAbbr', false);
    this.gridColumnApi.setColumnVisible('casePack',  false);
    this.gridColumnApi.setColumnVisible('packDescption', false);
    this.gridColumnApi.setColumnVisible('stockNo', false);
    this.gridColumnApi.setColumnVisible('vendorPart',  false);
    this.gridColumnApi.setColumnVisible('vendorNumber',  false);
    this.gridColumnApi.setColumnVisible('vendorName',  false);
    this.gridColumnApi.setColumnVisible('poDate', false);
    this.gridColumnApi.setColumnVisible('pallet',  false);
    this.gridColumnApi.setColumnVisible('prc',  false);
    this.gridColumnApi.setColumnVisible('avl', false);
    this.gridColumnApi.setColumnVisible('qoo', false);
    this.gridColumnApi.setColumnVisible('val', false);
    this.gridColumnApi.setColumnVisible('avloo', false);
}

  doSearch= (filterObject) => {
    this.setState({searchClicked: true, rowData:[]});
    var _reportColumn =[];
     _reportColumn = filterObject['reportColumn'].reduce((res,val) =>{
      res[val.code] = val.value;
      return res;
    },{});
   
    var _dataColumn = filterObject['dataColumn'].reduce((res,val) =>{
      res[val.code] = val.value;
      return res;
    },{});
    var itemEtry ="";
    const dataEntr = filterObject['ItemEntryList']
    .map(filteredData => (
      itemEtry = itemEtry+ filteredData.itemvalue  
    ));
   

    var searchObject = {
      dimCustPart:filterObject['reportColumn'].filter(p => p.value==="Customer Part#" && p.isChecked===true).length ||0,
      dimItem: filterObject['reportColumn'].filter(p => p.value==="Item Number" && p.isChecked===true).length || 0,
      dimCo: filterObject['reportColumn'].filter(p => p.value==="Warehouse" && p.isChecked===true).length || 0,
      dimStk: filterObject['reportColumn'].filter(p => p.value==="Stock/Non-stock Item" && p.isChecked===true).length|| 0,
      dimVPart: filterObject['reportColumn'].filter(p => p.value==="Vendor Part" && p.isChecked===true).length|| 0,
      dimVend: filterObject['reportColumn'].filter(p => p.value==="Vendor" && p.isChecked===true).length|| 0,
      dimPODate: filterObject['reportColumn'].filter(p => p.value==="Estimated Delvery Date to Distributor Warehouse" && p.isChecked===true).length|| 0,
      dimPal: filterObject['reportColumn'].filter(p => p.value==="Pallet Qty" && p.isChecked===true).length|| 0,
      dimCurPrice: filterObject['reportColumn'].filter(p => p.value==="Current Price" && p.isChecked===true).length|| 0,

      meaAVL: filterObject['dataColumn'].filter(p => p.value==="Quantity Available" && p.isChecked===true).length|| 0,
      meaVAL:filterObject['dataColumn'].filter(p => p.value==="Product Value Available" && p.isChecked===true).length||0,
      meaQOO: filterObject['dataColumn'].filter(p => p.value==="Quantity on Demand" && p.isChecked===true).length||0,
      meaAVLOO: filterObject['dataColumn'].filter(p => p.value==="Available + On Order" && p.isChecked===true).length|| 0,
      frItem:filterObject['fromItemRange']==''?'':filterObject['fromItemRange'],
      toItem:filterObject['toItemRange']==''?'':filterObject['toItemRange'],
      cPType:(filterObject['ItemEntryType']=="Distributor Item#")?'B':(filterObject['ItemEntryType']=="Customer Part#")?'C':'V',
      custList:filterObject['ciCustomers'].length>0?filterObject['ciCustomers'].join(''):filterObject['isAllCICustomers'],
      vendList:filterObject['ciVendors'].length>0?filterObject['ciVendors'].join(''):filterObject['isAllCIVedors'].join(''),
      itemList:itemEtry
    };
    
   // alert(filterObject['dataColumn'].filter(p => p.value==="Quantity Available" && p.isChecked===true).length);
   this.showGrid(searchObject);

    getConsolidatedInventory(searchObject)
    .then((data) => { 
      this.setState({ searchClicked: false, rowData: data||[] })
            
      this.setState({inventoryList:getFilteredData(data, this.showFilterExportColumn(searchObject))})
      if(this.state.rowData.length === 0)
    {
      this.setState({
        show: true,
        userMessage:
          "No inventory matches the criteria.",
      });
    }
    });
        
   
   }
   showcolumns=(filterObject)=>{
    var searchObject = {
      dimCustPart:filterObject['reportColumn'].filter(p => p.value==="Customer Part#" && p.isChecked===true).length ||0,
      dimItem: filterObject['reportColumn'].filter(p => p.value==="Item Number" && p.isChecked===true).length || 0,
      dimCo: filterObject['reportColumn'].filter(p => p.value==="Warehouse" && p.isChecked===true).length || 0,
      dimStk: filterObject['reportColumn'].filter(p => p.value==="Stock/Non-stock Item" && p.isChecked===true).length|| 0,
      dimVPart: filterObject['reportColumn'].filter(p => p.value==="Vendor Part" && p.isChecked===true).length|| 0,
      dimVend: filterObject['reportColumn'].filter(p => p.value==="Vendor" && p.isChecked===true).length|| 0,
      dimPODate: filterObject['reportColumn'].filter(p => p.value==="Estimated Delvery Date to Distributor Warehouse" && p.isChecked===true).length|| 0,
      dimPal: filterObject['reportColumn'].filter(p => p.value==="Pallet Qty" && p.isChecked===true).length|| 0,
      dimCurPrice: filterObject['reportColumn'].filter(p => p.value==="Current Price" && p.isChecked===true).length|| 0,

      meaAVL: filterObject['dataColumn'].filter(p => p.value==="Quantity Available" && p.isChecked===true).length|| 0,
      meaVAL:filterObject['dataColumn'].filter(p => p.value==="Product Value Available" && p.isChecked===true).length||0,
      meaQOO: filterObject['dataColumn'].filter(p => p.value==="Quantity on Demand" && p.isChecked===true).length||0,
      meaAVLOO: filterObject['dataColumn'].filter(p => p.value==="Available + On Order" && p.isChecked===true).length|| 0,
     
    };

    this.showGrid(searchObject);
   }
  showGrid = (searchObject) => {
     let showDescription = (searchObject.dimCustPart == 1 || searchObject.dimItem == 1)
      ? true
      : false;
    this.gridColumnApi.setColumnVisible('customerPart', searchObject.dimCustPart == 1 ? true : false);
    this.gridColumnApi.setColumnVisible('description', showDescription);
    this.gridColumnApi.setColumnVisible('itemNumber', searchObject.dimItem == 1 ? true : false);
    this.gridColumnApi.setColumnVisible('locationAbbr', (searchObject.dimCo == 1)?true:false);
    this.gridColumnApi.setColumnVisible('casePack', (searchObject.dimItem == 1 && searchObject.dimCo == 1)?true:false);
    this.gridColumnApi.setColumnVisible('packDescption', (searchObject.dimItem == 1 && searchObject.dimCo == 1)?true:false);
    this.gridColumnApi.setColumnVisible('stockNo', searchObject.dimStk == 1 ? true : false);
    this.gridColumnApi.setColumnVisible('vendorPart', searchObject.dimVPart == 1 ? true : false);
    this.gridColumnApi.setColumnVisible('vendorNumber', searchObject.dimVend == 1 ? true : false);
    this.gridColumnApi.setColumnVisible('vendorName', searchObject.dimVend == 1 ? true : false);
    this.gridColumnApi.setColumnVisible('poDate', searchObject.dimPODate == 1 ? true : false);
    this.gridColumnApi.setColumnVisible('pallet', searchObject.dimPal == 1 ? true : false);
    this.gridColumnApi.setColumnVisible('prc', searchObject.dimCurPrice == 1 ? true : false);
    this.gridColumnApi.setColumnVisible('avl', searchObject.meaAVL == 1 ? true : false);
    this.gridColumnApi.setColumnVisible('qoo', searchObject.meaQOO == 1 ? true : false);
    this.gridColumnApi.setColumnVisible('val', searchObject.meaVAL == 1 ? true : false);
    this.gridColumnApi.setColumnVisible('avloo', searchObject.meaAVLOO == 1 ? true : false);
  }

  showFilterExportColumn(searchObject) {    
    let filterPropList = [];

    if (searchObject.dimCustPart==1)
    {
    filterPropList.push('CustomerPart');
    filterPropList.push('Description');
    }
    if (searchObject.dimItem==1)
    {
    filterPropList.push('ItemNumber');
    filterPropList.push('Description');
    }
    if (searchObject.dimCo==1)
    {
    filterPropList.push('Warehouse');
    }
    if (searchObject.dimStk==1)
    {
    filterPropList.push('StockNonStockItem');      
    }
    if (searchObject.dimVPart==1)
    {
    filterPropList.push('VendorPart');   
    } 
    if(searchObject.dimVend==1)
    {
     filterPropList.push('VendorNumber'); 
    filterPropList.push('VendorName');     
    }     
    if(searchObject.dimPODate==1)
    {
    filterPropList.push('EstimatedDeliveryDatetoDistributorWarehouse');  
    }
    if(searchObject.dimPal==1)
    {
    filterPropList.push('PalletQty');
    }
    if(searchObject.dimCurPrice==1)
    {
    filterPropList.push('CurrentPrice');
    }
    if(searchObject.meaAVL==1)
    {
    filterPropList.push('QuantityAvailable');
    }
    if(searchObject.meaQOO==1)
    {
    filterPropList.push('QuantityOnOrder');
    }
    if(searchObject.meaVAL==1)
    {
    filterPropList.push('ProductValueAvailable');
    }
    if(searchObject.meaAVLOO==1)
    {
    filterPropList.push('AvailableOnOrder');
    }

    return filterPropList;
  }
  handleClose = () => this.setState({ show: false });

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;  
  };

    
    render() {
      const renderCIGrid = () => {
        return <div className= {`ag-theme-alpine ${appConstants.isR3App ? "apGridR3" : "apGrid"}`}
        style={{ height: "80%" }}>
           {this.state.searchClicked?
        <ESSProgressBar/>
       :''}
        <AgGridReact
            columnDefs={this.state.colHeaders}
            rowData={this.state.rowData} 
            defaultColDef={this.state.defaultColDef}
            onGridReady={this.onGridReady}  
            rowHeight="30"   
            headerHeight="30" 
            rowSelection="single"
            rowClassRules={this.state.rowClassRules}
            enableCellTextSelection={true}
            tooltipShowDelay="0"
            >
        </AgGridReact>
      </div>;
  }
        return (
            <div>
        <div          
          className={`container-fluid text-center ${
            appConstants.isR3App ? "contentPageR3" : "contentPage"
          }`}       //contentPageR3       
        >
           <ESSModal
            handleClose={this.handleClose}
            btn1Text="OK"
            userMessage={this.state.userMessage}
            isAlert="true"
            show={this.state.show}
          />
          <div
            className={` card text-left cardNoBorder ${
              appConstants.isR3App ? "logintitleR3" : "logintitle"
            }`} style={{height: "80%"}}
          >
            <h6 className="card-header">Consolidated Inventory List</h6>
            <div className="card-body" style={{ backgroundColor: "#fff", padding:"2px", height:"80%"  }}>
              <div className="row content" style={{height:"80%"}}>
                <div className="col-sm-12" style={{height:"80%"}}>
                  <div className="row" ref="filterComponent">
                    <div className="col-sm-10">
                      <CIFilter  doSearch={this.doSearch} doClear={this.doClear} showcolumns={this.showcolumns} />
                    </div>                    
                    <div className="col-sm-1" >
                    <div style={{paddingTop:"5px"}}>
                      { this.state.inventoryList.length > 0 ? 
                           <CSVLink ref="csv" data={this.state.inventoryList} filename={'Filtered-ConsolidatedInventory-Report.csv'}> 
                         <img src={downloadbutton} title="Download Filtered Consolidated Inventory List Items."  alt="Download Report" />
                          </CSVLink> 
                          : undefined} 
                    </div>
                    </div>
                    <div className="col-sm-1" >
                      
                    </div>
                  </div>
                  <div
                    className="row"                  
                    ref="gridComponent" style={{ height: "80%" }}
                  >
                    <div className="col-sm-12">
                   { renderCIGrid() }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        );
    }
}

export default ConsolidatedInventory;
function compare (number1, number2) {
  if (number1 === null && number2 === null) {
  return 0;
  }
  if(isNaN(number1)){ return -1}
  if(isNaN(number2)){ return 1}
  if (number1 === null) {
  return -1;
  }
  if (number2 === null) {
  return 1;
  }
  return number1 - number2;}

   function dateFormatter(param) {
    if(param.value != null &&  param.value != "" && param.value != undefined){
    var d = param.value.split(" ");
    var dateValue = d[0];
    return dateValue;
    }
    else return "";
  }
  function fn_DateCompare(DateA, DateB) {
    var a = new Date(DateA);
    var b = new Date(DateB);
  
    var msDateA = Date.UTC(a.getFullYear(), a.getMonth()+1, a.getDate());
    var msDateB = Date.UTC(b.getFullYear(), b.getMonth()+1, b.getDate());
  
    if (parseFloat(msDateA) < parseFloat(msDateB))
      return -1;  // less than
    else if (parseFloat(msDateA) == parseFloat(msDateB))
      return 0;  // equal
    else if (parseFloat(msDateA) > parseFloat(msDateB))
      return 1;  // greater than
    else
      return null;  // error
  }

  function currencyFormatterWithParam(params) {
    if (params.value !== undefined && params.value !== "" && params.value !== null && params.value !== '0' && params.value !== '0.00') {
    if (parseFloat(params.value) < 0) return "-$" +  (parseFloat(params.value).toFixed(2) * -1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else return "$" +   (parseFloat(params.value).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    else return "$0.00";
  }