import React, { Component } from "react";
import downloadAll from "../assets/images/DownloadAll.png";
import { CSVLink } from "react-csv";
import { getInventoryItems } from "../_services/inventory.service";
import { getFilteredData } from "../_services/inventory.service";
import downloadSpinner from '../assets/images/downloadSpinner.gif';
import { CSVDownload } from "react-csv";
import user from '../models/User';
import { decryptData } from '.././Utils';

export default class ExportReport extends Component {
  constructor(props) {
   // user.toUser = JSON.parse(localStorage.getItem("UserMenu"));
   const salt = process.env.SALT || process.env.REACT_APP_KEY;
   const originalData = decryptData(localStorage.getItem("UserMenu"), salt);
   user.toUser = originalData;

    super(props);    
    this.state = {
      exportHeaders:[],
      inventoryList: [],
      showbutton:true
    };
    this.csvLink = React.createRef();
  }

  
  getExportColumnsHeaders() {
    let exportColumnsList = [
      { label: 'Customer Part Number', key: "CustomerPartNumber"},
      { label: 'Item Number', key: "DistributorPartnumber"},
      { label: 'Ware-house', key: "Warehouse"},
      { label: 'Stk', key: "StockNonstock"},
      { label: 'Description', key:  "PartDescription"},
      { label: 'Case/Pack', key:  "CasePack"},
      { label: 'Pack Description', key:  "PackDesc"},
      { label: 'Days Vendor Lead Time', key:  "VendorDaysLeadTime"}];
      if(user.toUser.CanViewInventoryPrices === "Y")
       exportColumnsList.push( { label: 'Price', key: "Price"});
      if( user.toUser.QuantityOnHandViewPermission === "Y")
       exportColumnsList.push({ label: 'Quantity On Hand', key:"QuantityOnHand"});
      if(user.toUser.QuantityOnHandViewPermission === "Y")
       exportColumnsList.push({ label: 'Total Product Value on hand', key:"TotalProductValueOnHand"});
      if(user.toUser.QuantityOnOrderViewPermission === "Y")
       exportColumnsList.push({ label: 'Quantity on Order', key:"QuantityOnOrder"});
      if(user.toUser.QuantityOnOrderViewPermission === "Y")
       exportColumnsList.push({ label: 'Estimated Delivery Date To Warehouse', key:"ScheduledDate"});
      if(user.toUser.CanViewInventoryDaysOnHand === "Y")
       exportColumnsList.push({ label: '"Inventory Days On Hand', key:"DaysOnHand"});
      if(user.toUser.MinMaxQuantityViewPermission === "Y")
       exportColumnsList.push({ label: 'Required Inventory Levels Min Max', key:"MaximumOrderQty"});
    
    return exportColumnsList;
  }


  getExportColumns() {
    let exportColumnsList = [
      "CustomerPartNumber",
      "DistributorPartnumber",
      "Warehouse",
      "StockNonstock",
      "PartDescription",
      "CasePack",
      "PackDesc",
      "VendorDaysLeadTime"];
      if(user.toUser.CanViewInventoryPrices === "Y")
       exportColumnsList.push( "Price");
      if( user.toUser.QuantityOnHandViewPermission === "Y")
       exportColumnsList.push("QuantityOnHand");
      if(user.toUser.QuantityOnHandViewPermission === "Y")
       exportColumnsList.push("TotalProductValueOnHand");
      if(user.toUser.QuantityOnOrderViewPermission === "Y")
       exportColumnsList.push("QuantityOnOrder");
      if(user.toUser.QuantityOnOrderViewPermission === "Y")
       exportColumnsList.push("ScheduledDate");
      if(user.toUser.CanViewInventoryDaysOnHand === "Y")
       exportColumnsList.push("DaysOnHand");
      if(user.toUser.MinMaxQuantityViewPermission === "Y")
       exportColumnsList.push("MaximumOrderQty");
    
    return exportColumnsList;
  }

  fetchData = () => {
    this.setState({ showbutton: false });
    getInventoryItems()
      .then((data) => {
        this.setState({ inventoryList: getFilteredData(data, this.getExportColumns()), exportHeaders:this.getExportColumnsHeaders() }, () => {
          setTimeout(() => {
            this.csvLink.current.link.click();
          }, 50);
          this.setState({ showbutton: true });
        });
      })
      .catch(console.log);
  };
  componentDidMount() {}
  render() {
    return (
      <div style={{ position: "absolute" }}>
        { this.state.showbutton? 
          <button id="downloadButton" style={{ border: "none" }} onClick={this.fetchData}>
          <img
            src={downloadAll}
            title="Download All Inventory Items."
            alt="Download Report"
          /> </button>: <img src={downloadSpinner} title="Please wait."  alt="Downloading Report" />
        }
      
       
       

        <CSVLink
          data={this.state.inventoryList}
          headers= {this.state.exportHeaders}
          filename="All-Inventory-Report.csv"
          className="hidden"
          ref={this.csvLink}
          target="_blank"
        />
        {/*
                    { this.state.inventoryList.length > 0 ? 
                        //    <CSVLink ref="csv" data={this.state.inventoryList} filename={'All-Inventory-Report.csv'}>
                        //         <img src={downloadAll} title="Download All Inventory Items."  alt="Download Report" />
                        //  </CSVLink>
                        <CSVDownload filename={'All-Inventory-Report.csv'} data={this.state.inventoryList}  target="_blank" /> 
                         : undefined}  */}
      </div>
    );
  }
}
