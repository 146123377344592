import React, { Component } from "react";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import downloadbutton from '../../assets/images/downloadbutton.gif';
import InventoryFilter from "./InventoryFilter";
import {getFilteredData} from "../../_services/inventory.service";
import {CSVLink} from 'react-csv';
import appConstants from "../../_services/applicationConstant";
import ExportInventoryReport from "../../components/ExportInventoryReport";
import {getInventoryData} from "../../_services/inventory.service";
import ESSModal from "../../components/ESSModal.jsx";
import user from '../../models/User';
import ESSProgressBar from "../../components/ESSProgressBar";
import { decryptData } from '../../Utils';

class Inventory extends Component {
  constructor(props) {
    //user.toUser = JSON.parse(localStorage.getItem("UserMenu"));
    const salt = process.env.SALT || process.env.REACT_APP_KEY;
    const originalData = decryptData(localStorage.getItem("UserMenu"), salt);
    user.toUser = originalData;
    super(props);
    this.state = {
      searchText:'',
      vendor:'', 
      searchType:'', 
      pageCount:'', 
      qualifier:'',
      searchClicked:false,
      exportHeaders:[],
      columnDefs: [
        { headerName: "Customer Part Number", sortable:true, field: "CustomerPartNumber", cellStyle: {
          textAlign: "center",
          borderRight: "0.1px solid lightgray",
        }, resizable: true, width:120,sortable:true,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: `${appConstants.isR3App? "R3 Item Number" : "Item Number"}`,  cellStyle: {
          textAlign: "center",
          borderRight: "0.1px solid lightgray",
        }, field: "DistributorPartnumber",sortable:true,resizable: true,  width:120,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: "Ware-house",  cellStyle: {
          textAlign: "left",
          borderRight: "0.1px solid lightgray",
        }, field: "Warehouse",sortable:true, resizable: true,  width:120,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: "Stk",  cellStyle: {
          textAlign: "left ",
          borderRight: "0.1px solid lightgray",
        }, field: "StockNonstock", resizable: true,  width:90,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: "Description",  cellStyle: {
          textAlign: "left",
          borderRight: "0.1px solid lightgray",
        }, field: "PartDescription", 
        valueFormatter: inventoryDescription,
        resizable: true ,  width:180,
         headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}`
        },
        { headerName: "Case/Pack",  cellStyle: {
          textAlign: "center",
          borderRight: "0.1px solid lightgray",
        }, field: "CasePack", sortable:true,resizable: true,  width:120,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: "Pack Description",  cellStyle: {
          textAlign: "left",
          borderRight: "0.1px solid lightgray",
        }, field: "PackDesc", resizable: true,  width:180,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: "Price", type: 'rightAligned', valueFormatter: currencyFormatter, 
        hide:  user.toUser.CanViewInventoryPrices == "Y" ? false: true,         
         cellStyle: {
          textAlign: "right",
          borderRight: "0.1px solid lightgray",
        }, field: "Price", resizable: true, width:100,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: "Quantity on hand", 
        hide:  user.toUser.QuantityOnHandViewPermission == "Y" ? false: true,         
        cellStyle: {
          textAlign: "right",
          borderRight: "0.1px solid lightgray",
        }, field: "QuantityOnHand", resizable: true,  width:100,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: "Total Product Value on hand",  valueFormatter: currencyFormatter,
        hide:  user.toUser.QuantityOnHandViewPermission == "Y" && user.toUser.CanViewInventoryPrices == 'Y' ? false: true,   
        cellStyle: {
          textAlign: "right",
          borderRight: "0.1px solid lightgray",
        }, field: "TotalProductValueOnHand", type: 'rightAligned', resizable: true,  width:80,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` }, 
        { headerName: "Quantity on Order", 
        hide:  user.toUser.QuantityOnOrderViewPermission == "Y" ? false: true, //
        cellStyle: {
          textAlign: "right",
          borderRight: "0.1px solid lightgray",
        }, 
        field: "QuantityOnOrder", resizable: true,  width:100,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },
        { headerName: "Estimated Delivery Date To Warehouse",  
        hide:  user.toUser.QuantityOnOrderViewPermission == "Y" ? false: true, 
        cellStyle: {
          textAlign: "center",
          borderRight: "0.1px solid lightgray",
        }, 
        field: "ScheduledDate", resizable: true, 
        cellRenderer: dateFormatter,
         width:100,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` },      
       
        { headerName: "Inventory Days On Hand",
        hide:  user.toUser.CanViewInventoryDaysOnHand == "Y" ? false: true, //
          cellStyle: {
          textAlign: "right",
          borderRight: "0.1px solid lightgray",
        }, field: "DaysOnHand", 
        resizable: true,  width:120, headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` } ,        
        { headerName: "Days Vendor Lead Time",  cellStyle: {
          textAlign: "right",
          borderRight: "0.1px solid lightgray",
        }, field: "VendorDaysLeadTime", resizable: true,  width:120, headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` } ,
        { headerName: "Required Inventory Levels Min Max",  
        hide:  user.toUser.MinMaxQuantityViewPermission == "Y" ? false: true, //CanViewInventoryDaysOnHand
        cellStyle: {
          textAlign: "center",
          borderRight: "0.1px solid lightgray",
        }, 
        field: "MaximumOrderQty",
        valueFormatter: minMaxOrderQnty,
         resizable: true,  width:100,headerClass: `${appConstants.isR3App? "gridheaderR3" : "gridheader"}` }
       
      ],
        
        rowData:[],
        inventoryList:[],
        show: false,
        userMessage: "",
    };
  }
  getExportColumns() {
    let exportColumnsList = [
      "CustomerPartNumber",
      "DistributorPartnumber",
      "Warehouse",
      "StockNonstock",
      "PartDescription",
      "CasePack",
      "PackDesc",
      "VendorDaysLeadTime"];
      if(user.toUser.CanViewInventoryPrices === "Y")
       exportColumnsList.push( "Price");
      if( user.toUser.QuantityOnHandViewPermission === "Y")
       exportColumnsList.push("QuantityOnHand");
      if(user.toUser.QuantityOnHandViewPermission === "Y")
       exportColumnsList.push("TotalProductValueOnHand");
      if(user.toUser.QuantityOnOrderViewPermission === "Y")
       exportColumnsList.push("QuantityOnOrder");
      if(user.toUser.QuantityOnOrderViewPermission === "Y")
       exportColumnsList.push("ScheduledDate");
      if(user.toUser.CanViewInventoryDaysOnHand === "Y")
       exportColumnsList.push("DaysOnHand");
      if(user.toUser.MinMaxQuantityViewPermission === "Y")
       exportColumnsList.push("MaximumOrderQty");
    
    return exportColumnsList;
  }


  getExportColumnsHeaders() {
    let exportColumnsList = [
      { label: 'Customer Part Number', key: "CustomerPartNumber"},
      { label: 'Item Number', key: "DistributorPartnumber"},
      { label: 'Ware-house', key: "Warehouse"},
      { label: 'Stk', key: "StockNonstock"},
      { label: 'Description', key:  "PartDescription"},
      { label: 'Case/Pack', key:  "CasePack"},
      { label: 'Pack Description', key:  "PackDesc"},
      { label: 'Days Vendor Lead Time', key:  "VendorDaysLeadTime"}];
      if(user.toUser.CanViewInventoryPrices === "Y")
       exportColumnsList.push( { label: 'Price', key: "Price"});
      if( user.toUser.QuantityOnHandViewPermission === "Y")
       exportColumnsList.push({ label: 'Quantity On Hand', key:"QuantityOnHand"});
      if(user.toUser.QuantityOnHandViewPermission === "Y")
       exportColumnsList.push({ label: 'Total Product Value on hand', key:"TotalProductValueOnHand"});
      if(user.toUser.QuantityOnOrderViewPermission === "Y")
       exportColumnsList.push({ label: 'Quantity on Order', key:"QuantityOnOrder"});
      if(user.toUser.QuantityOnOrderViewPermission === "Y")
       exportColumnsList.push({ label: 'Estimated Delivery Date To Warehouse', key:"ScheduledDate"});
      if(user.toUser.CanViewInventoryDaysOnHand === "Y")
       exportColumnsList.push({ label: '"Inventory Days On Hand', key:"DaysOnHand"});
      if(user.toUser.MinMaxQuantityViewPermission === "Y")
       exportColumnsList.push({ label: 'Required Inventory Levels Min Max', key:"MaximumOrderQty"});
    
    return exportColumnsList;
  }

  doSearch= (selectedsearchText, selectedvendor, selectedsearchType, selectedpageCount, selectedqualifier) => {
   this.setState({searchText:selectedsearchText,
    vendor:selectedvendor,
    searchType:selectedsearchType,
    pageCount:selectedpageCount,
    qualifier:selectedqualifier,
    searchClicked:true,
    rowData:[]
  });
 
    getInventoryData(selectedsearchText, selectedvendor, selectedsearchType, selectedpageCount, selectedqualifier)
    .then((data) => {      
     this.setState({inventoryList:getFilteredData(data, this.getExportColumns()), exportHeaders: this.getExportColumnsHeaders()})
      this.setState({ rowData: data, searchClicked:false })     
      if (data.length === 0) {
        this.setState({
          show: true,
          userMessage:
            "No Inventory Matched the selected criteria.",
        });
      }
      
    });
  }
  handleClose = () => this.setState({ show: false });

  onGridReady = params => {    
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit(); 
  };
  render() {
    const renderInventoryGrid = () => {
          return <div className= {`ag-theme-alpine ${
            appConstants.isR3App ? "gridMainR3" : "gridMain"
          }`} style={ {height: "85%" } }>
        {this.state.searchClicked?
        <ESSProgressBar/>
       :''}
          <AgGridReact          
              columnDefs={this.state.columnDefs}
              enableCellTextSelection={true}
              onGridReady={this.onGridReady} 
              rowData={this.state.rowData} 
               rowHeight="30"     headerHeight="48"   footerHeight="30"    
              >
          </AgGridReact>
        </div>;      
    }
    return (
      <div>
        <div          
          className={`container-fluid text-center ${
            appConstants.isR3App ? "contentPageR3" : "contentPage"
          }`}       style={{height:"100%"}}   
        >
        <ESSModal
            handleClose={this.handleClose}
            btn1Text="OK"
            userMessage={this.state.userMessage}
            isAlert="true"
            show={this.state.show}
          />
          <div
            className={` card text-left cardNoBorder ${
              appConstants.isR3App ? "logintitleR3" : "logintitle"
            }`} style={{ height: "80%"}}
          >
            <h6 className="card-header">Inventory List</h6>
            <div className="card-body" style={{ backgroundColor: "#fff", padding:"0.5rem" }}>
              <div className="row content" style={{height:"98%"}}>
                <div className="col-sm-12" style={{height:"98%"}}>
                  <div className="row" ref="filterComponent">
                    <div className="col-sm-10">
                      <InventoryFilter  doSearch={this.doSearch} />
                    </div>                    
                    <div className="col-sm-1" style={{paddingTop:"30px"}}>
                      <ExportInventoryReport />
                    </div>
                    <div className="col-sm-1" style={{paddingTop:"30px"}}>
                      { this.state.inventoryList.length > 0 ? 
                           <CSVLink ref="csv" data={this.state.inventoryList} headers={this.state.exportHeaders} filename={'Filtered-Inventory-Report.csv'}> 
                         <img src={downloadbutton} title="Download Filtered Inventory Items."  alt="Download Report" />
                          </CSVLink> 
                          : undefined} 
                    </div>
                  </div>
                  <div
                    className="row"                  
                    ref="gridComponent" style={{height:"98%"}}
                  >
                    <div className="col-sm-12" style={{height:"98%"}}>
                    {  renderInventoryGrid() }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Inventory;
function inventoryDescription(params){
  return params.data.PartDescription + " "+ params.data.PartDescription2;
}
function currencyFormatter(params) {
  if (params.value !== undefined && params.value !== "" && params.value !== null && params.value!== '0' &&  params.value!== '0.00') {
    if (parseFloat(params.value) < 0) return "-$" +  (parseFloat(params.value).toFixed(2) * -1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    else return "$" +  (parseFloat(params.value).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return "$0.00";
}

function minMaxOrderQnty(params){
 return  params.node.data.MinimumOrderQty + " " +params.node.data.MaximumOrderQty;  
}

function dateFormatter(param) {
  if(param.value != null &&  param.value != "" && param.value != undefined){
  var d = param.value.split(" ");
  var dateValue = d[0];
  return dateValue;
  }
  else return "";
}