import React, { Component } from 'react';
import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./components/Login";
import CustomerFooter from "./components/CustomerFooter";
import CustomerList from "./components/CustomerList";
import EmployeeList from "./components/EmployeeList";
import HeaderSelfService from "./components/HeaderSelfService";
import { Redirect } from "react-router-dom";
import {routes} from "./_services/routeConfiguration";
import ESSModal from "./components/ESSModal.jsx";
import checkJWTTokenExpiry from "./_helpers/auth-header";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: "false",
    };
    this.setSessionState = this.setSessionState.bind(this);
  }

  // checkJWTTokenExpiry = () => {
  //   var jwtTokenExp = localStorage.getItem("jwtExpiry");
  //   if (jwtTokenExp !== null && jwtTokenExp !== "") {
  //     var currentDate = new Date().toUTCString();
  //     var jwtDate = new Date(jwtTokenExp).toUTCString();
  //     if (new Date(jwtDate) < new Date(currentDate)) {  localStorage.clear(); return false;}
  //     else return true;
  //   } else return false;
  // };



  setSessionState = (validation) => {
    this.setState({ loggedIn: "true" });
  };

  handleClose = () => { this.setState({ show: false });  window.location = "./Login"; };

  render() {
    //document.addEventListener("contextmenu", (event) => event.preventDefault());

  //   window.addEventListener('blur', function()
  //   {
  //     const jwtValidcheck = checkJWTTokenExpiry();
  //     if(jwtValidcheck)
  //     {
  //      // this.alert("session expired");
  //     }
  //     else
  //     {
  //       window.location = "./ESSModal";
  //     }
  //  }, false);
   
  
    const jwtValid = checkJWTTokenExpiry();
    
    const Layout = ({ children }) => (
      <div>
        <header>
          <HeaderSelfService />
        </header>
        {children}
        <footer className="footerBunzl">
          <CustomerFooter />
        </footer>
      </div>
    );

    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route
              path="/Login"
              exact
              render={() => {
                return <Login setSessionStateFunc={this.setSessionState} />;
              }}
            />
            <Route
              path="/"
              exact
              render={() => {
                return <Login setSessionStateFunc={this.setSessionState} />;
              }}
            />
            <Route
              path="/CustomerList"
              exact
              render={() => {
                if ( jwtValid && (
                  this.state.loggedIn === "true" ||
                  localStorage.getItem("isSessionValid") == "true"
                )
                )
                  return <CustomerList fromMenu="false" />;
                //else return <Redirect to="/Login" />;
                else return <Redirect to="/ESSModal" />;
              }}
            />
             <Route
              path="/ESSModal"
              exact
              render={() => {                
                  return <ESSModal
                  handleClose={this.handleClose}
                  btn1Text="OK"
                  userMessage="Your Session has been expired. Please login again!"
                  isAlert="true"
                  show="true"
                />;
               
              }}
            />
             <Route
              path="/SSOModal"
              exact
              render={() => {                
                  return <ESSModal
                  handleClose={this.handleClose}
                  btn1Text="OK"
                  userMessage="An Error Occured.Please login manually!"
                  isAlert="true"
                  show="true"
                />;
               
              }}
            />
            <Route
              path="/EmployeeList"
              exact
              render={() => {
                if (  jwtValid && (
                  this.state.loggedIn === "true" ||
                  localStorage.getItem("isSessionValid") === "true"
                ))
                  return <EmployeeList fromMenu="false" />;
                //else return <Redirect to="/Login" />;
        
                else return <Redirect to="/ESSModal" />;
              }}
            />

            <Layout>
              {routes.map((item, i) =>  jwtValid && (
                this.state.loggedIn === "true" ||
                localStorage.getItem("isSessionValid") === "true" ) ? (
                  <Route exact {...item} />
                ) : (
                  //<Redirect to="/Login" />
                  <Redirect to="/ESSModal" />
                )
              )}
            </Layout>
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
