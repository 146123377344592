import api from './apiUrl';
import {authHeader,encodedURL} from '../_helpers/auth-header'

var identityKey=localStorage.getItem('identityKey')

export function getSearchTypes(isR3){
var searchTypes = [
    { value: "CP", label: 'Customer Part' },
    { value: "DS", label: 'Description' },    
    { value: "VP", label: 'Vendor Part' }   
];
if(isR3)
searchTypes.push( {value: "IT", label: 'R3 Item #'});
else
searchTypes.push( {value: "IT", label: 'Item #'});
return searchTypes;   
}

export function getPageNumbers(){
    var pageNumbers = [
        { value: "20", label: '20' },
        { value: "50", label: '50' },    
        { value: "100", label: '100' }   ,
        { value: "500", label: '500' }   ,
        { value: "1000", label: '1000' } ,
        { value: "5000", label: '5000' },
        { value: "10000", label: '10000' },
        { value: "15000", label: '15000' },
        { value: "32000", label: '32000' },

        ];
   
    return pageNumbers;   
}
function getDescription(desc)
{
    return  desc.replace(/"/g,'\""') ;
}

export function getFilteredData(data){
   let filteredData=[];
    data.forEach((item) => {
        filteredData.push({
            itemNumber: item.itemNumber,
            vendorPart: item.vendorPart,
            customerPartNumber: item.customerPartNumber,
            description: getDescription(item.description),
            flyerPage: item.flyerPage,
            sellCase: item.sellCase,
            sellUms: item.sellUms,
            sellPrice: currencyFormatter(item.sellPrice),
            listPrice: currencyFormatter(item.listPrice),
            pendPrice: currencyFormatter(item.pendPrice),
            effectiveDate: dateFormatter(item.effectiveDate),
            directValue:''
           });
          })
    return filteredData;   
}
    

export function getVendors(companyNumber, jwtToken) {
    return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/inventory/vendors?companyNumber=${companyNumber}`)}`, {
        method: 'GET',
        headers:authHeader()
    }).then(response => response.json());
}

export function getPriceListReport(){
    var urlparam='&numberRows='+32000
    urlparam+='&qualifier='+'contains'
   
        urlparam+='&vendor='+''
   
   
        urlparam+='&searchWordType='+''
   
        urlparam+='&searchWord='+''
   
    return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/pricelist/getpricelist?identityKey=${identityKey.trim()}&companyNumber=${localStorage.getItem('companyNumber')}&customerNumber=${localStorage.getItem('customerNumber')}&loginType=${localStorage.getItem('loginTypeCode')}${urlparam}`)}`, {
        method: 'GET',
        headers:authHeader()
    }).then(response => response.json());
  
  }

export function  getInventoryItems(url = '', jwtToken){
    return fetch(url, {
        method: 'GET',
        headers: authHeader()
    }).then(response => response.json());
}

export function getPriceListData(searchText, vendor, searchType, pageCount, qualifier)

{
    var urlparam='&numberRows='+pageCount
    urlparam+='&qualifier='+qualifier
    if(vendor!=='0')
    {
        urlparam+='&vendor='+vendor
    }
    if(searchType!=='none')
    {
        urlparam+='&searchWordType='+searchType
    }
    if(searchText!=='')
    {
        urlparam+='&searchWord='+searchText
    }
    return fetch(`${api.proxyUrl}${api.url}${encodedURL(`/pricelist/getpricelist?identityKey=${identityKey.trim()}&companyNumber=${localStorage.getItem('companyNumber')}&customerNumber=${localStorage.getItem('customerNumber')}&loginType=${localStorage.getItem('loginTypeCode')}${urlparam}`)}`, {
        method: 'GET',
        headers:authHeader()
    }).then(response => response.json());
}
    function currencyFormatter(value) {
    if (value !== undefined && value !== "") {
      if (parseFloat(value) < 0) return "-$" + (parseFloat(value).toFixed(2) * -1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      else return "$" + (parseFloat(value).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return "";
  }
function dateFormatter(inputDate) {
    if(inputDate != null &&  inputDate != "" && inputDate != undefined){
    var d = inputDate.split(" ");
    var dateValue = d[0];
    return dateValue;
    }
    else return "";
  }
    
    
